import React, { Component } from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import MeetingKeynoteSpeakerForm from "./MeetingKeynoteSpeakerForm";
import TitleHeader from "../../../components/TitleHeader/TitleHeader";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";
import swal from "sweetalert";
import axios from "../../../axios";
import { Alert, Auth } from "../../Service/Services";
import { CompanyAPI } from "../../../api";
import { array } from "prop-types";
import EmailToSupervisor from "./EmailToSupervisor";

class MeetingKeynoteSpeakerIndex extends Component {
  constructor(props) {
    super(props);
    this.datatable_element = React.createRef();
  }

  state = {
    loading: false,
    id: null,
    name: "",
    redirect: false,
    redirect_to_url: "",
    selected_items: [],
  };

  onPrintAllCard = () => {
    const print_url = axios.defaults.baseURL + "/keynoteprintcard";
    window.open(print_url, "_blank");
  };

  onInsert = () => {
    swal({
      content: {
        element: "input",
        attributes: {
          placeholder:
            "ชื่อและนามสกุลของวิทยากรที่ต้องการเพิ่ม เช่น สมชาย แสนดี",
        },
      },
    }).then((name) => {
      if (name !== null && name.trim() !== "") {
        this.setState({ loading: true });

        // Check Keynote exists by name
        const url = "getkeynoteidbyname";
        axios.post(url, { name }).then((res) => {
          this.setState({
            loading: false,
            id: res.data.id,
            name: name,
            redirect: true,
            redirect_to_url: "/create/" + name,
          });
        });
      } else {
        Alert.error("กรุณาระบุชื่อของวิทยากร");
      }
    });
  };

  onPrintReportInvitationClick = (criteria) => {
    const url = axios.defaults.baseURL + "/report/keynoteinvitation";

    window.open(url, "_blank");
  };

  onPrintReportClick = (criteria) => {
    const url = axios.defaults.baseURL + "/report/keynotereport";

    window.open(url, "_blank");
  };

  onPrintOutgoingsClick = (criteria) => {
    const url = axios.defaults.baseURL + "/report/keynotereportoutgoings";

    window.open(url, "_blank");
  };

  onPrintClick = (params) => {
    const key = params.key;
    const url = axios.defaults.baseURL + "/report/keynoteletter/" + key;

    window.open(url, "_blank");
  };

  onAttachFile = () => {
    this.setState({ redirect: true, redirect_to_url: "/attach" });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      let url = this.props.url;

      if (this.state.id !== null) {
        url += "/" + (this.state.id + "/edit");
      } else {
        if (this.state.redirect_to_url !== "") {
          url += this.state.redirect_to_url;
        }
      }

      return <Redirect to={url} />;
    }

    return;
  };

  sendInvitation = () => {
    if (this.state.selected_items.length === 0) {
      Alert.error("กรุณาเลือกวิทยาการที่ต้องการส่งคำเชิญ");
    } else {
      Alert.confirm("คุณแน่ใจไหมที่จะส่งจดหมายเชิญ").then((res) => {
        if (res === true) {
          this.setState({ loading: true });

          const selected_items = this.state.selected_items
            .map((i) => i.key)
            .join(",");
          const url = "keynotemail/" + selected_items;
          axios.get(url).then((res) => {
            this.setState({
              loading: false,
            });

            const send_success_amount = res.data.send_success_amount;

            Alert.done(
              "จดหมายเชิญถูกส่งเรียบร้อยทั้งหมด " +
                send_success_amount +
                " ฉบับ"
            ).then((res) => {
              this.datatable_element.current.refresh();
            });
          });
        }
      });
    }
  };

  render() {
    const redirect = this.getRedirect();
    const columns = [
      {
        label: "รหัสวิทยากร",
        align: "center",
        name: "id",
        search_type: "string",
      },
      {
        label: "ชื่อ - นามสกุล",
        align: "left",
        name: "first_name_th",
        search_type: "string",
      },
      {
        label: "สถานะการส่งบัตรเชิญ",
        align: "center",
        name: "invitation_flag",
      },
      {
        label: "สถานะออกจดหมายต้นสังกัด",
        align: "center",
        name: "company_flag",
      },
      { label: "แสดงหน้าหลัก", align: "center", name: "feature" },
      {
        label: "บัตรเข้าร่วมงาน",
        align: "center",
        name: "card",
        sortable: false,
        search: false,
      },
    ];

    let invite_button = "";
    let allow_checkbox = false;
    let allow_trash = false;

    const is_admin = Auth.isRole("admin");

    if (is_admin) {
      invite_button = (
        <button
          type="button"
          onClick={this.sendInvitation}
          className="btn btn-primary btn-lg btn-block mt-3 mb-0"
        >
          <i className="fa fa-paper-plane-o"></i> ส่งจดหมายเชิญ
        </button>
      );
      allow_checkbox = true;
      allow_trash = true;
    }

    const api_url = this.props.url;

    const buttons = [
      {
        label: '<i className="fa fa-file"></i> จัดการเอกสารแนบ',
        className: "btn btn-info",
        onClick: this.onAttachFile,
      },
      {
        label: '<i className="fa fa-plus"></i> เพิ่มข้อมูล',
        className: "btn btn-success",
        onClick: this.onInsert,
      },
      {
        label:
          '<i className="fa fa-id-card-o"></i> พิมพ์บัตรเข้าร่วมงานทั้งหมด',
        className: "btn btn-warning",
        onClick: this.onPrintAllCard,
      },
      {
        is_multiple: true,
        label: '<i class="fa fa-print"></i> พิมพ์รายงาน',
        className: "btn btn-primary",
        name: "btn_print_report",
        datasource: [
          {
            label: '<i className="fa fa-print"></i> รายงานต้นสังกัด',
            className: "btn btn-primary",
            onClick: this.onPrintReportInvitationClick,
          },
          {
            label: '<i className="fa fa-print"></i> รายงาน',
            className: "btn btn-warning",
            onClick: this.onPrintReportClick,
          },
          {
            label: '<i className="fa fa-print"></i> รายงานค่าใช้จ่าย',
            className: "btn btn-warning",
            onClick: this.onPrintOutgoingsClick,
          },
        ],
      },
      <EmailToSupervisor />,
    ];

    return (
      <BoxContainer loading={this.state.loading}>
        {redirect}
        {invite_button}
        <DataTable
          ref={this.datatable_element}
          buttons={buttons}
          allow_trash={allow_trash}
          onPrintClick={this.onPrintClick}
          allow_insert={false}
          columns={columns}
          allow_checkbox={allow_checkbox}
          allow_active={true}
          allow_print={true}
          url={api_url}
          onSelectItem={(items) => this.setState({ selected_items: items })}
        ></DataTable>
      </BoxContainer>
    );
  }
}

export default MeetingKeynoteSpeakerIndex;
