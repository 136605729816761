import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import FrontendGalleryForm from './FrontendGalleryForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class FrontendGallery extends Component {
    render() {
        const columns = [
            { label: 'ชื่ออัลบั้ม', align: 'left', name: 'name', search_type: 'string' },
            { label: 'ภาพหน้าปก', align: 'center', name: 'thubmnail', sortable: false, },
        ];

        const api_url = '/master/gallery';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ภาพกิจกรรม' program_type='Frontend'></TitleHeader>
                    
                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable columns={columns} allow_active={true} url={api_url}></DataTable>)} />
                    
                    <Route path={`${this.props.match.path}/create`} component={props => (<FrontendGalleryForm {...props} url={api_url}></FrontendGalleryForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<FrontendGalleryForm {...props} url={api_url}></FrontendGalleryForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default FrontendGallery
