import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import FrontendAgendaForm from './FrontendAgendaForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class FrontendAgenda extends Component {
    render() {
        const columns = [
            { label: 'ชื่อ', align: 'left', name: 'name', search_type: 'string' },
            { label: 'ไฟล์', align: 'left', name: 'link' },
        ];

        const api_url = '/master/agenda';
        // const datasource = [
        //     { key: 1, active: true, value: ['Agenda'] },
        // ];

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='กำหนดการดำเนินงาน (เอกสารแนบ)' program_type='Frontend'></TitleHeader>
                    
                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable columns={columns} allow_active={true} url={api_url}></DataTable>)} />
                    
                    <Route path={`${this.props.match.path}/create`} component={props => (<FrontendAgendaForm {...props} url={api_url}></FrontendAgendaForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<FrontendAgendaForm {...props} url={api_url}></FrontendAgendaForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default FrontendAgenda
