import React, { Component } from "react";
// import logo from '../assets/logo.svg';
// import { Router, Route } from 'react-router-dom';
import { Router, Route, Switch } from "react-router-dom";
import UserContextProvider from "../context/UserContextProvider";
import "./App.css";
import Sidebar from "./Sidebar/Sidebar";
import Navbar from "./Navbar/Navbar";
import Dashboard from "../pages/Dashboard/Dashboard";
import AdminTools from "../pages/AdminTools/AdminTools";
import AdminSite from "../pages/AdminSite/AdminSite";

import Login from "../pages/Authentication/Login/Login";
import ForgotPassword from "../pages/Authentication/ForgotPassword/ForgotPassword";
import ChangePassword from "../pages/Authentication/ChangePassword/ChangePassword";
import Profile from "../pages/Profile/Profile";
import EditPassword from "../pages/Profile/ChangePassword";
import AuthToken from "../pages/Authentication/AuthToken";
import Notfound from "../pages/Authentication/Service/404";

import UserInternal from "../pages/User/UserInternal/UserInternal";
import UserReviewer from "../pages/User/UserReviewer/UserReviewer";
import UserExternal from "../pages/User/UserExternal/UserExternal";
import UserPermission from "../pages/User/UserPermission/UserPermission";
import UserVIP from "../pages/User/UserVIP/UserVIP";
import UserFee from "../pages/User/UserFee/UserFee";
import UserWorkplace from "../pages/User/UserWorkplace/UserWorkplace";
import UserCard from "../pages/User/UserCard/UserCard";
import UserCertificate from "../pages/User/UserCertificate/UserCertificate";
import UserPromotion from "../pages/User/UserPromotion/UserPromotion";
import UserBank from "../pages/User/UserBank/UserBank";
import UserExternalMember from "../pages/User/UserExternal/UserExternalMember";
import MemberGroup from "../pages/User/MemberGroup/MemberGroup";
import MemberGroupERP from "../pages/User/MemberGroupERP/MemberGroupERP";
import OnlineTheme from "../pages/User/OnlineTheme/OnlineTheme";

import FrontendAgenda from "../pages/Frontend/FrontendAgenda/FrontendAgenda";
import FrontendBanner from "../pages/Frontend/FrontendBanner/FrontendBanner";
import FrontendNews from "../pages/Frontend/FrontendNews/FrontendNews";
import FrontendPopup from "../pages/Frontend/FrontendPopup/FrontendPopup";
import FrontendSponsor from "../pages/Frontend/FrontendSponsor/FrontendSponsor";
import FrontendGallery from "../pages/Frontend/FrontendGallery/FrontendGallery";
import FrontendVenue from "../pages/Frontend/FrontendVenue/FrontendVenue";
import FrontendHotel from "../pages/Frontend/FrontendHotel/FrontendHotel";
import FrontendFAQ from "../pages/Frontend/FrontendFAQ/FrontendFAQ";

import AbstractCategory from "../pages/Abstract/AbstractCategory/AbstractCategory";
import AbstractPeriod from "../pages/Abstract/AbstractPeriod/AbstractPeriod";
// import AbstractCheck from '../pages/Abstract/AbstractCheck/AbstractCheck';
import AbstractReviewer from "../pages/Abstract/AbstractReviewer/AbstractReviewer";
import AbstractSummary from "../pages/Abstract/AbstractSummary/AbstractSummary";

import AbstractHACC from "../pages/Abstract/AbstractHACC/AbstractHACC";
import AbstractBH from "../pages/Abstract/AbstractBH/AbstractBH";

import PublishCategory from "../pages/Publish/PublishCategory/PublishCategory";
import PublishDocument from "../pages/Publish/PublishDocument/PublishDocument";

import MeetingRoom from "../pages/Meeting/MeetingRoom/MeetingRoom";
import MeetingSection from "../pages/Meeting/MeetingSection/MeetingSection";
import MeetingKeynoteSpeaker from "../pages/Meeting/MeetingKeynoteSpeaker/MeetingKeynoteSpeaker";
import MeetingWorkshop from "../pages/Meeting/MeetingWorkshop/MeetingWorkshop";
import MeetingStaffPresentation from "../pages/Meeting/MeetingStaffPresentation/MeetingStaffPresentation";
import MeetingStaffAbstraction from "../pages/Meeting/MeetingStaffAbstraction/MeetingStaffAbstraction";

import OtherEmailTemplate from "../pages/Other/OtherEmailTemplate/OtherEmailTemplate";
import OtherSetting from "../pages/Other/OtherSetting/OtherSetting";

import OfflineBackup from "../pages/Offline/OfflineBackup/OfflineBackup";
import OfflineCheckIn from "../pages/Offline/OfflineCheckIn/OfflineCheckIn";
import OfflineCheckInRoom from "../pages/Offline/OfflineCheckInRoom/OfflineCheckInRoom";
import OfflineMonitor from "../pages/Offline/OfflineMonitor/OfflineMonitor";

import LogFrontend from "../pages/Log/LogFrontend/LogFrontend";
import LogBackend from "../pages/Log/LogBackend/LogBackend";
import Reviewer from "../pages/Reviewer/Reviewer";
import SuperReviewer from "../pages/SuperReviewer/SuperReviewer";
import KeynoteSpeaker from "../pages/KeynoteSpeaker/KeynoteSpeaker";
import Asset from "../pages/Asset/Asset";
import Site from "../pages/Site/Site";

import ReportPaymentPaid from "../pages/Report/ReportPaymentPaid/ReportPaymentPaid";
import ReportQuestionnaire from "../pages/Report/ReportQuestionnaire/ReportQuestionnaire";

// import Sample from '../pages/sample';
import { authenticationService } from "../pages/Authentication/Service/authentication.service";
import { PrivateRoute } from "./PrivateRoute";
import { history } from "../pages/Authentication/_helpers/";
import menuService from "../pages/Authentication/Service/menu.service";
import Agreement from "../pages/Agreement/Agreement";
const routes = [
  {
    code: "MT33",
    path: "/master/site",
    component: AdminSite,
  },
  {
    code: "MT02",
    path: "/admin/internal",
    component: UserInternal,
  },
  {
    code: "MT03",
    path: "/admin/ireviewer",
    component: UserReviewer,
  },
  {
    code: "MT04",
    path: "/user/external",
    component: UserExternal,
  },
  {
    code: "MT05",
    path: "/user/membergroup",
    component: MemberGroup,
  },
  {
    code: "MT06",
    path: "/user/permission",
    component: UserPermission,
  },
  {
    code: "MT07",
    path: "/master/guest",
    component: UserVIP,
  },
  {
    code: "MT08",
    path: "/master/paymentperiod",
    component: UserFee,
  },
  {
    code: "MT09",
    path: "/master/company",
    component: UserWorkplace,
  },
  {
    code: "MT10",
    path: "/master/card",
    component: UserCard,
  },
  {
    code: "MT11",
    path: "/admin/bank",
    component: UserBank,
  },
  {
    code: "MT12",
    path: "/master/sectiononline",
    component: OnlineTheme,
  },
  {
    code: "MT14",
    path: "/master/agenda",
    component: FrontendAgenda,
  },
  {
    code: "MT15",
    path: "/master/banner",
    component: FrontendBanner,
  },
  {
    code: "MT16",
    path: "/master/news",
    component: FrontendNews,
  },
  {
    code: "MT17",
    path: "/master/popup",
    component: FrontendPopup,
  },
  {
    code: "MT18",
    path: "/master/sponsor",
    component: FrontendSponsor,
  },
  {
    code: "MT19",
    path: "/master/gallery",
    component: FrontendGallery,
  },
  {
    code: "MT20",
    path: "/master/venue",
    component: FrontendVenue,
  },
  {
    code: "MT21",
    path: "/master/hotel",
    component: FrontendHotel,
  },
  {
    code: "MT22",
    path: "/master/faq",
    component: FrontendFAQ,
  },
  {
    code: "TS02",
    path: "/master/abstract_topic",
    component: AbstractCategory,
  },
  {
    code: "TS03",
    path: "/master/abstract_period",
    component: AbstractPeriod,
  },
  {
    code: "TS04",
    path: "/admin/abstract",
    component: AbstractReviewer,
  },
  {
    code: "TS05",
    path: "/admin/abstractsummary",
    component: AbstractSummary,
  },
  {
    code: "TS06",
    path: "/admin/abstract_hacc",
    component: AbstractHACC,
  },
  {
    code: "TS07",
    path: "/admin/abstract_bh",
    component: AbstractBH,
  },
  {
    code: "MT24",
    path: "/publish/category",
    component: PublishCategory,
  },
  {
    code: "MT25",
    path: "/publish/document",
    component: PublishDocument,
  },
  {
    code: "MT32",
    path: "/other/emailtemplate",
    component: OtherEmailTemplate,
  },
  {
    code: "PC02",
    path: "/offline/backup",
    component: OfflineBackup,
  },
  {
    code: "PC03",
    path: "/offline/checkin",
    component: OfflineCheckIn,
  },
  {
    code: "PC04",
    path: "/offline/checkinroom",
    component: OfflineCheckInRoom,
  },
  {
    code: "RP01",
    path: "/offline/monitor",
    component: OfflineMonitor,
  },
  {
    code: "RP03",
    path: "/log/backend",
    component: LogBackend,
  },
  {
    code: "RP04",
    path: "/log/frontend",
    component: LogFrontend,
  },
  {
    code: "RP06",
    path: "/report/paymentpaid",
    component: ReportPaymentPaid,
  },

  {
    code: "MT28",
    path: "/meeting/section",
    component: MeetingSection,
  },
  {
    code: "MT30",
    path: "/meeting/workshop",
    component: MeetingWorkshop,
  },
  {
    code: "MT34",
    path: "/master/promotion",
    component: UserPromotion,
  },
  {
    code: "RP07",
    path: "/report/questionnaire",
    component: ReportQuestionnaire,
  },
  {
    code: "MT35",
    path: "/admin/membergrouperp",
    component: MemberGroupERP,
  },
];

const fixed_routes = [
  {
    path: "/",
    exact: true,
    component: Dashboard,
  },
  {
    path: "/profile",
    exact: true,
    component: Profile,
  },
  {
    path: "/profile/changepassword",
    component: EditPassword,
  },
  {
    path: "/forgotpassword",
    component: ForgotPassword,
  },
  {
    path: "/changepassword/token=:token",
    component: ChangePassword,
  },
  {
    path: "/reviewer",
    component: Reviewer,
  },
  {
    path: "/superreviewer",
    component: SuperReviewer,
  },
  {
    path: "/keynotespeaker",
    component: KeynoteSpeaker,
  },
  {
    path: "/meeting/present",
    component: MeetingStaffPresentation,
  },
  {
    path: "/meeting/abstract",
    component: MeetingStaffAbstraction,
  },
  {
    path: "/meeting/keynote",
    component: MeetingKeynoteSpeaker,
  },
  {
    path: "/meeting/room",
    component: MeetingRoom,
  },
  {
    path: "/site",
    component: Site,
  },
  {
    path: "/agreement",
    component: Agreement,
    exact: true,
  },
  {
    path: "/admintools",
    component: AdminTools,
    exact: true,
  },
  {
    path: "*",
    component: Notfound,
    exact: true,
  },
];

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      routes: [],
      routes_without_theme: [
        {
          path: "/",
          exact: true,
          component: Login,
        },
        {
          path: "/login",
          component: Login,
          exact: true,
        },
        {
          path: "/forgotpassword",
          component: ForgotPassword,
        },
        {
          path: "/changepassword/token=:token",
          component: ChangePassword,
        },
        {
          path: "/profile",
          component: Profile,
        },
        {
          path: "/authtoken/:type/:token",
          component: AuthToken,
        },
        {
          path: "*",
          component: Notfound,
          exact: true,
        },
      ],
    };
  }
  componentDidMount() {
    authenticationService.currentUser.subscribe((x) =>
      this.setState({ currentUser: x })
    );

    const authen_routes = menuService
      .getCurrentMenu()
      .map((menu) => {
        const route = routes.find((f) => f.code === menu.code);
        return route;
      })
      .filter((f) => f !== undefined);

    this.setState({ routes: [...authen_routes, ...fixed_routes] });
  }

  render() {
    const { currentUser } = this.state;
    var container;
    if (currentUser) {
      const menu = this.state.routes.map((route, index) => (
        <PrivateRoute
          key={index}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ));

      container = (
        <div id="wrapper">
          <Sidebar></Sidebar>
          <div id="page-wrapper" className="gray-bg">
            <div className="row border-bottom">
              <Navbar></Navbar>
            </div>
            <Switch>{menu}</Switch>
            <div className="footer">
              <strong>Copyright</strong> HA Forum &copy; 2019
            </div>
          </div>
        </div>
      );

      return (
        <UserContextProvider>
          <Router history={history}>{container}</Router>
        </UserContextProvider>
      );
    } else {
      container = this.state.routes_without_theme.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ));

      return (
        <UserContextProvider>
          <Router history={history}>
            <Switch>{container}</Switch>
          </Router>
        </UserContextProvider>
      );
    }
  }
}
export default App;
