import React, { Component } from 'react'
import DataForm from './../../../components/DataForm/DataForm';
import FormInput from './../../../components/DataForm/FormInput';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import { Services } from '../../Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import MeetingSectionKeynote from './MeetingSectionKeynote';

class MeetingSectionForm extends Component {
  // Set default state
  state = {
    data:{
      code: '',
      name: '',
      date: '',
      start_time: '',
      end_time: '',
      remark: '',
      active: true,
      publish: false,
      keynote_sections: [],
    },
    datasource: {
      keynote: [],
    },
    redirect: false,
    loading: false,
  }

  retrieveData = (id, datasource) => {
    axios.get(`${this.props.url}/${id}`).then(res => {
      const data = res.data;
      const new_data = {
        id: data.id,
        code: data.code,
        name: data.name,
        date: data.date_text,
        start_time: data.start_time,
        end_time: data.end_time,
        remark: data.remark,
        active: data.active,
        publish: data.publish,
        keynote_sections: data.keynote_sections,
      }

      this.setState({ loading: false, data: new_data, datasource });
    });
  }

  init = () => {
    const promise = new Promise((resolve) => {
      const params = {
        tables: [
          {name: 'keynote'},
        ],
      }
  
      axios.post('datasource', params).then(res => {
        const keynote = res.data.keynote.map( item => {
          return {
            key: item.id,
            label: item.name,
          };
        });
  
        const datasource = {
          keynote,
        };

        resolve(datasource);
      });
    });

    return promise;
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;

    this.init().then( datasource => {
      if(id !== undefined){
        this.retrieveData(id, datasource);
      }else{
        this.setState({ loading: false, datasource });
      }
    });
  }

  getValue = target => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target
      }
    });
  }

  onSubmit = e => {
    const params = this.state.data;
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    Services.submitRequest({ params, url }).then( res => { this.setState({redirect, loading: false}) }).catch( errors => {this.setState({ errors,loading: false })});
  }

  onKeynoteChange = target => {
    let data = { ...this.state.data };
    data.keynote_sections = target;

    this.setState({ data });
  }

  getRedirect = () => {
    const redirect = this.state.redirect;
    
    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  }

  getFields = () => {
    var fields = [
      { label: 'รหัสหัวข้อ', name: 'code', type: 'textbox', required: true, placeholder: 'A1-200', value: this.state.data.code},
      { label: 'หัวข้อการประชุม', name: 'name', type: 'textbox', required: true, remark: 'หัวข้อการประชุมไม่ต้องมีรหัสนำหน้า', value: this.state.data.name},
      { label: 'วันที่', name: 'date', type: 'datepicker', required: true, value: this.state.data.date},
      { label: 'เวลาเริ่ม', name: 'start_time', type: 'timepicker', required: true, value: this.state.data.start_time},
      { label: 'เวลาสิ้นสุด', name: 'end_time', type: 'timepicker', required: true, value: this.state.data.end_time},
      { label: 'หมายเหตุ', name: 'remark', type: 'textarea', required: false, value: this.state.data.remark},
      { label: 'เผยแพร่', name: 'publish', type: 'switch', required: false, value: this.state.data.publish },
      { label: 'Active', name: 'active', type: 'switch', required: false, value: this.state.data.active },
    ];

    return fields;
  }

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
        >
          <div className="form-group">
            <label className="col-form-label field-required">วิทยากร</label> 
            
            <MeetingSectionKeynote
              datasource={this.state.datasource.keynote}
              onChange={this.onKeynoteChange}
              data={this.state.data.keynote_sections}
            ></MeetingSectionKeynote>
          </div>
        </DataForm>
      </BoxContainer>
    )
  }
}

MeetingSectionForm.setDefaultProps = {
  url: '',
}

export default MeetingSectionForm
