import React, { Component } from "react";
import DataForm from "./../../../components/DataForm/DataForm";
import FormInput from "./../../../components/DataForm/FormInput";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import { Services } from "../../Service/Services";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";
import { data } from "jquery";

class UserPermissionForm extends Component {
  // Set default state
  state = {
    data: {
      name: "",
      active: true,
      permission_menus: [],
    },
    datasource: {
      menu: [],
    },
    redirect: false,
    loading: true,
  };

  init = () => {
    const promise = new Promise((resolve) => {
      const params = {
        tables: [{ name: "menu" }],
      };

      axios.post("datasource", params).then((res) => {
        const menu = res.data.menu.map((item) => {
          return {
            key: item.id,
            label: item.label,
            is_main: item.is_main,
            main_menu_id: item.main_menu_id,
            icon_class: item.icon_class,
          };
        });

        const datasource = {
          menu,
        };

        resolve(datasource);
      });
    });

    return promise;
  };

  retrieveData = (id, datasource) => {
    axios.get(`${this.props.url}/${id}`).then((res) => {
      const data = res.data.entity;
      data.permission_menus = data.permission_menus.map((m) => m.menu_id);
      const loading = false;
      this.setState({ loading, data, datasource });
    });
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;

    this.init().then((datasource) => {
      if (id !== undefined) {
        this.retrieveData(id, datasource);
      } else {
        const data = { ...this.state.data };
        data.permission_menus = datasource.menu.map((m) => m.key);

        this.setState({ loading: false, datasource, data });
      }
    });
  };

  getValue = (target) => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
  };

  onSubmit = (e) => {
    const params = this.state.data;
    const url = this.props.url;
    const redirect = true;

    this.setState({ loading: true });
    Services.submitRequest({ params, url })
      .then((res) => {
        this.setState({ redirect, loading: false });
      })
      .catch((errors) => {
        this.setState({ errors, loading: false });
      });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  };

  getFields = () => {
    const id = this.state.id;

    var fields = [
      {
        label: "สิทธิ์",
        name: "name",
        type: "textbox",
        required: true,
        value: this.state.data.name,
      },
      {
        label: "Active",
        name: "active",
        type: "switch",
        value: this.state.data.active,
      },
    ];

    return fields;
  };

  onActiveMenu = (active, menu_id, is_main, main_menu_id = null) => {
    const data = { ...this.state.data };

    if (is_main === true) {
      const child_menus = this.state.datasource.menu
        .filter((f) => f.main_menu_id === menu_id)
        .map((m) => m.key);

      if (active === true) {
        if (data.permission_menus.indexOf(menu_id) === -1)
          data.permission_menus.push(menu_id);

        child_menus.map((child_menu_id) => {
          if (data.permission_menus.indexOf(child_menu_id) === -1)
            data.permission_menus.push(child_menu_id);
        });
      } else {
        console.log("child_menus", child_menus);
        data.permission_menus = data.permission_menus.filter(
          (f) => f !== menu_id && child_menus.indexOf(f) === -1
        );
      }
    } else {
      if (active === true && data.permission_menus.indexOf(menu_id) === -1) {
        data.permission_menus.push(menu_id);

        if (data.permission_menus.indexOf(main_menu_id) === -1)
          data.permission_menus.push(main_menu_id);
      } else {
        data.permission_menus = data.permission_menus.filter(
          (f) => f !== menu_id
        );

        const child_menus = this.state.datasource.menu
          .filter((f) => f.main_menu_id === main_menu_id)
          .map((m) => m.key);

        if (!data.permission_menus.find((f) => child_menus.indexOf(f) >= 0)) {
          data.permission_menus = data.permission_menus.filter(
            (f) => f !== main_menu_id
          );
        }
      }
    }

    this.setState({ data });
  };

  getPermissionField = () => {
    const header = (
      <tr>
        <th>เมนู</th>
        <th>Active</th>
      </tr>
    );

    const permission = this.state.datasource.menu.map((item) => {
      const label = item.label;
      const permission_menus = this.state.data.permission_menus;
      var child = [];
      var parent;
      const switchFieldActive = {
        name: "menu",
        type: "switch",
        value: permission_menus.indexOf(item.key) >= 0,
      };

      if (item.is_main === true) {
        parent = (
          <tr className="">
            <td>
              <i className={item.icon_class}></i> {label}
            </td>
            <td className="text-center">
              <FormInput
                getValue={(value) =>
                  this.onActiveMenu(value.menu, item.key, item.is_main)
                }
                field={switchFieldActive}
              ></FormInput>
            </td>
          </tr>
        );
      } else {
        parent = (
          <tr>
            <td className="pl-5">
              <i className="fa fa-arrow-right"></i> {label}
            </td>
            <td className="text-center">
              <FormInput
                getValue={(value) =>
                  this.onActiveMenu(
                    value.menu,
                    item.key,
                    item.is_main,
                    item.main_menu_id
                  )
                }
                field={switchFieldActive}
              ></FormInput>
            </td>
          </tr>
        );
      }

      return (
        <React.Fragment>
          {parent}
          {child}
        </React.Fragment>
      );
    });

    return (
      <table className="table">
        <thead>{header}</thead>
        <tbody>{permission}</tbody>
      </table>
    );
  };

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;
    const permission = this.getPermissionField();

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
        >
          <div>{permission}</div>
        </DataForm>
      </BoxContainer>
    );
  }
}

UserPermissionForm.setDefaultProps = {
  url: "",
};

export default UserPermissionForm;
