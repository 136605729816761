import axios from "../axios";

const sendToERP = (listId = []) => {
  const promise = new Promise((resolve, reject) => {
    const url = "erp/sendselected";
    const params = {
      id: listId,
    };
    axios
      .post(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((res) => {
        reject(res);
      });
  });

  return promise;
};

const sendToERPSingle = (id) => {
  const promise = new Promise((resolve, reject) => {
    const url = `erp/sendsingle/${id}`;
    axios
      .post(url)
      .then((res) => {
        resolve(res);
      })
      .catch((res) => {
        reject(res);
      });
  });

  return promise;
};

export default {
  sendToERP,
  sendToERPSingle,
};
