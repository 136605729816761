import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import MeetingSectionForm from "./MeetingSectionForm";
import TitleHeader from "../../../components/TitleHeader/TitleHeader";

class MeetingSection extends Component {
  render() {
    const columns = [
      {
        label: "รหัสหัวข้อ",
        align: "center",
        name: "name",
        search_type: "string",
      },
      {
        label: "หัวข้อการประชุม",
        align: "left",
        name: "code",
        search_type: "string",
      },
      { label: "วันที่ เวลา", align: "center", name: "date" },
    ];

    const api_url = "/meeting/section";

    return (
      <BrowserRouter>
        <div>
          <TitleHeader
            program_name="หัวข้อประชุม"
            program_type="ห่องประชุม"
          ></TitleHeader>

          <button className="d-none btn btn-primary btn-lg btn-block mt-3">
            <i className="fa fa-cloud-upload"></i> เผยแพร่กำหนดการ
          </button>

          <Route
            exact
            path={`${this.props.match.path}/`}
            component={() => (
              <DataTable
                columns={columns}
                allow_active={true}
                url={api_url}
              ></DataTable>
            )}
          />

          <Route
            path={`${this.props.match.path}/create`}
            component={(props) => (
              <MeetingSectionForm {...props} url={api_url}></MeetingSectionForm>
            )}
          />
          <Route
            path={`${this.props.match.path}/:id/edit`}
            component={(props) => (
              <MeetingSectionForm {...props} url={api_url}></MeetingSectionForm>
            )}
          />
        </div>
      </BrowserRouter>
    );
  }
}

export default MeetingSection;
