import React, { Component } from "react";
import FormInput from "./../../../components/DataForm/FormInput";

class MeetingKeynoteSpeakerSection extends Component {
  getValue = (target, index) => {
    const new_data = { ...this.props.data };

    const name = Object.keys(target)[0];
    const value = Object.values(target)[0];
    new_data.keynote_sections[index][name] = value;

    new_data.total = 0;
    /*const keynote_sections = new_data.keynote_sections.filter( f => f.active === true && f.pay !== 0 && f.pay !== '' ).map(item => {
            const data = this.props.datasource.section.find( f => f.key === parseInt(item.section_id));
            let duration = 0;
            if(data !== undefined){
                duration = data.duration;
            }

            return item.pay * duration;
        });
        
        if(keynote_sections.length > 0){
            new_data.total = keynote_sections.reduce( (total, num) => (parseFloat(total) + parseFloat(num)) );
        }*/

    this.props.onChange(new_data);
  };

  onDelete = (index) => {
    const new_data = { ...this.props.data };
    new_data.keynote_sections.splice(index, 1);

    this.props.onChange(new_data);
  };

  onInsert = () => {
    const new_data = { ...this.props.data };
    new_data.keynote_sections.push({
      // keynote_id: '',
      section_id: "",
      pay: 0,
      active: true,
    });

    this.props.onChange(new_data);
  };

  render() {
    const row = this.props.data.keynote_sections.map((item, index) => {
      const datasource = this.props.datasource.section;
      const field_section = {
        name: "section_id",
        type: "ddl",
        value: item.section_id,
        datasource,
        required: true,
      };
      const field_pay = {
        name: "pay",
        type: "number",
        value: item.pay,
        required: false,
      };
      const field_meeting_status = {
        name: "active",
        type: "checkbox",
        value: item.active,
      };

      const data = datasource.find((data) => data.key == item.section_id);
      let description = "";

      if (data !== undefined) {
        description = data.description;
      }

      return (
        <tr key={index}>
          <td>
            <FormInput
              field={field_section}
              getValue={(target) => {
                this.getValue(target, index);
              }}
            ></FormInput>
            <small className="text-mute">
              <strong>รายละเอียด:</strong> {description}
            </small>
          </td>
          <td>
            <FormInput
              field={field_pay}
              getValue={(target) => {
                this.getValue(target, index);
              }}
            ></FormInput>
          </td>
          <td className="text-center">
            <FormInput
              field={field_meeting_status}
              getValue={(target) => {
                this.getValue(target, index);
              }}
            ></FormInput>
          </td>
          <td className="text-center">
            <div className="btn-group">
              <button
                onClick={() => {
                  this.onDelete(index);
                }}
                type="button"
                data-toggle="tooltip"
                data-placemeent="top"
                data-original-title="Trash"
                title="Trash"
                className="btn btn-danger btn-sm ml-1"
              >
                <i className="fa fa-trash"></i> ลบ
              </button>
            </div>
          </td>
        </tr>
      );
    });

    return (
      <div className="panel-body">
        <button
          type="button"
          onClick={this.onInsert}
          className="btn btn-primary float-right"
        >
          <i className="fa fa-plus"></i> เพิ่มข้อมูล
        </button>
        <table className="table">
          <thead>
            <tr>
              <th className="text-center">หัวข้อประชุม</th>
              <th className="text-center">ค่าตอบแทนวิทยากร</th>
              <th className="text-center">สถานะการขึ้นบรรยาย</th>
              <th className="text-center">#</th>
            </tr>
          </thead>
          <tbody>{row}</tbody>
        </table>
      </div>
    );
  }
}

MeetingKeynoteSpeakerSection.setDefaultProps = {
  datasource: {},
  onChange: {},
  data: [],
};

export default MeetingKeynoteSpeakerSection;
