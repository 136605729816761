import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import MemberGroupForm from "./MemberGroupForm";
import TitleHeader from "../../../components/TitleHeader/TitleHeader";
import axios from "../../../axios";

class MemberGroup extends Component {
  printCancelPaymentReportExcel = () => {
    const url = axios.defaults.baseURL + "/report/cancelpayment/excel";

    window.open(url, "_blank");
  };

  printCancelPaymentReportPDF = () => {
    const url = axios.defaults.baseURL + "/report/cancelpayment/pdf";

    window.open(url, "_blank");
  };

  render() {
    const status_datasource = [
      { key: 1, label: "ยังไม่ได้พิมพ์ใบแจ้งหนี้" },
      { key: 2, label: "พิมพ์ใบแจ้งหนี้แล้ว" },
      { key: 3, label: "ชำระเงินแล้ว" },
      { key: 4, label: "ขอคืนเงิน" },
    ];

    const columns = [
      {
        label: "โรงพยาบาล",
        align: "left",
        name: "company_name",
        sortable: false,
      },
      {
        label: "ผู้ใช้งาน",
        align: "left",
        name: "users.name",
        search_type: "string",
      },
      {
        label: "เบอร์โทรศัพท์",
        align: "left",
        name: "users.telephone",
        search_type: "string",
      },
      {
        label: "Ref 1",
        align: "left",
        name: "payments.ref1",
        search_type: "string",
      },
      {
        label: "Ref 2",
        align: "left",
        name: "payments.ref2",
        search_type: "string",
      },
      { label: "ยอดชำระ", align: "right", name: "payments.total" },
      {
        label: "วันที่ลงทะเบียน",
        align: "center",
        name: "member_groups.created_at",
      },
      {
        label: "วันที่ขอคืนเงิน",
        align: "center",
        name: "member_groups.cancel_date",
      },
      {
        label: "จำนวนสมาชิก",
        align: "right",
        name: "member_count",
        sortable: false,
      },
      {
        label: "สถานะ",
        align: "center",
        name: "status",
        sortable: false,
        search_type: "ddl",
        search: { type: "ddl", datasource: status_datasource },
      },
      { label: "สถานที่", align: "center", name: "env", sortable: false },
      { label: "เอกสาร", align: "center", name: "document", sortable: false },
    ];

    const api_url = "/user/membergroup";

    const buttons = [
      {
        is_multiple: true,
        label: '<i class="fa fa-print"></i> พิมพ์รายงานขอคืนเงินเข้าร่วมงาน',
        className: "btn btn-primary",
        name: "btn_print_cancel_payment_report",
        datasource: [
          {
            label: '<i class="fa fa-file-excel-o"></i> Excel',
            onClick: this.printCancelPaymentReportExcel,
          },
          {
            label: '<i class="fa fa-file-pdf-o"></i> PDF',
            onClick: this.printCancelPaymentReportPDF,
          },
        ],
      },
    ];

    return (
      <BrowserRouter>
        <div>
          <TitleHeader
            program_name="ผู้ลงทะเบียนเข้าร่วมงาน"
            program_type="ข้อมูลบัญชีผู้ใช้งาน"
          ></TitleHeader>

          <Route
            exact
            path={`${this.props.match.path}/`}
            component={() => (
              <DataTable
                show_action={true}
                allow_import={false}
                columns={columns}
                allow_active={false}
                allow_insert={true}
                allow_trash={true}
                allow_edit={true}
                url={api_url}
                buttons={buttons}
              ></DataTable>
            )}
          />

          <Route
            path={`${this.props.match.path}/create`}
            component={(props) => (
              <MemberGroupForm {...props} url={api_url}></MemberGroupForm>
            )}
          />
          <Route
            path={`${this.props.match.path}/:id/edit`}
            component={(props) => (
              <MemberGroupForm {...props} url={api_url}></MemberGroupForm>
            )}
          />
        </div>
      </BrowserRouter>
    );
  }
}

export default MemberGroup;
