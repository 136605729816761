import React, { Component } from 'react'
import FormInput from './../../../components/DataForm/FormInput';

class MeetingKeynoteSpeakerFollower extends Component {
    getValue = (target, index) => {
        const new_data = { ...this.props.data };
        const name = Object.keys(target)[0];
        const value = Object.values(target)[0];
        new_data[name] = value;
  
        this.props.onChange(new_data);
    }

    getFields = () => {
        const fields = [
            { label: 'คำนำหน้าชื่อ', type:'ddl', name: 'follower_prefix_id', value: this.props.data.follower_prefix_id, datasource: this.props.datasource.prefix_name, allow_new_item: true },
            { label: 'ชื่อ', type:'textbox', name: 'follower_first_name', value: this.props.data.follower_first_name },
            { label: 'นามสกุล', type:'textbox', name: 'follower_last_name', value: this.props.data.follower_last_name },
            { label: 'อีเมล', type:'textbox', name: 'follower_email', value: this.props.data.follower_email },
            { label: 'เบอร์โทร', type:'textbox', name: 'follower_telephone', value: this.props.data.follower_telephone },
            { label: 'รหัสบัตรประชาชน', type:'textbox', name: 'follower_identity_number', value: this.props.data.follower_identity_number },
        ]

        return fields;
    }
  
    render() {
        const fields = this.getFields().map((field, index) => {
            return <FormInput key={index} field={field} getValue={this.getValue}></FormInput>
        });

        return (
            <div className="panel-body">
                {fields}
            </div>
        )
    }
  }
  
MeetingKeynoteSpeakerFollower.setDefaultProps = {
    datasource: [],
    onChange: {},
    data: [],
}

export default MeetingKeynoteSpeakerFollower
