import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import DataTable from "../../components/DataTable/DataTable";
import TitleHeader from "../../components/TitleHeader/TitleHeader";
import ScoreInput from "../Abstract/ScoreInput/ScoreInput";
import { Alert, Services } from "../Service/Services";
import axios from "../../axios";
const $ = window.$;

class ReviewerIndex extends Component {
  constructor(props) {
    super(props);

    this.data_table_element = React.createRef();
  }

  state = {
    title: "",
    subtitle: "",
    is_allow_to_review: false,
    round: 0,
  };

  onSelectScore = (point, id, abstract_no) => {
    Alert.confirm(
      "คุณแน่ใจใช่ไหมที่จะให้คะแนน " +
        point +
        " คะแนน ให้กับผลงานหมายเลข " +
        abstract_no
    ).then((res) => {
      if (res === true) {
        const url = this.props.url;
        const params = { score: point, id };

        Services.submitRequest({ params, url })
          .then((res) => {
            // retrieve new data
            this.data_table_element.current.refresh();
            console.log("Update complete");
          })
          .catch((errors) => {
            console.log(errors);
          });
      }
    });
  };

  beforeBind = (data) => {
    const new_data = data.map((item) => {
      const key = item.key;
      const abstract_no = item.value[0];
      const value = item.value[3];
      const score_input = (
        <ScoreInput
          value={value}
          disabled={!this.state.is_allow_to_review}
          onSelectScore={(score) => this.onSelectScore(score, key, abstract_no)}
          round={this.state.round}
        ></ScoreInput>
      );

      item.value[3] = score_input;

      return item;
    });

    return new_data;
  };

  componentDidMount = () => {
    const url = "current/site";
    axios.get(url).then((res) => {
      const site = res.data;
      const abstract_period = site.abstract_periods.find(
        (f) => f.code === (site.complete_abstract ? "r2" : "r")
      );
      let subtitle = "";

      if (abstract_period && abstract_period.end_date_text)
        subtitle = `สิ้นสุดการตรวจผลงาน: ${abstract_period.end_date_text}`;

      if (site.complete_abstract === false) {
        this.setState({
          title: "การตรวจผลงานวิชาการ ครั้งที่ 1",
          subtitle: subtitle,
          is_allow_to_review: site.is_allow_to_review,
          round: 1,
        });
      } else if (
        site.complete_abstract === true &&
        site.complete_abstract2 === false
      ) {
        this.setState({
          title: "การตรวจผลงานวิชาการ ครั้งที่ 2",
          subtitle: subtitle,
          is_allow_to_review: site.is_allow_to_review,
          round: 2,
        });
      } else {
        this.setState({
          title: "สิ้นสุดการตรวจผลงาน",
          subtitle: "",
          is_allow_to_review: false,
          round: 0,
        });
      }
    });
  };

  render() {
    const { columns, url } = this.props;

    return (
      <>
        <div className="jumbotron jumbotron-fluid mb-0">
          <div className="container">
            <h1 className="display-4">{this.state.title}</h1>
            <h3>{this.state.subtitle}</h3>
          </div>
        </div>
        <DataTable
          ref={this.data_table_element}
          columns={columns}
          beforeBind={this.beforeBind}
          allow_active={false}
          allow_edit={true}
          allow_insert={false}
          allow_trash={false}
          allow_print={false}
          url={url}
        ></DataTable>
      </>
    );
  }
}

ReviewerIndex.defaultProps = {
  url: "",
  columns: [],
};

export default ReviewerIndex;
