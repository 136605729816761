import React, { useReducer, createContext } from "react";
import { useEffect } from "react";
import menuService from "../pages/Authentication/Service/menu.service";
import siteService from "../pages/Authentication/Service/site.service";

const defaultUserState = {
  siteName: "",
  siteId: null,
  userName: "",
};

const UserAction = {
  UPDATE_SITE: "updateSite",
  UPDATE_USER: "updateUser",
};

export const UserContext = createContext({
  siteName: "",
  siteId: null,
  userName: "",
  // addItem: (item) => {},
  // removeItem: (id) => {}
});

const userReducer = (state, action) => {
  switch (action.type) {
    case UserAction.UPDATE_SITE:
      const { id: siteId, name: siteName } = action.site;

      return { ...state, siteId, siteName };
    case UserAction.UPDATE_USER:
      return state;
    default:
      return state;
  }
};

const UserContextProvider = ({ children }) => {
  const currentSite = siteService.getCurrentSite();
  if (currentSite) {
    defaultUserState.siteName = currentSite.name;
  }

  const [userState, dispatch] = useReducer(userReducer, defaultUserState);

  const userContext = {
    ...userState,
    updateSite: (site) => {
      siteService.setCurrentSite(site);
      dispatch({ type: UserAction.UPDATE_SITE, site: site });
    },
    updateUser: (user) => {
      dispatch({ type: UserAction.UPDATE_USER, user: user });
    },
  };

  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  );
};

export default UserContextProvider;
