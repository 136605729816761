import React, { Component } from "react";
import DataForm from "./../../../components/DataForm/DataForm";
import axios from "../../../axios";
import { CompanyAPI } from "../../../api";
import { Redirect } from "react-router-dom";
import { Services } from "../../Service/Services";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";
import _ from "lodash";

class UserVIPForm extends Component {
  // Set default state
  state = {
    data: {
      id: "",
      code: "",
      telephone: "",
      prefix_id: "",
      first_name: "",
      last_name: "",
      position: "",
      identity_no: "",
      career_id: "",
      career_number: "",
      company_id: "",
      job_identity_no: "",
      guest_type_id: "",
      active: true,
      email: "",
      supervisor: "",
      company_email: "",
    },
    datasource: {
      prefix_name: [],
      career: [],
      guest_type: [],
      company: [],
    },
    redirect: false,
    loading: true,
  };

  retrieveData = (id) => {
    axios.get(`${this.props.url}/${id}`).then((res) => {
      const data = res.data;
      const new_data = {
        id: data.id,
        code: data.code,
        telephone: data.telephone,
        prefix_id: data.prefix_id || data.prefix_text,
        first_name: data.first_name,
        last_name: data.last_name,
        position: data.position,
        identity_no: data.identity_no,
        career_id: data.career_id || data.career_text,
        career_number: data.career_number,
        company_id: data.company_id || data.company_text,
        job_identity_no: data.job_identity_no,
        guest_type_id: data.guest_type_id,
        active: data.active,
        email: data.email,
        supervisor: data.supervisor,
        company_email: data.company_email,
      };
      this.setState({ loading: false, data: new_data });
    });
  };
  retrieveDataCompany = (company_id) => {
    if (!company_id) return;
    if (!Services.isNumeric(company_id)) return;
    if (this.state.loading === true) return;

    this.setState({ loading: true });
    CompanyAPI.updateCompanySelect(company_id)
      .then((res) => {
        if (res.status == 200 && !_.isEmpty(res.data)) {
          this.setState({
            loading: false,
            data: {
              ...this.state.data,
              supervisor: res.data.supervisor,
              company_email: res.data.email,
            },
          });
        }
      })
      .catch(() => {});
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;

    const params = {
      tables: [
        { name: "prefix_name" },
        { name: "career" },
        { name: "guest_type" },
        { name: "company" },
      ],
    };

    axios.post("datasource", params).then((res) => {
      const prefix_name = res.data.prefix_name.map((item) => {
        return {
          key: item.id,
          label: item.name,
        };
      });

      const career = res.data.career.map((item) => {
        return {
          key: item.id,
          label: item.name,
        };
      });

      const guest_type = res.data.guest_type.map((item) => {
        return {
          key: item.id,
          label: item.name,
        };
      });

      const company = res.data.company.map((item) => {
        return {
          key: item.id,
          label: item.name,
        };
      });

      const datasource = {
        prefix_name,
        career,
        guest_type,
        company,
      };

      if (id !== undefined) {
        this.setState({ datasource });
        this.retrieveData(id);
      } else {
        const loading = false;
        this.setState({ loading, datasource });
      }
    });
  };

  getValue = (target) => {
    const new_data = { ...this.state.data };
    const name = Object.keys(target)[0];
    const value = Object.values(target)[0];
    new_data[name] = value;

    if (
      name === "company_id" &&
      this.state.data.company_id != target.company_id
    ) {
      this.retrieveDataCompany(target.company_id);
    }

    this.setState({
      data: new_data,
    });
  };

  onSubmit = (e) => {
    const params = this.state.data;
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    loading = false;

    Services.submitRequest({ params, url })
      .then((res) => {
        this.setState({ redirect, loading });
      })
      .catch((errors) => {
        this.setState({ errors, loading });
      });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  };

  getFields = () => {
    const id = this.state.id;

    var fields = [
      {
        label: "รหัสผู้ใช้งาน",
        name: "code",
        type: "textbox",
        required: true,
        value: this.state.data.code,
      },
      {
        label: "อีเมล",
        name: "email",
        type: "textbox",
        required: false,
        value: this.state.data.email,
      },
      {
        label: "ประเภท",
        name: "guest_type_id",
        type: "ddl",
        required: true,
        value: this.state.data.guest_type_id,
        datasource: this.state.datasource.guest_type,
      },
      {
        label: "สถานที่ทำงาน ",
        name: "company_id",
        type: "ddl",
        required: false,
        value: this.state.data.company_id,
        datasource: this.state.datasource.company,
        allow_new_item: true,
      },
      {
        label: "ต้นสังกัด",
        name: "supervisor",
        type: "textbox",
        required: false,
        value: this.state.data.supervisor,
      },
      {
        label: "อีเมลสำหรับส่งเมลต้นสังกัด เช่น อีเมลระบบสารบรรณ ของต้นสังกัด",
        type: "textbox",
        name: "company_email",
        value: this.state.data.company_email,
      },
      {
        label: "คำนำหน้าชื่อ",
        name: "prefix_id",
        type: "ddl",
        required: false,
        value: this.state.data.prefix_id,
        datasource: this.state.datasource.prefix_name,
        allow_new_item: true,
      },
      {
        label: "ชื่อ",
        name: "first_name",
        type: "textbox",
        required: false,
        value: this.state.data.first_name,
      },
      {
        label: "นามสกุล",
        name: "last_name",
        type: "textbox",
        required: false,
        value: this.state.data.last_name,
      },
      {
        label: "รหัสบัตรประชาชน",
        name: "identity_no",
        type: "textbox",
        required: false,
        value: this.state.data.identity_no,
      },
      {
        label: "โทรศัพท์มือถือ",
        name: "telephone",
        type: "textbox",
        required: false,
        value: this.state.data.telephone,
      },
      {
        label: "ตำแหน่ง",
        name: "position",
        type: "textbox",
        required: false,
        value: this.state.data.position,
      },
      {
        label: "วิชาชีพ",
        name: "career_id",
        type: "ddl",
        required: false,
        value: this.state.data.career_id,
        datasource: this.state.datasource.career,
        allow_new_item: true,
      },
      {
        label: "เลขที่ประกอบวิชาชีพ",
        name: "career_number",
        type: "textbox",
        required: false,
        value: this.state.data.career_number,
      },
      {
        label: "เลขที่สมาชิกสภาการพยาบาล",
        name: "job_identity_no",
        type: "textbox",
        required: false,
        value: this.state.data.job_identity_no,
      },
      {
        label: "Active",
        name: "active",
        type: "switch",
        value: this.state.data.active,
      },
    ];

    return fields;
  };

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
        ></DataForm>
      </BoxContainer>
    );
  }
}

UserVIPForm.setDefaultProps = {
  url: "",
};

export default UserVIPForm;
