import React, { Component } from "react";
import axios from "../../../axios";
import { Services } from "../../Service/Services";
var HtmlToReactParser = require("html-to-react").Parser;

class AbstractDetail extends Component {
  state = {
    data: {
      user_email: "",
      user_name: "",
      abstract_no: "",
      abstract_type_name: "",
      abstract_topic_name: "",
      name: "",
      author: "",
      status_text: "",
      asset_file_preview: "",
      asset_file_created_at: "",
      abstract_created_at: "",
      reviewers: [],
      comments: [],
      EC: "",
    },
    url: "admin/abstract",
  };

  retrieveData = () => {
    const id = this.props.abstract_id;
    axios.get(`${this.state.url}/${id}`).then((res) => {
      const data = res.data;
      this.setState({ data });
    });
  };

  componentDidMount = () => {
    this.retrieveData();
  };

  render() {
    const {
      abstract_no,
      abstract_type_name,
      abstract_topic_name,
      name,
      author,
      status,
      status_text,
      asset_file_preview,
      asset_file_created_at,
      abstract_created_at,
      reviewers,
      comments,
      user_email,
      user_name,
      EC,
    } = this.state.data;

    const htmlToReactParser = new HtmlToReactParser();
    const element_asset_file_preview =
      htmlToReactParser.parse(asset_file_preview);

    let status_element = "";

    if (status === "done") {
      status_element = Services.htmlParse(status_text); // <span className="label label-primary">{}</span>
    } else {
      status_element = Services.htmlParse(status_text); // <span className="label label-warning">{}</span>
    }

    const reviewer_element = reviewers.map((item, key) => {
      const { name, score, updated_at, role_name } = item;
      let score_element = "";

      if (score !== "" && score !== null) {
        score_element = (
          <span className="label label-primary">{score} คะแนน</span>
        );
      } else {
        score_element = (
          <span className="label label-warning">ยังไม่ได้ประเมิน</span>
        );
      }

      return (
        <div className="feed-element" key={key}>
          <div className="media-body ">
            <div className="float-right mt-2">{score_element}</div>
            <strong>{name}</strong> - <small>{role_name}</small> <br />
            <small className="text-muted">{updated_at}</small>
          </div>
        </div>
      );
    });

    const comment_element = comments.map((item, key) => {
      const { name, created_at, comment, asset_file_preview, role_name, EC } =
        item;

      const element_asset_file_preview_comment =
        htmlToReactParser.parse(asset_file_preview);

      return (
        <div className="feed-element" key={key}>
          <div className="media-body ">
            <strong>{name}</strong> - <small>{role_name}</small>
            <br />
            <small className="text-muted">{created_at}</small>
            <div className="well">
              <i className="fa fa-quote-left float-left"></i>
              <span style={{ fontSize: "30px" }}>{comment}</span>
              <br />
              {element_asset_file_preview_comment}
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="m-b-md">
              <h2>
                {abstract_no} - {abstract_topic_name} - {name}
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <dl className="row mb-0">
              <div className="col-sm-4 text-sm-right">
                <dt>อีเมลเจ้าของบัญชี:</dt>{" "}
              </div>
              <div className="col-sm-8 text-sm-left">
                <dd className="mb-1">{user_email}</dd>{" "}
              </div>
            </dl>
            <dl className="row mb-0">
              <div className="col-sm-4 text-sm-right">
                <dt>ชื่อเจ้าของบัญชี:</dt>{" "}
              </div>
              <div className="col-sm-8 text-sm-left">
                <dd className="mb-1">{user_name}</dd>{" "}
              </div>
            </dl>
            <dl className="row mb-0">
              <div className="col-sm-4 text-sm-right">
                <dt>เจ้าของผลงาน:</dt>{" "}
              </div>
              <div className="col-sm-8 text-sm-left">
                <dd className="mb-1">{author}</dd>{" "}
              </div>
            </dl>
            <dl className="row mb-0">
              <div className="col-sm-4 text-sm-right">
                <dt>ประเภทผลงาน:</dt>{" "}
              </div>
              <div className="col-sm-8 text-sm-left">
                <dd className="mb-1">{abstract_type_name}</dd>
              </div>
            </dl>
            <dl className="row mb-0">
              <div className="col-sm-4 text-sm-right">
                <dt>หมวดหมู่:</dt>{" "}
              </div>
              <div className="col-sm-8 text-sm-left">
                <dd className="mb-1">{abstract_topic_name}</dd>
              </div>
            </dl>
            <dl className="row mb-0">
              <div className="col-sm-4 text-sm-right">
                {" "}
                <dt>ชื่อผลงาน:</dt>
              </div>
              <div className="col-sm-8 text-sm-left">
                {" "}
                <dd className="mb-1">{name}</dd>
              </div>
            </dl>
            <dl className="row mb-0">
              <div className="col-sm-4 text-sm-right">
                {" "}
                <dt>EC:</dt>
              </div>
              <div className="col-sm-8 text-sm-left">
                {" "}
                <dd className="mb-1">{EC}</dd>
              </div>
            </dl>
          </div>
          <div className="col-lg-6" id="cluster_info">
            <dl className="row mb-0">
              <div className="col-sm-4 text-sm-right">
                <dt>สถานะ:</dt>
              </div>
              <div className="col-sm-8 text-sm-left">
                <dd className="mb-1">{status_element}</dd>
              </div>
            </dl>
            <dl className="row mb-0">
              <div className="col-sm-4 text-sm-right">
                <dt>วันที่ส่งผลงาน:</dt>
              </div>
              <div className="col-sm-8 text-sm-left">
                <dd className="mb-1">{abstract_created_at}</dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <dl className="row mb-0">
              <div className="col-sm-2 text-sm-right">
                <dt>บทคัดย่อ:</dt>
              </div>
              <div className="col-sm-10 text-sm-left">
                <dd>
                  {element_asset_file_preview}{" "}
                  <small>{asset_file_created_at}</small>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        {this.props.showHistory && (
          <div className="row m-t-sm">
            <div className="col-lg-12">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    การให้คะแนน
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    ประวัติการแจ้งแก้ไข{" "}
                    <span className="badge badge-danger">
                      {comments.length}
                    </span>
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="feed-activity-list mt-3">
                    {reviewer_element}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="feed-activity-list mt-3">
                    {comment_element}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

AbstractDetail.defaultProps = {
  abstract_id: null,
  showHistory: true,
};

export default AbstractDetail;
