import React, { Component } from "react";
import axios from "../../../axios";

class ERPSummary extends Component {
  state = {
    data: {
      done: "?",
      fail: "?",
      waiting: "?",
      done_amount: "?",
      fail_amount: "?",
      waiting_amount: "?",
    },
  };

  refresh = () => {
    this.getERPSummary();
  };

  componentDidMount = () => {
    this.getERPSummary();
  };

  getERPSummary = () => {
    const url = "erp/summary";
    axios.get(url).then((res) => {
      const data = res.data;

      this.setState({
        data,
      });
    });
  };

  render() {
    const { done, done_amount, fail, fail_amount, waiting, waiting_amount } =
      this.state.data;

    return (
      <div className="row">
        <div className="col-lg-4 col-12">
          <div className="widget style1 bg-warning">
            <div className="row">
              <div className="col-4">
                <i className="fa fa-hourglass fa-5x"></i>
              </div>
              <div className="col-8 text-right">
                <span> รอส่ง </span>{" "}
                <span className="badge badge-warning">
                  <i className="fa fa-bell-o"></i> {waiting}
                </span>
                <h2 className="font-bold">{waiting_amount} ฿</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-12">
          <div className="widget style1 bg-danger">
            <div className="row">
              <div className="col-4">
                <i className="fa fa-times fa-5x"></i>
              </div>
              <div className="col-8 text-right">
                <span> ล้มเหลว </span>{" "}
                <span className="badge badge-danger">
                  <i className="fa fa-bell-o"></i> {fail}
                </span>
                <h2 className="font-bold">{fail_amount} ฿</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-12">
          <div className="widget style1 bg-primary">
            <div className="row">
              <div className="col-4">
                <i className="fa fa-check fa-5x"></i>
              </div>
              <div className="col-8 text-right">
                <span> เสร็จสิ้น </span>{" "}
                <span className="badge badge-primary">
                  <i className="fa fa-bell-o"></i> {done}
                </span>
                <h2 className="font-bold">{done_amount} ฿</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ERPSummary.defaultProps = {};

export default ERPSummary;
