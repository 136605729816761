import React, { Component } from "react";
import DataForm from "./../../../components/DataForm/DataForm";
import FormInput from "./../../../components/DataForm/FormInput";
import axios from "../../../axios";
import { CompanyAPI } from "../../../api";
import { Redirect } from "react-router-dom";
import { Services, Auth } from "../../Service/Services";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";
import MeetingKeynoteSpeakerInfo from "./MeetingKeynoteSpeakerInfo";
import MeetingKeynoteSpeakerWorkplace from "./MeetingKeynoteSpeakerWorkplace";
import MeetingKeynoteSpeakerProfile from "./MeetingKeynoteSpeakerProfile";
import MeetingKeynoteSpeakerFollower from "./MeetingKeynoteSpeakerFollower";
import MeetingKeynoteSpeakerTransport from "./MeetingKeynoteSpeakerTransport";
// import MeetingKeynoteSpeakerHotel from "./MeetingKeynoteSpeakerHotel";
import MeetingKeynoteSpeakerMeal from "./MeetingKeynoteSpeakerMeal";
import MeetingKeynoteSpeakerSection from "./MeetingKeynoteSpeakerSection";
import MeetingKeynoteSpeakerTotal from "./MeetingKeynoteSpeakerTotal";

class MeetingKeynoteSpeakerForm extends Component {
  // Set default state
  state = {
    data: {
      id: null,
      prefix_th_id: "",
      prefix_th_text: "",
      first_name_th: "",
      last_name_th: "",
      prefix_en_text: "",
      prefix_en_id: "",
      first_name_en: "",
      last_name_en: "",
      email: "",
      has_email: "1",
      telephone: "",
      identity_number: "",
      account_number: "",
      bank_id: "",
      account_name: "",
      speaker_bookbank_asset_file_id: "",
      speaker_bookbank_asset_file_preview: "",
      career_id: "",
      career_text: "",
      career_number: "",
      nurse_number: "",
      feature: false,
      invitation_flag: "",
      company_id: "",
      company_text: "",
      supervisor: "",
      address: "",
      district: "",
      sub_district: "",
      zip_code: "",
      province_id: "",
      profile_asset_file_id: "",
      profile_asset_file_preview: "",
      avatar_asset_file_id: "",
      avatar_asset_file_preview: "",
      position: "",
      company_email: "",

      asset_file_payment: "",
      asset_file_payment_preview: [],
      asset_file_payment_delete_id: [],

      asset_file_hotel: "",
      asset_file_hotel_preview: [],
      asset_file_hotel_delete_id: [],

      asset_file_transport: "",
      asset_file_transport_preview: [],
      asset_file_transport_delete_id: [],

      follower_prefix_id: "",
      follower_prefix_text: "",
      follower_first_name: "",
      follower_last_name: "",
      follower_email: "",
      follower_telephone: "",
      follower_identity_number: "",
      by_private_car_from: "",
      by_private_car_to: "",
      by_private_car_distance: "",
      by_private_car_price: "",
      by_private_car_license: "",
      by_private_car_province_id: "",
      by_public_car_from: "",
      by_public_car_to: "",
      by_public_car_distance: "",
      by_public_car_price: "",
      by_taxi_2_from: "",
      by_taxi_2_to: "",
      by_taxi_2_distance: "",
      by_taxi_2_price: "",
      by_train_from: "",
      by_train_to: "",
      by_train_price: "",
      by_plane_from: "",
      by_plane_to: "",
      by_plane_price: "",
      hotel_room_type: "",
      hotel_name: "",
      hotel_telephone: "",
      hotel_checkin: "",
      hotel_checkout: "",
      hotel_remark: "",
      food_type: "thai",
      food_type_other: "",
      total: "",
      active: true,
      keynote_sections: [],
      payment_status: "unpaid",
    },
    datasource: {
      prefix_name: [],
      career: [],
      company: [],
      province: [],
      section: [],
      bank: [],

      food_type: [
        { key: "thai", label: "อาหารไทย" },
        { key: "halal", label: "อาหารฮาลาล" },
        { key: "vegetarian", label: "อาหารมังสวิรัติ" },
        {
          key: "other",
          label: "อื่นๆ",
          trigger: true,
          textbox_name: "food_type_other",
        },
      ],
      room_type: [
        { key: "s", label: "พักเดี่ยว" },
        { key: "c", label: "พักคู่" },
        { key: "n", label: "ไม่พัก" },
      ],
      email: [
        { key: "1", label: "มีอีเมล", trigger: true, textbox_name: "email" },
        { key: "0", label: "ไม่มีอีเมล" },
      ],
      payment_status: [
        { key: "unpaid", label: "ยังไม่ชำระเงิน" },
        { key: "paid", label: "ชำระเงินแล้ว" },
      ],
    },
    redirect: false,
    loading: true,
  };

  retrieveData = (id, datasource) => {
    axios.get(`${this.props.url}/${id}`).then((res) => {
      const data = res.data;
      const new_data = {
        id: data.id,
        prefix_th_id: data.prefix_th_id || data.prefix_th_text,
        first_name_th: data.first_name_th,
        last_name_th: data.last_name_th,
        prefix_en_id: data.prefix_en_id || data.prefix_en_text,
        first_name_en: data.first_name_en,
        last_name_en: data.last_name_en,
        email: data.email,
        has_email: data.has_email === true ? "1" : "0",
        telephone: data.telephone,
        identity_number: data.identity_number,
        account_number: data.account_number,
        bank_id: data.bank_id,
        account_name: data.account_name,
        speaker_bookbank_asset_file_id: data.speaker_bookbank_asset_file_id,
        speaker_bookbank_asset_file_preview:
          data.speaker_bookbank_asset_file_preview,
        career_id: data.career_id || data.career_text,
        career_number: data.career_number,
        nurse_number: data.nurse_number,
        feature: data.feature,
        invitation_flag: data.invitation_flag,
        company_id: data.company_id || data.company_text,
        supervisor: data.supervisor,
        address: data.address,
        district: data.district,
        sub_district: data.sub_district,
        zip_code: data.zip_code,
        province_id: data.province_id,
        profile_asset_file_id: data.profile_asset_file_id,
        profile_asset_file_preview: data.profile_asset_file_preview,
        avatar_asset_file_id: data.avatar_asset_file_id,
        avatar_asset_file_preview: data.avatar_asset_file_preview,
        position: data.position,
        company_email: data.company_email,
        follower_prefix_id:
          data.follower_prefix_id || data.follower_prefix_text,
        follower_first_name: data.follower_first_name,
        follower_last_name: data.follower_last_name,
        follower_email: data.follower_email,
        follower_telephone: data.follower_telephone,
        follower_identity_number: data.follower_identity_number,
        by_private_car_from: data.by_private_car_from,
        by_private_car_to: data.by_private_car_to,
        by_private_car_distance: data.by_private_car_distance,
        by_private_car_price: data.by_private_car_price,
        by_private_car_license: data.by_private_car_license,
        by_private_car_province_id: data.by_private_car_province_id,
        by_public_car_from: data.by_public_car_from,
        by_public_car_to: data.by_public_car_to,
        by_public_car_distance: data.by_public_car_distance,
        by_public_car_price: data.by_public_car_price,
        by_taxi_2_from: data.by_taxi_2_from,
        by_taxi_2_to: data.by_taxi_2_to,
        by_taxi_2_distance: data.by_taxi_2_distance,
        by_taxi_2_price: data.by_taxi_2_price,
        by_train_from: data.by_train_from,
        by_train_to: data.by_train_to,
        by_train_price: data.by_train_price,
        by_plane_from: data.by_plane_from,
        by_plane_to: data.by_plane_to,
        by_plane_price: data.by_plane_price,
        hotel_room_type: data.hotel_room_type,
        hotel_name: data.hotel_name,
        hotel_telephone: data.hotel_telephone,
        hotel_checkin: data.hotel_checkin_text,
        hotel_checkout: data.hotel_checkout_text,
        hotel_remark: data.hotel_remark,
        food_type: data.food_type,
        food_type_other: data.food_type_other || "",
        total: data.total,
        active: data.active,
        keynote_sections: data.keynote_sections,
        asset_file_payment_preview: data.asset_file_payment_preview,
        asset_file_payment_delete_id: [],
        asset_file_hotel_preview: data.asset_file_hotel_preview,
        asset_file_hotel_delete_id: [],
        asset_file_transport_preview: data.asset_file_transport_preview,
        asset_file_transport_delete_id: [],
        payment_status: data.payment_status ?? "unpaid",
      };

      this.setState({ loading: false, data: new_data, datasource });
    });
  };

  init = () => {
    const promise = new Promise((resolve) => {
      const params = {
        tables: [
          { name: "prefix_name" },
          { name: "career" },
          { name: "company" },
          { name: "province" },
          { name: "section" },
          { name: "bank" },
        ],
      };

      axios.post("datasource", params).then((res) => {
        const prefix_name = res.data.prefix_name.map((item) => {
          return {
            key: item.id,
            label: item.name,
          };
        });

        const career = res.data.career.map((item) => {
          return {
            key: item.id,
            label: item.name,
          };
        });

        const company = res.data.company.map((item) => {
          //supervisor,address,district,sub_district,province_id,zip_code
          return {
            key: item.id,
            label: item.name,
          };
        });

        const province = res.data.province.map((item) => {
          return {
            key: item.id,
            label: item.name,
          };
        });

        const section = res.data.section.map((item) => {
          return {
            key: item.id,
            label: item.label,
            description: item.description,
            duration: item.duration,
          };
        });
        const bank = res.data.bank.map((item) => {
          return {
            key: item.id,
            label: item.name,
            code: item.code,
          };
        });

        const datasource = {
          ...this.state.datasource,
          prefix_name,
          career,
          company,
          province,
          section,
          bank,
        };

        resolve(datasource);
      });
    });

    return promise;
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;

    this.init().then((datasource) => {
      if (id !== undefined) {
        this.retrieveData(id, datasource);
      } else {
        let data = { ...this.state.data };
        const name = this.props.match.params.name;
        if (name) {
          var params_name = name.trim().split(" ");
          data.first_name_th = params_name[0] ?? "";
          data.last_name_th = params_name[1] ?? "";
        }

        this.setState({ loading: false, datasource, data });
      }
    });
  };

  onChange = (new_data) => {
    this.setState({ data: new_data });
  };

  getValue = (target) => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
  };

  onSubmit = (e) => {
    const params = { ...this.state.data };
    const url = this.props.url;
    const is_keynote_speaker = Auth.isRole("keynote_speaker");
    const redirect = is_keynote_speaker ? false : true;
    let loading = true;

    this.setState({ loading });

    Services.submitRequestWithFile({ params, url })
      .then((res) => {
        this.setState({ redirect, loading: false, errors: [] });
      })
      .catch((errors) => {
        this.setState({ errors, loading: false });
      });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  };

  render() {
    const redirect = this.getRedirect();
    let url = this.props.url;
    const is_admin = Auth.isRole("admin");
    const is_keynote_speaker = Auth.isRole("keynote_speaker");
    const invitation_flag = this.state.data.invitation_flag;
    let invitation_message;

    if (invitation_flag === true) {
      invitation_message =
        "<span class='label label-primary'>ส่งจดหมายเชิญแล้ว</span>";
    } else {
      invitation_message =
        "<span class='label label-danger'>ยังไม่ได้ส่งจดหมายเชิญ</span>";
    }

    if (is_keynote_speaker === true) {
      url = "";
    }

    const field_invite_status = {
      label: "สถานะการส่งจดหมายเชิญ",
      type: "label",
      value: invitation_message,
    };
    const field_feature = {
      label: "แสดงหน้าหลัก",
      name: "feature",
      type: "switch",
      required: false,
      value: this.state.data.feature,
      disabled: is_admin === false,
    };
    const field_active = {
      label: "Active",
      name: "active",
      type: "switch",
      required: false,
      value: this.state.data.active,
    };

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm onSubmit={this.onSubmit} url={url} getValue={this.getValue}>
          <div>
            <div className="tabs-container">
              <ul className="nav nav-tabs" role="tablist">
                <li>
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#tab-1"
                  >
                    ข้อมูลบุคคล
                  </a>
                </li>
                <li>
                  <a className="nav-link" data-toggle="tab" href="#tab-2">
                    สถานที่ทำงาน
                  </a>
                </li>
                <li>
                  <a className="nav-link" data-toggle="tab" href="#tab-3">
                    ประวัติ ตำแหน่งและความเชี่ยวชาญ
                  </a>
                </li>
                <li>
                  <a className="nav-link" data-toggle="tab" href="#tab-4">
                    ผู้ติดตาม / เลขา
                  </a>
                </li>
                <li>
                  <a className="nav-link" data-toggle="tab" href="#tab-5">
                    การเดินทาง
                  </a>
                </li>
                {/*<li>
                  <a className="nav-link" data-toggle="tab" href="#tab-6">
                    ห้องพัก (กรณีให้ สรพ. จัดที่พักให้)
                  </a>
                </li>*/}
                <li>
                  <a className="nav-link" data-toggle="tab" href="#tab-7">
                    อาหาร
                  </a>
                </li>
                <li>
                  <a className="nav-link" data-toggle="tab" href="#tab-8">
                    ห้องบรรยาย
                  </a>
                </li>
                <li>
                  <a className="nav-link" data-toggle="tab" href="#tab-9">
                    ค่าตอบแทน
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div role="tabpanel" id="tab-1" className="tab-pane active">
                  <MeetingKeynoteSpeakerInfo
                    data={this.state.data}
                    datasource={this.state.datasource}
                    onChange={this.onChange}
                  ></MeetingKeynoteSpeakerInfo>
                </div>
                <div role="tabpanel" id="tab-2" className="tab-pane">
                  <MeetingKeynoteSpeakerWorkplace
                    data={this.state.data}
                    isLoading={this.state.loading}
                    datasource={this.state.datasource}
                    onChange={this.onChange}
                    setIsLoading={(isLoading) =>
                      this.setState({ loading: isLoading })
                    }
                  ></MeetingKeynoteSpeakerWorkplace>
                </div>
                <div role="tabpanel" id="tab-3" className="tab-pane">
                  <MeetingKeynoteSpeakerProfile
                    data={this.state.data}
                    datasource={this.state.datasource}
                    onChange={this.onChange}
                  ></MeetingKeynoteSpeakerProfile>
                </div>
                <div role="tabpanel" id="tab-4" className="tab-pane">
                  <MeetingKeynoteSpeakerFollower
                    data={this.state.data}
                    datasource={this.state.datasource}
                    onChange={this.onChange}
                  ></MeetingKeynoteSpeakerFollower>
                </div>
                <div role="tabpanel" id="tab-5" className="tab-pane">
                  <MeetingKeynoteSpeakerTransport
                    data={this.state.data}
                    datasource={this.state.datasource}
                    onChange={this.onChange}
                  ></MeetingKeynoteSpeakerTransport>
                </div>
                {/* <div role="tabpanel" id="tab-6" className="tab-pane">
                  <MeetingKeynoteSpeakerHotel
                    data={this.state.data}
                    datasource={this.state.datasource}
                    onChange={this.onChange}
                  ></MeetingKeynoteSpeakerHotel>
                </div> */}
                <div role="tabpanel" id="tab-7" className="tab-pane">
                  <MeetingKeynoteSpeakerMeal
                    data={this.state.data}
                    datasource={this.state.datasource}
                    onChange={this.onChange}
                  ></MeetingKeynoteSpeakerMeal>
                </div>
                <div role="tabpanel" id="tab-8" className="tab-pane">
                  <MeetingKeynoteSpeakerSection
                    data={this.state.data}
                    datasource={this.state.datasource}
                    onChange={this.onChange}
                  ></MeetingKeynoteSpeakerSection>
                </div>
                <div role="tabpanel" id="tab-9" className="tab-pane">
                  <MeetingKeynoteSpeakerTotal
                    data={this.state.data}
                    datasource={this.state.datasource}
                    onChange={this.onChange}
                  ></MeetingKeynoteSpeakerTotal>
                </div>
              </div>
            </div>
            <FormInput field={field_invite_status}></FormInput>
            <FormInput
              field={field_feature}
              getValue={this.getValue}
            ></FormInput>
            <FormInput
              field={field_active}
              getValue={this.getValue}
            ></FormInput>
          </div>
        </DataForm>
      </BoxContainer>
    );
  }
}

MeetingKeynoteSpeakerForm.setDefaultProps = {
  url: "",
};

export default MeetingKeynoteSpeakerForm;
