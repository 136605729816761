export default {
  setCurrentMenu,
  getCurrentMenu,
  removeCurrentMenu,
};

const LOCAL_STORAGE_MENU_NAME = "currentMenu";

function setCurrentMenu(menues) {
  localStorage.setItem(
    LOCAL_STORAGE_MENU_NAME,
    JSON.stringify(
      menues.map((menu) => {
        return {
          id: menu.id,
          code: menu.code,
          name: menu.name,
          label: menu.label,
          is_main: menu.is_main,
          icon_class: menu.icon_class,
          link: menu.link,
          main_menu_id: menu.main_menu_id,
          sequence: menu.sequence,
        };
      })
    )
  );
}

function getCurrentMenu() {
  const currentSite = JSON.parse(localStorage.getItem(LOCAL_STORAGE_MENU_NAME));

  return currentSite ?? [];
}

function removeCurrentMenu() {
  localStorage.removeItem(LOCAL_STORAGE_MENU_NAME);
}
