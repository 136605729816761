import React, { Component } from "react";
import axios from "../../../axios";
import { CompanyAPI } from "../../../api";
import FormInput from "./../../../components/DataForm/FormInput";
import { Services } from "../../Service/Services";
import _ from "lodash";

class MeetingKeynoteSpeakerWorkplace extends Component {
  getValue = (target) => {
    const new_data = { ...this.props.data };
    const name = Object.keys(target)[0];
    const value = Object.values(target)[0];
    new_data[name] = value;

    if (
      name === "company_id" &&
      this.props.data.company_id != target.company_id
    ) {
      this.retrieveData(target.company_id);
    }

    this.props.onChange(new_data);
  };

  retrieveData = (company_id) => {
    if (!company_id) return;
    if (!Services.isNumeric(company_id)) return;

    this.props.setIsLoading(true);
    CompanyAPI.updateCompanySelect(company_id)
      .then((res) => {
        if (res.status == 200 && !_.isEmpty(res.data)) {
          this.props.onChange({
            ...this.props.data,
            province_id: res.data.province_id,
            address: res.data.address,
            district: res.data.district,
            sub_district: res.data.sub_district,
            zip_code: res.data.zip_code,
            supervisor: res.data.supervisor,
            company_email: res.data.email,
          });
          this.props.setIsLoading(false);
        }
      })
      .catch(() => {});
  };

  getFields = () => {
    const fields = [
      {
        label: "ที่ทำงาน ",
        type: "ddl",
        name: "company_id",
        value: this.props.data.company_id,
        datasource: this.props.datasource.company,
        allow_new_item: true,
      },
      {
        label: "เรียนต้นสังกัด (เช่น ผู้อำนวยการ)",
        type: "textbox",
        name: "supervisor",
        value: this.props.data.supervisor,
      },
      {
        label: "อีเมลสำหรับส่งเมลต้นสังกัด เช่น อีเมลระบบสารบรรณ ของต้นสังกัด",
        type: "textbox",
        name: "company_email",
        value: this.props.data.company_email,
      },
      {
        label: "ที่อยู่",
        type: "textbox",
        name: "address",
        value: this.props.data.address,
      },
      {
        label: "แขวง / ตำบล",
        type: "textbox",
        name: "sub_district",
        value: this.props.data.sub_district,
      },
      {
        label: "เขต / อำเภอ",
        type: "textbox",
        name: "district",
        value: this.props.data.district,
      },
      {
        label: "จังหวัด",
        type: "ddl",
        name: "province_id",
        value: this.props.data.province_id,
        datasource: this.props.datasource.province,
      },
      {
        label: "รหัสไปรษณีย์",
        type: "textbox",
        name: "zip_code",
        value: this.props.data.zip_code,
      },
    ];

    return fields;
  };

  render() {
    const fields = this.getFields().map((field, index) => {
      return (
        <FormInput
          key={index}
          field={field}
          getValue={this.getValue}
        ></FormInput>
      );
    });
    // console.log(this.props);

    return <div className="panel-body">{fields}</div>;
  }
}

MeetingKeynoteSpeakerWorkplace.setDefaultProps = {
  datasource: [],
  onChange: {},
  data: [],
};

export default MeetingKeynoteSpeakerWorkplace;
