import React, { useState, useEffect, useRef } from "react";
import Modal from "../../../components/Modal/Modal";
import axios from "../../../axios";
import { Alert, Services } from "../../Service/Services";

const MemberGroupMember = ({
  members,
  datasource,
  is_online,
  getValue,
  loading,
}) => {
  const fileInputRef = useRef(null);
  const modalRef = useRef(null);

  const defaultFields = [
    {
      name: "avatar",
      label: "รูปภาพ",
      type: "avatar",
      multiple: false,
      default_value: null,
    },
    {
      name: "prefix_id",
      label: "คำนำหน้า",
      default_value: null,
      required: true,
      type: "ddl",
      allow_new_item: true,
      datasource: datasource.prefix_name,
    },
    {
      name: "first_name",
      label: "ชื่อ",
      default_value: "",
      required: true,
      type: "textbox",
    },
    {
      name: "last_name",
      label: "นามสกุล",
      default_value: "",
      required: true,
      type: "textbox",
    },
    {
      name: "email",
      label: "อีเมล",
      default_value: "",
      required: is_online === "1" ? true : false,
      type: "textbox",
    },
    {
      name: "identity_number",
      label: "รหัสบัตรประชาชน",
      default_value: "",
      required: true,
      type: "textbox",
    },
    {
      name: "career_id",
      label: "วิชาชีพ",
      default_value: null,
      required: true,
      type: "ddl",
      allow_new_item: true,
      datasource: datasource.career,
    },
    {
      name: "career_number",
      label: "เลขที่ประกอบวิชาชีพ",
      default_value: "",
      type: "textbox",
    },
    {
      name: "nurse_number",
      label: "เลขสภาการพยาบาล",
      default_value: "",
      description: "ถ้าอาชีพเป็นพยาบาล จะต้องกรอก เลขสภาการพยาบาล",
      type: "textbox",
    },
    {
      name: "food_type",
      label: "ประเภทอาหาร",
      default_value: "normal",
      type: "radio",
      datasource: datasource.food_type,
    },
  ];

  const [headers, setHeaders] = useState([]);
  const [fields, setFields] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const export_url = axios.defaults.baseURL + "/member/download?" + Date.now();

  useEffect(() => {
    if (is_online === "1") {
      setHeaders([
        { label: "ชื่อ - นามสกุล", name: "name", align: "left" },
        {
          label: "เลขที่บัตรประชาชน",
          name: "identity_number",
          align: "center",
        },
        { label: "Theme", name: "theme", align: "center" },
      ]);

      setFields((f) => {
        return [
          ...defaultFields,
          {
            name: "online_register_type",
            label: "ประเภทออนไลน์",
            default_value: null,
            datasource: datasource.online_register_type,
            type: "radio",
          },
          {
            name: "section_online_id",
            label: "ธีม",
            default_value: null,
            datasource: datasource.section_online,
            type: "radio",
          },
        ];
      });
    } else {
      setHeaders([
        { label: "ชื่อ - นามสกุล", name: "name", align: "left" },
        {
          label: "เลขที่บัตรประชาชน",
          name: "identity_number",
          align: "center",
        },
      ]);

      setFields(defaultFields);
    }
  }, [is_online, datasource]);

  const onValidate = (data, rowIndex = null) => {
    const _errors = [];

    const isValidInput = (name) => {
      let fieldName = name + (rowIndex !== null ? "_" + rowIndex : "");
      if (!data[fieldName]) return false;
      return true;
    };

    if (!isValidInput("prefix_id")) _errors.push("กรุณาระบุคำนำหน้า");
    if (!isValidInput("first_name")) _errors.push("กรุณาระบุชื่อ");
    if (!isValidInput("last_name")) _errors.push("กรุณาระบุนามสกุล");
    if (!isValidInput("career_id")) _errors.push("กรุณาระบุวิชาชีพ");

    if (is_online === "1") {
      if (!isValidInput("email")) _errors.push("กรุณาระบุอีเมล");
    }

    return _errors;
  };

  const importFile = (e) => {
    fileInputRef.current.click();
  };

  const uploadFile = (e) => {
    const url = "member/import";
    const params = {
      url,
      params: {
        import: e.target.files[0],
      },
    };

    Services.submitRequestWithFile(params)
      .then((res) => {
        const data = res.data;

        const updatedMembers = [...members, ...data];

        getValue(updatedMembers);

        fileInputRef.current.value = "";
      })
      .catch((errors) => {
        Alert.error(errors.join("\n"), "มีข้อผิดพลาด");

        fileInputRef.current.value = "";
      });
  };

  const onSubmit = (data, rowIndex = null) => {
    const updatedMembers = [...members];
    const fieldNamePostfix = rowIndex !== null ? "_" + rowIndex : "";

    const newData = {
      avatar: data["avatar" + fieldNamePostfix],
      prefix_id: data["prefix_id" + fieldNamePostfix],
      first_name: data["first_name" + fieldNamePostfix],
      last_name: data["last_name" + fieldNamePostfix],
      email: data["email" + fieldNamePostfix],
      identity_number: data["identity_number" + fieldNamePostfix],
      career_id: data["career_id" + fieldNamePostfix],
      career_number: data["career_number" + fieldNamePostfix],
      nurse_number: data["nurse_number" + fieldNamePostfix],
      online_register_type: data["online_register_type" + fieldNamePostfix],
      section_online_id: data["section_online_id" + fieldNamePostfix],
      food_type: data["food_type" + fieldNamePostfix],
    };

    if (rowIndex !== null) {
      updatedMembers[rowIndex] = { ...updatedMembers[rowIndex], ...newData };
    } else {
      updatedMembers.push(newData);
    }

    getValue(updatedMembers);
  };

  const getFieldValue = (member, name) => {
    switch (name) {
      case "name":
        const prefix = datasource.prefix_name.find(
          (f) => f.key === parseInt(member.prefix_id)
        );

        return `${prefix ? prefix.label : member.prefix_text} ${
          member.first_name
        } ${member.last_name}`;
      case "theme":
        const theme = datasource.online_register_type.find(
          (f) => f.key === member.online_register_type
        );
        return `${theme ? theme.label : ""}`;
    }

    return member[name];
  };

  const removeItem = (rowIndex) => {
    Alert.confirm("คุณแน่ใจไหมจะลบข้อมูลนี้").then((res) => {
      if (res === true) {
        const updatedMembers = [...members];
        updatedMembers.splice(rowIndex, 1);

        getValue(updatedMembers);
      }
    });
  };

  const getModalField = (member, rowIndex) => {
    let modelFields = JSON.parse(JSON.stringify(fields));

    modelFields = modelFields.map((field) => {
      field.default_value = member[field.name] ?? "";
      field.name = field.name + "_" + rowIndex;

      return field;
    });

    return modelFields;
  };

  return (
    <div>
      <label className="col-form-label">ข้อมูลสมาชิก</label>
      <input
        ref={fileInputRef}
        type="file"
        className="d-none"
        onChange={uploadFile}
      ></input>
      <div className="btn-group float-right ml-2" role="group">
        <button
          type="button"
          className="btn btn-primary dropdown-toggle btn-sm"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          นำเข้าข้อมูล
        </button>
        <ul className="dropdown-menu">
          <li>
            <a target="_blank" href={export_url}>
              ดาวน์โหลด Template
            </a>
          </li>
          <li>
            <a onClick={importFile}>นำเข้าข้อมูล</a>
          </li>
        </ul>
      </div>
      <Modal
        button_class="btn btn-light btn-sm ml-1 mb-2 float-right"
        label={
          <>
            <i className="fa fa-plus"></i> เพิ่มข้อมูล
          </>
        }
        title="Member"
        fields={fields}
        getValue={onSubmit}
        loading={loading}
        onValidate={onValidate}
        ref={modalRef}
      />
      <table className="table table-bordered">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header.name} className="text-center">
                {header.label}
              </th>
            ))}
            <th># Action</th>
          </tr>
        </thead>
        <tbody>
          {members.map((member, rowIndex) => {
            return (
              <tr>
                {headers.map((header) => (
                  <td key={header.name} className={"text-" + header.align}>
                    {getFieldValue(member, header.name)}
                  </td>
                ))}
                <td className="text-center">
                  <Modal
                    button_class="btn btn-light btn-sm"
                    label={
                      <>
                        <i className="fa fa-pencil"></i>
                      </>
                    }
                    title="Member"
                    fields={getModalField(member, rowIndex)}
                    getValue={(data) => onSubmit(data, rowIndex)}
                    loading={loading}
                    onValidate={(data) => onValidate(data, rowIndex)}
                  />
                  <button
                    onClick={() => {
                      removeItem(rowIndex);
                    }}
                    type="button"
                    data-toggle="tooltip"
                    data-placemeent="top"
                    data-original-title="Trash"
                    title="Trash"
                    className="btn btn-danger btn-sm ml-1"
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MemberGroupMember;
