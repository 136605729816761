import React, { Component } from "react";
import DataForm from "./../../../components/DataForm/DataForm";
import FormInput from "./../../../components/DataForm/FormInput";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import { Services } from "../../Service/Services";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";
import { CheckInAPI } from "../../../api";

class OfflineMonitorForm extends Component {
  __constructor = () => {
    this.timer = null;
  };

  // Set default state
  state = {
    data: {
      roles: {
        member: {
          label: "ผู้เข้าร่วมงาน",
          all: 0,
          dates: [],
        },
        keynote: {
          label: "วิทยากร",
          all: 0,
          dates: [],
        },
        guest: {
          label: "แขกรับเชิญ",
          all: 3,
          dates: [],
        },
      },
      date_label: [],
      careers: [],
    },
    redirect: false,
    loading: true,
    miniLoading: true,
  };

  retrieveData = () => {
    CheckInAPI.getMonitor().then((data) => {
      this.setState({
        data,
        loading: false,
        miniLoading: false,
      });

      this.timer = setTimeout(() => {
        this.setState({
          miniLoading: true,
        });
        this.retrieveData();
      }, 60000);
    });
  };

  componentDidMount = () => {
    this.retrieveData();
  };

  componentWillUnmount = () => {
    clearTimeout(this.timer);
  };

  render() {
    const { date_label, roles, careers } = this.state.data;
    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        <h3 className="alert alert-info">การลงทะเบียน</h3>
        <div className="row">
          <div className="col-12">
            <div className="text-right">
              <div className="float-left text-muted form-text">
                <small>
                  Status:{" "}
                  <span id="status">
                    {this.state.miniLoading ? "Loading..." : "Done"}
                  </span>
                </small>
              </div>
            </div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th rowSpan="2" className="text-center align-middle">
                    ประเภท
                  </th>
                  <th rowSpan="2" className="text-center align-middle">
                    จำนวนตั้งต้น
                  </th>
                  <th
                    colSpan={date_label.length}
                    className="text-center align-middle"
                  >
                    จำนวนคนที่มาลงทะเบียน
                  </th>
                  <th rowSpan="2" className="text-center align-middle">
                    ยังไม่มาลงทะเบียน
                  </th>
                </tr>
                <tr>
                  {date_label.map((label) => (
                    <th key={label} className="text-center vertical-middle">
                      {label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-left">{roles.member.label}</td>
                  <td className="text-right">
                    {Services.convertToMoney(roles.member.all, 0)}
                  </td>
                  {roles.member.dates.map(
                    (date_checkin, date_checkin_index) => (
                      <td key={date_checkin_index} className="text-right">
                        {Services.convertToMoney(date_checkin, 0)}
                      </td>
                    )
                  )}
                  <td className="text-right">
                    {Services.convertToMoney(roles.member.remaining, 0)}
                  </td>
                </tr>
                <tr>
                  <td className="text-left">{roles.guest.label}</td>
                  <td className="text-right">
                    {Services.convertToMoney(roles.guest.all, 0)}
                  </td>
                  {roles.guest.dates.map((date_checkin, date_checkin_index) => (
                    <td key={date_checkin_index} className="text-right">
                      {Services.convertToMoney(date_checkin, 0)}
                    </td>
                  ))}
                  <td className="text-right">
                    {Services.convertToMoney(roles.guest.remaining, 0)}
                  </td>
                </tr>
                <tr>
                  <td className="text-left">{roles.keynote.label}</td>
                  <td className="text-right">
                    {Services.convertToMoney(roles.keynote.all, 0)}
                  </td>
                  {roles.keynote.dates.map(
                    (date_checkin, date_checkin_index) => (
                      <td key={date_checkin_index} className="text-right">
                        {Services.convertToMoney(date_checkin, 0)}
                      </td>
                    )
                  )}
                  <td className="text-right">
                    {Services.convertToMoney(roles.keynote.remaining, 0)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="hr-line-dashed"></div>

        <h3 className="alert alert-info">แยกตามอาชีพ</h3>
        <div className="row">
          <div className="col-12">
            <div className="text-right">
              <div className="float-left text-muted form-text">
                <small>
                  Status:{" "}
                  <span id="status">
                    {this.state.miniLoading ? "Loading..." : "Done"}
                  </span>
                </small>
              </div>
            </div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th rowSpan="2" className="text-center align-middle">
                    ประเภท
                  </th>
                  <th rowSpan="2" className="text-center align-middle">
                    จำนวนตั้งต้น
                  </th>
                  <th
                    colSpan={date_label.length}
                    className="text-center align-middle"
                  >
                    จำนวนคนที่มาลงทะเบียน
                  </th>
                  <th rowSpan="2" className="text-center align-middle">
                    ยังไม่มาลงทะเบียน
                  </th>
                </tr>
                <tr>
                  {date_label.map((label) => (
                    <th key={label} className="text-center vertical-middle">
                      {label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {careers.map((career) => {
                  return (
                    <tr key={career.label}>
                      <td className="text-left">{career.label}</td>
                      <td className="text-right">
                        {Services.convertToMoney(career.all, 0)}
                      </td>
                      {career.dates.map((date_checkin, date_checkin_index) => (
                        <td key={date_checkin_index} className="text-right">
                          {Services.convertToMoney(date_checkin, 0)}
                        </td>
                      ))}
                      <td className="text-right">
                        {career.remaining !== undefined
                          ? Services.convertToMoney(career.remaining, 0)
                          : ""}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="hr-line-dashed"></div>
      </BoxContainer>
    );
  }
}

OfflineMonitorForm.setDefaultProps = {
  url: "",
};

export default OfflineMonitorForm;
