import React, { Component } from "react";
import DataForm from "./../../../components/DataForm/DataForm";
import FormInput from "./../../../components/DataForm/FormInput";
import DateRange from "../../../components/DateRange/daterange";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import { Services } from "../../Service/Services";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";

class UserFeeForm extends Component {
  // Set default state
  state = {
    data: {
      end_date_paid: "",
      start_date: "",
      end_date: "",
      guest_th: "",
      presenter_th: "",
      guest_en: "",
      presenter_en: "",
      online_free: "",
      online_selected: "",
      early_bird_online_free: "",
      early_bird_online_selected: "",
      early_bird_onground: "",
      active: true,
    },
    datasource: {},
    redirect: false,
    loading: true,
  };

  retrieveData = (id) => {
    axios.get(`${this.props.url}/${id}`).then((res) => {
      const data = res.data;
      const new_data = {
        id: data.id,
        end_date_paid: data.end_date_paid_text,
        start_date: data.start_date_text,
        end_date: data.end_date_text,
        guest_th: data.guest_th,
        presenter_th: data.presenter_th,
        guest_en: data.guest_en,
        presenter_en: data.presenter_en,
        online_free: data.online_free,
        online_selected: data.online_selected,
        early_bird_online_free: data.early_bird_online_free,
        early_bird_online_selected: data.early_bird_online_selected,
        early_bird_onground: data.early_bird_onground,
        active: data.active,
      };

      this.setState({ data: new_data, loading: false });
    });
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;

    if (id !== undefined) {
      this.retrieveData(id);
    } else {
      const loading = false;
      this.setState({ loading });
    }
  };

  getValue = (target) => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
  };

  onSubmit = (e) => {
    const params = this.state.data;
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    Services.submitRequest({ params, url })
      .then((res) => {
        this.setState({ redirect, loading });
      })
      .catch((errors) => {
        this.setState({ errors, loading });
      });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  };

  getFields = () => {
    const id = this.state.id;

    var fields = [
      {
        label: "อีเมล",
        name: "email",
        type: "email",
        required: true,
        value: this.state.data.email,
      },
    ];

    return fields;
  };

  getValue = (target) => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
  };

  render() {
    const redirect = this.getRedirect();
    const url = this.props.url;

    const field_active = {
      label: "Active",
      name: "active",
      type: "switch",
      value: this.state.data.active,
    };
    const field_end_date_paid = {
      label: "วันที่สิ้นสุดการชำระ",
      name: "end_date_paid",
      type: "datepicker",
      value: this.state.data.end_date_paid,
      required: true,
    };
    // const field_company_code = { label: 'Company Code', name: 'company_code', type: 'textbox', value: this.state.data.company_code };
    const field_member_t = {
      label: "ผู้เข้าร่วมงาน (บาท)",
      name: "guest_th",
      type: "textbox",
      value: this.state.data.guest_th,
    };
    const field_abstract_t = {
      label: "ผู้เข้าส่งผลงาน (บาท)",
      name: "presenter_th",
      type: "textbox",
      value: this.state.data.presenter_th,
    };
    const field_member_o = {
      label: "ผู้เข้าร่วมงาน (USD)",
      name: "guest_en",
      type: "textbox",
      value: this.state.data.guest_en,
    };
    const field_abstract_o = {
      label: "ผู้เข้าส่งผลงาน (USD)",
      name: "presenter_en",
      type: "textbox",
      value: this.state.data.presenter_en,
    };

    const field_online_free = {
      label: "Free Theme (บาท)",
      name: "online_free",
      type: "textbox",
      value: this.state.data.online_free,
    };
    const field_online_selected = {
      label: "เลือก 1 Theme (บาท)",
      name: "online_selected",
      type: "textbox",
      value: this.state.data.online_selected,
    };

    const early_bird_online_free = {
      label: "Free Theme (บาท)",
      name: "early_bird_online_free",
      type: "textbox",
      value: this.state.data.early_bird_online_free,
    };

    const early_bird_online_selected = {
      label: "เลือก 1 Theme (บาท)",
      name: "early_bird_online_selected",
      type: "textbox",
      value: this.state.data.early_bird_online_selected,
    };

    const field_early_bird_onground = {
      label: "Onsite",
      name: "early_bird_onground",
      type: "textbox",
      value: this.state.data.early_bird_onground,
    };

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm onSubmit={this.onSubmit} url={url} getValue={this.getValue}>
          <div>
            <div className="form-group">
              <label className="col-form-label field-required">
                วันที่เริ่มต้น - วันที่สิ้นสุด
              </label>
              <DateRange
                required="true"
                name_start="start_date"
                value_start={this.state.data.start_date}
                value_end={this.state.data.end_date}
                name_end="end_date"
                datepickerCallback={this.getValue}
              ></DateRange>
            </div>
            <FormInput
              getValue={this.getValue}
              field={field_end_date_paid}
            ></FormInput>
            <h3 className="alert alert-info">คนไทย</h3>
            <div className="row">
              <div className="col-12 col-md-6">
                <FormInput
                  getValue={this.getValue}
                  field={field_member_t}
                ></FormInput>
              </div>
              <div className="col-12 col-md-6">
                <FormInput
                  getValue={this.getValue}
                  field={field_abstract_t}
                ></FormInput>
              </div>
            </div>
            <div className="hr-line-dashed"></div>
            <h3 className="alert alert-info">คนต่างชาติ</h3>
            <div className="row">
              <div className="col-12 col-md-6">
                <FormInput
                  getValue={this.getValue}
                  field={field_member_o}
                ></FormInput>
              </div>
              <div className="col-12 col-md-6">
                <FormInput
                  getValue={this.getValue}
                  field={field_abstract_o}
                ></FormInput>
              </div>
            </div>
            <div className="hr-line-dashed"></div>
            <h3 className="alert alert-info">ลงทะเบียนออนไลน์</h3>
            <div className="row">
              <div className="col-12 col-md-6">
                <FormInput
                  getValue={this.getValue}
                  field={field_online_free}
                ></FormInput>
              </div>
              <div className="col-12 col-md-6">
                <FormInput
                  getValue={this.getValue}
                  field={field_online_selected}
                ></FormInput>
              </div>
            </div>
            <div className="hr-line-dashed"></div>
            <h3 className="alert alert-info">Early Bird</h3>
            <div className="row">
              <div className="col-12 col-md-6">
                <FormInput
                  getValue={this.getValue}
                  field={early_bird_online_free}
                ></FormInput>
              </div>
              <div className="col-12 col-md-6">
                <FormInput
                  getValue={this.getValue}
                  field={early_bird_online_selected}
                ></FormInput>
              </div>
              <div className="col-12 col-md-6">
                <FormInput
                  getValue={this.getValue}
                  field={field_early_bird_onground}
                ></FormInput>
              </div>
            </div>
            <div className="hr-line-dashed"></div>
            <FormInput
              getValue={this.getValue}
              field={field_active}
            ></FormInput>
          </div>
        </DataForm>
      </BoxContainer>
    );
  }
}

UserFeeForm.setDefaultProps = {
  url: "",
};

export default UserFeeForm;
