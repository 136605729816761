import React, { Component } from 'react'
import DataForm from './../../../components/DataForm/DataForm';
import FormInput from './../../../components/DataForm/FormInput';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import { Services } from '../../Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';

class UserCertificateForm extends Component {
  // Set default state
  state = {
    data:{
      username: '',
      email: '',
      name: '',
      status: 'Active',
      active: true,
      prefix_name: '',
      telephone: '',
      company: 1,
      role: 1,
      user_role: '',
      abstract_category: '',
    },
    datasource: {
      
    },
    redirect: false,
    loading: false,
  }

  retrieveData = id => {
    
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;

    if(id !== undefined){
      this.retrieveData(id);
    }else{
      const loading = false;
      this.setState({loading});
    }
  }

  getValue = target => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target
      }
    });
  }

  onSubmit = e => {
    // const params = this.state.data;
    // const url = this.props.url;
    // const redirect = true;
    // let loading = true;

    // this.setState({ loading });

    // Services.submitRequest({ params, url }).then( res => { this.setState({redirect, loading}) }).catch( errors => {this.setState({ errors,loading })});
  }

  getRedirect = () => {
    const redirect = this.state.redirect;
    
    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  }

  getFields = () => {
    const id = this.state.id;

    var fields = [
        { label: 'แนบไฟล์ Template สำหรับใบ Certificate', name: 'province', type: 'upload', required: false, multiple: false },
    ];

    return fields;
  }

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;
    const field = { label: 'รายละเอียด', type: 'texteditor', name: 'section' };

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
        >
        </ DataForm>
      </BoxContainer>
    )
  }
}

UserCertificateForm.setDefaultProps = {
  url: '',
}

export default UserCertificateForm
