import React, { Component } from "react";
import DataTable from "../../../components/DataTable/DataTable";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";
import swal from "sweetalert";
import axios from "../../../axios";
import { Alert, Auth, Services } from "../../Service/Services";
import { BrowserRouter, Route, Redirect } from "react-router-dom";

class UserReviewer extends Component {
  constructor(props) {
    super(props);
    this.datatable_element = React.createRef();
  }

  state = {
    loading: false,
    id: null,
    email: "",
    redirect: false,
    redirect_to_url: "",
  };

  onImportFile = (params) => {
    const import_url = "/importuserreviewer";

    const new_params = {
      params,
      url: import_url,
    };

    this.setState({ loading: true });

    Services.submitRequestWithFile(new_params)
      .then((res) => {
        this.setState({ loading: false });
        this.datatable_element.current.refresh();
      })
      .catch((err) => {
        this.setState({ loading: false });
        swal({
          title: "Error",
          text: err.join("\n"),
          icon: "error",
          button: "OK",
        });
        console.log("catch", err);
      });
  };

  onInsert = () => {
    swal({
      content: {
        element: "input",
        attributes: {
          placeholder:
            "อีเมลของผู้เชี่ยวชาญที่ต้องการเพิ่ม เช่น reviewer@gmail.com",
        },
      },
    }).then((email) => {
      if (email !== null && email.trim() !== "") {
        this.setState({ loading: true });

        const url = "getuseridbyemail";
        axios.post(url, { email }).then((res) => {
          this.setState({
            loading: false,
            id: res.data.id,
            email: email,
            redirect: true,
            redirect_to_url: "/create/" + email,
          });
        });
      } else {
        Alert.error("กรุณาระบุอีเมลของผู้เชี่ยวชาญ");
      }
    });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      let url = this.props.url;

      if (this.state.id !== null) {
        url += "/" + (this.state.id + "/edit");
      } else {
        if (this.state.redirect_to_url !== "") {
          url += this.state.redirect_to_url;
        }
      }

      return <Redirect to={url} />;
    }

    return;
  };

  render() {
    const redirect = this.getRedirect();
    const columns = [
      {
        label: "ชื่อ - นามสกุล",
        align: "left",
        name: "name",
        search_type: "string",
      },
      { label: "อีเมล", align: "left", name: "email", search_type: "string" },
      { label: "สถานะ", align: "center", name: "status", sortable: false },
    ];

    const api_url = this.props.url;
    const export_url = axios.defaults.baseURL + "/exportuserreviewer";

    const buttons = [
      {
        label: '<i className="fa fa-plus"></i> เพิ่มข้อมูล',
        className: "btn btn-success",
        onClick: this.onInsert,
      },
    ];

    return (
      <BoxContainer loading={this.state.loading}>
        {redirect}
        <DataTable
          ref={this.datatable_element}
          buttons={buttons}
          allow_import={true}
          columns={columns}
          allow_insert={false}
          allow_active={true}
          export_url={export_url}
          onImportFile={this.onImportFile}
          url={api_url}
        ></DataTable>
      </BoxContainer>
    );
  }
}

export default UserReviewer;
