import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import PublishCategoryForm from './PublishCategoryForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class PublishCategory extends Component {
    render() {
        const columns = [
            { label: 'หมวดหมู่', align: 'left', name: 'name', search_type: 'string' },
        ];

        const api_url = '/publish/category';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='หมวดหมู่' program_type='เอกสารเผยแพร่'></TitleHeader>
                    
                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable columns={columns} allow_active={true} url={api_url}></DataTable>)} />
                    
                    <Route path={`${this.props.match.path}/create`} component={props => (<PublishCategoryForm {...props} url={api_url}></PublishCategoryForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<PublishCategoryForm {...props} url={api_url}></PublishCategoryForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default PublishCategory
