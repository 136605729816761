import axios from "../axios";

const getCompanyEmail = () => {
  const promise = new Promise((resolve, reject) => {
    const url = `guestcompanyemail`;
    axios
      .get(url)
      .then((res) => resolve(res.data))
      .catch((res) => {
        reject(res);
      });
  });

  return promise;
};

const sendCompanyEmail = (guest_id_list) => {
  const promise = new Promise((resolve, reject) => {
    const url = `guestcompanyemail`;
    axios
      .post(url, { guest_id_list })
      .then((res) => resolve(res.data))
      .catch((res) => {
        reject(res);
      });
  });

  return promise;
};

export default {
  getCompanyEmail,
  sendCompanyEmail,
};
