import React, { Component } from "react";
// import { useContext } from "react";
import { UserContext } from "../../context/UserContextProvider";
import { authenticationService } from "../../pages/Authentication/Service/authentication.service";
import siteService from "../../pages/Authentication/Service/site.service";
import { Services, Auth } from "../../pages/Service/Services";

class Navbar extends Component {
  static contextType = UserContext;

  logout() {
    authenticationService.logout();
  }
  render() {
    const is_admin = Auth.isRole("admin");
    let siteName = "";
    let changesite;

    if (is_admin) {
      const userInfo = this.context;
      siteName = userInfo ? userInfo.siteName : "งานประชุมวิชาการ";
      changesite = (
        <li>
          <span className="m-r-sm text-muted d-none d-sm-block">
            <a className="btn btn-link" href="/site">
              <u>{siteName}</u>
            </a>
          </span>
          <span className="m-r-sm text-muted d-sm-none">
            <a className="btn btn-link" href="/site">
              <u>งานประชุมวิชาการ</u>
            </a>
          </span>
        </li>
      );
    }

    return (
      <nav
        className="navbar navbar-static-top white-bg App-mb"
        role="navigation"
      >
        <div className="navbar-header">
          <a
            className="navbar-minimalize minimalize-styl-2 btn btn-primary "
            href="/"
          >
            <i className="fa fa-bars"></i>{" "}
          </a>
        </div>
        <ul className="nav navbar-top-links navbar-right">
          {changesite}
          <li>
            <button className="btn btn-link" onClick={this.logout}>
              <i className="fa fa-sign-out"></i> Log out
            </button>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Navbar;
