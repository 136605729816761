import 'summernote/dist/summernote';
import 'summernote/dist/summernote.css';
import 'codemirror/lib/codemirror.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Services } from '../../pages/Service/Services';

const $ = window.$;

class TextEditor extends Component {
  constructor(props) {
    super(props);

    this.editor = {};
    this.noteEditable = null;
    this.notePlaceholder = null;

    this.element = React.createRef();
  }

  componentDidMount= () => {
    const defaultOptions = {
      height: 300,
    }
    const options = (this.props.options || defaultOptions);
    const codeview = this.props.codeview;
    // const codeviewCommand = codeview ? 'codeview.activate' : 'codeview.deactivate';
    options.callbacks = this.callbacks;

    this.editor = $(this.element.current);
    this.editor.summernote(options);
    if (codeview) {
      this.editor.summernote('codeview.activate');
    }
  }

  componentWillReceiveProps = (nextProps) => {
    const { props } = this;

    const codeview = nextProps.codeview;
    const codeviewCommand = codeview ? 'codeview.activate' : 'codeview.deactivate';

    if (typeof nextProps.value === 'string' && props.value !== nextProps.value) {
      this.replace(nextProps.value);
    }

    if (typeof nextProps.disabled === 'boolean' && props.disabled !== nextProps.disabled) {
      this.toggleState(nextProps.disabled);
    }
    if (codeview !== props.codeview) {
      this.editor.summernote(codeviewCommand);
    }
  }

  shouldComponentUpdate = () => {
    return false;
  }

  componentWillUnmount = () => {
    if (this.editor.summernote) {
      this.editor.summernote('destroy');
    }
  }

  onInit = () => {
    const { disabled, onInit } = this.props;

    const $container = this.editor.parent();
    this.noteEditable = $container.find('.note-editable');
    this.notePlaceholder = $container.find('.note-placeholder');

    if (typeof disabled === 'boolean') {
      this.toggleState(disabled);
    }

    if (typeof onInit === 'function') {
      onInit({
        summernote: this.editor.summernote.bind(this.editor),
        focus: this.focus,
        isEmpty: this.isEmpty,
        reset: this.reset,
        replace: this.replace,
        disable: this.disable,
        enable: this.enable,
        insertImage: this.insertImage,
        insertNode: this.insertNode,
        insertText: this.insertText
      });
    }
  }

  onImageUpload = (images) => {
    const { onImageUpload } = this.props;

    if (typeof onImageUpload === 'function') {
      onImageUpload(images, this.insertImage);
    }

    console.log('onImageUpload', images);
  }

  focus = () => {
    this.editor.summernote('focus');
  }

  isEmpty = () => {
    return this.editor.summernote('isEmpty');
  }

  reset = () => {
    this.editor.summernote('reset');
  }

  replace = (content) => {
    const { noteEditable, notePlaceholder } = this;
    const prevContent = noteEditable.html();
    const contentLength = content.length;

    if (prevContent !== content) {
      if (this.isEmpty() && contentLength > 0) {
        notePlaceholder.hide();
      } else if (contentLength === 0) {
        notePlaceholder.show();
      }

      noteEditable.html(content);
    }
  }

  disable = () => {
    this.editor.summernote('disable');
  }

  enable = () => {
    this.editor.summernote('enable');
  }

  toggleState = (disabled) => {
    if (disabled) {
      this.disable();
    } else {
      this.enable();
    }
  }

  insertImage = (url, filenameOrCallback) => {
    this.editor.summernote('insertImage', url, filenameOrCallback);

    console.log('insertImage', url);
  }

  insertNode = (node) => {
    this.editor.summernote('insertNode', node);
  }

  insertText = (text) => {
    this.editor.summernote('insertText', text);
  }

  get callbacks() {
    const props = this.props;

    return {
      onInit: this.onInit,
      onEnter: props.onEnter,
      onFocus: props.onFocus,
      onBlur: props.onBlur,
      onKeyup: props.onKeyUp,
      onKeydown: props.onKeyDown,
      onPaste: props.onPaste,
      onChange: (value) => {
        props.onChange(this.props.name, value);
      },
      onImageUpload: this.onImageUpload
    };
  }

  render() {
    const { children, className } = this.props;

    return (
      <div className={className}>
        <div ref={this.element}>{Services.htmlParse(this.props.value)}</div>
      </div>
    );
  }
}

TextEditor.propTypes = {
  tag: PropTypes.string, // will determing using div or textarea field for form components like redux-form
  children: PropTypes.node, // instead of value, using children makes more sense for div and textarea blocks
  codeview: PropTypes.bool,
  className: PropTypes.string,
  options: PropTypes.object,
  disabled: PropTypes.bool,
  onInit: PropTypes.func,
  onEnter: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  onPaste: PropTypes.func,
  onChange: PropTypes.func,
  onImageUpload: PropTypes.func
};

TextEditor.defaultProps = {
  name: '',
  onChange: {},
  value: '',
  height: 300,
}

export default TextEditor