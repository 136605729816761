import React, { Component } from "react";
import DataForm from "./../../../components/DataForm/DataForm";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import { Services } from "../../Service/Services";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";

class UserWorkplaceForm extends Component {
  // Set default state
  state = {
    data: {
      id: null,
      name: "",
      province_id: "",
      address: "",
      street: "",
      soi: "",
      district: "",
      sub_district: "",
      zip_code: "",
      tax_identity_no: "",
      active: true,
      h_code: "",
      supervisor: "",
      email: "",
    },
    datasource: {
      province: [],
    },
    redirect: false,
    loading: true,
  };

  retrieveData = (id) => {
    axios.get(`${this.props.url}/${id}`).then((res) => {
      const data = {
        id: res.data.id,
        name: res.data.name,
        province_id: res.data.province_id,
        address: res.data.address,
        street: res.data.street,
        soi: res.data.soi,
        district: res.data.district,
        sub_district: res.data.sub_district,
        zip_code: res.data.zip_code,
        tax_identity_no: res.data.tax_identity_no,
        active: res.data.active,
        h_code: res.data.h_code,
        supervisor: res.data.supervisor,
        email: res.data.email,
      };

      this.setState({ data, loading: false });
    });
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;

    const params = {
      tables: [{ name: "province" }],
    };

    axios.post("datasource", params).then((res) => {
      const province = res.data.province.map((item) => {
        return {
          key: item.id,
          label: item.name,
        };
      });

      const datasource = {
        province,
      };

      if (id !== undefined) {
        this.setState({ datasource });
        this.retrieveData(id);
      } else {
        const loading = false;
        this.setState({ loading, datasource });
      }
    });
  };

  getValue = (target) => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
  };

  onSubmit = (e) => {
    let params = this.state.data;

    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    loading = false;

    Services.submitRequest({ params, url })
      .then((res) => {
        this.setState({ redirect, loading });
      })
      .catch((errors) => {
        this.setState({ errors, loading });
      });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  };

  getFields = () => {
    var fields = [
      {
        label: "ชื่อสถานที่ทำงาน",
        name: "name",
        type: "textbox",
        required: true,
        value: this.state.data.name,
      },
      {
        label: "H Code",
        name: "h_code",
        type: "textbox",
        required: false,
        value: this.state.data.h_code,
      },
      {
        label: "ชื่อหัวหน้าต้นสังกัด",
        name: "supervisor",
        type: "textbox",
        required: false,
        value: this.state.data.supervisor,
      },
      {
        label: "หมายเลขประจำตัวผู้เสียภาษี",
        name: "tax_identity_no",
        type: "textbox",
        required: false,
        value: this.state.data.tax_identity_no,
      },
      {
        label: "อีเมล",
        name: "email",
        type: "textbox",
        required: false,
        value: this.state.data.email,
      },
      {
        label: "ที่อยู่",
        name: "address",
        type: "textbox",
        required: false,
        value: this.state.data.address,
      },
      {
        label: "ถนน",
        name: "street",
        type: "textbox",
        required: false,
        value: this.state.data.street,
      },
      {
        label: "ซอย",
        name: "soi",
        type: "textbox",
        required: false,
        value: this.state.data.soi,
      },
      {
        label: "เขต/อำเภอ",
        name: "district",
        type: "textbox",
        required: false,
        value: this.state.data.district,
      },
      {
        label: "แขวง/ตำบล",
        name: "sub_district",
        type: "textbox",
        required: false,
        value: this.state.data.sub_district,
      },
      {
        label: "จังหวัด",
        name: "province_id",
        type: "ddl",
        required: false,
        value: this.state.data.province_id,
        datasource: this.state.datasource.province,
      },
      {
        label: "รหัสไปรษณีย์",
        name: "zip_code",
        type: "textbox",
        required: false,
        value: this.state.data.zip_code,
      },
      {
        label: "Active",
        name: "active",
        type: "switch",
        required: false,
        value: this.state.data.active,
      },
    ];

    return fields;
  };

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
        ></DataForm>
      </BoxContainer>
    );
  }
}

UserWorkplaceForm.setDefaultProps = {
  url: "",
};

export default UserWorkplaceForm;
