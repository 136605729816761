import React, { Component, useState } from "react";
import axios from "../../axios";
import BoxContainer from "../../components/BoxContainer/BoxContainer";
import TitleHeader from "../../components/TitleHeader/TitleHeader";
import FormInput from "../../components/DataForm/FormInput";
import { Alert } from "../Service/Services";
import { AdminToolsAPI } from "../../api";
import { Redirect } from "react-router-dom";
import { authenticationService } from "../Authentication/Service/authentication.service";

const AdminTools = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [values, setValues] = useState({});

  const user = authenticationService.currentUserValue;
  if (user.roles.includes("admin") === false) return <Redirect to="/404" />;

  const changePasswordFields = [
    {
      type: "email",
      value: values.email ?? "",
      name: "email",
      label: "Email",
      required: true,
    },
    {
      type: "password",
      value: values.newPassword ?? "",
      name: "newPassword",
      label: "New Password",
      required: true,
    },
    {
      type: "password",
      value: values.confirmPassword ?? "",
      name: "confirmPassword",
      label: "Confirm New Password",
      required: true,
    },
  ];

  const getValue = (value) => {
    setValues((prev) => {
      return { ...prev, ...value };
    });
  };

  const isValidChangePasswordForm = () => {
    const formErrors = [];
    if (values.email.trim() === "") formErrors.push("กรุณากรอก Email");
    if (values.newPassword.trim() === "")
      formErrors.push("กรุณากรอก New Password");
    if (values.confirmPassword.trim() === "")
      formErrors.push("กรุณากรอก Confirm New Password");
    else if (values.confirmPassword.trim() !== values.newPassword.trim())
      formErrors.push("Confirm New Password จะต้องเท่ากับ New Password");

    return formErrors;
  };

  const onChangePasswordSubmitted = (e) => {
    e.preventDefault();
    const formErrors = isValidChangePasswordForm();
    if (formErrors.length > 0) return setErrors(formErrors);

    Alert.confirm("คุณแน่ใจไหมที่จะเปลี่ยนรหัสผ่าน").then((res) => {
      const email = values.email.trim();
      const password = values.newPassword.trim();
      if (res === true) {
        AdminToolsAPI.changePassword(
          values.email.trim(),
          values.newPassword.trim()
        )
          .then(() => {
            setErrors([]);
            Alert.done(
              `รหัสผ่านถูกเปลี่ยนเรียบร้อย \n\nEmail: ${email} \nPassword: ${password}`
            );
          })
          .catch((errors) => {
            setErrors(errors);
          });
      }
    });
  };

  return (
    <div>
      <TitleHeader
        program_name="เครื่องมือสำหรับผู้ดูแลระบบ"
        program_type="Admin Tools"
      ></TitleHeader>
      <BoxContainer loading={isLoading} errors={errors}>
        <form onSubmit={onChangePasswordSubmitted}>
          <div className="card">
            <div className="card-header">
              <h3>Change Password</h3>
            </div>
            <div className="card-body">
              {changePasswordFields.map((field) => (
                <FormInput key={field.name} field={field} getValue={getValue} />
              ))}
            </div>
            <div className="card-footer">
              <button className="btn btn-primary btn-block">
                Change Password
              </button>
            </div>
          </div>
        </form>
      </BoxContainer>
    </div>
  );
};

export default AdminTools;
