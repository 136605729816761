import React, { Component } from "react";
import { Auth } from "../../Service/Services";
import FormInput from "./../../../components/DataForm/FormInput";

class MeetingKeynoteSpeakerTotal extends Component {
  getValue = (target, index) => {
    const new_data = { ...this.props.data };
    const name = Object.keys(target)[0];
    const value = Object.values(target)[0];
    new_data[name] = value;

    this.props.onChange(new_data);
  };

  onDelete = (name, id) => {
    if (name.includes("asset_file_payment")) {
      const new_data = { ...this.props.data };
      const index = new_data.asset_file_payment_preview.findIndex(
        (item) => item.id === id
      );

      if (index !== -1) {
        new_data.asset_file_payment_preview[index].is_delete = true;
        new_data.asset_file_payment_delete_id.push(id);

        this.props.onChange(new_data);
      }
    }
  };

  getFields = (param) => {
    const fields = [
      {
        label: "ผลรวมค่าตอบแทน",
        type: "number",
        name: "total",
        value: this.props.data.total,
        disabled: param.is_admin === false
      },
      {
        label: "สถานะการชำระเงิน",
        name: "payment_status",
        type: "radio",
        required: false,
        inline: true,
        value: this.props.data.payment_status,
        datasource: this.props.datasource.payment_status,
      },
      {
        label: "ใบเสร็จ / เอกสารแนบ ",
        type: "file",
        name: "asset_file_payment[]",
        value: this.props.data.asset_file_payment_preview,
        multiple: true,
      },
    ];

    return fields;
  };

  render() {
    const is_admin = Auth.isRole("admin");
    let param = {
      is_admin: is_admin
    }
    const fields = this.getFields(param).map((field, index) => {      
      return (
        <FormInput
          key={index}
          field={field}
          getValue={this.getValue}
          onDelete={this.onDelete}
        ></FormInput>
      );
    });

    return <div className="panel-body">{fields}</div>;
  }
}

MeetingKeynoteSpeakerTotal.setDefaultProps = {
  datasource: [],
  onChange: {},
  data: [],
};

export default MeetingKeynoteSpeakerTotal;
