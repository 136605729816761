import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import UserPromotionForm from "./UserPromotionForm";
import TitleHeader from "../../../components/TitleHeader/TitleHeader";

class UserPromotion extends Component {
  render() {
    const columns = [
      {
        label: "โปรโมชั่น",
        align: "left",
        name: "name",
        search_type: "string",
      },
      {
        label: "ประเภทการลงทะเบียน",
        align: "center",
        name: "is_online",
      },
      {
        label: "ช่วงวันที่",
        align: "center",
        name: "start_date",
      },
    ];

    const api_url = "/master/promotion";

    return (
      <BrowserRouter>
        <div>
          <TitleHeader
            program_name="บริหารจัดการโปรโมชั่น"
            program_type="ข้อมูลบัญชีผู้ใช้งาน"
          ></TitleHeader>

          <Route
            exact
            path={`${this.props.match.path}/`}
            component={() => (
              <DataTable
                allow_import={false}
                columns={columns}
                allow_active={true}
                url={api_url}
              ></DataTable>
            )}
          />

          <Route
            path={`${this.props.match.path}/create`}
            component={(props) => (
              <UserPromotionForm {...props} url={api_url}></UserPromotionForm>
            )}
          />
          <Route
            path={`${this.props.match.path}/:id/edit`}
            component={(props) => (
              <UserPromotionForm {...props} url={api_url}></UserPromotionForm>
            )}
          />
        </div>
      </BrowserRouter>
    );
  }
}

export default UserPromotion;
