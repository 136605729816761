import axios from "../axios";
import { Services } from "../pages/Service/Services";

const getMember = (code) => {
  const promise = new Promise((resolve, reject) => {
    const url = `offline/checkin/${code}`;
    axios
      .get(url)
      .then((res) => resolve(res.data))
      .catch(reject);
  });

  return promise;
};

const saveMember = (data, id) => {
  const promise = new Promise((resolve, reject) => {
    const url = `offline/checkin/${id}`;
    axios
      .put(url, data)
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(Services.getErrorArray(error));
        Services.goToTop();
      });
  });

  return promise;
};

const replaceMember = (data, id) => {
  const promise = new Promise((resolve, reject) => {
    const url = `offline/checkinreplace/${id}`;
    axios
      .put(url, data)
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(Services.getErrorArray(error));
        Services.goToTop();
      });
  });

  return promise;
};

const getMonitor = () => {
  const promise = new Promise((resolve, reject) => {
    const url = `offline/monitor`;
    axios
      .get(url)
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(Services.getErrorArray(error));
        Services.goToTop();
      });
  });

  return promise;
};

export default {
  getMember,
  saveMember,
  replaceMember,
  getMonitor,
};
