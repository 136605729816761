import swal from "sweetalert";
import axios from "../../axios";
import * as moment from "moment";
import cookie from "js-cookie";
var HtmlToReactParser = require("html-to-react").Parser;
const $ = window.$;
// const MONTHS = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
// const MONTHS_TH = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'];
const FULL_MONTHS_TH = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];
export const Services = {
  response,
  error,
  getThaiDateFullFormat,
  convertDate,
  convertToMoney,
  convertMoneyToInt,
  convertToDate,
  submitRequest,
  submitRequestWithFile,
  goToTop,
  convertToDecimal,
  htmlParse,
  goToTopModal,
  isNumeric,
  getErrorArray,
};

export const Auth = {
  isAuth,
  getToken,
  isRole,
  getCurrentUser,
};

export const Alert = {
  confirm(text, title = "ยืนยัน") {
    const promise = new Promise((resolve, reject) => {
      swal({
        title: title,
        text: text,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((confirm) => {
        resolve(confirm);
      });
    });

    return promise;
  },
  done(text = "บันทึกข้อมูลเสร็จสิ้น", title = "Success") {
    const promise = new Promise((resolve, reject) => {
      swal({
        title: title,
        text: text,
        icon: "success",
        button: "OK",
      }).then((res) => {
        resolve(res);
      });
    });

    return promise;
  },
  error(text = "มีข้อผิดพลาด", title = "Error") {
    const promise = new Promise((resolve, reject) => {
      swal({
        title: title,
        text: text,
        icon: "error",
        button: "OK",
      }).then((res) => {
        resolve(res);
      });
    });

    return promise;
  },
};

function goToTop() {
  var body = $("html, body");
  body.stop().animate({ scrollTop: 0 }, 500, "swing");
}

function goToTopModal() {
  var modal = $(".modal");
  modal.stop().animate({ scrollTop: 0 }, 500, "swing");
}

function submitRequestWithFile(params, options = {}) {
  // const default_params = {
  //     url: '',
  //     params: [],
  // }

  const default_options = {
    show_popup: true,
  };

  options = {
    ...default_options,
    ...options,
  };

  const request_options = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  let form_data = new FormData();

  for (var key in params.params) {
    let value = params.params[key];
    if (value === null) {
      value = "";
    }

    if (value instanceof Array) {
      form_data.append(key, JSON.stringify(value));
    } else if (value instanceof FileList) {
      for (let i = 0; i < value.length; i++) {
        form_data.append(key, value[i]);
      }
    } else {
      form_data.append(key, value);
    }
  }

  const params_request = {
    url: params.url,
    // params: { ...form_data },
  };

  const promise = new Promise((resolve, reject) => {
    let request;
    const id = params.params.id;
    const url = params_request.url;

    if (id !== undefined && id !== null) {
      // Update
      form_data.append("_method", "PUT");
      request = axios.post(url + "/" + id, form_data, request_options);
    } else {
      // Insert
      request = axios.post(url, form_data, request_options);
    }

    request
      .then((result) => {
        if (options.show_popup === true) {
          swal({
            title: "Success",
            text: "บันทึกข้อมูลเสร็จสิ้น",
            icon: "success",
            button: "OK",
          }).then((res) => {
            resolve(result);
          });
        } else {
          resolve(result);
        }
      })
      .catch((res) => {
        // if(res.data){
        //     reject(res.data);
        // }else{

        console.log("catch service", res);

        try {
          var msg = [];

          if (res.data instanceof Array) {
            msg = res.data;
          } else {
            for (var i in res.data.errors) {
              res.data.errors[i].map((item) => {
                msg.push(item);
              });
            }
          }

          this.goToTop();
          reject(msg);
        } catch (error) {
          swal({
            title: "Error",
            text: "Something wrong, please try again latter.",
            icon: "error",
            button: "OK",
          }).then(() => reject([]));
          console.log(error);
        }
        // }
      });
  });

  return promise;
}

function submitRequest(params) {
  const default_params = {
    // method: 'post',
    url: "",
    params: [],
  };

  const params_request = {
    ...default_params,
    ...params,
  };

  const promise = new Promise((resolve, reject) => {
    let request;
    const id = params_request.params.id;
    const url = params_request.url;
    if (id !== undefined && id !== null && id !== "" && id !== 0) {
      // Update
      request = axios.put(url + "/" + id, params_request.params);
    } else {
      // Insert
      request = axios.post(url, params_request.params);
    }

    request
      .then((result) => {
        swal({
          title: "Success",
          text: "บันทึกข้อมูลเสร็จสิ้น",
          icon: "success",
          button: "OK",
        }).then((res) => {
          resolve(result);
        });
      })
      .catch((res) => {
        var msg = [];

        if (res.data) {
          for (var i in res.data.errors) {
            res.data.errors[i].map((item) => {
              msg.push(item);
            });
          }
        } else {
          for (var i in res.response.data.errors) {
            res.response.data.errors[i].map((item) => {
              msg.push(item);
            });
          }
        }

        this.goToTop();

        reject(msg);
      });
  });

  return promise;
}

function response(axios_promise) {
  var promise = new Promise((resolve, reject) => {
    axios_promise
      .then((res) => {
        swal({
          title: "Success",
          text: "บันทึกข้อมูลเสร็จสิ้น",
          icon: "success",
          button: "OK",
        }).then((res) => {
          resolve(res);
        });
      })
      .catch((res) => {
        var errors = [];
        if (res.status === 500) {
          var error_message = res.response.statusText;

          errors.push(error_message);
        } else {
          var msg = [];
          for (var i in res.response.data.errors) {
            res.response.data.errors[i].map((item) => {
              msg.push(item);
            });
          }

          errors = msg;
        }

        reject(errors);
      });
  });

  return promise;
}

function getErrorArray(error) {
  var msg = [];
  if (error.data) {
    for (var i in error.data.errors) {
      error.data.errors[i].map((item) => {
        msg.push(item);
      });
    }
  } else {
    for (var i in error.response.data.errors) {
      error.response.data.errors[i].map((item) => {
        msg.push(item);
      });
    }
  }

  return msg;
}

function error(error) {
  var promise = new Promise((resolve, reject) => {
    var error_message = "";
    if (error.status === 500) {
      error_message = error.response.statusText;
    } else {
      var msg = [];
      for (var i in error.response.data.errors) {
        error.response.data.errors[i].map((item) => {
          msg.push(item);
        });
      }

      error_message = msg.join("\n");

      // Show error in container will be good
    }
    swal({
      title: error_message,
      icon: "error",
      button: "OK",
    }).then((res) => {
      resolve(res);
    });
  });

  return promise;
}

function getThaiDateFullFormat(_date) {
  if (_date === null) return "";
  var date = new Date(_date);

  var day = date.getDate();
  var month = FULL_MONTHS_TH[date.getMonth()];
  var year = date.getFullYear() + 543;
  return day + " " + month + " " + year;
}

function convertDate(_date) {
  return moment(_date).format("DD/MM/YYYY");
}

function convertToDate(_date, format = "DD/MM/YYYY") {
  return moment(_date, format);
}

function convertToMoney(money, digit = 2) {
  if (money !== undefined && money !== null && money !== "") {
    money = parseFloat(money);
    return money.toLocaleString(undefined, {
      maximumFractionDigits: digit,
      minimumFractionDigits: digit,
    });
  }

  return "";
}
function convertMoneyToInt(money) {
  return parseInt(money.replace(/[^0-9]/g, ""));
}

function convertToDecimal(numeric) {
  const number = parseFloat(numeric);

  if (isNaN(number)) {
    return 0;
  } else {
    return number;
  }
}

function isAuth() {
  // const auth = cookie.get("_token_forum") !== undefined;
  const auth = localStorage.getItem("_token_forum") !== null;

  return auth;
}

function isRole(role) {
  // role = admin, staff, keynote_speaker, reviewer, super_reviewer
  const current_user = this.getCurrentUser();

  if (current_user !== null && current_user.roles.indexOf(role) !== -1) {
    return true;
  }

  return false;
}

function getCurrentUser() {
  const current_user = JSON.parse(localStorage.getItem("currentUser"));

  return current_user;
}

function getToken() {
  // const token = cookie.get("_token_forum");
  const token = localStorage.getItem("_token_forum");

  return token;
}

function htmlParse(html) {
  var htmlToReactParser = new HtmlToReactParser();
  const reactElement = htmlToReactParser.parse(html);

  return reactElement;
}

function isNumeric(str) {
  if (typeof str === "number") return true; // we only process strings!
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}
