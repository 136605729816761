import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import UserVIPForm from "./UserVIPForm";
import TitleHeader from "../../../components/TitleHeader/TitleHeader";
import { Alert, Auth, Services } from "../../Service/Services";
import axios from "../../../axios";
import swal from "sweetalert";
import GuestEmailToSupervisor from "./GuestEmailToSupervisor";

class UserVIP extends Component {
  constructor(props) {
    super(props);
    this.datatable_element = React.createRef();
  }

  onImportFile = (params) => {
    const import_url = "/importguest";

    const new_params = {
      params,
      url: import_url,
    };

    Services.submitRequestWithFile(new_params)
      .then((res) => {
        this.datatable_element.current.refresh();
      })
      .catch((err) => {
        swal({
          title: "Error",
          text: err.join("\n"),
          icon: "error",
          button: "OK",
        });
        console.log("catch", err);
      });
  };

  printReport = (criteria) => {
    const { keyword, filter } = criteria;
    const params = [
      ["keyword", keyword],
      ["filter", JSON.stringify(filter)],
    ];

    const url =
      axios.defaults.baseURL +
      "/report/guest?" +
      params.map((i) => i[0] + "=" + i[1]).join("&");

    window.open(url, "_blank");
  };

  printLetter = (criteria) => {
    const { keyword, filter } = criteria;
    const params = [
      ["keyword", keyword],
      ["filter", JSON.stringify(filter)],
    ];

    const url =
      axios.defaults.baseURL +
      "/report/guestletter?" +
      params.map((i) => i[0] + "=" + i[1]).join("&");

    window.open(url, "_blank");
  };

  onPrintClick = (params) => {
    const key = params.key;
    const url = axios.defaults.baseURL + "/report/guestletter/" + key;

    window.open(url, "_blank");
  };

  onPrintAllCard = () => {
    const print_url = axios.defaults.baseURL + "/guestprintcard";
    window.open(print_url, "_blank");
  };

  sendEmail = () => {
    Alert.confirm("คุณแน่ใจไหมที่จะส่งอีเมล").then((res) => {
      if (res === true) {
        this.setState({
          loading: true,
        });
        const url = "emailtoguest";
        axios.get(url).then((res) => {
          Alert.done(
            "อีเมลถูกส่งแล้ว " + res.data.completeCount + " ฉบับ"
          ).then(() => {
            this.setState({
              loading: false,
            });
          });
        });
      }
    });
  };

  render() {
    const columns = [
      {
        label: "รหัสผู้ใช้งาน",
        align: "center",
        name: "code",
        search_type: "string",
        search: { range: false },
      },
      {
        label: "ชื่อ - นามสกุล",
        align: "left",
        name: "first_name",
        search_type: "string",
      },
      {
        label: "อีเมล",
        align: "left",
        name: "email",
        search_type: "string",
      },
      { label: "สถานะการพิมพ์", align: "center", name: "is_print" },
      { label: "สถานะการส่งอีเมล", align: "center", name: "is_email" },
      { label: "สถานะการแก้ไข", align: "center", name: "publish" },
      {
        label: "สถานะออกจดหมายต้นสังกัด",
        align: "center",
        name: "company_flag",
      },
      { label: "ประเภท", align: "left", name: "user_role", sortable: false },
      {
        label: "บัตรเข้าร่วมงาน",
        align: "center",
        name: "card",
        sortable: false,
        search: false,
      },
    ];

    const api_url = "/master/guest";
    const export_url = axios.defaults.baseURL + "/exportguest";
    const buttons = [
      {
        label: "พิมพ์รายงาน",
        className: "btn btn-primary",
        onClick: this.printReport,
      },
      {
        label: "พิมพ์จดหมาย",
        className: "btn btn-info",
        onClick: this.printLetter,
      },
      {
        label: "ส่งอีเมล",
        className: "btn btn-success",
        onClick: this.sendEmail,
      },
      {
        label:
          '<i className="fa fa-id-card-o"></i> พิมพ์บัตรเข้าร่วมงานทั้งหมด',
        className: "btn btn-warning",
        onClick: this.onPrintAllCard,
      },
      <GuestEmailToSupervisor />,
    ];

    return (
      <BrowserRouter>
        <div>
          <TitleHeader
            program_name="ลงทะเบียนแขกรับเชิญ"
            program_type="ข้อมูลบัญชีผู้ใช้งาน"
          ></TitleHeader>

          <Route
            exact
            path={`${this.props.match.path}/`}
            component={() => (
              <DataTable
                ref={this.datatable_element}
                allow_import={true}
                columns={columns}
                allow_active={true}
                allow_print={true}
                export_url={export_url}
                onPrintClick={this.onPrintClick}
                onImportFile={this.onImportFile}
                url={api_url}
                buttons={buttons}
              ></DataTable>
            )}
          />

          <Route
            path={`${this.props.match.path}/create`}
            component={(props) => (
              <UserVIPForm {...props} url={api_url}></UserVIPForm>
            )}
          />
          <Route
            path={`${this.props.match.path}/:id/edit`}
            component={(props) => (
              <UserVIPForm {...props} url={api_url}></UserVIPForm>
            )}
          />
        </div>
      </BrowserRouter>
    );
  }
}

export default UserVIP;
