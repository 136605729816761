import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import DataTable from "../../components/DataTable/DataTable";
import TitleHeader from "../../components/TitleHeader/TitleHeader";
import imgNotFound from "../../assets/images/imagenotfound.png";
import axios from "../../axios";
import { Alert, Auth } from "../Service/Services";
import BoxContainer from "../../components/BoxContainer/BoxContainer";
import siteService from "../Authentication/Service/site.service";
import { UserContext } from "../../context/UserContextProvider";
const $ = window.$;

class Site extends Component {
  static contextType = UserContext;

  state = {
    sites: [],
    loading: true,
  };

  changeSite = (site) => {
    Alert.confirm("คุณแน่ใจไหมที่จะเปลี่ยนงานประชุม").then((res) => {
      if (res === true) {
        this.context.updateSite(site);
        Alert.done("งานประชุมถูกเปลี่ยนเรียบร้อย").then(() =>
          window.location.reload()
        );
      }
    });
  };

  retrieve = () => {
    const currentSite = siteService.getCurrentSite();
    const api_url = "getsites";

    axios.get(api_url).then((res) => {
      const datasource = res.data;

      const sites = datasource.map((item, key) => {
        let current;
        let buttons = [];

        if (item.status === "currently") {
          current = (
            <span className="label label-primary">งานประชุมปัจจุบัน</span>
          );
        }

        if (item.logo === "") {
          item.logo = imgNotFound;
        }

        if (item.id === currentSite.id) {
          buttons.push(
            <span key="1" className="label label-primary">
              ถูกใช้อยู่
            </span>
          );
        } else {
          buttons.push(
            <a
              key="1"
              href="#"
              onClick={(e) => this.changeSite(item)}
              className="btn btn-block btn-lg btn-outline-secondary"
            >
              <i className="fa fa-refresh"></i> เปลี่ยน
            </a>
          );
        }

        return (
          <div className="col-lg-4" key={key}>
            <div className="contact-box center-version">
              {current}
              <a href="#">
                <img alt="image" className="rounded-circle" src={item.logo} />

                <h3 className="m-b-xs">
                  <strong style={{ lineHeight: "1.5" }}>{item.name}</strong>
                </h3>

                <div className="font-bold">{item.vision}</div>
                <address className="m-t-md">
                  <strong style={{ lineHeight: "1.5" }}>{item.place}</strong>
                </address>
              </a>
              <div className="contact-box-footer">{buttons}</div>
            </div>
          </div>
        );
      });

      this.setState({ sites, loading: false });
    });
  };

  componentDidMount = () => {
    this.retrieve();
  };

  render() {
    return (
      <BrowserRouter>
        <BoxContainer loading={this.state.loading}>
          <TitleHeader
            program_name="เปลี่ยนงานประชุม"
            program_type="การประชุม"
          ></TitleHeader>
          <div className="row mt-3">{this.state.sites}</div>
        </BoxContainer>
      </BrowserRouter>
    );
  }
}

export default Site;
