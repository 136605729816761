import React, { Component } from "react";
import DataForm from "./../../../components/DataForm/DataForm";
import FormInput from "./../../../components/DataForm/FormInput";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import { Services } from "../../Service/Services";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";
import DateRange from "../../../components/DateRange/daterange";

class AbstractPeriodForm extends Component {
  // Set default state
  state = {
    data: {
      abstract_create_start_date: "",
      abstract_create_end_date: "",
      abstract_reviewer_start_date: "",
      abstract_reviewer_end_date: "",
      abstract_super_reviewer_start_date: "",
      abstract_super_reviewer_end_date: "",

      abstract_create_hacc_start_date: "",
      abstract_create_hacc_end_date: "",
      abstract_reviewer_2_start_date: "",
      abstract_reviewer_2_end_date: "",
      abstract_super_reviewer_2_start_date: "",
      abstract_super_reviewer_2_end_date: "",
    },
    url: "master/abstract_period",
    redirect: false,
    loading: false,
  };

  retrieveData = () => {
    axios.get(`${this.state.url}`).then((res) => {
      const data = res.data;
      this.setState({ data });
    });
  };

  componentDidMount = () => {
    this.retrieveData();
  };

  datepickerCallback = (target) => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
  };

  onSubmit = (e) => {
    const params = this.state.data;
    const url = this.state.url;
    const redirect = true;
    let loading = true;

    params.id = 1;

    this.setState({ loading });

    loading = false;

    Services.submitRequest({ params, url })
      .then((res) => {
        this.setState({ redirect, loading });
      })
      .catch((errors) => {
        this.setState({ errors, loading });
      });
  };

  render() {
    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        <DataForm onSubmit={this.onSubmit}>
          <div className="form-group">
            <label className="col-form-label field-required">
              ระยะเวลาส่งผลงาน
            </label>
            <DateRange
              name_start="abstract_create_start_date"
              value_start={this.state.data.abstract_create_start_date}
              value_end={this.state.data.abstract_create_end_date}
              name_end="abstract_create_end_date"
              datepickerCallback={this.datepickerCallback}
            ></DateRange>
          </div>
          <div className="form-group">
            <label className="col-form-label field-required">
              ระยะเวลาส่งผลงานสำหรับ HACC
            </label>
            <DateRange
              name_start="abstract_create_hacc_start_date"
              value_start={this.state.data.abstract_create_hacc_start_date}
              value_end={this.state.data.abstract_create_hacc_end_date}
              name_end="abstract_create_hacc_end_date"
              datepickerCallback={this.datepickerCallback}
            ></DateRange>
          </div>
          <div className="form-group">
            <label className="col-form-label field-required">
              ระยะเวลาตรวจสอบโดยผู้เชี่ยวชาญ รอบ 1
            </label>
            <DateRange
              name_start="abstract_reviewer_start_date"
              value_start={this.state.data.abstract_reviewer_start_date}
              value_end={this.state.data.abstract_reviewer_end_date}
              name_end="abstract_reviewer_end_date"
              datepickerCallback={this.datepickerCallback}
            ></DateRange>
          </div>
          <div className="form-group">
            <label className="col-form-label field-required">
              ระยะเวลาตรวจสอบโดยผู้เชี่ยวชาญสูงสุด รอบ 1
            </label>
            <DateRange
              name_start="abstract_super_reviewer_start_date"
              value_start={this.state.data.abstract_super_reviewer_start_date}
              value_end={this.state.data.abstract_super_reviewer_end_date}
              name_end="abstract_super_reviewer_end_date"
              datepickerCallback={this.datepickerCallback}
            ></DateRange>
          </div>

          <div className="form-group">
            <label className="col-form-label field-required">
              ระยะเวลาตรวจสอบโดยผู้เชี่ยวชาญ รอบ 2
            </label>
            <DateRange
              name_start="abstract_reviewer_2_start_date"
              value_start={this.state.data.abstract_reviewer_2_start_date}
              value_end={this.state.data.abstract_reviewer_2_end_date}
              name_end="abstract_reviewer_2_end_date"
              datepickerCallback={this.datepickerCallback}
            ></DateRange>
          </div>
          <div className="form-group">
            <label className="col-form-label field-required">
              ระยะเวลาตรวจสอบโดยผู้เชี่ยวชาญสูงสุด รอบ 2
            </label>
            <DateRange
              name_start="abstract_super_reviewer_2_start_date"
              value_start={this.state.data.abstract_super_reviewer_2_start_date}
              value_end={this.state.data.abstract_super_reviewer_2_end_date}
              name_end="abstract_super_reviewer_2_end_date"
              datepickerCallback={this.datepickerCallback}
            ></DateRange>
          </div>
        </DataForm>
      </BoxContainer>
    );
  }
}

AbstractPeriodForm.setDefaultProps = {
  url: "",
};

export default AbstractPeriodForm;
