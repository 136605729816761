import React, { Component } from "react";
import DataForm from "./../../../components/DataForm/DataForm";
import FormInput from "./../../../components/DataForm/FormInput";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import { Services } from "../../Service/Services";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";

class LogBackendForm extends Component {
  // Set default state
  state = {
    data: {
      created_at_text: "",
      program_name: "",
      username: "",
      description: "",
      short_description: "",
    },
    redirect: false,
    loading: true,
  };

  retrieveData = (id) => {
    axios.get(`${this.props.url}/${id}`).then((res) => {
      const data = res.data;
      this.setState({ data, loading: false });
    });
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;

    this.retrieveData(id);
  };

  getValue = (target) => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  };

  render() {
    const redirect = this.getRedirect();
    const url = this.props.url;

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm allow_save={false} url={url} getValue={this.getValue}>
          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <strong>วันที่ เวลา: </strong>
              {this.state.data.created_at}
            </div>
            <div className="col-12 col-md-6 mb-2">
              <strong>ผู้ดำเนินการ: </strong>
              {this.state.data.username}
            </div>
            <div className="col-12 col-md-6 mb-2">
              <strong>หัวข้อ: </strong>
              {this.state.data.short_description}
            </div>
            <div className="col-12 col-md-6 mb-2">
              <strong>โปรแกรม: </strong>
              {this.state.data.program_name}
            </div>
            <div className="col-12">
              <h3>รายละเอียด: </h3>
              {Services.htmlParse(this.state.data.description)}
            </div>
          </div>
        </DataForm>
      </BoxContainer>
    );
  }
}

LogBackendForm.setDefaultProps = {
  url: "",
};

export default LogBackendForm;
