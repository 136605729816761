import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Services } from "../../pages/Service/Services";
const $ = window.$;

class SearchTool extends Component {
  constructor(props) {
    super(props);
    this.file_element = React.createRef();
  }

  changeRecordPerPage = (e) => {
    this.props.changeRecordPerPage(parseInt(e.target.value));
  };

  importFile = (e) => {
    $(this.file_element.current).click();
  };

  uploadFile = (e) => {
    // const import_url = this.props.import_url;

    const { files } = e.target;

    const params = { import_file: files[0] };

    this.props.onImportFile(params);

    this.file_element.current.value = null;
  };

  isReactElement = (element) => {
    return React.isValidElement(element);
  };

  render() {
    const path = window.location.pathname;
    let buttons = [];

    if (this.props.allow_print === true && this.props.print_multiple === true) {
      buttons.push(
        <button
          key={1}
          type="button"
          className="btn btn-light"
          data-toggle="tooltip"
          data-placemeent="top"
          data-original-title="Export"
          title="Export"
        >
          <i className="fa fa-print"></i>
        </button>
      );
    }

    if (this.props.allow_trash === true && this.props.trash_multiple === true) {
      buttons.push(
        <button
          key={2}
          type="button"
          className="btn btn-light"
          data-toggle="tooltip"
          data-placemeent="top"
          data-original-title="Trash"
          title="Trash"
        >
          <i className="fa fa-trash"></i>
        </button>
      );
    }

    if (this.props.allow_import === true) {
      const export_url = this.props.export_url;
      const file_element = (
        <input
          ref={this.file_element}
          type="file"
          className="d-none"
          onChange={this.uploadFile}
        ></input>
      );

      // <div class="dropdown">
      //   <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
      //     Dropdown button
      //   </button>
      //   <div class="dropdown-menu">
      //     <a class="dropdown-item" href="#">Action</a>
      //     <a class="dropdown-item" href="#">Another action</a>
      //     <a class="dropdown-item" href="#">Something else here</a>
      //   </div>
      // </div>

      buttons.push(
        <div className="dropdown mx-2" key={4}>
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            นำเข้าข้อมูล
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" href={export_url}>
              ดาวน์โหลด Template
            </a>
            <a className="dropdown-item" onClick={this.importFile}>
              นำเข้าข้อมูล
            </a>
          </div>
          {file_element}
        </div>
      );
    }

    if (this.props.allow_insert === true) {
      buttons.push(
        <Link
          key={3}
          to={`${path}/create`}
          className="btn btn-success"
          data-toggle="tooltip"
          data-placemeent="top"
          data-original-title="Create"
          title="Create"
        >
          <i className="fa fa-plus"></i> เพิ่มข้อมูล
        </Link>
      );
    }

    if (this.props.buttons.length > 0) {
      this.props.buttons.map((button, key) => {
        if (this.isReactElement(button) === true) return buttons.push(button);

        let btnClassName = ["ml-2"];

        if (button.className) {
          btnClassName.push(button.className);
        } else {
          btnClassName.push("btn btn-primary");
        }

        if (button.is_multiple === true) {
          btnClassName.push("dropdown-toggle");

          const button_id = "btn_" + button.name + "_" + key;

          const sub_buttons = button.datasource.map(
            (sub_button, sub_button_key) => {
              return (
                <a
                  key={sub_button_key}
                  className="dropdown-item"
                  onClick={(e) =>
                    sub_button.onClick(
                      this.props.criteria,
                      this.props.selected_items
                    )
                  }
                >
                  {Services.htmlParse(sub_button.label)}
                </a>
              );
            }
          );

          buttons.push(
            <div className="dropdown" key={key + 5}>
              <button
                className={btnClassName.join(" ")}
                type="button"
                id={button_id}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {Services.htmlParse(button.label)}
              </button>
              <div className="dropdown-menu" aria-labelledby={button_id}>
                {sub_buttons}
              </div>
            </div>
          );
        } else {
          buttons.push(
            <button
              key={key + 5}
              className={btnClassName.join(" ")}
              onClick={(e) =>
                button.onClick(this.props.criteria, this.props.selected_items)
              }
            >
              {Services.htmlParse(button.label)}
            </button>
          );
        }
      });
    }

    return (
      <div className="mb-2 row">
        <div className="col-12 col-md-6">
          <div className="form-inline my-2">
            <div className="form-group">
              <label htmlFor="page-amount-input" className="mr-2">
                Show{" "}
              </label>
              <select
                className="form-control form-control-sm"
                id="page-amount-input"
                onChange={this.changeRecordPerPage}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={0}>ALL</option>
              </select>
              <span className="ml-2">records</span>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 text-right">
          <div id="toolbar">{buttons}</div>
        </div>
      </div>
    );
  }
}

SearchTool.defaultProps = {
  changeRecordPerPage: [],
  allow_print: false,
  print_multiple: false,
  trash_multiple: false,
  allow_trash: false,
  allow_insert: true,
  allow_import: true,
  export_url: "",
  import_url: "",
  onImportFile: {},
  buttons: [],
  criteria: {},
  selected_items: [],
};

export default SearchTool;
