import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import UserCardForm from './UserCardForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class UserCard extends Component {
    render() {
        const columns = [
            { label: 'ประเภท Template', align: 'left', name: 'card_type_name', sortable: false },
        ];

        const api_url = '/master/card';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='จัดการ Template เอกสาร' program_type='ข้อมูลบัญชีผู้ใช้งาน'></TitleHeader>
                    
                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable columns={columns} allow_active={false} url={api_url} allow_trash={false} allow_insert={false}></DataTable>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<UserCardForm {...props} url={api_url}></UserCardForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default UserCard
