import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import OfflineBackupForm from './OfflineBackupForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class OfflineBackup extends Component {
    render() {
        const columns = [
            { label: 'วันที่ เวลา', align: 'center', name: 'created_at', search_type: 'string' },
            { label: 'สถานะ', align: 'center', name: 'status' },
        ];

        const api_url = '/offline/backup';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ย้ายข้อมูล' program_type='Offline'></TitleHeader>
                    
                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable columns={columns} url={api_url} allow_active={false}></DataTable>)} />
                    
                    <Route path={`${this.props.match.path}/create`} component={props => (<OfflineBackupForm {...props} url={api_url}></OfflineBackupForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<OfflineBackupForm {...props} url={api_url}></OfflineBackupForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default OfflineBackup
