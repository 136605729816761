import React, { useEffect, useReducer } from "react";
import DataForm from "./../../../components/DataForm/DataForm";
import { useParams, useHistory } from "react-router-dom";
import axios from "../../../axios";
import { Alert, Services } from "../../Service/Services";
import Dropdown from "../../../components/Dropdown/Dropdown";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";
import { CheckInAPI } from "../../../api";
import moment from "moment";
import MemberType from "./MemberType";
const $ = window.$;
const _ = require("lodash");

const initState = {
  data: {
    id: "",
    code: "",
    identity_number: "",
    prefix: "",
    first_name: "",
    last_name: "",
    career: "",
    career_number: "",
    nurse_number: "",
    telephone: "",
    company_name: "",
    register_date: "",
    type: "",
    first_update: true,
  },
  datasource: {
    prefix_name: [],
    career: [],
  },
  loading: true,
};

const ACTION = {
  RETRIEVE: "retrieve",
  SET_LOADING: "set_loading",
  CLEAR: "clear",
  UPDATE_VALUE: "update_value",
  SET_ERRORS: "set_errors",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION.RETRIEVE:
      return {
        ...state,
        data: action.payload.data,
        datasource: action.payload.datasource,
        loading: false,
        errors: [],
      };
    case ACTION.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case ACTION.UPDATE_VALUE:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };

    case ACTION.SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };

    case ACTION.CLEAR:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          identity_number: "",
          prefix: "",
          first_name: "",
          last_name: "",
          career: "",
          career_number: "",
          nurse_number: "",
        },
        errors: [],
      };

    default:
      return state;
  }
};

const OfflineCheckInForm = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const { code } = useParams();
  const history = useHistory();

  const getAPIDatasource = () => {
    const api_url = "datasource";
    const params = {
      tables: [{ name: "career" }, { name: "prefix_name" }],
    };

    const promise = axios
      .post(api_url, params)
      .then((res) => {
        const datasource = {
          career: res.data.career.map((item) => {
            return { key: item.id, label: item.name, code: item.code };
          }),
          prefix_name: res.data.prefix_name.map((item) => {
            return { key: item.id, label: item.name };
          }),
        };

        return datasource;
      })
      .catch((res) => {
        console.log("error", res);
      });

    return promise;
  };

  useEffect(() => {
    getAPIDatasource().then((datasource) => {
      retrieveData(code, datasource);
    });
  }, []);

  const retrieveData = (code, datasource) => {
    dispatch({ type: ACTION.SET_LOADING, payload: true });
    CheckInAPI.getMember(code.trim())
      .then((data) => {
        dispatch({ type: ACTION.RETRIEVE, payload: { data, datasource } });
      })
      .catch((res) => {
        const errors = res.data.errors;
        dispatch({ type: ACTION.SET_ERRORS, payload: errors });
      });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const target = { [name]: value };
    dispatch({ type: ACTION.UPDATE_VALUE, payload: target });
  };

  const saveData = () => {
    dispatch({ type: ACTION.SET_LOADING, payload: true });
    const params = state.data;
    CheckInAPI.replaceMember(params, state.data.id)
      .then(() => {
        Alert.done("เปลี่ยนคนมาแทนเรียบร้อย").then(() => {
          history.push(`/offline/checkin`);
        });
      })
      .catch((errors) => {
        dispatch({ type: ACTION.SET_ERRORS, payload: errors });
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    Alert.confirm("คุณแน่ใจไหมที่จะบันทึกข้อมูล").then((res) => {
      if (res === true) saveData();
    });
  };

  const clearField = (e) => {
    e.preventDefault();

    dispatch({ type: ACTION.CLEAR });
  };

  const onBackClick = (e) => {
    e.preventDefault();
    history.push(`/offline/checkin/${state.data.code}`);
  };

  let buttons = (
    <>
      <button
        type="submit"
        className="btn btn-primary btn-block btn-lg"
        style={{ fontSize: "3em" }}
      >
        <i className="fa fa-floppy-o"></i> บันทึก
      </button>
      <button
        onClick={clearField}
        className="btn btn-warning btn-block btn-lg"
        style={{ fontSize: "3em" }}
      >
        <i className="fa fa-paint-brush"></i> ล้างข้อมูล
      </button>
      <button
        onClick={onBackClick}
        className="btn btn-info btn-block btn-lg"
        style={{ fontSize: "3em" }}
      >
        <i className="fa fa-chevron-left"></i> ย้อนกลับ
      </button>
    </>
  );

  const telephone = state.data.telephone;
  const company_name = state.data.company_name;
  const register_date = state.data.register_date;

  return (
    <BoxContainer loading={state.loading} errors={state.errors}>
      <DataForm allow_save={false} onSubmit={onSubmit} hideSeperateLine={true}>
        <div>
          <div className="alert alert-danger">
            <h2>
              <i className="fa fa-user"></i> เปลี่ยนคนมาแทน{" "}
              <MemberType value={state.data.type} />
            </h2>
          </div>
          <div className="d-flex mb-1 h3">
            <label htmlFor="code" className="pr-2">
              รหัสผู้ใช้:
            </label>
            <span>{state.data.code}</span>
          </div>
          <div className="d-flex mb-1 h3">
            <label htmlFor="identity_number" className="pr-2 field-required">
              เลขที่บัตรประชาชน:
            </label>
            <input
              type="text"
              className="flex-fill"
              id="identity_number"
              name="identity_number"
              onChange={onChange}
              value={state.data.identity_number}
              required={true}
              maxLength={13}
            ></input>
          </div>
          <div className="d-flex mb-1 h3">
            <label htmlFor="code" className="pr-2 field-required">
              คำนำหน้าชื่อ:
            </label>
            <div className="flex-fill">
              <Dropdown
                datasource={state.datasource.prefix_name}
                name="prefix"
                value={state.data.prefix}
                onChange={onChange}
                allow_new_item={true}
                required={true}
                placeholder="กรุณาเลือก"
              ></Dropdown>
            </div>
          </div>
          <div className="d-flex mb-1 h3">
            <label htmlFor="name" className="pr-2 field-required">
              ชื่อ:
            </label>
            <input
              type="text"
              className="flex-fill"
              id="first_name"
              name="first_name"
              onChange={onChange}
              value={state.data.first_name}
              required={true}
            ></input>
          </div>
          <div className="d-flex mb-1 h3">
            <label htmlFor="name" className="pr-2 field-required">
              นามสกุล:
            </label>
            <input
              type="text"
              className="flex-fill"
              id="last_name"
              name="last_name"
              onChange={onChange}
              value={state.data.last_name}
              required={true}
            ></input>
          </div>
          <div className="d-flex mb-1 h3">
            <label htmlFor="career_id" className="pr-2">
              วิชาชีพ:
            </label>
            <div className="flex-fill">
              <Dropdown
                datasource={state.datasource.career}
                name="career"
                value={state.data.career}
                onChange={onChange}
                allow_new_item={true}
                placeholder="กรุณาเลือก"
              ></Dropdown>
            </div>
          </div>
          <div className="d-flex mb-1 h3">
            <label htmlFor="career_number" className="pr-2">
              เลขที่ประกอบวิชาชีพ:
            </label>
            <input
              type="text"
              className="flex-fill"
              id="career_number"
              name="career_number"
              onChange={onChange}
              value={state.data.career_number}
            ></input>
          </div>
          <div className="d-flex mb-1 h3">
            <label htmlFor="nurse_number" className="pr-2">
              เลขที่สมาชิกสภาการพยาบาล:
            </label>
            <input
              type="text"
              className="flex-fill"
              id="nurse_number"
              name="nurse_number"
              onChange={onChange}
              value={state.data.nurse_number}
            ></input>
          </div>
          <div className="d-flex mb-1 h3">
            <label className="pr-2">เบอร์โทรศัพท์:</label>
            <span>{telephone}</span>
          </div>
          <div className="d-flex mb-1 h3">
            <label className="pr-2">ชื่อโรงพยาบาล:</label>
            <span>{company_name}</span>
          </div>
          <div className="d-flex mb-1 h3">
            <label className="pr-2">วันเวลาที่ลงทะเบียน:</label>
            {register_date
              ? moment(register_date).format("MMMM Do YYYY, h:mm:ss")
              : ""}
          </div>
          {buttons}
        </div>
      </DataForm>
    </BoxContainer>
  );
};

export default OfflineCheckInForm;
