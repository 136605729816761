import React, { Component } from "react";
import axios from "../../../axios";
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from "constants";

class AbstractScoreSummary extends Component {
  state = {
    data: {
      score_0: 0,
      score_1: 0,
      score_2: 0,
      waiting: 0,
    },
  };

  componentDidMount = () => {
    this.getAbstractScoreSummary();
  };

  getAbstractScoreSummary = () => {
    const url = "abstractscore";
    axios.get(url).then((res) => {
      const data = res.data;

      this.setState({
        data,
      });
    });
  };

  render() {
    const { score_0, score_1, score_2, waiting } = this.state.data;
    const iboxStyle = {
      marginBottom: 0,
      marginTop: "10px",
    };

    return (
      <div className="row">
        <div className="col-lg-3 col-12">
          <div className="ibox" style={iboxStyle}>
            <div className="ibox-title">
              <span className="label label-info">ไม่มีคะแนน</span>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins text-right">
                <i className="fa fa-book float-left"></i> {waiting}
              </h1>
              <small>ผลงาน</small>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-12">
          <div className="ibox" style={iboxStyle}>
            <div className="ibox-title">
              <span className="label label-danger">0 คะแนน</span>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins text-right">
                <i className="fa fa-book float-left"></i> {score_0}
              </h1>
              <small>ผลงาน</small>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-12">
          <div className="ibox" style={iboxStyle}>
            <div className="ibox-title">
              <span className="label label-warning">1 คะแนน</span>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins text-right">
                <i className="fa fa-book float-left"></i> {score_1}
              </h1>
              <small>ผลงาน</small>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-12">
          <div className="ibox" style={iboxStyle}>
            <div className="ibox-title">
              <span className="label label-primary">2 คะแนน</span>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins text-right">
                <i className="fa fa-book float-left"></i> {score_2}
              </h1>
              <small>ผลงาน</small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AbstractScoreSummary.defaultProps = {};

export default AbstractScoreSummary;
