import React, { Component } from 'react'
import FormInput from './../../../components/DataForm/FormInput';

class MeetingKeynoteSpeakerProfile extends Component {
    getValue = (target, index) => {
        const new_data = { ...this.props.data };
        const name = Object.keys(target)[0];
        const value = Object.values(target)[0];
        new_data[name] = value;
  
        this.props.onChange(new_data);
    }

    getFields = () => {
        const fields = [
            { label: 'ประวัติ ', type: 'file', name: 'profile_asset_file_id', value: this.props.data.profile_asset_file_preview, multiple: false },
        ]

        return fields;
    }

    render() {
        const fields = this.getFields().map((field, index) => {
            return <FormInput key={index} field={field} getValue={this.getValue}></FormInput>
        });

        return (
            <div className="panel-body">
                {fields}
            </div>
        )
    }
  }
  
MeetingKeynoteSpeakerProfile.setDefaultProps = {
    datasource: [],
    onChange: {},
    data: [],
}

export default MeetingKeynoteSpeakerProfile
