import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import SuperReviewerIndex from "./SuperReviewerIndex";
import SuperReviewerForm from "./SuperReviewerForm";
import TitleHeader from "../../components/TitleHeader/TitleHeader";
import axios from "../../axios";

class SuperReviewer extends Component {
  render() {
    const columns = [
      {
        label: "รหัสผลงาน",
        align: "center",
        name: "abstractions.abstract_no",
        search_type: "string",
      },
      {
        label: "หัวข้อ",
        align: "left",
        name: "abstract_topics.name",
        search_type: "string",
      },
      {
        label: "ชื่อผลงาน",
        align: "left",
        name: "abstractions.name",
        search_type: "string",
      },
      {
        label: "ผู้เชี่ยวชาญ",
        align: "left",
        name: "reviewers",
        sortable: false,
      },
      {
        label: "สรุปคะแนน",
        align: "center",
        name: "abstractions.score",
        sortable: true,
        search_type: "string",
      },
      { label: "เอกสาร", align: "left", name: "asset_file", sortable: false },
    ];

    const api_url = "/superreviewer";

    return (
      <BrowserRouter>
        <div>
          <TitleHeader
            program_name="ตรวจบทคัดย่อโดยผู้เชี่ยวชาญสูงสุด"
            program_type=""
          ></TitleHeader>

          <Route
            exact
            path={`${this.props.match.path}/`}
            component={() => (
              <SuperReviewerIndex columns={columns} url={api_url} />
            )}
          />

          <Route
            path={`${this.props.match.path}/create`}
            component={(props) => (
              <SuperReviewerForm {...props} url={api_url}></SuperReviewerForm>
            )}
          />
          <Route
            path={`${this.props.match.path}/:id/edit`}
            component={(props) => (
              <SuperReviewerForm {...props} url={api_url}></SuperReviewerForm>
            )}
          />
        </div>
      </BrowserRouter>
    );
  }
}

export default SuperReviewer;
