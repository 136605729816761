import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import AbstractPeriodForm from './AbstractPeriodForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class AbstractPeriod extends Component {
    render() {
        const api_url = '/abstract/period';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ช่วงเวลาส่งผลงาน' program_type='ผลงานวิชาการ'></TitleHeader>
                    
                    <Route path={`${this.props.match.path}`} component={props => (<AbstractPeriodForm {...props} url={api_url}></AbstractPeriodForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default AbstractPeriod
