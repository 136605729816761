import React, { Component } from 'react';
const $ = window.$;
class datepicker extends Component {
    constructor(props) {
        super(props);

        this.element = React.createRef();
    }

    componentDidMount() {
        $.fn.datepicker.dates['th'] = {
            days: ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัส", "ศุกร์", "เสาร์", "อาทิตย์"],
            daysShort: ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส", "อา"],
            daysMin: ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส", "อา"],
            months: ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"],
            monthsShort: ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."],
            today: "วันนี้"
        };
        $.fn.datepicker.defaults.language = 'th';
        $(this.element.current).datepicker({
            todayBtn: "linked",
            keyboardNavigation: false,
            forceParse: false,
            calendarWeeks: true,
            autoclose: true,
            format: 'dd/mm/yyyy',
        });
        
        var props = this.props;
        
        $(this.element.current).change(function (e) {
            props.datepickerCallback({ [e.target.name]: e.target.value });
        });
    }
    onChange = e => {
    }
    render() {
        return (
            // <div className="input-group date">
            //     <span className="input-group-addon">
            //         <i className="fa fa-calendar"></i>
            //     </span><input type="text" ref={this.element} className="form-control dateinput" autoComplete="off" name={this.props.name} onChange={this.onChange} defaultValue={this.props.value || ''} ></input>
            // </div>

            <div className="input-daterange input-group" id="datepicker" ref={this.element} >
                <span className="input-group-addon px-3"><i className='fa fa-calendar'></i></span>
                <input type="text" className="form-control dateinput" autoComplete="off" name={this.props.name_start} onChange={this.onChange} defaultValue={this.props.value_start || ''} ></input>
                <span className="input-group-addon px-3">ถึง</span>
                <input type="text" className="form-control dateinput" autoComplete="off" name={this.props.name_end} onChange={this.onChange} defaultValue={this.props.value_end || ''} ></input>
                
            </div>

        )
    }


}
export default datepicker;