import React, { Component } from "react";
import { Link } from "react-router-dom";
import ScoreInput from "../Abstract/ScoreInput/ScoreInput";
import Modal from "./../../components/Modal/Modal";
import axios from "../../axios";
import { Redirect } from "react-router-dom";
import { Services, Alert } from "../Service/Services";
import BoxContainer from "../../components/BoxContainer/BoxContainer";
import AbstractDetail from "../Abstract/AbstractDetail/AbstractDetail";

class ReviewerForm extends Component {
  // Set default state
  state = {
    data: {
      score: "",
      name: "",
      status: "1",
      active: true,
      allow_reject: false,
      is_allow_to_review: false,
    },
    datasource: {
      status: [
        { key: 1, label: "รอพิจารณา" },
        { key: 2, label: "0 คะแนน" },
        { key: 3, label: "1 คะแนน" },
        { key: 4, label: "2 คะแนน" },
      ],
    },
    redirect: false,
    loading: true,
  };

  retrieveData = (id) => {
    const url = this.props.url;

    axios.get(`${url}/${id}`).then(async (res) => {
      const data = res.data;
      const round = await this.getRound();
      data.round = round;
      this.setState({ data, loading: false });
    });
  };

  getRound = async () => {
    const url = "current/site";
    return axios.get(url).then((res) => {
      const site = res.data;
      let round = 0;

      if (site.complete_abstract === false) {
        round = 1;
      } else if (
        site.complete_abstract === true &&
        site.complete_abstract2 === false
      ) {
        round = 2;
      }

      return round;
    });
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;

    if (id !== undefined) {
      this.retrieveData(id);
    } else {
      const loading = false;
      this.setState({ loading });
    }
  };

  getValue = (target) => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
  };

  onSelectScore = (point) => {
    const abstract_no = this.state.data.abstract_no;
    Alert.confirm(
      "คุณแน่ใจใช่ไหมที่จะให้คะแนน " +
        point +
        " คะแนน ให้กับผลงานหมายเลข " +
        abstract_no
    ).then((res) => {
      if (res === true) {
        let loading = true;
        this.setState({ loading });

        const id = this.props.match.params.id;
        const redirect = true;
        const url = this.props.url;

        const params = { score: point, id };

        Services.submitRequest({ params, url })
          .then((res) => {
            this.setState({ redirect, loading });
          })
          .catch((errors) => {
            this.setState({ errors, loading });
          });
      }
    });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  };

  onRejectSubmit = (value) => {
    let loading = true;
    this.setState({ loading });

    const id = this.props.match.params.id;
    const redirect = true;
    const url = this.props.url;

    const params = {
      status: "reject",
      ...value,
      id,
    };

    Services.submitRequestWithFile({ params, url })
      .then((res) => {
        this.setState({ redirect, loading });
      })
      .catch((errors) => {
        this.setState({ errors, loading });
      });
  };

  render() {
    const redirect = this.getRedirect();
    const url = this.props.url;
    const abstract_id = this.props.match.params.id;
    const abstract_no = this.state.data.abstract_no;
    const modal_reject_fields = [
      {
        label: "รายละเอียด",
        name: "comment",
        type: "textarea",
        required: true,
      },
      { label: "เอกสารแนบ", name: "asset_file", type: "file", required: false },
    ];

    const buttons = (
      <div>
        <div className="d-inline float-left">
          คะแนน &nbsp;&nbsp;
          <ScoreInput
            disabled={!this.state.data.is_allow_to_review}
            value={this.state.data.score}
            onSelectScore={this.onSelectScore}
            round={this.state.data.round}
          ></ScoreInput>
        </div>
        <Link className="btn btn-white float-right mx-2" to={url}>
          ย้อนกลับ
        </Link>
        <div className="float-right">
          {this.state.data.allow_reject && (
            <Modal
              button_class="btn btn-danger"
              label="แจ้งแก้ไข"
              title="แจ้งแก้ไข"
              confirmation={{
                text: "คุณแน่ใจไหมที่จะแจ้งแก้ไขผลงานเลขที่ " + abstract_no,
              }}
              fields={modal_reject_fields}
              getValue={this.onRejectSubmit}
            ></Modal>
          )}
        </div>
      </div>
    );

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <div className="form-group row">
          <div className="col-12 text-right">{buttons}</div>
        </div>
        <div className="hr-line-dashed"></div>
        <AbstractDetail
          showHistory={false}
          abstract_id={abstract_id}
        ></AbstractDetail>
        <div className="hr-line-dashed"></div>
        <div className="form-group row">
          <div className="col-12 text-right">{buttons}</div>
        </div>
      </BoxContainer>
    );
  }
}

ReviewerForm.setDefaultProps = {
  url: "",
};

export default ReviewerForm;
