import React, { Component } from 'react'
import DataForm from './../../../components/DataForm/DataForm';
import FormInput from './../../../components/DataForm/FormInput';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import { Services } from '../../Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import MeetingRoomStaff from './MeetingRoomStaff';
import MeetingRoomSection from './MeetingRoomSection';

class MeetingRoomForm extends Component {
  // Set default state
  state = {
    data:{
      name: '',
      theme_name: '',
      amount: '',
      room_users: [],
      room_sections: [],
      active: true,
      publish: false,
    },
    datasource: {
      section: [],
      staff: [],
      room_role: []
    },
    redirect: false,
    loading: true,
  }

  retrieveData = (id, datasource) => {
    axios.get(`${this.props.url}/${id}`).then(res => {
      const data = res.data;
      const new_data = {
        id: data.id,
        name: data.name,
        theme_name: data.theme_name,
        amount: data.amount,
        room_users: data.room_users,
        room_sections: data.room_sections,
        active: data.active,
        publish: data.publish,
      }

      this.setState({ loading: false, data: new_data, datasource });
    });
  }

  init = () => {
    const promise = new Promise((resolve) => {
      const params = {
        tables: [
          {name: 'staff'},
          {name: 'section'},
          {name: 'room_role'},
        ],
      }
  
      axios.post('datasource', params).then(res => {
        const staff = res.data.staff.map( item => {
          return {
            key: item.id,
            label: item.name,
          };
        });
  
        const section = res.data.section.map( item => {
          return {
            key: item.id,
            label: item.label,
            description: item.description,
          };
        });

        const room_role = res.data.room_role.map( item => {
          return {
            key: item.id,
            label: item.name,
          };
        });
  
        const datasource = {
          staff,
          section,
          room_role
        };

        resolve(datasource);
      });
    });

    return promise;
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;

    this.init().then( datasource => {
      if(id !== undefined){
        this.retrieveData(id, datasource);
      }else{
        this.setState({ loading: false, datasource });
      }
    });
  }

  getValue = target => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target
      }
    });
  }

  onRoomStaffChange = target => {
    let data = { ...this.state.data };
    data.room_users = target;

    this.setState({ data });
  }

  onRoomSectionChange = target => {
    let data = { ...this.state.data };
    data.room_sections = target;

    this.setState({ data });
  }

  onSubmit = e => {
    const params = this.state.data;
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    Services.submitRequest({ params, url }).then( res => { this.setState({redirect, loading: false}) }).catch( errors => {this.setState({ errors,loading: false })});
  }

  getRedirect = () => {
    const redirect = this.state.redirect;
    
    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  }

  getFields = () => {
    var fields = [
      { label: 'ชื่อห้อง', name: 'name', type: 'textbox', required: true, value: this.state.data.name },
      { label: 'ชื่อธีมห้อง', name: 'theme_name', type: 'textbox', required: true, value: this.state.data.theme_name },
      { label: 'จำนวนที่รองรับได้สูงสุด', name: 'amount', type: 'textbox', required: true, value: this.state.data.amount },
      { label: 'เผยแพร่', name: 'publish', type: 'switch', required: false, value: this.state.data.publish },
      { label: 'Active', name: 'active', type: 'switch', required: false, value: this.state.data.active },
    ];

    return fields;
  }

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
        >
          <div className="tabs-container">
              <ul className="nav nav-tabs" role="tablist">
                  <li><a className="nav-link active" data-toggle="tab" href="#tab-1">ผู้รับผิดชอบ</a></li>
                  <li><a className="nav-link" data-toggle="tab" href="#tab-2">หัวข้อการประชุม</a></li>
              </ul>
              <div className="tab-content">
                  <div role="tabpanel" id="tab-1" className="tab-pane active">
                      <div className="panel-body">
                        <MeetingRoomStaff
                          datasource={
                            {
                              staff: this.state.datasource.staff,
                              room_role: this.state.datasource.room_role
                            }
                          }
                          data={this.state.data.room_users}
                          onChange={this.onRoomStaffChange}
                        ></MeetingRoomStaff>
                      </div>
                  </div>
                  <div role="tabpanel" id="tab-2" className="tab-pane">
                      <div className="panel-body">
                        <MeetingRoomSection
                          datasource={this.state.datasource.section}
                          onChange={this.onRoomSectionChange}
                          data={this.state.data.room_sections}
                        ></MeetingRoomSection>
                      </div>
                  </div>
              </div>
           </div>
        </DataForm>
      </BoxContainer>
    )
  }
}

MeetingRoomForm.setDefaultProps = {
  url: '',
}

export default MeetingRoomForm
