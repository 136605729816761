import React, { Component } from 'react';
import './TimerPicker.css';
const $ = window.$;

class timepicker extends Component {
    constructor(props){
        super(props);

        this.clockpicker = React.createRef();
        this.timepicker = React.createRef();
    }

    componentDidMount() {
        $(this.clockpicker.current).clockpicker({
            afterDone: () => {
                let input = $(this.clockpicker.current).find('input:first');
                let value = input.val().replace(':', '.');
                
                input.val(value);
            }
        });

        var props = this.props;
        $(this.timepicker.current).change(function (e) {
            props.timepickerCallback(e);
        });
    }
    onChange = e => {
    }

    componentDidUpdate = () => {
        if(this.props.value !== $(this.timepicker.current).val()){
            $(this.timepicker.current).val(this.props.value).trigger('change');
        }
    }

    render() {
        return (
            <div >
                <div className="form-group">
                    <div className="input-group clockpicker" data-autoclose="true" ref={this.clockpicker}>
                        <input type="text" className="form-control timepicker" ref={this.timepicker} name={this.props.name} id={this.props.name} defaultValue={this.props.value || ''} autoComplete="off" onChange={this.onChange} required={this.props.required} placeholder="รูปแบบเวลา 00.00"/>
                        <span className="input-group-addon">
                            <span className="fa fa-clock-o"></span>
                        </span>
                    </div>
                </div>
            </div>
        )
    }


}
timepicker.defaultProps = {
    required: false,
    value: '',
}

export default timepicker;