import React, { Component } from 'react'
import DataForm from './../../../components/DataForm/DataForm';
import FormInput from './../../../components/DataForm/FormInput';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import { Services } from '../../Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import AbstractDetail from '../AbstractDetail/AbstractDetail';

class AbstractReviewForm extends Component {
  // Set default state
  state = {
    data:{
      abstract_no: '',
      abstract_type: '',
      abstract_topic_name: '',
      name: '',
      author: '',
      status: '',
      asset_file_preview: '',
      asset_file_created_at: '',
    },
    datasource: { },
    redirect: false,
    loading: false,
  }

  render() {
    const url = this.props.url;
    const abstract_id = this.props.match.params.id;

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        <DataForm url={url} allow_save={false}>
          <AbstractDetail abstract_id={abstract_id}></AbstractDetail>
        </DataForm>
      </BoxContainer>
    )
  }
}

AbstractReviewForm.setDefaultProps = {
  url: '',
}

export default AbstractReviewForm
