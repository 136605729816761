import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { AuthenTokenAPI } from "../../../api";
import { authenticationService } from "../Service/authentication.service";
import { history } from "../../../pages/Authentication/_helpers";

class AuthToken extends Component {
  state = {
    token: null,
    redirect: false,
  };
  componentDidMount() {
    const { token, type } = this.props.match.params;

    // http://localhost:3001/authtoken/keynote/40wCx7nb1w

    if (token) {
      AuthenTokenAPI.getAuthByToken({ token, type })
        .then((res) => {
          const loginInfo = authenticationService.setLoginInfo(res);
          let { from } = this.props.location.state || {
            from: { pathname: "/" },
          };

          from = authenticationService.getRedirectPath({
            ...loginInfo,
            defaultPath: from,
          });

          this.props.history.push(from);
          history.go(0);
        })
        .catch((err) => {
          return this.setState({ redirect: true });
        });
    } else {
      return this.setState({ redirect: true });
    }
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
  };

  render() {
    return <>{this.renderRedirect()}</>;
  }
}
export default AuthToken;
