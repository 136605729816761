import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import PublishDocumentForm from './PublishDocumentForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class PublishDocument extends Component {
    render() {
        const columns = [
            { label: 'หมวดหมู่หลัก', align: 'left', name: 'publish_categories.name', search_type: 'string' },
            { label: 'หมวดหมู่ย่อย', align: 'left', name: 'publish_subcategories.name', search_type: 'string' },
            { label: 'หัวข้อ', align: 'left', name: 'publishes.title', search_type: 'string' },
            { label: 'เอกสาร', align: 'left', name: 'publishes.name', search_type: 'string' },
        ];

        const api_url = '/publish/document';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='เอกสารเผยแพร่' program_type='เอกสารเผยแพร่'></TitleHeader>
                    
                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable columns={columns} allow_active={true} url={api_url}></DataTable>)} />
                    
                    <Route path={`${this.props.match.path}/create`} component={props => (<PublishDocumentForm {...props} url={api_url}></PublishDocumentForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<PublishDocumentForm {...props} url={api_url}></PublishDocumentForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default PublishDocument
