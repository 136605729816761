import React, { Component } from 'react'
import DataForm from './../../../components/DataForm/DataForm';
import FormInput from './../../../components/DataForm/FormInput';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import { Services } from '../../Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import MeetingWorkshopSection from './MeetingWorkshopSection';

class MeetingWorkshopForm extends Component {
  // Set default state
  state = {
    data:{
      room_id: '',
      name: '',
      capacity: '',
      transaction_date: '',
      start_time: '',
      end_time: '',
      date_register_start: '',
      date_register_end: '',
      active: true,
      workshop_sections: [],
    },
    datasource: {
      section: [],
      room: [],
    },
    redirect: false,
    loading: false,
  }

  retrieveData = (id, datasource) => {
    axios.get(`${this.props.url}/${id}`).then(res => {
      const data = res.data;
      const new_data = {
        id: data.id,
        room_id: data.room_id,
        name: data.name,
        capacity: data.capacity,
        transaction_date: data.transaction_date_text,
        start_time: data.start_time,
        end_time: data.end_time,
        date_register_start: data.date_register_start_text,
        date_register_end: data.date_register_end_text,
        active: data.active,
        workshop_sections: data.workshop_sections,
      }

      this.setState({ loading: false, data: new_data, datasource });
    });
  }

  init = () => {
    const promise = new Promise((resolve) => {
      const params = {
        tables: [
          { name: 'room' },
          { name: 'section' },
        ],
      }
  
      axios.post('datasource', params).then(res => {
        const room = res.data.room.map( item => {
          return {
            key: item.id,
            label: item.name,
          };
        });

        const section = res.data.section.map( item => {
          return {
            key: item.id,
            label: item.name,
            description: item.description,
          };
        });
  
        const datasource = {
          room,
          section
        };

        resolve(datasource);
      });
    });

    return promise;
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;

    this.init().then( datasource => {
      if(id !== undefined){
        this.retrieveData(id, datasource);
      }else{
        this.setState({ loading: false, datasource });
      }
    });
  }

  getValue = target => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target
      }
    });
  }

  onWorkshopSectionChange = target => {
    let data = { ...this.state.data };
    data.workshop_sections = target;

    this.setState({ data });
  }

  onSubmit = e => {
    const params = this.state.data;
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    Services.submitRequest({ params, url }).then( res => { this.setState({redirect, loading: false}) }).catch( errors => {this.setState({ errors,loading: false })});
  }

  getRedirect = () => {
    const redirect = this.state.redirect;
    
    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  }

  getFields = () => {
    const id = this.state.id;

    var fields = [
      { label: 'ห้องประชุม', name: 'room_id', type: 'ddl', required: true, value: this.state.data.room_id, datasource: this.state.datasource.room },
      { label: 'ชื่อ Workshop', name: 'name', type: 'textbox', required: true, value: this.state.data.name },
      { label: 'จำนวนที่เปิดรับ', name: 'capacity', type: 'textbox', required: true, value: this.state.data.capacity },
      { label: 'วันที่เริ่ม', name: 'transaction_date', type: 'datepicker', required: true, value: this.state.data.transaction_date },
      { label: 'เวลาเริ่มต้น', name: 'start_time', type: 'timepicker', required: false, value: this.state.data.start_time },
      { label: 'เวลาสิ้นสุด', name: 'end_time', type: 'timepicker', required: false, value: this.state.data.end_time },
      { label: 'วันที่เปิดรับการลงทะเบียน', name: 'date_register', type: 'datepicker', required: true, isRange: true, value: [ this.state.data.date_register_start, this.state.data.date_register_end ] },
      { label: 'Active', name: 'active', type: 'switch', required: false, value: this.state.data.active },
    ];

    return fields;
  }

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
        >
          <MeetingWorkshopSection
            datasource={this.state.datasource.section}
            onChange={this.onWorkshopSectionChange}
            data={this.state.data.workshop_sections}
          ></MeetingWorkshopSection>
        </DataForm>
      </BoxContainer>
    )
  }
}

MeetingWorkshopForm.setDefaultProps = {
  url: '',
}

export default MeetingWorkshopForm