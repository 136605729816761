import React, { Component, memo } from "react";
import FormInput from "../DataForm/FormInput";
import { Alert, Services } from "../../pages/Service/Services";
const $ = window.$;

class Modal extends Component {
  constructor(props) {
    super(props);

    this.element = React.createRef();
  }

  state = {
    data: {},
    errors: [],
  };

  onSaveClick = () => {
    // e.preventDefault();

    const { onValidate } = this.props;

    if (this.props.confirmation !== null) {
      Alert.confirm(
        this.props.confirmation.text,
        this.props.confirmation.title
      ).then((res) => {
        if (res === true) {
          if (onValidate) {
            let _errors = onValidate(data);
            if (_errors.length > 0) {
              this.setState({ errors: _errors });
              Services.goToTopModal();
              return;
            }
          }

          const data = this.state.data;

          this.props.getValue(data);

          $(this.element.current).modal("hide");

          this.clearState();
        }
      });
    } else {
      const data = this.state.data;

      if (onValidate) {
        let _errors = onValidate(data);
        if (_errors.length > 0) {
          this.setState({ errors: _errors });
          Services.goToTopModal();
          return;
        }
      }

      this.props.getValue(data);

      $(this.element.current).modal("hide");

      this.clearState();
    }
  };

  clearState = () => {
    const defaultValues = {};
    this.props.fields.map((f) => {
      defaultValues[f.name] = f.default_value || "";
    });

    this.setState({ data: defaultValues, errors: [] });
  };

  showModal = () => {
    $(this.element.current).modal("show");
  };

  getValue = (target) => {
    let data = this.state.data;

    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
  };

  handleFieldKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.onSaveClick();
    }
  };

  handleFieldKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.onSaveClick();
    }
  };

  getFields = () => {
    const fields = this.props.fields.map((field, key) => {
      let value = this.state.data[field.name];

      field.value = value;

      return (
        <FormInput
          onKeyDown={this.handleFieldKeyDown}
          onKeyUp={this.handleFieldKeyUp}
          key={key}
          field={field}
          getValue={this.getValue}
          formValidate={false}
        />
      );
    });

    return fields;
  };

  componentDidMount = () => {
    // set default value;
    this.clearState();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      JSON.stringify(prevProps.fields) !== JSON.stringify(this.props.fields)
    ) {
      if (this.props.loading === false) {
        this.clearState();
        console.log("componentDidUpdate fields was changed");
      }
    }
  };

  getErrorContainer = () => {
    const errors = this.state.errors;
    const error_elements = errors.map((error_message, index) => {
      return (
        <li key={index}>
          <i className="fa fa-times"></i> {error_message}
        </li>
      );
    });

    if (errors.length > 0) {
      const error_container = (
        <div className="alert alert-danger" role="alert">
          <h3 className="alert-heading">มีข้อผิดพลาด !!!</h3>
          <ul className="list-unstyled">{error_elements}</ul>
        </div>
      );

      return error_container;
    }

    return;
  };

  render() {
    const fields = this.getFields();
    const { label, button_class, title } = this.props;
    const error_container = this.getErrorContainer();

    return (
      <>
        <button type="button" className={button_class} onClick={this.showModal}>
          {label}
        </button>

        <div className="text-left modal fade" ref={this.element}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header bg-primary">
                <h5 className="modal-title" id="exampleModalLabel">
                  {title}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                {error_container}
                {fields}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  onClick={this.onSaveClick}
                  className="btn btn-primary"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Modal.defaultProps = {
  button_class: "btn btn-primary",
  label: "Label",
  title: "Title",
  fields: [],
  value: {},
  getValue: {},
  confirmation: null, //{ title: '', text: '' }
  loading: false,
  onValidate: null,
};

export default memo(Modal);
