import React, { Component } from "react";
import Switcher from "./../Switcher/Switcher";
import LabelStatus from "./../LabelStatus/LabelStatus";
import DatePicker from "../Datepicker/datepicker";
import Timepicker from "../Timepicker/Timepicker";
import Dropdown from "../Dropdown/Dropdown";
import RadioInput from "../RadioInput/RadioInput";
import TextEditor from "../TextEditor/TextEditor";
import FileInput from "../FileInput/FileInput";
import Avatar from "../Avatar/Avatar";
import { Services } from "../../pages/Service/Services";
import axios from "../../axios";

class FormInput extends Component {
  state = {};
  componentDidMount() {}

  handleKeyDown = (e) => {
    if (this.props.onKeyDown && this.props.onKeyDown instanceof Function) {
      this.props.onKeyDown(e);
    }
  };

  handleKeyUp = (e) => {
    if (this.props.onKeyUp && this.props.onKeyUp instanceof Function) {
      this.props.onKeyUp(e);
    }
  };

  SwitchHandler = (active) => {
    this.props.getValue(active);
  };

  onCheckboxChange = (event) => {
    let value = [...this.props.field.value] || [];
    const target_value = event.target.value;
    const index = value.map((i) => "" + i).indexOf(target_value);

    if (index === -1) {
      value.push(target_value);
    } else {
      value.splice(index, 1);
    }

    this.props.getValue({ [event.target.name]: value });
  };

  onChange = (event) => {
    this.props.getValue({ [event.target.name]: event.target.value });
  };

  onChangeManual = (newValue) => {
    this.props.getValue(newValue);
  };

  onTextEditorChange = (name, value) => {
    this.props.getValue({ [name]: value });
  };

  onFileChange = (event) => {
    const { name, files } = event.target;

    if (files.length === 1) {
      this.props.getValue({ [name]: files[0] });
    } else {
      this.props.getValue({ [name]: files });
    }
  };

  onImageUpload = (images, callback) => {
    const request_options = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const url = "uploadasset";

    let form_data = new FormData();
    if (!images.length) return;

    for (let i = 0; i < images.length; i++) {
      form_data.append("files[]", images[i], images[i].name);
    }

    axios.post(url, form_data, request_options).then((res) => {
      res.data.map((data) => {
        const url = data.url;
        const file_name = data.file_name;
        callback(url, file_name);
      });
    });
  };

  datepickerCallback = (event) => {
    if (this.props.datepickerCallback) {
      this.props.datepickerCallback({
        [event.target.name]: event.target.value,
      });
    } else {
      this.props.getValue({ [event.target.name]: event.target.value });
    }
  };
  timepickerCallback = (event) => {
    if (this.props.timepickerCallback) {
      this.props.timepickerCallback({
        [event.target.name]: event.target.value,
      });
    } else {
      this.props.getValue({ [event.target.name]: event.target.value });
    }
  };
  selectValue = (event) => {
    this.props.selectValue(event.target.value);
  };
  render() {
    const { formValidate, field } = this.props;
    const maxLength = field.maxLength || 200;

    let input = (
      <input
        type="text"
        onKeyDown={this.handleKeyDown}
        onKeyUp={this.handleKeyUp}
        className="form-control"
        id={field.name}
        name={field.name}
        required={formValidate ? field.required : false}
        placeholder={field.placeholder}
        value={field.value || ""}
        onChange={this.onChange}
        maxLength={maxLength}
      />
    );

    if (field.type === "percent") {
      input = (
        <div className="input-group">
          <input
            type="number"
            onKeyDown={this.handleKeyDown}
            onKeyUp={this.handleKeyUp}
            className="form-control"
            id={field.name}
            name={field.name}
            required={formValidate ? field.required : false}
            placeholder={field.placeholder}
            value={field.value || ""}
            onChange={this.onChange}
          />
          <span className="input-group-addon">
            <i className="fa fa-percent"></i>
          </span>
        </div>
      );
    } else if (field.type === "number") {
      input = (
        <input
          type="number"
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          className="form-control"
          id={field.name}
          name={field.name}
          required={formValidate ? field.required : false}
          value={field.value || ""}
          onChange={this.onChange}
          disabled={field.disabled}
        />
      );
    } else if (field.type === "password") {
      input = (
        <input
          type="password"
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          className="form-control"
          id={field.name}
          name={field.name}
          required={formValidate ? field.required : false}
          value={field.value || ""}
          onChange={this.onChange}
          maxLength={maxLength}
        />
      );
    } else if (field.type === "url") {
      input = (
        <input
          type="url"
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          className="form-control"
          id={field.name}
          placeholder="http://www.google.com"
          name={field.name}
          required={formValidate ? field.required : false}
          value={field.value || ""}
          onChange={this.onChange}
          maxLength={maxLength}
        />
      );
    } else if (field.type === "email") {
      input = (
        <input
          type="email"
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          className="form-control"
          id={field.name}
          placeholder="name@example.com"
          name={field.name}
          required={formValidate ? field.required : false}
          value={field.value || ""}
          onChange={this.onChange}
          maxLength={maxLength}
        />
      );
      // } else if (field.type === 'text') {
      //   input = <LabelStatus status={field.value}></LabelStatus >
    } else if (field.type === "label") {
      if (React.isValidElement(field.value)) {
        input = field.value;
      } else {
        input = <>{Services.htmlParse(field.value)}</>;
      }
    } else if (field.type === "switch") {
      input = (
        <Switcher
          name={field.name}
          disabled={field.disabled}
          value={field.value}
          onChange={this.SwitchHandler}
        ></Switcher>
      );
    } else if (field.type === "textarea") {
      input = (
        <textarea
          className="form-control"
          name={field.name}
          id={field.name}
          value={field.value || ""}
          onChange={this.onChange}
        ></textarea>
      );
    } else if (field.type === "ddl") {
      input = (
        <Dropdown
          multiple={field.multiple}
          name={field.name}
          datasource={field.datasource}
          required={formValidate ? field.required : false}
          disabled={field.disabled}
          value={field.value}
          onChange={this.onChange || field.onChange}
          minimumInputLength={field.minimumInputLength}
          allow_new_item={field.allow_new_item}
        ></Dropdown>
      );
    } else if (
      field.type === "dropdownlist" &&
      field.datasource !== undefined &&
      field.datasource !== null
    ) {
      const options = field.datasource.map((item, index) => {
        if (item.active) {
          return (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          );
        }
        return null;
      });
      input = (
        <select
          className="form-control"
          id={field.name}
          name={field.name}
          defaultValue={field.value}
        >
          {options}
        </select>
      );
    } else if (
      field.type === "dropdownlist_select_value" &&
      field.datasource !== undefined &&
      field.datasource !== null
    ) {
      const options = field.datasource.map((item, index) => {
        if (item.active) {
          return (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          );
        }
        return null;
      });
      input = (
        <select
          className="form-control"
          id={field.name}
          name={field.name}
          defaultValue={field.value}
          onChange={this.selectValue}
          disabled={field.disabled}
        >
          {options}
        </select>
      );
    } else if (field.type === "datepicker") {
      input = (
        <DatePicker
          name={field.name}
          datepickerCallback={this.datepickerCallback}
          value={field.value || ""}
          isRange={field.isRange}
          required={formValidate ? field.required : false}
        ></DatePicker>
      );
    } else if (field.type === "province" && field.datasource !== undefined) {
      const options = field.datasource.map((item, index) => (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      ));
      input = (
        <select
          className="form-control"
          id={field.name}
          name={field.name}
          defaultValue={field.value}
        >
          {options}
        </select>
      );
    } else if (field.type === "timepicker") {
      input = (
        <Timepicker
          name={field.name}
          timepickerCallback={this.timepickerCallback}
          value={field.value || ""}
          required={formValidate ? field.required : false}
        ></Timepicker>
      );
    } else if (field.type === "checkbox") {
      if (field.datasource) {
        const checkboxs = field.datasource.map((item, index) => {
          const id = field.name + "_" + item.key;
          const checked =
            field.value.map((i) => "" + i).indexOf(item.key + "") !== -1;

          return (
            <div className="form-check" key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                checked={checked}
                onChange={this.onCheckboxChange}
                value={item.key}
                name={field.name}
                id={id}
              />
              <label className="form-check-label" htmlFor={id}>
                {item.label}
              </label>
            </div>
          );
        });

        input = <div>{checkboxs}</div>;
      } else {
        input = (
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={field.value}
              onChange={(e) =>
                this.onChangeManual({
                  [field.name]: e.target.checked,
                })
              }
              value={field.value}
              name={field.name}
              id={field.name}
            />
          </div>
        );
      }
    } else if (field.type === "radio" && field.datasource !== undefined) {
      const radio = field.datasource.map((item, index) => {
        const checked = field.value == item.key;
        // const id = (field.name + '_' + item.key);
        const textbox_name = item.textbox_name;
        const textbox_value = item.textbox_value;

        return (
          <RadioInput
            textbox_name={textbox_name}
            textbox_value={textbox_value}
            key={index}
            trigger={item.trigger}
            value={item.key}
            checked={checked}
            name={field.name}
            label={item.label}
            onChange={this.onChange}
            inline={field.inline}
          ></RadioInput>
        );
      });

      input = <div>{radio}</div>;
    } else if (field.type === "texteditor") {
      // input = <Editor init={{ plugins: 'link table' }} />
      input = (
        <TextEditor
          onChange={this.onTextEditorChange}
          name={field.name}
          value={field.value}
          onImageUpload={this.onImageUpload}
        ></TextEditor>
      );
    } else if (field.type === "file") {
      input = (
        <FileInput
          onDelete={this.props.onDelete}
          showUpload={field.showUpload}
          multiple={field.multiple}
          extension={field.extension}
          onChange={this.onFileChange}
          name={field.name}
          value={field.value}
          required={formValidate ? field.required : false}
        ></FileInput>
      );
    } else if (field.type === "avatar") {
      input = (
        <Avatar
          required={formValidate ? field.required : false}
          value={field.value}
          name={field.name}
          onChange={(e) => this.props.getValue(e)}
          multiple={field.multiple}
          disabled={field.disabled}
        />
      );
    }

    // else if (field.type === 'textbox') {
    //   input = <input type="text" className="form-control" id={field.name} placeholder="name@example.com" name={field.name}
    //     value={this.props.data ? this.props.data : null} />
    // }
    const label_class_name = ["col-form-label"];
    if (field.required) label_class_name.push("field-required");

    let remark = "";

    if (field.remark) {
      remark = <small className="text-muted">{field.remark}</small>;
    }

    const label_container_class_name = ["form-group"];

    if (field.label === undefined) {
      return input;
    } else {
      if (field.type === "label") {
        label_container_class_name.push("mb-0");
      }

      return (
        <div className={label_container_class_name.join(" ")}>
          <label className={label_class_name.join(" ")} htmlFor={field.name}>
            {field.label}:
          </label>{" "}
          {input} {remark}
        </div>
      );
    }
  }
}

FormInput.defaultProps = {
  field: {}, // type, value, name, required, label, datasource
  getValue: [],
  onDelete: null, // function delete
  formValidate: true,
};

export default FormInput;
