import React, { Component } from "react";
import DataForm from "./../../components/DataForm/DataForm";
import FormInput from "./../../components/DataForm/FormInput";
import axios from "../../axios";
import { Redirect } from "react-router-dom";
import { Services, Alert, Auth } from "../Service/Services";
import BoxContainer from "../../components/BoxContainer/BoxContainer";
const $ = window.$;

class KeynoteSpeakerForm extends Component {
  constructor(props) {
    super(props);

    this.item = {};
    this.upload_file = React.createRef();
  }
  // Set default state
  state = {
    data: {
      name: "",
      email: "",
      follower_name: "",
      hotel: "",
      food_type: "",
      sections: [],
      id: "",
      download: [],
    },
    datasource: {
      keynote_speakers: [],
    },
    url: {
      get: "getkeynote",
      // attach_file: axios.defaults.baseStorageURL + '/keynotes.zip',
      upload: "keynoteupload",
      delete: "keynotedeleteasset",
    },
    loading: true,
  };

  retrieveData = () => {
    let keynote_id = this.state.data.id;
    if (Auth.isRole("staff")) {
      if (keynote_id === "") {
        if (this.state.datasource.keynote_speakers.length > 0) {
          keynote_id = this.state.datasource.keynote_speakers[0].key;
        } else {
          return;
        }
      }
    }

    let url = this.state.url.get;

    if (keynote_id) {
      url += "/" + keynote_id;
    }

    axios
      .get(url)
      .then((res) => {
        const data = res.data;
        const new_data = {
          name: data.name,
          email: data.email,
          follower_name: data.follower_name,
          hotel: data.hotel,
          food_type: data.food_type_text,
          sections: data.sections,
          id: keynote_id === "" ? data.id : keynote_id,
          download: data.download,
        };
        this.setState({ data: new_data, loading: false });
      })
      .catch((res) => {
        console.log(res);
        Alert.error("คุณไม่มีสิทธิ์ในข้อมูลนี้");
      });
  };

  componentDidMount = () => {
    if (Auth.isRole("staff")) {
      axios.get("getallkeynoteinroom").then((res) => {
        const keynote_speakers = res.data.map((item) => {
          return {
            key: item.id,
            label: item.name,
          };
        });

        const datasource = {
          keynote_speakers,
        };

        this.setState({ datasource, loading: false });

        this.retrieveData();
      });
    } else {
      this.retrieveData();
    }
  };

  getValue = (target) => {
    let data = this.state.data;
    const name = Object.keys(target)[0];
    const value = Object.values(target)[0];

    if (name === "id" && value != data.id) {
      this.setState({
        data: {
          ...data,
          ...target,
        },
        loading: true,
      });

      this.retrieveData();
    } else {
      this.setState({
        data: {
          ...data,
          ...target,
        },
      });
    }
  };

  onSubmit = (e) => {
    const { name, files } = e.target;
    const params = {
      [name]: files,
      ...this.item,
    };

    const url = this.state.url.upload;
    let loading = true;

    this.setState({ loading });

    Services.submitRequestWithFile({ params, url })
      .then((res) => {
        $(this.upload_file.current).val(null);
        this.retrieveData();
      })
      .catch((errors) => {
        this.setState({ errors, loading: false });
      });
  };

  onDeleteAsset = (id) => {
    Alert.confirm("คุณแน่ใจไหมที่จะลบไฟล์นี้").then((res) => {
      if (res === true) {
        this.setState({ loading: true });
        const delete_url = this.state.url.delete + "/" + id;
        axios.delete(delete_url).then(() => {
          this.retrieveData();
        });
      }
    });
  };

  fileOnChange = (event) => {
    this.onSubmit(event);
  };

  uploadFile = (item) => {
    this.item = item;
    $(this.upload_file.current).trigger("click");
  };

  getFields = () => {
    var fields = [
      {
        label: "ชื่อ - นามสกุล",
        name: "name",
        type: "label",
        required: false,
        value: this.state.data.name,
      },
      {
        label: "อีเมล",
        name: "email",
        type: "label",
        required: false,
        value: this.state.data.email,
      },
      {
        label: "ผู้ติดตาม",
        name: "follower_name",
        type: "label",
        required: false,
        value: this.state.data.follower_name,
      },
      {
        label: "ห้องพัก",
        name: "hotel",
        type: "label",
        required: false,
        value: this.state.data.hotel,
      },
      {
        label: "ประเภทอาหาร",
        name: "food_type",
        type: "label",
        required: false,
        value: this.state.data.food_type,
      },
    ];

    return fields;
  };

  onClickEditProfile = () => {
    const url = "/meeting/keynote/" + this.state.data.id + "/edit";
    window.open(url, "_blank");
  };

  render() {
    const fields = this.getFields();
    const url = this.props.url;
    const room_sections_field = this.state.data.sections.map((item, key) => {
      const asset_file_abstraction = item.asset_files
        .filter((asset_file) => asset_file.type === "abstraction")
        .map((asset_file, asset_file_index) => {
          return (
            <li key={asset_file_index}>
              {asset_file_index + 1}.{" "}
              {Services.htmlParse(asset_file.asset_file_preview)}{" "}
              <i
                onClick={(e) => {
                  this.onDeleteAsset(asset_file.id);
                }}
                className="fa fa-close text-danger"
              ></i>
            </li>
          );
        });

      const asset_file_present = item.asset_files
        .filter((asset_file) => asset_file.type === "presentation")
        .map((asset_file, asset_file_index) => {
          return (
            <li key={asset_file_index}>
              {asset_file_index + 1}.{" "}
              {Services.htmlParse(asset_file.asset_file_preview)}{" "}
              <i
                onClick={(e) => {
                  this.onDeleteAsset(asset_file.id);
                }}
                className="fa fa-close text-danger"
              ></i>
            </li>
          );
        });

      return (
        <React.Fragment key={key}>
          <tr className="bg-primary text-white">
            <td className="p-3" colSpan="2">
              {item.description}
            </td>
          </tr>
          <tr>
            <td className="text-left">
              <button
                className="btn btn-link"
                type="button"
                onClick={(e) => {
                  this.uploadFile({
                    keynote_section_id: item.id,
                    type: "abstraction",
                  });
                }}
              >
                <u>
                  <i className="fa fa-plus"></i> เพิ่ม Abstract
                </u>
              </button>
              <ul className="list-unstyled">{asset_file_abstraction}</ul>
            </td>
            <td className="text-left">
              <button
                className="btn btn-link"
                type="button"
                onClick={(e) => {
                  this.uploadFile({
                    keynote_section_id: item.id,
                    type: "presentation",
                  });
                }}
              >
                <u>
                  <i className="fa fa-plus"></i> เพิ่ม Presentation
                </u>
              </button>
              <ul className="list-unstyled">{asset_file_present}</ul>
            </td>
          </tr>
        </React.Fragment>
      );
    });

    let ddl_keynote_speakers;

    if (Auth.isRole("staff")) {
      ddl_keynote_speakers = (
        <FormInput
          field={{
            name: "id",
            type: "ddl",
            value: this.state.data.id,
            datasource: this.state.datasource.keynote_speakers,
          }}
          getValue={this.getValue}
        ></FormInput>
      );
    }

    const link_download = this.state.data.download.map((item) => {
      return Services.htmlParse(item);
    });

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        <div className="row">
          <div className="col-12 col-md-6">{ddl_keynote_speakers}</div>
          <div className="text-right col-12 col-md-6">
            <button
              className="btn btn-warning float-right"
              onClick={this.onClickEditProfile}
            >
              แก้ไขประวัติส่วนตัว
            </button>
            <div className="dropdown mx-2 float-right">
              <a
                className="btn btn-secondary dropdown-toggle"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                เอกสารแนบ
              </a>

              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                {link_download}
                {/* <a className="dropdown-item" href={this.state.url.attach_file}>เอกสารวิทยากร.zip</a> */}
              </div>
            </div>
          </div>
        </div>
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          allow_save={false}
          getValue={this.getValue}
        >
          <hr />
          <h2>ห้องบรรยาย</h2>
          <table className="table">
            <tbody>{room_sections_field}</tbody>
          </table>
          <input
            className="d-none"
            type="file"
            name="upload_file[]"
            ref={this.upload_file}
            onChange={this.fileOnChange}
          ></input>
        </DataForm>
      </BoxContainer>
    );
  }
}

KeynoteSpeakerForm.setDefaultProps = {
  url: "",
};

export default KeynoteSpeakerForm;
