import React from "react";
import FormInput from "../../../components/DataForm/FormInput";

export default function ReviewerGroup({ values = [], datasource, onChange }) {
  const onInsert = () => {
    onChange([
      ...values,
      {
        reviewer_1_user_id: null,
        reviewer_2_user_id: null,
        reviewer_3_user_id: null,
      },
    ]);
  };
  const onDelete = (index) => {
    onChange(
      values.filter((f, fi) => {
        return fi !== index;
      })
    );
  };

  const getValue = (target, index) => {
    const new_values = [...values];
    const name = Object.keys(target)[0];
    const value = Object.values(target)[0];
    new_values[index][name] = value;
    onChange(new_values);
  };

  const row = values.map((item, index) => {
    const field_user_1 = {
      name: "reviewer_1_user_id",
      type: "ddl",
      datasource: datasource.reviewer,
      value: item.reviewer_1_user_id,
      required: false,
    };
    const field_user_2 = {
      name: "reviewer_2_user_id",
      type: "ddl",
      datasource: datasource.reviewer,
      value: item.reviewer_2_user_id,
      required: false,
    };
    const field_user_3 = {
      name: "reviewer_3_user_id",
      type: "ddl",
      datasource: datasource.reviewer,
      value: item.reviewer_3_user_id,
      required: false,
    };

    return (
      <tr key={index}>
        <td>
          <FormInput
            field={field_user_1}
            getValue={(target) => {
              getValue(target, index);
            }}
          ></FormInput>
        </td>
        <td>
          <FormInput
            field={field_user_2}
            getValue={(target) => {
              getValue(target, index);
            }}
          ></FormInput>
        </td>
        <td>
          <FormInput
            field={field_user_3}
            getValue={(target) => {
              getValue(target, index);
            }}
          ></FormInput>
        </td>
        <td className="text-center">
          <div className="btn-group">
            <button
              onClick={() => {
                onDelete(index);
              }}
              type="button"
              data-toggle="tooltip"
              data-placemeent="top"
              data-original-title="Trash"
              title="Trash"
              className="btn btn-danger btn-sm ml-1"
            >
              <i className="fa fa-trash"></i> ลบ
            </button>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div className="card">
      <div className="card-header">
        <h3>ผู้เชี่ยวชาญ</h3>
      </div>
      <div className="card-body">
        <div className="card-text">
          <button
            type="button"
            onClick={onInsert}
            className="btn btn-primary float-right"
          >
            <i className="fa fa-plus"></i> เพิ่มข้อมูล
          </button>
          <table className="table">
            <thead>
              <tr>
                <th className="text-center">ผู้เชี่ยวชาญคนที่ 1</th>
                <th className="text-center">ผู้เชี่ยวชาญคนที่ 2</th>
                <th className="text-center">ผู้เชี่ยวชาญคนที่ 3</th>
                <th className="text-center">#</th>
              </tr>
            </thead>
            <tbody>{row}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
