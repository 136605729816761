import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import OfflineMonitorForm from './OfflineMonitorForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class OfflineMonitor extends Component {
    render() {
        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ติดตามการลงทะเบียน' program_type='Offline'></TitleHeader>
                    
                    <Route exact path={`${this.props.match.path}`} component={props => (<OfflineMonitorForm {...props}></OfflineMonitorForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default OfflineMonitor
