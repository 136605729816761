import React, { Component } from "react";
import FormInput from "./../../../components/DataForm/FormInput";
import { Auth } from "../../Service/Services";

class MeetingKeynoteSpeakerInfo extends Component {
  getValue = (target) => {
    const new_data = { ...this.props.data };
    const name = Object.keys(target)[0];
    const value = Object.values(target)[0];
    new_data[name] = value;

    this.props.onChange(new_data);
  };

  getFields = () => {
    const is_keynote_speaker = Auth.isRole("keynote_speaker");

    const email_datasource = this.props.datasource.email.map((item) => {
      if (item.key === "1") {
        item.textbox_value = this.props.data.email;
      }

      return item;
    });

    const invitation_flag = this.props.data.invitation_flag;
    let email_field;

    if (invitation_flag) {
      let email_value = this.props.data.email;

      if (this.props.data.has_email === "0") {
        email_value += ' <span class="label label-danger">ไม่มีอีเมล</span>';
      }

      email_field = {
        label: "อีเมล",
        type: "label",
        name: "email",
        value: email_value,
      };
    } else {
      email_field = {
        label: "อีเมล",
        type: "radio",
        name: "has_email",
        value: this.props.data.has_email,
        required: true,
        datasource: email_datasource,
      };
    }

    const fields = [
      {
        label: "ตำแหน่ง",
        type: "textbox",
        name: "position",
        required: false,
        value: this.props.data.position,
      },
      {
        label: "รูปวิทยากร",
        type: "file",
        name: "avatar_asset_file_id",
        value: this.props.data.avatar_asset_file_preview,
        required: false,
        multiple: false,
      },
      email_field,
      {
        label: "โทรศัพท์มือถือ",
        type: "textbox",
        name: "telephone",
        required: is_keynote_speaker ? true : false,
        value: this.props.data.telephone,
      },
      {
        label: "เลขที่บัตรประชาชน",
        type: "textbox",
        name: "identity_number",
        required: is_keynote_speaker ? true : false,
        value: this.props.data.identity_number,
        maxlength: "13",
      },
      {
        label: "เลขที่บัญชี",
        type: "textbox",
        name: "account_number",
        required: is_keynote_speaker ? true : false,
        value: this.props.data.account_number,
      },
      {
        label: "ชื่อธนาคาร",
        type: "ddl",
        name: "bank_id",
        value: this.props.data.bank_id,
        required: is_keynote_speaker ? true : false,
        datasource: this.props.datasource.bank,
        allow_new_item: false,
      },
      {
        label: "ชื่อบัญชี",
        type: "textbox",
        name: "account_name",
        required: is_keynote_speaker ? true : false,
        value: this.props.data.account_name,
      },
      {
        label: "รูปหน้า Bookbank",
        type: "file",
        name: "speaker_bookbank_asset_file_id",
        required: is_keynote_speaker ? true : false,
        value: this.props.data.speaker_bookbank_asset_file_preview,
        multiple: false,
      },
      {
        label: "วิชาชีพ",
        type: "ddl",
        name: "career_id",
        value: this.props.data.career_id,
        datasource: this.props.datasource.career,
        allow_new_item: true,
      },
      {
        label: "เลขที่ประกอบวิชาชีพ",
        type: "textbox",
        name: "career_number",
        value: this.props.data.career_number,
      },
      {
        label: "เลขที่สมาชิกสภาการพยาบาล",
        type: "textbox",
        name: "nurse_number",
        value: this.props.data.nurse_number,
      },
    ];

    return fields;
  };

  render() {
    const field_prefix_th = (
      <FormInput
        field={{
          label: "คำนำหน้าชื่อ",
          name: "prefix_th_id",
          value: this.props.data.prefix_th_id,
          type: "ddl",
          datasource: this.props.datasource.prefix_name,
          allow_new_item: true,
        }}
        getValue={this.getValue}
      ></FormInput>
    );
    const field_name_th = (
      <FormInput
        field={{
          label: "ชื่อ",
          name: "first_name_th",
          value: this.props.data.first_name_th,
          type: "textbox",
        }}
        getValue={this.getValue}
      ></FormInput>
    );
    const field_surname_th = (
      <FormInput
        field={{
          label: "นามสกุล",
          name: "last_name_th",
          value: this.props.data.last_name_th,
          type: "textbox",
        }}
        getValue={this.getValue}
      ></FormInput>
    );
    const field_prefix_en = (
      <FormInput
        field={{
          label: "คำนำหน้าชื่อ",
          name: "prefix_en_id",
          value: this.props.data.prefix_en_id,
          type: "ddl",
          datasource: this.props.datasource.prefix_name,
          allow_new_item: true,
        }}
        getValue={this.getValue}
      ></FormInput>
    );
    const field_name_en = (
      <FormInput
        field={{
          label: "ชื่อ",
          name: "first_name_en",
          value: this.props.data.first_name_en,
          type: "textbox",
        }}
        getValue={this.getValue}
      ></FormInput>
    );
    const field_surname_en = (
      <FormInput
        field={{
          label: "นามสกุล",
          name: "last_name_en",
          value: this.props.data.last_name_en,
          type: "textbox",
        }}
        getValue={this.getValue}
      ></FormInput>
    );

    const fields = this.getFields().map((field, index) => {
      return (
        <FormInput
          key={index}
          field={field}
          getValue={this.getValue}
        ></FormInput>
      );
    });

    return (
      <div className="panel-body">
        <div className="tabs-container">
          <ul className="nav nav-tabs" role="tablist">
            <li>
              <a className="nav-link active" data-toggle="tab" href="#tab-1-1">
                TH
              </a>
            </li>
            <li>
              <a className="nav-link" data-toggle="tab" href="#tab-2-1">
                EN
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div role="tabpanel" id="tab-1-1" className="tab-pane active">
              <div className="panel-body">
                {field_prefix_th}
                {field_name_th}
                {field_surname_th}
              </div>
            </div>
            <div role="tabpanel" id="tab-2-1" className="tab-pane">
              <div className="panel-body">
                {field_prefix_en}
                {field_name_en}
                {field_surname_en}
              </div>
            </div>
          </div>
        </div>
        {fields}
      </div>
    );
  }
}

MeetingKeynoteSpeakerInfo.setDefaultProps = {
  datasource: [],
  onChange: {},
  data: [],
};

export default MeetingKeynoteSpeakerInfo;
