import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import AbstractSummaryForm from "./AbstractSummaryForm";
import TitleHeader from "../../../components/TitleHeader/TitleHeader";
import AbstractScoreSummary from "../../Abstract/AbstractScoreSummary/AbstractScoreSummary";
import axios from "../../../axios";

class AbstractSummary extends Component {
  printReportAbstraction = (criteria) => {
    const { keyword, filter } = criteria;
    const params = [
      ["keyword", keyword],
      ["filter", JSON.stringify(filter)],
    ];

    const url =
      axios.defaults.baseURL +
      "/report/abstract?" +
      params.map((i) => i[0] + "=" + i[1]).join("&");

    window.open(url, "_blank");
  };

  printReport = (criteria) => {
    const { keyword, filter } = criteria;
    const params = [
      ["keyword", keyword],
      ["filter", JSON.stringify(filter)],
    ];

    const url =
      axios.defaults.baseURL +
      "/report/abstractsummary?" +
      params.map((i) => i[0] + "=" + i[1]).join("&");

    window.open(url, "_blank");
  };

  printReport2 = (criteria) => {
    const { keyword, filter } = criteria;
    const params = [
      ["keyword", keyword],
      ["filter", JSON.stringify(filter)],
    ];

    const url =
      axios.defaults.baseURL +
      "/report/abstractsummary/2?" +
      params.map((i) => i[0] + "=" + i[1]).join("&");

    window.open(url, "_blank");
  };

  render() {
    const columns = [
      {
        label: "วันที่ส่งผลงาน",
        align: "center",
        name: "abstractions.created_at",
        search_type: "string",
      },
      {
        label: "รหัสผลงาน",
        align: "center",
        name: "abstractions.abstract_no",
        search_type: "string",
      },
      {
        label: "หัวข้อ",
        align: "left",
        name: "abstract_topics.name",
        search_type: "string",
      },
      {
        label: "ชื่อผลงาน",
        align: "left",
        name: "abstractions.name",
        search_type: "string",
      },
      {
        label: "ผู้เชี่ยวชาญ",
        align: "left",
        name: "reviewers",
        sortable: false,
      },
      {
        label: "ผู้เชี่ยวชาญสูงสุด",
        align: "left",
        name: "email",
        sortable: false,
      },
      {
        label: "คะแนน",
        align: "right",
        name: "abstractions.score",
        search_type: "string",
      },
      { label: "สถานะ", align: "center", name: "abstractions.status" },
    ];

    const api_url = "/admin/abstractsummary";
    const buttons = [
      {
        is_multiple: true,
        label: '<i class="fa fa-print"></i> พิมพ์รายงาน',
        className: "btn btn-primary",
        name: "btn_print_report",
        datasource: [
          {
            label: "ผลงานวิชาการ",
            onClick: this.printReportAbstraction,
          },
          {
            label: "ผลการตรวจผลงานวิชาการครั้งที่ 1",
            onClick: this.printReport,
          },
          {
            label: "ผลการตรวจผลงานวิชาการครั้งที่ 2",
            onClick: this.printReport2,
          },
        ],
      },
    ];

    return (
      <BrowserRouter>
        <div>
          <TitleHeader
            program_name="สรุปผล"
            program_type="ผลงานวิชาการ"
          ></TitleHeader>
          <AbstractScoreSummary></AbstractScoreSummary>
          <Route
            exact
            path={`${this.props.match.path}/`}
            component={() => (
              <DataTable
                columns={columns}
                buttons={buttons}
                allow_edit={false}
                allow_view={true}
                allow_active={false}
                allow_insert={false}
                allow_trash={false}
                url={api_url}
              ></DataTable>
            )}
          />

          <Route
            path={`${this.props.match.path}/create`}
            component={(props) => (
              <AbstractSummaryForm
                {...props}
                url={api_url}
              ></AbstractSummaryForm>
            )}
          />
          <Route
            path={`${this.props.match.path}/:id/view`}
            component={(props) => (
              <AbstractSummaryForm
                {...props}
                url={api_url}
              ></AbstractSummaryForm>
            )}
          />
        </div>
      </BrowserRouter>
    );
  }
}

export default AbstractSummary;
