import axios from "axios";
import { Auth } from "./pages/Service/Services";
import { authenticationService } from "./pages/Authentication/Service/authentication.service";
import siteService from "./pages/Authentication/Service/site.service";

const config = {
  baseURL: "",
  params: {
    // baseStorageURL: '',
  },
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
};

config.params.baseStorageURL = process.env.REACT_APP_API_URL_STORAGE;
config.baseURL = process.env.REACT_APP_API_URL;

const instance = axios.create(config);

const token = Auth.getToken();

const site = siteService.getCurrentSite();

if (token)
  instance.defaults.headers.common["Authorization"] = "Bearer " + token;

if (site) instance.defaults.headers.common["siteId"] = site.id;

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    // Do something with response error
    if (error.response ? error.response.status === 401 : false) {
      console.log("unauthorized, logging out ...");
      authenticationService.doLogout();
    }

    return Promise.reject(error.response);
  }
);

instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // console.log("request", config);

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default instance;
