import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import OfflineCheckInForm from "./OfflineCheckInForm";
import OfflineCheckInFormReplace from "./OfflineCheckInFormReplace";

class OfflineCheckIn extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Route
            exact
            path={`${this.props.match.path}/:code?`}
            component={(props) => (
              <OfflineCheckInForm {...props}></OfflineCheckInForm>
            )}
          />
          <Route
            path={`${this.props.match.path}/replace/:code`}
            component={(props) => (
              <OfflineCheckInFormReplace {...props}></OfflineCheckInFormReplace>
            )}
          />
        </div>
      </BrowserRouter>
    );
  }
}

export default OfflineCheckIn;
