import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import AbstractHACCForm from "./AbstractHACCForm";
import TitleHeader from "../../../components/TitleHeader/TitleHeader";
import swal from "sweetalert";
import axios from "../../../axios";
import { Services } from "../../Service/Services";

class AbstractHACC extends Component {
  constructor(props) {
    super(props);
    this.datatable_element = React.createRef();
  }

  onImportFile = (params) => {
    const import_url = "/importhacc";

    const new_params = {
      params,
      url: import_url,
    };

    Services.submitRequestWithFile(new_params)
      .then((res) => {
        this.datatable_element.current.refresh();
      })
      .catch((err) => {
        swal({
          title: "Error",
          text: err.join("\n"),
          icon: "error",
          button: "OK",
        });
        console.log("catch", err);
      });
  };

  render() {
    const columns = [
      { label: "รหัสผลงานวิชาการ", align: "center", name: "abstract_no" },
      { label: "ชื่อเจ้าของผลงาน", align: "left", name: "name" },
      // { label: "ประเภทผลงาน", align: "left", name: "abstract_type" },
      { label: "หมวดหมู่", align: "left", name: "abstract_topic_id" },
    ];

    const api_url = "/admin/abstract_hacc";
    const export_url = axios.defaults.baseURL + "/exporthacc";

    return (
      <BrowserRouter>
        <div>
          <TitleHeader
            program_name="HACC"
            program_type="ผลงานวิชาการ"
          ></TitleHeader>

          <Route
            exact
            path={`${this.props.match.path}/`}
            component={() => (
              <DataTable
                columns={columns}
                allow_active={false}
                url={api_url}
                ref={this.datatable_element}
                allow_import={true}
                export_url={export_url}
                onImportFile={this.onImportFile}
              ></DataTable>
            )}
          />

          <Route
            path={`${this.props.match.path}/create`}
            component={(props) => (
              <AbstractHACCForm {...props} url={api_url}></AbstractHACCForm>
            )}
          />
          <Route
            path={`${this.props.match.path}/:id/edit`}
            component={(props) => (
              <AbstractHACCForm {...props} url={api_url}></AbstractHACCForm>
            )}
          />
        </div>
      </BrowserRouter>
    );
  }
}

export default AbstractHACC;
