import React, { Component } from "react";
import DataForm from "../../../components/DataForm/DataForm";
import TableForm from "../../../components/TableForm/TableForm";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import { Services } from "../../Service/Services";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";

class FrontendHotelForm extends Component {
  // Set default state
  state = {
    data: {
      name: "",
      telephone: "",
      fax: "",
      remark: "",
      additional_bed: "",
      facilities: "",
      active: true,
      facility_car: false,
      facility_breakfast: false,
      asset_file_booking_preview: "",
      asset_file_map_preview: "",
      hotel_rooms: [],
    },
    redirect: false,
    loading: false,
  };

  retrieveData = (id) => {
    axios.get(`${this.props.url}/${id}`).then((res) => {
      let data = res.data;
      data.hotel_rooms = data.hotel_rooms_show;
      data.facility_car = data.facilities.includes("C");
      data.facility_breakfast = data.facilities.includes("B");

      this.setState({ data });
    });
  };

  getValueHotelDetail = (target) => {
    let data = {
      ...this.state.data,
      ...target,
    };

    this.setState({ data });
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;

    if (id !== undefined) {
      this.retrieveData(id);
    } else {
      const loading = false;
      this.setState({ loading });
    }
  };

  getValue = (target) => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
  };

  onSubmit = (e) => {
    let params = { ...this.state.data };
    let facilities = [];

    if (params.facility_car === true) {
      facilities.push("C");
    }

    if (params.facility_breakfast === true) {
      facilities.push("B");
    }

    params.facilities = facilities.join("|");
    console.log(params.hotel_rooms);
    params.hotel_rooms = JSON.stringify(params.hotel_rooms);

    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    loading = false;

    Services.submitRequestWithFile({ params, url })
      .then((res) => {
        this.setState({ redirect, loading });
      })
      .catch((errors) => {
        this.setState({ errors, loading });
      });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  };

  getFields = () => {
    const id = this.state.id;

    var fields = [
      {
        label: "ชื่อ",
        name: "name",
        type: "textbox",
        required: true,
        value: this.state.data.name,
      },
      {
        label: "เบอร์โทรศัพท์",
        name: "telephone",
        type: "textbox",
        required: false,
        value: this.state.data.telephone,
      },
      {
        label: "เบอร์แฟกซ์",
        name: "fax",
        type: "textbox",
        required: false,
        value: this.state.data.fax,
      },
      {
        label: "หมายเหตุ",
        name: "remark",
        type: "textbox",
        required: false,
        value: this.state.data.remark,
      },
      {
        label: "ราคาเตียงเสริม",
        name: "additional_bed",
        type: "textbox",
        required: false,
        value: this.state.data.additional_bed,
      },
      {
        label: "แบบฟอร์ม",
        name: "asset_file_booking",
        type: "file",
        required: false,
        multiple: false,
        value: this.state.data.asset_file_booking_preview,
      },
      {
        label: "แผนที่",
        name: "asset_file_map",
        type: "file",
        required: false,
        multiple: false,
        value: this.state.data.asset_file_map_preview,
      },
      {
        label: "มีรถรับส่ง",
        name: "facility_car",
        type: "switch",
        required: false,
        value: this.state.data.facility_car,
      },
      {
        label: "มีอาหารเช้า",
        name: "facility_breakfast",
        type: "switch",
        required: false,
        value: this.state.data.facility_breakfast,
      },
      {
        label: "Active",
        name: "active",
        type: "switch",
        required: false,
        value: this.state.data.active,
      },
    ];

    return fields;
  };

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
          fields={fields}
        >
          <TableForm
            controls={[
              { name: "name", label: "ห้องพัก", type: "textbox" },
              { name: "price", label: "ราคา", type: "number" },
            ]}
            label="ห้องพัก"
            name="hotel_rooms"
            value={this.state.data.hotel_rooms}
            getValue={this.getValueHotelDetail}
          ></TableForm>
        </DataForm>
      </BoxContainer>
    );
  }
}

FrontendHotelForm.setDefaultProps = {
  url: "",
};

export default FrontendHotelForm;
