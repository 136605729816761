import React, { Component } from "react";
import DataForm from "../../../components/DataForm/DataForm";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import { Services, Alert } from "../../Service/Services";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";

class UserReviewerForm extends Component {
  // Set default state
  state = {
    data: {
      email: "",
      name: "",
      status: "P",
      active: true,
      telephone: "",
      company: 1,
      abstract_category: [],
      user_roles: ["reviewer"],
    },
    datasource: {
      company: [
        { key: 1, label: "สถาบันรับรองคุณภาพสถานพยาบาล (องค์การมหาชน)" },
        { key: 2, label: "อื่นๆ" },
      ],
      abstract_category: [],
    },
    redirect: false,
    loading: true,
  };

  retrieveData = (id) => {
    axios.get(`${this.props.url}/${id}`).then((res) => {
      const data = {
        id: res.data.id,
        email: res.data.email,
        name: res.data.name,
        status: res.data.status,
        active: res.data.active,
        telephone: res.data.telephone,
        company: res.data.company,
        abstract_category: res.data.abstract_category,
      };

      this.setState({ data, loading: false });
    });
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;

    const datasource_url = "datasource";
    const params = {
      tables: [{ name: "abstract_topic_all" }],
    };

    axios.post(datasource_url, params).then((res) => {
      const abstract_category = res.data.abstract_topic_all.map((item, key) => {
        return {
          key: item.id,
          label: item.name,
        };
      });

      let datasource = {
        ...this.state.datasource,
        abstract_category,
      };

      this.setState({
        datasource,
      });

      if (id !== undefined) {
        this.retrieveData(id);
      } else {
        const loading = false;
        const data = { ...this.state.data };
        data.email = this.props.match.params.email;

        this.setState({ loading, data });
      }
    });
  };

  getValue = (target) => {
    let data = this.state.data;
    // const name = Object.keys(target)[0];
    // const value = Object.values(target)[0];

    // if(name === 'abstract_category'){
    //   const abstract_category = data.abstract_category;
    //   const valueInt = parseInt(value);
    //   const index = abstract_category.indexOf(valueInt);
    //   if(index === -1){
    //     abstract_category.push(valueInt);
    //   }else{
    //     abstract_category.splice(index, 1);
    //   }

    //   data.abstract_category = abstract_category;

    //   this.setState({
    //     data: {
    //       ...data,
    //     }
    //   });

    // }else{
    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
    // }
  };

  resetPassword = (e) => {
    Alert.confirm("คุณแน่ใจไหมที่จะรีเซ็ตรหัสผ่าน").then((res) => {
      if (res === true) {
        const id = this.props.match.params.id;
        const url = "auth/resetpassword";
        const params = {
          id,
        };

        axios
          .post(url, params)
          .then((res) => Alert.done().then(this.retrieveData(id)));
      }
    });
  };

  unlocked = (e) => {
    Alert.confirm("คุณแน่ใจไหมที่จะปลดล๊อค").then((res) => {
      if (res === true) {
        const id = this.props.match.params.id;
        const url = "auth/unlocked/";
        const params = {
          id,
        };

        axios
          .post(url, params)
          .then((res) => Alert.done().then(this.retrieveData(id)));
      }
    });
  };

  onSubmit = (e) => {
    let params = this.state.data;

    if (params.company === 1 || params.company === "1") {
      params.is_ha_department = true;
    } else {
      params.is_ha_department = false;
    }

    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    loading = false;

    Services.submitRequest({ params, url })
      .then((res) => {
        this.setState({ redirect, loading });
      })
      .catch((errors) => {
        this.setState({ errors, loading });
      });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  };

  getFields = () => {
    const id = this.props.match.params.id;

    const disabled = id !== undefined;

    var fields = [
      {
        label: "อีเมล",
        name: "email",
        type: disabled ? "label" : "email",
        required: true,
        value: this.state.data.email,
      },
      {
        label: "โทรศัพท์มือถือ",
        name: "telephone",
        type: "textbox",
        required: true,
        value: this.state.data.telephone,
        remark: "เบอร์โทรศัพท์คือรหัสผ่าน",
      },
      {
        label: "ชื่อ - นามสกุล",
        name: "name",
        type: "textbox",
        required: true,
        value: this.state.data.name,
      },
      {
        label: "หน่วยงาน",
        name: "company",
        type: "radio",
        value: this.state.data.company,
        datasource: this.state.datasource.company,
      },
      {
        label: "Active",
        name: "active",
        type: "switch",
        value: this.state.data.active,
      },
    ];

    if (id !== undefined) {
      const status = this.state.data.status;
      let status_text;

      // eslint-disable-next-line default-case
      switch (status) {
        case "active":
          status_text = '<span class="label label-primary">Active</span>';
          break;

        case "pending":
          status_text = '<span class="label label-warning">Pending</span>';
          break;

        case "locked":
          status_text = '<span class="label label-danger">Locked</span>';
          break;
      }

      fields.push({
        label: "สถานะ",
        name: "status",
        type: "label",
        required: false,
        value: status_text,
      });
    }

    fields.push({
      label: "หมวดหมู่ผลงาน",
      name: "abstract_category",
      type: "checkbox",
      value: this.state.data.abstract_category,
      datasource: this.state.datasource.abstract_category,
    });

    return fields;
  };

  getButtons = () => {
    const id = this.props.match.params.id;
    let buttons = [];

    if (id !== undefined) {
      const status = this.state.data.status;

      // eslint-disable-next-line default-case
      switch (status) {
        case "active":
          buttons.push(
            <input
              key={1}
              type="button"
              className="btn btn-warning mr-2"
              value="Reset รหัสผ่าน"
              onClick={this.resetPassword}
            />
          );
          break;

        case "locked":
          buttons.push(
            <input
              key={3}
              type="button"
              className="btn btn-danger mr-2"
              value="ปลดล๊อค"
              onClick={this.unlocked}
            />
          );
          break;
      }
    }

    return buttons;
  };

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;

    const buttons = this.getButtons();

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
          buttons={buttons}
        ></DataForm>
      </BoxContainer>
    );
  }
}

UserReviewerForm.setDefaultProps = {
  url: "",
};

export default UserReviewerForm;
