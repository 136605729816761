import React, { Component } from 'react'
import FormInput from './../../../components/DataForm/FormInput';

class MeetingKeynoteSpeakerMeal extends Component {
    getValue = (target, index) => {
        const new_data = { ...this.props.data };
        const name = Object.keys(target)[0];
        const value = Object.values(target)[0];
        new_data[name] = value;
  
        this.props.onChange(new_data);
    }

    getFields = () => {
        const food_type_datasource = this.props.datasource.food_type.map( item => {
            if(item.key === 'other'){
                item.textbox_value = this.props.data.food_type_other;
            }
            
            return item;
        });

        const fields = [
            { label: 'ประเภทอาหาร ', type: 'radio', name: 'food_type', value: this.props.data.food_type, datasource: food_type_datasource },
        ]

        return fields;
    }
  
    render() {
        const fields = this.getFields().map((field, index) => {
            return <FormInput key={index} field={field} getValue={this.getValue}></FormInput>
        });

        return (
            <div className="panel-body">
                {fields}
            </div>
        )
    }
  }
  
MeetingKeynoteSpeakerMeal.setDefaultProps = {
    datasource: [],
    onChange: {},
    data: [],
}

export default MeetingKeynoteSpeakerMeal
