import React, { Component } from 'react'
import DataForm from './../../../components/DataForm/DataForm';
import FormInput from './../../../components/DataForm/FormInput';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import { Services } from '../../Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';

class PublishDocumentForm extends Component {
  // Set default state
  state = {
    data:{
      id: null,
      title: '',
      name: '',
      asset_file_id: '',
      publish_category_id: '',
      publish_subcategory_id: '',
      abstract_id: '',
      active: true,
    },
    datasource: {
      publish_category: [],
      publish_subcategory: [],
    },
    redirect: false,
    loading: true,
  }

  init = () => {
    const promise = new Promise((resolve) => {
      const params = {
        tables: [
          {name: 'publish_category'},
          {name: 'publish_subcategory'},
        ],
      }
  
      axios.post('datasource', params).then(res => {
        const publish_category = res.data.publish_category.map( item => {
          return {
            key: item.id,
            label: item.name,
          };
        });
  
        const publish_subcategory = res.data.publish_subcategory.map( item => {
          return {
            key: item.id,
            label: item.name,
            publish_category_id: item.publish_category_id,
          };
        });
  
        const datasource = {
          publish_category,
          publish_subcategory,
        };

        resolve(datasource);
      });
    });

    return promise;
  }


  retrieveData = (id, datasource) => {
    axios.get(`${this.props.url}/${id}`).then(res => {
      const data = res.data;
      const new_data = {
        id: data.id,
        title: data.title,
        name: data.name,
        publish_category_id: data.publish_category_id,
        publish_subcategory_id: data.publish_subcategory_id,
        asset_file_preview: data.asset_file_preview,
        active: data.active,
      }

      this.setState({ data: new_data, loading: false, datasource });
    });
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;

    this.init().then( datasource => {
      if(id !== undefined){
        this.retrieveData(id, datasource);
      }else{
        this.setState({ loading: false, datasource });
      }
    });
  }

  getValue = target => {
    let data = this.state.data;
    let name = Object.keys(target)[0];

    if(name === 'publish_category_id'){
      this.setState({
        data: {
          ...data,
          ...target,
          publish_subcategory_id: '',
        }
      });
    }else{
      this.setState({
        data: {
          ...data,
          ...target,
        }
      });
    }
  }

  onSubmit = e => {
    const params = this.state.data;
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    Services.submitRequestWithFile({ params, url }).then( res => { this.setState({redirect, loading: false}) }).catch( errors => {this.setState({ errors,loading: false })});
  }

  getRedirect = () => {
    const redirect = this.state.redirect;
    
    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  }

  getFields = () => {
    const publish_category_id = this.state.data.publish_category_id;
    const publish_subcategory = this.state.datasource.publish_subcategory.filter( item => {
      return item.publish_category_id === parseInt(publish_category_id);
    });

    var fields = [
      { label: 'หัวข้อ', name: 'title', type: 'textbox', required: true, value: this.state.data.title },
      { label: 'ชื่อเอกสารดาวน์โหลด', name: 'name', type: 'textbox', required: false, value: this.state.data.name },
      { label: 'หมวดหมู่หลัก', name: 'publish_category_id', type: 'ddl', required: true, value: publish_category_id, datasource: this.state.datasource.publish_category },
      { label: 'หมวดหมู่ย่อย', name: 'publish_subcategory_id', type: 'ddl', required: false, value: this.state.data.publish_subcategory_id, datasource: publish_subcategory },
      { label: 'แนบไฟล์', name: 'asset_file', type: 'file', required: true, multiple: false, value: this.state.data.asset_file_preview },
      { label: 'Active', name: 'active', type: 'switch', required: false, value: this.state.data.active },
    ];

    return fields;
  }

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
        >
        </DataForm>
      </BoxContainer>
    )
  }
}

PublishDocumentForm.setDefaultProps = {
  url: '',
}

export default PublishDocumentForm
