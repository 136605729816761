import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import UserPermissionForm from "./UserPermissionForm";
import TitleHeader from "../../../components/TitleHeader/TitleHeader";

class UserPermission extends Component {
  render() {
    const columns = [
      { label: "สิทธิ์", align: "left", name: "name", search_type: "string" },
    ];

    const api_url = "/user/permission";

    return (
      <BrowserRouter>
        <div>
          <TitleHeader
            program_name="การกำหนดสิทธิ์"
            program_type="ข้อมูลบัญชีผู้ใช้งาน"
          ></TitleHeader>

          <Route
            exact
            path={`${this.props.match.path}/`}
            component={() => (
              <DataTable
                allow_import={false}
                columns={columns}
                allow_active={true}
                url={api_url}
              ></DataTable>
            )}
          />

          <Route
            path={`${this.props.match.path}/create`}
            component={(props) => (
              <UserPermissionForm {...props} url={api_url}></UserPermissionForm>
            )}
          />
          <Route
            path={`${this.props.match.path}/:id/edit`}
            component={(props) => (
              <UserPermissionForm {...props} url={api_url}></UserPermissionForm>
            )}
          />
        </div>
      </BrowserRouter>
    );
  }
}

export default UserPermission;
