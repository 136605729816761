import React, { Component } from "react";

class Pagination extends Component {
  constructor(props) {
    super(props);

    this.element = React.createRef();
  }

  state = {
    pagination_selector: 1,
    pagination: null,
  };

  getClassName() {
    let className = ["pagination", "flex-wrap"];
    const size = this.props.size.toLowerCase();
    const align = this.props.align.toLowerCase();

    if (size === "small") {
      className.push("pagination-sm");
    } else if (size === "large") {
      className.push("pagination-lg");
    }

    if (align === "center") {
      className.push("justify-content-center");
    } else if (align === "right") {
      className.push("justify-content-end");
    }

    return className;
  }

  componentDidMount() {
    this.activePagination();
    // console.log("pagination", "componentDidMount");
  }

  activePagination = () => {
    var default_options = {
      totalPages: this.props.totalPages
        ? this.props.totalPages !== Infinity
          ? this.props.totalPages
          : 1
        : 1,
      // totalPages: this.props.totalPages !== Infinity ? this.props.totalPages : 1,
      visiblePages: this.props.visiblePage,
      startPage: this.props.currentPage,
      initiateStartPageClick: false,
      onPageClick: (event, page) => {
        this.props.onPageChange(page);
        // this.$emit('page_changed', page);
        // const url = this.url;
        // const currentPage = this.currentPage;

        // if(url && page !== currentPage){
        //     window.location.href = `${url}?page=${page}`;
        // }
      },
    };

    // const pagination = $(this.element.current).twbsPagination(default_options);
  };

  componentDidUpdate() {
    // this.activePagination();
    // this.state.pagination.twbsPagination({totalPages: this.props.totalPages});
    // console.log("pagination", "componentDidUpdate", this.state.pagination);
  }

  onPageChange = (page_nummber) => {
    this.props.onPageChange(page_nummber);
  };

  render() {
    const pagination_class = this.getClassName();
    const page_nummber = [];
    const total_page = this.props.totalPages;
    const limit_page =
      this.props.visiblePage <= this.props.totalPages
        ? this.props.visiblePage
        : this.props.totalPages;
    const current_page = this.props.currentPage;

    const control_buttons_left = [
      { label: "First", active: current_page === 1 ? false : true, value: 1 },
      {
        label: "Previous",
        active: current_page === 1 ? false : true,
        value: current_page - 1,
      },
    ];

    const control_buttons_right = [
      {
        label: "Next",
        active: current_page === total_page ? false : true,
        value: current_page + 1,
      },
      {
        label: "Last",
        active: current_page === total_page ? false : true,
        value: this.props.totalPages,
      },
    ];

    const buttons_left = control_buttons_left.map((i, index) => {
      const class_name = [];
      var call_fn = () => this.onPageChange(i.value);

      if (i.active === false) {
        class_name.push("disabled");
        call_fn = null;
      }

      return (
        <li key={index} className="page-item">
          <a className={class_name.join(" ")} href="#" onClick={call_fn}>
            {i.label}
          </a>
        </li>
      );
    });

    const buttons_right = control_buttons_right.map((i, index) => {
      const class_name = [];
      var call_fn = () => this.onPageChange(i.value);

      if (i.active === false) {
        class_name.push("disabled");
        call_fn = null;
      }

      return (
        <li key={index} className="page-item">
          <a className={class_name.join(" ")} href="#" onClick={call_fn}>
            {i.label}
          </a>
        </li>
      );
    });

    var start_page = 1;
    var end_page = limit_page;

    if (current_page >= this.props.visiblePage - 1) {
      if (current_page + 1 >= total_page) {
        start_page = total_page - 6;
        end_page = total_page;
      } else {
        start_page = current_page - 4;
        end_page = current_page + 2;
      }
    }

    for (let i = start_page; i <= end_page; i++) {
      const link_class = ["page-item"];
      if (i === this.props.currentPage) {
        link_class.push("active");
      }

      var link = (
        <li
          key={i}
          className={link_class.join(" ")}
          onClick={() => this.onPageChange(i)}
        >
          <a className="page-link">{i}</a>
        </li>
      );
      page_nummber.push(link);
    }

    return (
      <nav>
        <ul className={pagination_class.join(" ")}>
          {buttons_left}
          {page_nummber}
          {buttons_right}
        </ul>
      </nav>
    );
  }
}

Pagination.defaultProps = {
  totalPages: 1,
  visiblePage: 7,
  size: "normal", // 'normal', 'small', 'large'
  align: "left", // 'center', 'left', 'right'
  url: "",
  currentPage: 1,
  onPageChange: [],
};

export default Pagination;
