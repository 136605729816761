import React, { Component } from 'react'
import DataForm from './../../../components/DataForm/DataForm';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import { Services } from '../../Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import UserBankFormPopup from './UserBankFormPopup';
import swal from 'sweetalert';

class UserBankForm extends Component {
  // Set default state
  state = {
    data:{
      asset_file: null,
      datasource: [],
    },
    selected_payment_popup: {
      
    },
    redirect: false,
    loading: false,
  }

  retrieveData = id => {
    axios.get(`${this.props.url}/${id}`).then(res => {
      const data = res.data;
      this.setState({ data });
    });
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;

    if(id !== undefined){
      this.retrieveData(id);
    }else{
      const loading = false;
      this.setState({loading});
    }
  }

  getValue = target => {
    let data = this.state.data;
    let name = Object.keys(data)[0];
    let loading = false;

    if(name === 'asset_file'){
      this.getPaymentFromFile(target);
      loading = true;
    }

    this.setState({
      data: {
        ...data,
        ...target
      },
      loading
    });
  }

  getPaymentFromFile = (asset_file) => {
    const value = Object.values(asset_file)[0];
    const params = { asset_file: value, flag: 'I' };
    const url = this.props.url;

    Services.submitRequestWithFile({ params, url }, { show_popup: false }).then( res => {
      const datasource = res.data;
      const data = {
        ...this.state.data,
        datasource: datasource,
      }

      this.setState({ data, loading: false });
    }).catch( res => {
      
      swal({
        title: "Error",
        text: "Something wrong, please try again latter.",
        icon: "error",
        button: "OK",
      });

    });
  }

  onSubmit = e => { 
    const params = { ...this.state.data };
    params.datasource = JSON.stringify(params.datasource);
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    loading = false;

    Services.submitRequestWithFile({ params, url }).then( res => { this.setState({redirect, loading}) }).catch( errors => {this.setState({ errors,loading })});
  }

  getRedirect = () => {
    const redirect = this.state.redirect;
    
    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  }

  selectPayment = (key) => {
    const datasource = [ ...this.state.data.datasource ];

    datasource[key].checked = !datasource[key].checked;

    this.setState({
      data:{
        asset_file: this.state.data.asset_file,
        datasource
      }
    });
  }

  editPayment = (key) => {
    let selected_payment_popup = [ ...this.state.data.datasource ][key];

		this.setState({ selected_payment_popup });
  }

  getFields = () => {
    const id = this.props.match.params.id;

    var fields = [
      { label: 'ไฟล์จากธนาคาร', name: 'asset_file', type: (id === undefined ? 'file' : 'label'), required: true, value: this.state.data.asset_file_preview, extension: 'excel' },
    ];

    return fields;
  }

  getDatasource = () => {
    const datasource = this.state.data.datasource;

    return datasource.map( (item, key) => {
      const disabled = (item.checkable === false);
      const checked = item.checked;
      let btn_check;

      if(item.payment_id !== null){
        btn_check = (<button type='button' className="btn btn-primary btn-sm" onClick={ e => this.editPayment(key) } data-toggle="modal" data-target="#userBankModal"><i className='fa fa-cogs'></i> ตรวจสอบ</button>);
      }

      return (<tr key={key}>
        <td><input disabled={disabled} type='checkbox' onChange={e => this.selectPayment(key)} checked={checked}/></td>
        <td className='text-center'>{item.receipt_date}</td>
        <td className='text-left'>{item.customer_name}</td>
        <td className='text-center'>{item.customer_no_ref_1}</td>
        <td className='text-center'>{item.ref_2}</td>
        <td className='text-right'>{item.invoice_amount}</td>
        <td className='text-right'>{item.receipt_amount}</td>
        <td className='text-center'>{Services.htmlParse(item.status)}</td>
        <td className='text-center'>
          {btn_check}
        </td>
      </tr>)
    })
  }

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;
    const datasource = this.getDatasource();
    const id = this.props.match.params.id;
    const allow_save = (id === undefined);
    let description;

    if(datasource.length > 0){
      description = (
        <React.Fragment>
          <strong>รายละเอียด:</strong>
          <table className="table table-striped w-100">
            <thead>
              <tr>
                <th className='text-center'><input type="checkbox" /></th>
                <th className='text-center'>วันที่ชำระ</th>
                <th className='text-center'>ชื่อลูกค้า</th>
                <th className='text-center'>Ref1</th>
                <th className='text-center'>Ref2</th>
                <th className='text-right'>จำนวน (ใบแจ้งชำระ)</th>
                <th className='text-right'>จำนวน (ธนาคาร)</th>
                <th className='text-center'>สถานะ</th>
                <th className='text-center'>#</th>
              </tr>
            </thead>
            <tbody>
              {datasource}
            </tbody>
          </table>
        </React.Fragment>
      )
    }

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          allow_save={allow_save}
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
        >
          <div>
            {description}
            <UserBankFormPopup { ...this.state.selected_payment_popup }></UserBankFormPopup>
          </div>
        </ DataForm>
      </BoxContainer>
    )
  }
}

UserBankForm.setDefaultProps = {
  url: '',
}

export default UserBankForm
