import React, { Component } from 'react'
import DataForm from './../../../components/DataForm/DataForm';
import FormInput from './../../../components/DataForm/FormInput';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import { Services, Alert } from '../../Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
const $ = window.$;

class MeetingKeynoteSpeakerAttach extends Component {
    constructor(props){
        super(props);
    
        this.upload_file = React.createRef();
    }

  // Set default state
  state = {
    data:{
        asset_file_preview: [],
    },
    url: {
        delete: '/meeting/attach',
        upload: '/meeting/attach',
        get: '/meeting/attach'
    },
    redirect: false,
    loading: true,
  }

  retrieveData = () => {
    axios.get(this.state.url.get).then(res => {
      const data = res.data;
      const new_data = {
        asset_file_preview: data,
      }

      this.setState({ loading: false, data: new_data });
    });
  }

  componentDidMount = () => {
    this.retrieveData();
  }

  getValue = target => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target
      }
    });
  }

  onSubmit = e => {
    const { name, files } = e.target;
    const params = {
      ...this.item,
      [name]: files
    };

    const url = this.state.url.upload;
    let loading = true;

    this.setState({ loading });

    Services.submitRequestWithFile({ params, url }).then( res => { 
      this.retrieveData();
    }).catch( errors => {this.setState({ errors,loading: false })});
  }

    onDeleteAsset = id => {
        Alert.confirm("คุณแน่ใจไหมที่จะลบไฟล์นี้").then(res => {
        if(res === true){
            this.setState({loading: true});
            const delete_url = this.state.url.delete + '/' + id;
            axios.delete(delete_url).then( () => {
                this.retrieveData();
            });
        }
        });
    }

    getRedirect = () => {
        const redirect = this.state.redirect;
        
        if (redirect) {
        const url = this.props.url;

        return <Redirect to={url} />;
        }

        return;
    }

    uploadFile = () => {
        $(this.upload_file.current).trigger('click');
    }
    
    fileOnChange = event => {
        this.onSubmit(event);
    }

    render() {
        const redirect = this.getRedirect();
        let url = this.props.url;
        
        const asset_file_preview = this.state.data.asset_file_preview.map( (asset_file, asset_file_index) => {
            return (<li key={asset_file_index}>{asset_file_index + 1}. {Services.htmlParse(asset_file.asset_file_preview)} <i onClick={e => { this.onDeleteAsset(asset_file.id) }} className='fa fa-close text-danger'></i></li>)
        });

        return (
        <BoxContainer loading={this.state.loading} errors={this.state.errors}>
            {redirect}
            <DataForm
            onSubmit={this.onSubmit}
            url={url}
            getValue={this.getValue}
            allow_save={false}
            >
                <div>
                    <strong>เอกสารแนบของวิทยากร</strong> <button className='btn btn-link' type='button' onClick={e => { this.uploadFile(); }}><u><i className='fa fa-plus'></i> เพิ่มเอกสารแนบ</u></button>
                    <ul className='list-unstyled'>
                        {asset_file_preview}
                    </ul>
                    
                    <input className='d-none' type='file' name='asset_files[]' multiple="multiple" ref={this.upload_file} onChange={this.fileOnChange}></input>
                </div>
            </DataForm>
        </BoxContainer>
        )
    }
}

MeetingKeynoteSpeakerAttach.setDefaultProps = {
  url: '',
}

export default MeetingKeynoteSpeakerAttach