import React, { Component } from 'react'
import DataForm from './../../../components/DataForm/DataForm';
import FormInput from './../../../components/DataForm/FormInput';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import { Services } from '../../Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';

class FrontendVenueForm extends Component {
  // Set default state
  state = {
    data:{
      name: '',
      name_th: '',
      desc: '',
      desc_en: '',
      active: true,
    },
    redirect: false,
    loading: false,
  }

  retrieveData = id => {
    axios.get(`${this.props.url}/${id}`).then(res => {
      const data = res.data;
      this.setState({ data });
    });
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;

    if(id !== undefined){
      this.retrieveData(id);
    }else{
      const loading = false;
      this.setState({loading});
    }
  }

  getValue = target => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target
      }
    });
  }

  onSubmit = e => {
    const params = this.state.data;
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    loading = false;

    Services.submitRequest({ params, url }).then( res => { this.setState({redirect, loading}) }).catch( errors => {this.setState({ errors,loading })});
  }

  getRedirect = () => {
    const redirect = this.state.redirect;
    
    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  }

  render() {
    const redirect = this.getRedirect();
    const url = this.props.url;

    const field_topic = { label: 'หัวข้อ', name: 'name', type: 'textbox', required: false, value: this.state.data.name };
    const field_desc = { label: 'รายละเอียด', name: 'desc', type: 'texteditor', required: false, value: this.state.data.desc };
    const field_topic_en = { label: 'หัวข้อ', name: 'name_en', type: 'textbox', required: false, value: this.state.data.name_en };
    const field_desc_en = { label: 'รายละเอียด', name: 'desc_en', type: 'texteditor', required: false, value: this.state.data.desc_en };
    const field_active = { label: 'Active', name: 'active', type: 'switch', required: false, value: this.state.data.active };
    
    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
        >
            <div className="tabs-container">
                <ul className="nav nav-tabs" role="tablist">
                    <li><a className="nav-link active" data-toggle="tab" href="#tab-1">TH</a></li>
                    <li><a className="nav-link" data-toggle="tab" href="#tab-2">EN</a></li>
                </ul>
                <div className="tab-content">
                    <div role="tabpanel" id="tab-1" className="tab-pane active">
                        <div className="panel-body">
                          <FormInput field={field_topic} getValue={this.getValue}></FormInput>
                          <FormInput field={field_desc} getValue={this.getValue}></FormInput>
                        </div>
                    </div>
                    <div role="tabpanel" id="tab-2" className="tab-pane">
                        <div className="panel-body">
                          <FormInput field={field_topic_en} getValue={this.getValue}></FormInput>
                          <FormInput field={field_desc_en} getValue={this.getValue}></FormInput>
                        </div>
                    </div>
                </div>
             </div>
             <FormInput field={field_active} getValue={this.getValue}></FormInput>
        </ DataForm>
      </BoxContainer>
    )
  }
}

FrontendVenueForm.setDefaultProps = {
  url: '',
}

export default FrontendVenueForm
