import React from "react";

export default function MemberType({ value }) {
  switch (value) {
    case "keynote":
      return <span className="badge badge-success">วิทยากร</span>;
    case "guest":
      return <span className="badge badge-warning">แขกรับเชิญ</span>;
    case "member":
      return <span className="badge badge-primary">ผู้เข้าร่วมงาน</span>;

    default:
      return <span></span>;
  }
}
