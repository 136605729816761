import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import UserWorkplaceForm from "./UserWorkplaceForm";
import TitleHeader from "../../../components/TitleHeader/TitleHeader";
import { CompanyAPI } from "../../../api";
import { Alert } from "../../Service/Services";
import UserWorkplaceIndex from "./UserWorkplaceIndex";

class UserWorkplace extends Component {
  render() {
    const api_url = "/master/company";

    return (
      <BrowserRouter>
        <div>
          <TitleHeader
            program_name="สถานที่ทำงาน"
            program_type="ข้อมูลบัญชีผู้ใช้งาน"
          ></TitleHeader>

          <Route
            exact
            path={`${this.props.match.path}/`}
            component={() => (
              <UserWorkplaceIndex url={api_url}></UserWorkplaceIndex>
            )}
          />

          <Route
            path={`${this.props.match.path}/create`}
            component={(props) => (
              <UserWorkplaceForm {...props} url={api_url}></UserWorkplaceForm>
            )}
          />
          <Route
            path={`${this.props.match.path}/:id/edit`}
            component={(props) => (
              <UserWorkplaceForm {...props} url={api_url}></UserWorkplaceForm>
            )}
          />
        </div>
      </BrowserRouter>
    );
  }
}

export default UserWorkplace;
