import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import AbstractCategoryForm from "./AbstractCategoryForm";
import TitleHeader from "../../../components/TitleHeader/TitleHeader";
import swal from "sweetalert";
import axios from "../../../axios";
import { Services } from "../../Service/Services";

class AbstractCategory extends Component {
  constructor(props) {
    super(props);
    this.datatable_element = React.createRef();
  }

  onImportFile = (params) => {
    const import_url = "/importabstracttopic";

    const new_params = {
      params,
      url: import_url,
    };

    Services.submitRequestWithFile(new_params)
      .then((res) => {
        this.datatable_element.current.refresh();
      })
      .catch((err) => {
        swal({
          title: "Error",
          text: err.join("\n"),
          icon: "error",
          button: "OK",
        });
        console.log("catch", err);
      });
  };

  render() {
    const columns = [
      { label: "ชื่อ", align: "left", name: "name", search_type: "string" },
      {
        label: "ประเภทผลงาน",
        align: "left",
        name: "abstract_types.name",
        search_type: "string",
      },
    ];

    const api_url = "/master/abstract_topic";
    const export_url = axios.defaults.baseURL + "/exportabstracttopic";

    return (
      <BrowserRouter>
        <div>
          <TitleHeader
            program_name="หมวดหมู่การนำเสนอ"
            program_type="ผลงานวิชาการ"
          ></TitleHeader>

          <Route
            exact
            path={`${this.props.match.path}/`}
            component={() => (
              <DataTable
                ref={this.datatable_element}
                columns={columns}
                allow_active={true}
                url={api_url}
                allow_import={true}
                export_url={export_url}
                onImportFile={this.onImportFile}
              ></DataTable>
            )}
          />

          <Route
            path={`${this.props.match.path}/create`}
            component={(props) => (
              <AbstractCategoryForm
                {...props}
                url={api_url}
              ></AbstractCategoryForm>
            )}
          />
          <Route
            path={`${this.props.match.path}/:id/edit`}
            component={(props) => (
              <AbstractCategoryForm
                {...props}
                url={api_url}
              ></AbstractCategoryForm>
            )}
          />
        </div>
      </BrowserRouter>
    );
  }
}

export default AbstractCategory;
