import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import UserInternalForm from './UserInternalForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class UserInternal extends Component {
    render() {
        const columns = [
            { label: 'ชื่อ - นามสกุล', align: 'left', name: 'name', search_type: 'string' },
            { label: 'อีเมล', align: 'left', name: 'email', search_type: 'string' },
            { label: 'บทบาท', align: 'left', name: 'role', sortable: false },
            { label: 'สถานะ', align: 'center', name: 'status', sortable: false },
        ];

        const api_url = '/admin/internal';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ผู้ใช้งานภายใน' program_type='ข้อมูลบัญชีผู้ใช้งาน'></TitleHeader>
                    
                    <Route exact path={`${this.props.match.path}/`} component={() => (
                        <DataTable allow_import={false} columns={columns} allow_active={true} url={api_url}></DataTable>
                    )} />
                    
                    <Route path={`${this.props.match.path}/create`} component={props => (<UserInternalForm {...props} url={api_url}></UserInternalForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<UserInternalForm {...props} url={api_url}></UserInternalForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default UserInternal
