import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import UserReviewerForm from "./UserReviewerForm";
import UserReviewerIndex from "./UserReviewerIndex";
import TitleHeader from "../../../components/TitleHeader/TitleHeader";

class UserReviewer extends Component {
  render() {
    const api_url = "/admin/ireviewer";

    return (
      <BrowserRouter>
        <div>
          <TitleHeader
            program_name="ผู้ใช้งานผู้เชี่ยวชาญ"
            program_type="ข้อมูลบัญชีผู้ใช้งาน"
          ></TitleHeader>

          <Route
            exact
            path={`${this.props.match.path}/`}
            component={() => (
              <UserReviewerIndex url={api_url}></UserReviewerIndex>
            )}
          />
          <Route
            path={`${this.props.match.path}/create/:email?`}
            component={(props) => (
              <UserReviewerForm {...props} url={api_url}></UserReviewerForm>
            )}
          />
          <Route
            path={`${this.props.match.path}/:id/edit`}
            component={(props) => (
              <UserReviewerForm {...props} url={api_url}></UserReviewerForm>
            )}
          />
        </div>
      </BrowserRouter>
    );
  }
}

export default UserReviewer;
