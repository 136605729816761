import React, { Component } from "react";
import DataForm from "./../../../components/DataForm/DataForm";
import FormInput from "./../../../components/DataForm/FormInput";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import { Services } from "../../Service/Services";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";
import ReviewerGroup from "./ReviewerGroup";

class AbstractCategoryForm extends Component {
  // Set default state
  state = {
    data: {
      name: "",
      abstract_type_id: null,
      active: true,
      reviewer_groups: [],
      code: "",
    },
    datasource: {
      abstract_type: [],
      reviewer: [],
    },
    redirect: false,
    loading: true,
  };

  retrieveData = (id, datasource = null) => {
    axios.get(`${this.props.url}/${id}`).then((res) => {
      const data = res.data;

      if (datasource === null) {
        datasource = this.state.datasource;
      }

      this.setState({ loading: false, data: data, datasource });
    });
  };

  init = () => {
    const id = this.props.match.params.id;

    const promise = new Promise((resolve) => {
      const params = {
        tables: [{ name: "abstract_type" }, { name: "reviewer", ref1: id }],
      };

      axios.post("datasource", params).then((res) => {
        const abstract_type = res.data.abstract_type.map((item) => {
          return {
            key: item.id,
            label: item.name,
            code: item.code,
          };
        });

        const reviewer = res.data.reviewer.map((item) => {
          return {
            key: item.id,
            label: item.name,
          };
        });

        const datasource = {
          ...this.state.datasource,
          abstract_type,
          reviewer,
        };

        resolve(datasource);
      });
    });

    return promise;
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;

    this.init().then((datasource) => {
      if (id !== undefined) {
        this.retrieveData(id, datasource);
      } else {
        this.setState({ loading: false, datasource });
      }
    });
  };

  getValue = (target) => {
    const name = Object.keys(target)[0];

    let data = { ...this.state.data };

    if (name === "abstract_type_id") {
      const value = Object.values(target)[0];

      data.code =
        this.state.datasource.abstract_type.find((f) => f.key === Number(value))
          ?.code || null;
    }
    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
  };

  onReviewerGroupChange = (new_reviewer_groups) => {
    this.setState({
      data: { ...this.state.data, reviewer_groups: new_reviewer_groups },
    });
  };

  onSubmit = (e) => {
    let params = this.state.data;

    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    loading = false;

    Services.submitRequestWithFile({ params, url })
      .then((res) => {
        this.setState({ redirect, loading });
      })
      .catch((errors) => {
        this.setState({ errors, loading });
      });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  };

  getFields = () => {
    const id = this.state.id;

    var fields = [
      {
        label: "หมวดหมู่การนำเสนอ",
        name: "name",
        type: "textbox",
        required: true,
        value: this.state.data.name,
      },
      {
        label: "ประเภทผลงาน",
        name: "abstract_type_id",
        type: "ddl",
        required: true,
        value: this.state.data.abstract_type_id,
        datasource: this.state.datasource.abstract_type,
      },
      {
        label: "Active",
        name: "active",
        type: "switch",
        required: false,
        value: this.state.data.active,
      },
    ];

    return fields;
  };

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
        >
          <ReviewerGroup
            datasource={this.state.datasource}
            values={this.state.data.reviewer_groups}
            onChange={this.onReviewerGroupChange}
          />
        </DataForm>
      </BoxContainer>
    );
  }
}

AbstractCategoryForm.setDefaultProps = {
  url: "",
};

export default AbstractCategoryForm;
