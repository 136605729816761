import React, { useRef, useState } from "react";
import FormInput from "../../../components/DataForm/FormInput";
import axios from "../../../axios";
import { Alert, Services } from "../../Service/Services";

const UserPromotionCompanies = ({ companies, onUpdate, datasource }) => {
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const removeItem = (rowIndex) => {
    const updatedCompanies = [...companies];
    updatedCompanies.splice(rowIndex, 1);

    onUpdate(updatedCompanies);
  };

  const onAddNew = () => {
    const newCompanies = [...companies];
    newCompanies.push(null);

    onUpdate(newCompanies);
  };

  const importFile = (e) => {
    fileInputRef.current.click();
  };

  const uploadFile = (e) => {
    setIsLoading(true);
    const url = "promotion/import";
    const params = {
      url,
      params: {
        import: e.target.files[0],
      },
    };

    Services.submitRequestWithFile(params)
      .then((res) => {
        const data = res.data;

        const updatedCompanies = [...companies, ...data];

        onUpdate(updatedCompanies);
        setIsLoading(false);

        fileInputRef.current.value = "";
      })
      .catch((errors) => {
        Alert.error(errors.join("\n"), "มีข้อผิดพลาด");

        fileInputRef.current.value = "";

        setIsLoading(false);
      });
  };

  const getValue = (target, index) => {
    const value = Object.values(target)[0];
    const newCompanies = [...companies];
    newCompanies[index] = value ? parseInt(value) : value;

    onUpdate(newCompanies);
  };

  const export_url = axios.defaults.baseURL + "/promotion/download";

  const row = companies.map((companyId, index) => {
    const fieldCompany = {
      name: "company_" + index,
      value: companyId,
      type: "ddl",
      minimumInputLength: 1,
      datasource: datasource.company,
    };

    return (
      <tr key={index}>
        <td>
          <FormInput
            field={fieldCompany}
            getValue={(target) => getValue(target, index)}
          ></FormInput>
        </td>
        <td className="text-center">
          <div className="btn-group">
            <button
              onClick={() => {
                removeItem(index);
              }}
              type="button"
              data-toggle="tooltip"
              data-placemeent="top"
              data-original-title="Trash"
              title="Trash"
              className="btn btn-danger btn-sm ml-1"
            >
              <i className="fa fa-trash"></i> ลบ
            </button>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <>
      <label className="col-form-label">สถาบันที่จะได้รับโปรโมชั่น:</label>
      <input
        ref={fileInputRef}
        type="file"
        className="d-none"
        onChange={uploadFile}
      ></input>
      <div className="float-right">
        <div className="btn-group" role="group">
          <button
            type="button"
            className="btn btn-primary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            นำเข้าข้อมูล
          </button>
          <ul className="dropdown-menu">
            <li>
              <a target="_blank" href={export_url}>
                ดาวน์โหลด Template
              </a>
            </li>
            <li>
              <a onClick={importFile}>นำเข้าข้อมูล</a>
            </li>
          </ul>
        </div>

        <button
          type="button"
          onClick={onAddNew}
          className="btn btn-primary ml-2"
        >
          <i className="fa fa-plus"></i> เพิ่มข้อมูล
        </button>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th className="text-center">ชื่อสถาบัน</th>
            <th className="text-center">#</th>
          </tr>
        </thead>
        <tbody>{isLoading ? "Loading..." : row}</tbody>
      </table>
    </>
  );
};

export default UserPromotionCompanies;
