import React from "react";
import Loader from "./Loader";
import "./LoaderContainer.css";

export default function LoaderContainer({ children, isLoading }) {
  return (
    <>
      <div>
        {isLoading && (
          <div className="loader-container d-flex align-items-center">
            <Loader />
          </div>
        )}
        {children}
      </div>
    </>
  );
}
