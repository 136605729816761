import React, { Component } from "react";
import "./Dropdown.css";
import axios from "../../axios";

const $ = window.$;

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.element = React.createRef();
    this.data = [];
  }

  componentDidMount = () => {
    const datasource = this.props.datasource;
    this.data = datasource.map((i) => {
      return { id: i.key, text: i.label };
    });

    this.rerender();
  };

  rerender = () => {
    let options = {
      placeholder: this.props.placeholder,
      allowClear: true,
      minimumInputLength: this.props.minimumInputLength,
      ajax: {
        transport: (params, success, failure) => {
          var items = this.data;
          // fitering if params.data.q available
          if (params.data && params.data.q) {
            items = items.filter(function (item) {
              let pass = false;

              try {
                pass = new RegExp(params.data.q).test(item.text);
              } catch (error) {
                console.log(error);
              }

              return pass;
            });
          }
          var promise = new Promise(function (resolve, reject) {
            resolve({ results: items });
          });
          promise.then(success);
          promise.catch(failure);
        },
      },
      // minimumInputLength: 1,
      // query: (query) => {
      //     var data = {results: []}, i, j, text, id;
      //     const term = query.term;
      //     const datasource = this.props.datasource.filter( f => (f.label.indexOf(term) !== -1));

      //     // for (i = 1; i < 10; i++) {
      //     //   const result = datasource[i];

      //     //   if(result === undefined){
      //     //     break;
      //     //   }else{
      //     //     text = result.label;
      //     //     id = result.key;
      //     //   }

      //     //   data.results.push({id, text});
      //     // }

      //     query.callback(data);
      // }
    };

    // if(this.props.datasource.minimumInputLength !== null) options.minimumInputLength = this.props.datasource.minimumInputLength;

    if (this.props.allow_new_item === true) {
      options.tags = true;
      options.createTag = function (params) {
        return {
          id: params.term,
          text: params.term,
          newOption: true,
        };
      };
      options.templateResult = function (data) {
        var $result = $("<span></span>");

        $result.text(data.text);

        if (data.newOption) {
          $result.append(" <em>(โปรดระบุ)</em>");
        }

        return $result;
      };
    }

    if (this.props.ajax === true && this.props.api_url !== "") {
      // options.ajax = {
      //   url: axios.defaults.baseURL + '/' + this.props.api_url,
      //   dataType: 'json',
      //   data: function(term, page) {
      //     return {
      //       q: term
      //     };
      //   },
      //   results: function(data, page) {
      //     console.log(data);
      //     return {
      //       results: data
      //     };
      //   }
      // }
    }

    $(this.element.current)
      .select2(options)
      .on("change.select2", (e) => {
        // var data = e.params.data;
        const { name, value } = e.target;

        const params = {
          target: { name, value },
        };

        if (this.props.value !== value) this.props.onChange(params);
      });
  };

  onChange = () => {};

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.datasource !== this.props.datasource) {
      const datasource = this.props.datasource;
      this.data = datasource.map((i) => {
        return { id: i.key, text: i.label };
      });
    }

    if (prevProps.value !== this.props.value) {
      $(this.element.current).val(this.props.value).trigger("change");
    }
  };

  render() {
    let datasource = this.props.datasource;
    const disabled = this.props.disabled;
    const value = this.props.value || "";

    if (this.props.value !== "") {
      const selected_data = datasource.find((f) => f.key == this.props.value);
      if (selected_data === undefined) {
        datasource.push({
          key: this.props.value,
          label: this.props.value,
        });
      }
    }

    var options = datasource.map((item, index) => (
      <option key={index} value={item.key}>
        {item.label}
      </option>
    ));

    options.unshift(
      <option value="" key={-1} checked>
        {this.props.placeholder}
      </option>
    );

    // if(this.props.required === false){
    //   options.unshift(<option value='' key={-1} checked>{this.props.placeholder}</option>);
    // }else{
    //   options.unshift(<option value='' key={-1} disabled>{this.props.placeholder}</option>);
    // }

    return (
      <select
        onChange={this.onChange}
        value={value}
        style={{ width: "100%" }}
        disabled={disabled}
        ref={this.element}
        required={this.props.required}
        className="form-control"
        id={this.props.name}
        name={this.props.name}
      >
        {options}
      </select>
    );
  }
}

Dropdown.defaultProps = {
  datasource: [],
  name: "",
  disabled: false,
  value: "",
  onChange: [],
  required: false,
  placeholder: "Please select",
  ajax: false,
  api_url: "",
  allow_new_item: false,
  minimumInputLength: 0,
  // minimumInputLength: null,
};

export default Dropdown;
