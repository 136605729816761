import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import OfflineCheckInRoomForm from './OfflineCheckInRoomForm';
import OfflineCheckInRoomFormRoom from './OfflineCheckInRoomFormRoom';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class OfflineCheckInRoom extends Component {
    render() {
        const api_url = '/offline/checkinroom';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ลงทะเบียนเข้าห้องประชุม' program_type='Offline'></TitleHeader>
                    
                    <Route exact path={`${this.props.match.path}`} component={props => (<OfflineCheckInRoomForm {...props} url={api_url}></OfflineCheckInRoomForm>)} />
                    <Route path={`${this.props.match.path}/room`} component={props => (<OfflineCheckInRoomFormRoom {...props} url={api_url}></OfflineCheckInRoomFormRoom>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default OfflineCheckInRoom
