import React, { Component } from 'react'
import DataForm from './../../../components/DataForm/DataForm';
import FormInput from './../../../components/DataForm/FormInput';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import { Services } from '../../Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
const $ = window.$;

class FrontendNewsForm extends Component {
  // Set default state
  state = {
    data:{
      active: true,
      feature: true,
      news_type: 'vdo',
      topic: '',
      desc: '',
      topic_en: '',
      desc_en: '',
      asset_file: '',
      asset_file_preview: '',
      asset_file_thumbnail: '',
      asset_file_thumbnail_preview: '',
      link: '',
    },
    datasource: {
      type: [
        { key: 'vdo', label: 'วีดีโอ' },
        { key: 'image', label: 'รูปภาพ' },
      ]
    },
    redirect: false,
    loading: false,
  }

  retrieveData = id => {
    axios.get(`${this.props.url}/${id}`).then(res => {
      const data = res.data;
      this.setState({ data });
    });
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;

    if(id !== undefined){
      this.retrieveData(id);
    }else{
      const loading = false;
      this.setState({loading});
    }

    $(function(){
      $('#tab-2').removeClass('show');
    });
  }

  getValue = target => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target
      }
    });
  }

  onSubmit = e => {
    const params = this.state.data;
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    loading = false;

    Services.submitRequestWithFile({ params, url }).then( res => { this.setState({redirect, loading}) }).catch( errors => {this.setState({ errors,loading })});
  }

  getRedirect = () => {
    const redirect = this.state.redirect;
    
    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  }

  getFields = () => {
    const id = this.state.id;

    var fields = [
      { label: 'แสดงหน้าหลัก', name: 'feature', type: 'switch', required: false, value: this.state.data.feature },
      { label: 'Active', name: 'active', type: 'switch', required: false, value: this.state.data.active },
      { label: 'รูปแบบข่าว', name: 'news_type', type: 'radio', inline: true ,required: false, value: this.state.data.news_type, datasource: this.state.datasource.type },
    ];

    if(this.state.data.news_type === 'vdo'){
      fields.push({ label: 'ลิงค์วีดีโอ Youtube', name: 'link', type: 'textbox', required: false, value: this.state.data.link, remark: 'ลิงค์ youtube (ตัวอย่างลิงค์ https://www.youtube.com/watch?v=nsauuWoL59s)' });
    }else{
      fields.push({ label: 'รูปภาพ', name: 'asset_file_thumbnail', type: 'file', required: false, multiple: false, value: this.state.data.asset_file_thumbnail_preview, extension: 'image' });
    }

    fields.push({ label: 'เอกสารแนบ', name: 'asset_file', type: 'file', required: false, multiple: false, value: this.state.data.asset_file_preview });

    return fields;
  }

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;

    const field_topic = { label: 'หัวข้อข่าว', name: 'topic', type: 'textbox', required: false, value: this.state.data.topic }
    const field_desc = { label: 'รายละเอียด', name: 'desc', type: 'texteditor', required: false, value: this.state.data.desc }
    const field_topic_en = { label: 'หัวข้อข่าว', name: 'topic_en', type: 'textbox', required: false, value: this.state.data.topic_en }
    const field_desc_en = { label: 'รายละเอียด', name: 'desc_en', type: 'texteditor', required: false, value: this.state.data.desc_en }
    const field_short_desc = { label: 'รายละเอียดแบบสั้น', name: 'short_desc', type: 'texteditor', required: false, value: this.state.data.short_desc }
    const field_short_desc_en = { label: 'รายละเอียดแบบสั้น', name: 'short_desc_en', type: 'texteditor', required: false, value: this.state.data.short_desc_en }

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
        >
            <div className="tabs-container">
                <ul className="nav nav-tabs" role="tablist">
                    <li><a className="nav-link active" data-toggle="tab" href="#tab-1">TH</a></li>
                    <li><a className="nav-link" data-toggle="tab" href="#tab-2">EN</a></li>
                </ul>
                <div className="tab-content">
                    <div role="tabpanel" id="tab-1" className="tab-pane active">
                        <div className="panel-body">
                          <FormInput getValue={this.getValue} field={field_topic}></FormInput>
                          <FormInput getValue={this.getValue} field={field_short_desc}></FormInput>
                          <FormInput getValue={this.getValue} field={field_desc}></FormInput>
                        </div>
                    </div>
                    <div role="tabpanel" id="tab-2" className="tab-pane show">
                        <div className="panel-body">
                          <FormInput getValue={this.getValue} field={field_topic_en}></FormInput>
                          <FormInput getValue={this.getValue} field={field_short_desc_en}></FormInput>
                          <FormInput getValue={this.getValue} field={field_desc_en}></FormInput>
                        </div>
                    </div>
                </div>
            </div>
        </DataForm>
      </BoxContainer>
    )
  }
}

FrontendNewsForm.setDefaultProps = {
  url: '',
}

export default FrontendNewsForm
