import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import UserWorkplaceForm from "./UserWorkplaceForm";
import TitleHeader from "../../../components/TitleHeader/TitleHeader";
import { CompanyAPI } from "../../../api";
import { Alert } from "../../Service/Services";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";

class UserWorkplaceIndex extends Component {
  constructor(props) {
    super(props);
    this.datatable_element = React.createRef();
  }

  state = {
    loading: false,
    selected_id: [],
  };
  onUpdateClick = () => {
    Alert.confirm("คุณแน่ใจไหมที่จะอัปเดตข้อมูลจาก API").then((res) => {
      if (res === true) {
        this.setState({ loading: true });
        CompanyAPI.updateCompany()
          .then((res) => {
            if(res.status == 200){
                this.datatable_element.current.refresh();
                Alert.done("อัปเดตสถานที่ทำงานเรียบร้อย");
                this.setState({
                  loading: false,
                });
            }
          })
          .catch(() => {
            Alert.error("เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ");
          });
      }
    });
  };

  render() {
    const columns = [
      {
        label: "สถานที่ทำงาน",
        align: "left",
        name: "name",
        search_type: "string",
      },
    ];

    const buttons = [
      {
        label:
          '<i class="fa fa-refresh" aria-hidden="true"></i> อัปเดตสถานที่ทำงาน',
        className: "btn btn-warning",
        onClick: this.onUpdateClick,
      },
    ];

    return (
      <BoxContainer loading={this.state.loading}>
        <DataTable
          ref={this.datatable_element}
          allow_import={false}
          columns={columns}
          allow_insert={false}
          allow_trash={false}
          allow_active={true}
          url={this.props.url}
          buttons={buttons}
        ></DataTable>
      </BoxContainer>
    );
  }
}

export default UserWorkplaceIndex;
