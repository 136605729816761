import React, { Component } from "react";
import DataTable from "../../../components/DataTable/DataTable";
import axios from "../../../axios";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";
import { Alert } from "../../Service/Services";
import { ERPAPI } from "../../../api";
import ERPSummary from "./ERPSummary";

class MemberGRoupERPIndex extends Component {
  constructor(props) {
    super(props);
    this.datatable_element = React.createRef();
    this.summary_element = React.createRef();
  }

  state = {
    loading: false,
    redirect: false,
    redirect_to_url: "",
  };

  sendAllToERP = () => {
    Alert.confirm("คุณแน่ใจไหมที่จะส่งข้อมูลไปยัง ERP").then((res) => {
      if (res === true) {
        this.setState({ loading: true });

        const url = "erp/sendall";
        axios
          .post(url)
          .then((res) => {
            this.setState({
              loading: false,
            });

            const { complete, incomplete } = res.data;

            Alert.done(
              "เสร็จสิ้นจำนวน " +
                complete +
                " รายการ \nไม่เสร็จสิ้นจำนวน " +
                incomplete +
                " รายการ"
            ).then((res) => {
              this.datatable_element.current.refresh();
              this.summary_element.current.refresh();
            });
          })
          .catch((error) => {
            this.setState({ loading: false });
            Alert.error("ระบบมีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ");
            console.log("ERROR", error.data);
          });
      }
    });
  };

  sendSelectedToERP = (criteria, selected_items) => {
    if (selected_items.length === 0) {
      Alert.error("กรุณาเลือกรายการที่ต้องการส่ง ERP");
    } else {
      Alert.confirm("คุณแน่ใจไหมที่จะส่งข้อมูลไปยัง ERP").then((res) => {
        if (res === true) {
          this.setState({ loading: true });

          ERPAPI.sendToERP(selected_items.map((item) => item.key))
            .then(() => {
              this.setState({ loading: false });
              Alert.done("ส่งข้อมูลไปยัง ERP เรียบร้อย").then(() => {
                this.datatable_element.current.refresh();
                this.summary_element.current.refresh();
              });
            })
            .catch((error) => {
              this.setState({ loading: false });
              Alert.error("ระบบมีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ");
              console.log("ERROR", error.data);
            });
        }
      });
    }
  };

  onPrintReportClick = (criteria) => {
    console.log("MemberGroupERPIndex:78 =>", criteria);

    const params = [
      ["receive_date", criteria.filter.receive_date || ""],
      ["status", criteria.filter.status || ""],
    ];

    const url =
      axios.defaults.baseURL +
      "/report/erptransaction?" +
      params.map((i) => i[0] + "=" + i[1]).join("&");

    window.open(url, "_blank");
  };

  render() {
    const status_datasource = [
      { key: "done", label: "สำเร็จ" },
      { key: "fail", label: "ไม่สำเร็จ" },
      { key: "waiting", label: "รอส่ง" },
    ];

    const columns = [
      {
        label: "เลขที่ใบเสร็จรับเงิน",
        align: "left",
        name: "erp_document_no",
      },
      {
        label: "ชื่อที่ออกใบเสร็จ",
        align: "left",
        name: "name",
        width: "200px",
      },
      {
        label: "ที่อยู่ในการออกใบเสร็จ",
        align: "left",
        name: "address1",
        width: "300px",
      },
      {
        label: "วันที่รับเงิน",
        align: "center",
        name: "receive_date",
        search_type: "datepicker",
      },
      {
        label: "จำนวนเงินรวม Vat",
        align: "right",
        name: "total",
        summaryable: true,
      },
      {
        label: "รหัสประจำตัวผู้เสียภาษี",
        align: "center",
        name: "tax_id",
      },
      {
        label: "Ref 1",
        align: "left",
        name: "ref1",
      },
      {
        label: "Ref 2",
        align: "left",
        name: "ref2",
      },
      {
        label: "โรงพยาบาล",
        align: "left",
        name: "company_name",
        sortable: false,
        width: "200px",
      },
      {
        label: "จำนวนหน่วย",
        align: "right",
        name: "unit",
        sortable: false,
        summaryable: true,
      },
      {
        label: "สถานะ",
        align: "center",
        name: "status",
        sortable: false,
        search_type: "ddl",
        search: { type: "ddl", datasource: status_datasource },
      },
    ];

    const api_url = "/admin/membergrouperp";

    const buttons = [
      {
        is_multiple: true,
        label: '<i class="fa fa-print"></i> ส่งข้อมูล ERP',
        className: "btn btn-primary",
        name: "btn_erp",
        datasource: [
          {
            label: "ส่งข้อมูล ERP ทั้งหมด ที่ยังไม่ส่ง",
            onClick: this.sendAllToERP,
          },
          {
            label: "ส่งข้อมูล ERP",
            onClick: this.sendSelectedToERP,
          },
        ],
      },
      {
        label: '<i className="fa fa-print"></i> รายงาน ERP',
        className: "btn btn-warning",
        onClick: this.onPrintReportClick,
      },
    ];

    return (
      <BoxContainer loading={this.state.loading}>
        <ERPSummary ref={this.summary_element} />
        <DataTable
          ref={this.datatable_element}
          show_action={true}
          allow_import={false}
          columns={columns}
          allow_active={false}
          allow_insert={false}
          allow_trash={false}
          allow_edit={true}
          allow_view={false}
          allow_checkbox={true}
          url={api_url}
          show_summary_footer={true}
          buttons={buttons}
        ></DataTable>
      </BoxContainer>
    );
  }
}

export default MemberGRoupERPIndex;
