import React, { Component } from 'react'
import FormInput from './../../../components/DataForm/FormInput';

class MeetingRoomStaff extends Component {
  getValue = (target, index) => {
    const new_data = [ ...this.props.data ];
    const name = Object.keys(target)[0];
    const value = Object.values(target)[0];
    new_data[index][name] = value;

    this.props.onChange(new_data);
  }

  onDelete = index => {
    const new_data = [ ...this.props.data ];
    new_data.splice(index, 1);

    this.props.onChange(new_data);
  }

  onInsert = () => {
    const new_data = [ ...this.props.data ];
    new_data.push({
        user_id: '',
        room_role_id: '',
    });

    console.log(new_data);

    this.props.onChange(new_data);
  }

  render() {
    const row = this.props.data.map((item, index) => {
        const field_user = { name: 'user_id', type: 'ddl', datasource: this.props.datasource.staff, value: item.user_id, required: true };
        const field_room_role = { name: 'room_role_id', type: 'ddl', datasource: this.props.datasource.room_role, value: item.room_role_id, required: true };

        return (
            <tr key={index}>
                <td><FormInput field={field_user} getValue={target => { this.getValue(target, index) }}></FormInput></td>
                <td><FormInput field={field_room_role} getValue={target => { this.getValue(target, index) }}></FormInput></td>
                <td className='text-center'>
                <div className="btn-group">
                    <button onClick={() => { this.onDelete(index) }} type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Trash" title="Trash" className="btn btn-danger btn-sm ml-1"><i className="fa fa-trash"></i> ลบ</button></div>
                </td>
            </tr>
        )
    });

    return (
      <div>
            <button type='button' onClick={this.onInsert} className='btn btn-primary float-right'><i className='fa fa-plus'></i> เพิ่มข้อมูล</button>
            <table className='table'>
                <thead>
                    <tr>
                        <th className='text-center'>ผู้รับผิดชอบ</th>
                        <th className='text-center'>หน้าที่</th>
                        <th className='text-center'>#</th>
                    </tr>
                </thead>
                <tbody>
                    {row}
                </tbody>
            </table>
      </div>
    )
  }
}

MeetingRoomStaff.setDefaultProps = {
  datasource: {
      staff: [],
      room_role: [],
  },
  onChange: {},
  data: [],
}

export default MeetingRoomStaff