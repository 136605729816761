import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import MeetingKeynoteSpeakerForm from './MeetingKeynoteSpeakerForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import MeetingKeynoteSpeakerIndex from './MeetingKeynoteSpeakerIndex';
import MeetingKeynoteSpeakerAttach from './MeetingKeynoteSpeakerAttach';

class MeetingKeynoteSpeaker extends Component {
    state = {
        api_url: '/meeting/keynote',
    }

    render() {
        const api_url = this.state.api_url;

        return (
            <BrowserRouter>
                <TitleHeader program_name='วิทยากร' program_type='ห้องประชุม'></TitleHeader>
                
                <Route exact path={`${this.props.match.path}/`} component={() => (
                    <MeetingKeynoteSpeakerIndex url={api_url}></MeetingKeynoteSpeakerIndex>
                )} />
                
                <Route path={`${this.props.match.path}/create/:name?`} component={props => (<MeetingKeynoteSpeakerForm {...props} url={api_url}></MeetingKeynoteSpeakerForm>)} />
                <Route path={`${this.props.match.path}/:id/edit`} component={props => (<MeetingKeynoteSpeakerForm {...props} url={api_url}></MeetingKeynoteSpeakerForm>)} />
                <Route path={`${this.props.match.path}/attach`} component={props => (<MeetingKeynoteSpeakerAttach {...props} url={api_url}></MeetingKeynoteSpeakerAttach>)} />
            </BrowserRouter>
        )
    }
}

export default MeetingKeynoteSpeaker
