import React, { Component } from 'react'
import axios from '../../../axios';
import { Alert, Services } from '../../Service/Services';
const $ = window.$;

class UserBankFormPopup extends Component {
	constructor(props) {
	  super(props);

	  this.modal = React.createRef();
	}

	state = {
		data:{
            contact: {
                name: '',
                telephone: '',
                document_address: '',
                receipt_address: '',
            },
            members: [],
        }
	}

	componentWillReceiveProps = (nextProps) => {
		if(nextProps.payment_id !== null){
			this.getPaymentDetail(nextProps.payment_id);
        }
        
        console.log('componentWillReceiveProps', nextProps);
    }
    
    getPaymentDetail = (payment_id) => {
        const url = `memberpayment/${payment_id}`;
        axios.get(url).then( res => {
            const data = res.data;
            const contact = data.contact;
            const members = data.members;

            this.setState({
                data: {
                    contact, members
                }
            });
        })
    }

    getContacts = () => {
        const fields = [];
        const contact = this.state.data.contact;

        fields.push({ label: 'ชื่อ', value: contact.name });
        fields.push({ label: 'เบอร์โทร', value: contact.telephone });
        fields.push({ label: 'ประเภทการออกใบเสร็จ', value: contact.receipt_address_type });
        fields.push({ label: 'ที่อยู่ในการออกใบเสร็จ', value: contact.receipt_address });
        fields.push({ label: 'ที่อยู่ในการส่งเอกสาร', value: contact.document_address });

        return fields.map( (field,key) => {
            return (<div key={key}><strong>{field.label}: </strong>{field.value}</div>);
        });
    }

    deleteMember = (name, id, key) => {
        const url = `memberpayment/${id}`;

        Alert.confirm('คุณแน่ใจไหมจะลบข้อมูลการลงทะเบียนของ' + name).then( res => {
            if(res === true){
                // delete member here
                axios.delete(url).then(res => {
                    this.getPaymentDetail(this.props.payment_id);
                });
            }
        })
    }

    restoreMember = (name, id, key) => {
        const url = `memberpayment/${id}`;

        Alert.confirm('คุณแน่ใจไหมจะนำข้อมูลกลับมา' + name).then( res => {
            if(res === true){
                axios.delete(url).then(res => {
                    this.getPaymentDetail(this.props.payment_id);
                });
            }
        })
    }

    getMembers = () => {
        const members = this.state.data.members.map( (item, key) => {

            let btn_delete;

            if(item.deleted === false){
                btn_delete = (<button type='button' className='btn btn-danger' onClick={e => this.deleteMember(item.name, item.id, key)}><i className='fa fa-trash'></i> ลบข้อมูล</button>);
            }else{
                btn_delete = (<button type='button' className='btn btn-warning' onClick={e => this.restoreMember(item.name, item.id, key)}><i className='fa fa-refresh'></i> นำข้อมูลกลับ</button>);
            }

            return (<tr key={key}>
                <td className='text-left'>{item.name}</td>
                <td className='text-center'>{item.identity_number}</td>
                <td className='text-center'>{Services.htmlParse(item.delete_status)}</td>
                <td className='text-center'>
                    {btn_delete}
                </td>
            </tr>)
        });

        return members;
    }

	componentDidMount = () => {
		$(this.modal.current).on('hidden.bs.modal', e => {
			
		});
    }
    
    getFields = () => {
        let fields = [];
        const props = this.props;

        fields.push({ label: 'Payment Date', value: props.receipt_date });
        fields.push({ label: 'Customer Name', value: props.customer_name });
        fields.push({ label: 'Ref 1', value: props.customer_no_ref_1 });
        fields.push({ label: 'Ref 2', value: props.ref_2 });
        fields.push({ label: 'Transaction Code', value: props.transaction_code });
        fields.push({ label: 'Amount', value: props.receipt_amount });

        return fields.map( (field,key) => {
            return (<div key={key}><strong>{field.label}: </strong>{field.value}</div>);
        });
    }

  	render() {
        const fields = this.getFields();
        const contacts = this.getContacts();
        const members = this.getMembers();

	    return (
	      <div ref={this.modal} className="modal fade" id="userBankModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
	        <div className="modal-dialog modal-lg" role="document">
	          <div className="modal-content">
	            <div className="modal-header bg-primary text-white">
	              <h5 className="modal-title" id="exampleModalLabel">ข้อมูลการชำระเงิน</h5>
	              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
	                <span aria-hidden="true">&times;</span>
	              </button>
	            </div>
                <div className="modal-body">
                    {fields}
                    <hr />
                    <h3>ข้อมูลผู้ประสานงาน</h3>
                    {contacts}
                    <hr />
                    <h3>ข้อมูลสมาชิก</h3>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th className='text-center'>ชื่อ - นามสกุล</th>
                                <th className='text-center'>หมายเลขบัตรประจำตัวประชาชน</th>
                                <th className='text-center'>สถานะ</th>
                                <th className='text-center'>#</th>
                            </tr>
                        </thead>
                        <tbody>
                            {members}
                        </tbody>
                    </table>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">ปิด</button>
                </div>
	          </div>
	        </div>
	      </div>
    	)
  	}
}

UserBankFormPopup.defaultProps = {
    payment_id: null,
    sequence_no: '',
    bank_code: '',
    company_account: '',
    payment_date: '',
    payment_time: '',
    customer_name: '',
    customer_no_ref_1: '',
    ref_2: '',
    reg_3: '',
    branch_no: '',
    teller_no: '',
    kind_of_transaction: '',
    transaction_code: '',
    cheque_no: '',
    amount: '',
    cheque_bank_code: '',
}

export default UserBankFormPopup;