import React, { Component } from "react";
import axios from "../../../axios";
import { Services } from "../../Service/Services";
var HtmlToReactParser = require("html-to-react").Parser;

class ScoreInput extends Component {
  state = {
    data: {},
  };

  onSelectScore = (score) => {
    this.props.onSelectScore(score);
  };

  getScoreInput = () => {
    const value = this.props.value;
    const disabled = this.props.disabled;
    const scores = [0, 1, 2];
    const round = 1; //this.props.round; // Request old score from K.Mai

    const score_input = scores.map((item, key) => {
      let btn_class_name = ["btn"];

      if (value !== null && value === item) {
        btn_class_name.push("btn-primary");
      } else {
        btn_class_name.push("btn-secondary");

        if (disabled === true) btn_class_name.push("d-none");
      }

      return (
        <button
          key={key}
          type="button"
          className={btn_class_name.join(" ")}
          onClick={(e) => this.onSelectScore(item)}
          disabled={disabled}
        >
          {item - (round === 2 ? 1 : 0)}
        </button>
      );
    });

    return score_input;
  };

  render() {
    const score_input_list = this.getScoreInput();

    return (
      <div className="btn-group mr-2" role="group" aria-label="First group">
        {score_input_list}
      </div>
    );
  }
}

ScoreInput.defaultProps = {
  onSelectScore: null,
  value: null,
  disabled: false,
  round: 0,
};

export default ScoreInput;
