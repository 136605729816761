import React from "react";
import { Services } from "../../Service/Services";

export default function MemberGroupERPStatus({
  status,
  erp_errors = [],
  erp_document_date = null,
  erp_document_no = "",
}) {
  switch (status) {
    case "done":
      return (
        <div className="alert alert-primary" role="alert">
          <h3 className="alert-heading">เสร็จสิ้น</h3>
          <hr />
          <p>
            <strong>เลขที่เอกสาร ERP:</strong> {erp_document_no} <br />
            <strong>วันที่เอกสาร ERP:</strong> {erp_document_date}
          </p>
        </div>
      );
    case "fail":
      return (
        <div className="alert alert-danger" role="alert">
          <h3 className="alert-heading">ล้มเหลว</h3>
          <hr />
          <ul>
            {erp_errors.map((error) => {
              return (
                <li>
                  {error.errorCode}: {error.errorMessage}
                </li>
              );
            })}
          </ul>
        </div>
      );
    default:
      return (
        <div className="alert alert-warning" role="alert">
          <h3 className="alert-heading">รอส่ง</h3>
        </div>
      );
  }
}
