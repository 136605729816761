import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
// import DataTable from '../../../components/DataTable/DataTable';
import FormInput from "../../../components/DataForm/FormInput";
import TitleHeader from "../../../components/TitleHeader/TitleHeader";
import axios from "../../../axios";
import Pagination from "./../../../components/Pagination/Pagination";
import SearchTool from "./../../../components/DataTable/SearchTool";
import BoxContainer from "./../../../components/BoxContainer/BoxContainer";
import swal from "sweetalert";
import { Auth } from "./../../Service/Services";
const $ = window.$;

class AbstractReviewer extends Component {
  state = {
    datasource: [],
    reviewer_datasource: [],
    api_url: "/admin/abstract",
    abstract_reviewers: [],
    loading: true,
    criteria: {
      records_per_page: 10,
      page_number: 1,
    },
    total_pages: 1,
    total_records: 0,
    page_status: false,
  };

  changeRecordPerPage = (records_per_page) => {
    const params = {
      ...this.state.criteria,
      records_per_page,
    };

    this.retrieveAbstracts(params);
  };

  changePage = (page_number) => {
    const params = {
      ...this.state.criteria,
      page_number,
    };

    this.retrieveAbstracts(params);
  };

  getStartRowRunning = () => {
    let start_running =
      (this.state.criteria.page_number - 1) *
      this.state.criteria.records_per_page;

    return start_running + 1;
  };

  getEndRowRunning = () => {
    let start_row_running = this.getStartRowRunning();
    let end_running =
      parseInt(start_row_running) +
      parseInt(this.state.criteria.records_per_page) -
      1;

    if (
      this.state.criteria.records_per_page === 0 ||
      end_running > this.state.total_records
    )
      return this.state.total_records;
    else return end_running;
  };

  componentDidMount = () => {
    const params = {
      tables: [{ name: "reviewer" }],
    };

    axios.post("/datasource", params).then((res_datasource) => {
      const reviewer_datasource = res_datasource.data.reviewer.map((item) => {
        return {
          key: item.id,
          label: item.name,
          abstract_topics: item.user_abstract_topics
            ? item.user_abstract_topics.map((item) => item.abstract_topic_id)
            : [],
        };
      });

      this.setState({ reviewer_datasource });

      this.retrieveData();
    });
  };

  retrieveAbstracts = (params = {}) => {
    const loading = this.state.loading;

    if (loading === false) {
      this.setState({ loading: true });
    }

    const api_url = this.state.api_url;
    var default_params = {
      records_per_page: this.state.criteria.records_per_page,
      page_number: params.page_number
        ? params.page_number
        : this.state.criteria.page_number,
    };

    $.extend(true, default_params, params);

    axios.get(api_url, { params: default_params }).then((res) => {
      const total_records = parseInt(res.headers.total_records);
      const datasource = res.data;

      let total_pages = 1;
      if (default_params.records_per_page === 0 || total_records === 0) {
        total_pages = 1;
      } else {
        total_pages = Math.ceil(
          total_records / default_params.records_per_page
        );
      }

      const new_state = {
        datasource,
        loading: false,
        criteria: default_params,
        total_pages,
        total_records,
        page_status: true,
      };

      this.setState(new_state);
    });
  };

  retrieveData = () => {
    this.retrieveAbstracts();

    axios.get("abstractreviewer").then((res) => {
      const abstract_reviewers = res.data;

      this.setState({ abstract_reviewers });
    });
  };

  getValue = (target, index) => {
    const name = Object.keys(target)[0];
    const value = Object.values(target)[0];
    let datasource = [...this.state.datasource];

    if (datasource[index].value[name] != value) {
      datasource[index].value = {
        ...datasource[index].value,
        ...target,
      };

      const api_url = this.state.api_url;

      const params = {
        ...datasource[index].value,
        ...target,
      };

      axios.put(api_url + "/" + datasource[index].key, params).then((res) => {
        const abstract_reviewers = res.data;

        this.setState({ abstract_reviewers });
      });
    }
  };

  submitAbstractToReviewer = (id, abstract_no, index) => {
    swal({
      title: "ส่งไปหาผู้เชี่ยวชาญ",
      text:
        "คุณแน่ใจไหมที่จะส่งผลงานรหัส " + abstract_no + " ไปให้กับผู้เชี่ยวชาญ",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirm) => {
      if (confirm) {
        this.setState({ loading: true });

        const url = `abstractsubmit/${id}`;
        const params = {
          id,
        };

        axios.put(url, params).then((res) => {
          let datasource = [...this.state.datasource];
          datasource.splice(index, 1);

          this.setState({ datasource, loading: false });
        });
      }
    });
  };

  submitAbstractToReviewerAll = () => {
    swal({
      title: "ส่งไปหาผู้เชี่ยวชาญ",
      text: "คุณแน่ใจไหมที่จะส่งผลงานทั้งหมดไปให้กับผู้เชี่ยวชาญ",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirm) => {
      if (confirm) {
        this.setState({ loading: true });

        const url = `abstractsubmit`;

        axios.put(url).then((res) => {
          this.retrieveData();

          swal({
            title: "เรียบร้อย",
            text: "ข้อมูลผลงานถูกส่งให้ผู้เชี่ยวชาญเรียบร้อย",
            icon: "success",
          });
        });
      }
    });
  };

  autoGenerateReviewer = () => {
    swal({
      title: "กำหนดข้อมูลผู้เชี่ยวชาญอัตโนมัติ",
      text: "คุณแน่ใจไหมที่จะกำหนดข้อมูลผู้เชี่ยวชาญให้กับผลงานทั้งหมดอัตโนมัติ",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirm) => {
      if (confirm) {
        this.setState({ loading: true });

        const url = `abstractreviewerauto`;

        axios.put(url).then((res) => {
          this.retrieveData();

          swal({
            title: "เรียบร้อย",
            text: "ข้อมูลผลงานทั้งหมดถูกอัพเดทผู้เชี่ยวชาญเรียบร้อย",
            icon: "success",
          });
        });
      }
    });
  };

  render() {
    const columns = [
      { label: "รหัสผลงาน", align: "center", name: "abstract_no" },
      { label: "หัวข้อ", align: "left", name: "abstract_topic_name" },
      { label: "ชื่อผลงาน", align: "left", name: "name" },
      { label: "ผู้เชี่ยวชาญ", align: "left", name: "reviewer1" },
      { label: "#", align: "left", name: "action" },
    ];

    const table_headers = columns.map((item, key) => {
      return (
        <th className="text-center" key={key}>
          {item.label}
        </th>
      );
    });

    const datasource = this.state.datasource.map((item, key) => {
      const data = item.value;

      const datasource = this.state.reviewer_datasource.filter((item) => {
        return item.abstract_topics.indexOf(data.abstract_topic_id) !== -1;
      });

      let disabled = true;

      if (
        data.reviewer1 !== null &&
        data.reviewer1 !== "" &&
        data.reviewer2 !== null &&
        data.reviewer2 !== "" &&
        data.reviewer3 !== null &&
        data.reviewer3 !== ""
      ) {
        disabled = false;
      }

      const reviewer1 = data.reviewer1 === null ? "" : data.reviewer1;
      const reviewer2 = data.reviewer2 === null ? "" : data.reviewer2;
      const reviewer3 = data.reviewer3 === null ? "" : data.reviewer3;

      return (
        <tr key={key}>
          <td className="text-center">{data.abstract_no}</td>
          <td>{data.abstract_topic_name}</td>
          <td>{data.name}</td>
          <td>
            <div>
              <FormInput
                field={{
                  name: "reviewer1",
                  type: "ddl",
                  datasource: datasource,
                  value: reviewer1,
                }}
                getValue={(e) => this.getValue(e, key)}
              ></FormInput>
            </div>
            <div className="my-1">
              <FormInput
                field={{
                  name: "reviewer2",
                  type: "ddl",
                  datasource: datasource,
                  value: reviewer2,
                }}
                getValue={(e) => this.getValue(e, key)}
              ></FormInput>
            </div>
            <div>
              <FormInput
                field={{
                  name: "reviewer3",
                  type: "ddl",
                  datasource: datasource,
                  value: reviewer3,
                }}
                getValue={(e) => this.getValue(e, key)}
              ></FormInput>
            </div>
          </td>
          <td className="text-center">
            <button
              className="btn btn-primary"
              onClick={(e) =>
                this.submitAbstractToReviewer(item.key, data.abstract_no, key)
              }
              disabled={disabled}
            >
              ส่งข้อมูล
            </button>
          </td>
        </tr>
      );
    });

    const abstract_reviewers = this.state.abstract_reviewers.map(
      (item, key) => {
        return (
          <li key={key}>
            <i className="fa fa-person"></i>
            {key + 1}. {item.name}{" "}
            <span className="badge badge-secondary">
              {item.abstract_reviewers_count}
            </span>
          </li>
        );
      }
    );

    return (
      <BrowserRouter>
        <div>
          <TitleHeader
            program_name="ส่งข้อมูลให้ผู้เชี่ยวชาญ"
            program_type="ผลงานวิชาการ"
          ></TitleHeader>
          <BoxContainer loading={this.state.loading}>
            <button
              className="btn btn-block btn-lg btn-primary"
              onClick={this.autoGenerateReviewer}
            >
              <i className="fa fa-cog"></i> กำหนดข้อมูลผู้เชี่ยวชาญอัตโนมัติ
            </button>
            <button
              className="btn btn-block btn-lg btn-primary"
              onClick={this.submitAbstractToReviewerAll}
            >
              <i className="fa fa-paper-plane"></i>{" "}
              ส่งผลงานให้ผู้เชี่ยวชาญตรวจทั้งหมด
            </button>
            <div className="row">
              <div className="col-12 col-md-9">
                <SearchTool
                  changeRecordPerPage={this.changeRecordPerPage}
                  allow_insert={false}
                  allow_trash={false}
                  allow_import={false}
                ></SearchTool>
                <table className="table table-striped table-bordered table-hover">
                  <thead>
                    <tr>{table_headers}</tr>
                  </thead>
                  <tbody>{datasource}</tbody>
                </table>
                <div>
                  <div className="pull-right">
                    <Pagination
                      totalPages={this.state.total_pages}
                      onPageChange={this.changePage}
                      currentPage={this.state.criteria.page_number}
                    ></Pagination>
                  </div>
                  <div className="pull-left">
                    Showing {this.getStartRowRunning()} to{" "}
                    {this.getEndRowRunning()} of {this.state.total_records}{" "}
                    records
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
              <div className="col-12 col-md-3 pt-2">
                <h2>
                  <strong>Reviewers</strong>
                </h2>
                <ul className="list-unstyled mb-3">{abstract_reviewers}</ul>
              </div>
            </div>
          </BoxContainer>
        </div>
      </BrowserRouter>
    );
  }
}

export default AbstractReviewer;
