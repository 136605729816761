import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import axios from '../../axios';
import { Services } from '../Service/Services';
import swal from 'sweetalert';

class ChangePassword extends Component {
    state = {
        old_password: null,
        password: null,
        confirm_password: null
    };
    componentWillMount() {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        this.setState({
            id: user.id,
        })
    }
    onSubmit = e => {
        e.preventDefault();
        if (this.state.old_password && this.state.password && this.state.confirm_password && this.state.password === this.state.confirm_password) {
            axios.post('/auth/newpassword', {
                id: this.state.id,
                old_password: this.state.old_password,
                new_password: this.state.password,
            }).then(res => {
                if (res.data.status) {
                    swal({
                        title: "Success",
                        text: 'บันทึกข้อมูลเสร็จสิ้น',
                        icon: "success",
                        button: "OK",
                    });
                } else {
                    swal({
                        title: "Error",
                        text: res.data.message,
                        icon: "error",
                        button: "OK",
                    });
                }
            }).catch(error => {
                Services.error(error);
            });
        } else if (this.state.password !== this.state.confirm_password) {
            swal({
                title: "Error",
                text: 'รหัสผ่านไม่ตรงกัน',
                icon: "error",
                button: "OK",
            });

        } else {
            swal({
                title: "Error",
                text: 'กรุณากรอกรหัสผ่านให้ครบ',
                icon: "error",
                button: "OK",
            });
        }

    }
    onChange = (event) => { this.setState({ [event.target.name]: event.target.value }); }

    render() {
        return (
            <form onSubmit={this.onSubmit}>
                <div className="row wrapper wrapper-content animated fadeInRight">
                    <div className='col-12'>
                        <div className='ibox'>
                            <div className='ibox-content'>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="col-form-label">รหัสผ่านเดิม:</label> <input type="password" name="old_password" className="form-control" onChange={this.onChange} value={this.state.old_password} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="col-form-label">รหัสผ่านใหม่:</label> <input type="password" name="password" className="form-control" onChange={this.onChange} value={this.state.password} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="col-form-label">ยืนยันรหัสผ่าน:</label> <input type="password" name="confirm_password" className="form-control" onChange={this.onChange} value={this.state.confirm_password} />
                                    </div>
                                </div>
                                <div className="hr-line-dashed"></div>
                                <div className="form-group row">
                                    <div className="col-12 text-right">
                                        <button className="btn btn-primary mr-2" type="submit" >บันทึก</button>
                                        <Link className="btn btn-white" to={'/'}>กลับสู่หน้า Dashboard</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}

export default ChangePassword
