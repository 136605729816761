import React, { Component } from 'react'
import DataForm from './../../../components/DataForm/DataForm';
import FormInput from './../../../components/DataForm/FormInput';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import { Services } from '../../Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import DateRange from '../../../components/DateRange/daterange';

class FrontendPopupForm extends Component {
  // Set default state
  state = {
    data:{
      start_date: '',
      end_date: '',
      topic: '',
      desc: '',
      topic_en: '',
      desc_en: '',
      active: true,
    },
    redirect: false,
    loading: false,
  }

  retrieveData = id => {
    axios.get(`${this.props.url}/${id}`).then(res => {
      let data = res.data;
      data.start_date = data.start_date_text;
      data.end_date = data.end_date_text;
      
      this.setState({ data });
    });
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;

    if(id !== undefined){
      this.retrieveData(id);
    }else{
      const loading = false;
      this.setState({loading});
    }
  }

  getValue = target => {
    let data = this.state.data;

    this.setState({
      data: {
        ...data,
        ...target
      }
    });
  }

  onSubmit = e => {
    const params = this.state.data;
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    loading = false;

    Services.submitRequestWithFile({ params, url }).then( res => { this.setState({redirect, loading}) }).catch( errors => {this.setState({ errors,loading })});
  }

  getRedirect = () => {
    const redirect = this.state.redirect;
    
    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  }

  render() {
    const redirect = this.getRedirect();
    const url = this.props.url;

    const field_topic = { label: 'หัวข้อ', name: 'topic', type: 'textbox', value: this.state.data.topic, required: true }
    const field_description = { label: 'รายละเอียด', name: 'desc', type: 'texteditor', value: this.state.data.desc, required: true }
    const field_topic_en = { label: 'หัวข้อ', name: 'topic_en', type: 'textbox', value: this.state.data.topic_en, required: false }
    const field_description_en = { label: 'รายละเอียด', name: 'desc_en', type: 'texteditor', value: this.state.data.desc_en, required: false }

    const field_active = { label: 'Active', name: 'active', type: 'switch', required: false, value: this.state.data.active }

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
        >
          <div>
            <div className='form-group'>
              <label className="col-form-label field-required">วันที่เริ่มต้น - วันที่สิ้นสุด</label>
              <DateRange name_start='start_date' value_start={this.state.data.start_date} value_end={this.state.data.end_date} name_end='end_date' datepickerCallback={this.getValue} required='true'></DateRange>
            </div>
            <div className="tabs-container">
                <ul className="nav nav-tabs" role="tablist">
                    <li><a className="nav-link active" data-toggle="tab" href="#tab-1">TH</a></li>
                    <li><a className="nav-link" data-toggle="tab" href="#tab-2">EN</a></li>
                </ul>
                <div className="tab-content">
                    <div role="tabpanel" id="tab-1" className="tab-pane active">
                        <div className="panel-body">
                          <FormInput field={field_topic} getValue={this.getValue}></FormInput>
                          <FormInput field={field_description} getValue={this.getValue}></FormInput>
                        </div>
                    </div>
                    <div role="tabpanel" id="tab-2" className="tab-pane">
                        <div className="panel-body">
                          <FormInput field={field_topic_en} getValue={this.getValue}></FormInput>
                          <FormInput field={field_description_en} getValue={this.getValue}></FormInput>
                        </div>
                    </div>
                </div>
             </div>
             <FormInput field={field_active}></FormInput>
          </div>
        </ DataForm>
      </BoxContainer>
    )
  }
}

FrontendPopupForm.setDefaultProps = {
  url: '',
}

export default FrontendPopupForm
