import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import UserBankForm from "./UserBankForm";
import TitleHeader from "../../../components/TitleHeader/TitleHeader";

class UserBank extends Component {
  render() {
    const columns = [
      { label: "วันที่", align: "center", name: "created_at" },
      { label: "สำเร็จ", align: "center", name: "amount_of_success" },
      { label: "ล้มเหลว", align: "center", name: "amount_of_fails" },
      { label: "ไฟล์ที่นำเข้า", align: "left", name: "asset_file" },
    ];

    const api_url = "/admin/bank";
    // const datasource = [
    //     { key: 1, active: true, value: ['Customer #1', 'Ref_1', 'Ref_2', '1,500.00', '1,500.00' ] },
    //     { key: 2, active: true, value: ['Customer #2', 'Ref_1', 'Ref_2', '1,500.00', '1,500.00' ] },
    //     { key: 3, active: true, value: ['Customer #3', 'Ref_1', 'Ref_2', '1,500.00', '1,500.00' ] },
    // ];

    return (
      <BrowserRouter>
        <div>
          <TitleHeader
            program_name="นำเข้าข้อมูลการจ่ายเงินจากธนาคาร"
            program_type="ข้อมูลบัญชีผู้ใช้งาน"
          ></TitleHeader>

          <Route
            exact
            path={`${this.props.match.path}/`}
            component={() => (
              <DataTable
                allow_import={false}
                url={api_url}
                columns={columns}
                allow_edit={true}
                allow_view={false}
                allow_active={false}
              ></DataTable>
            )}
          />

          <Route
            path={`${this.props.match.path}/create`}
            component={(props) => (
              <UserBankForm {...props} url={api_url}></UserBankForm>
            )}
          />
          <Route
            path={`${this.props.match.path}/:id/:action`}
            component={(props) => (
              <UserBankForm {...props} url={api_url}></UserBankForm>
            )}
          />
        </div>
      </BrowserRouter>
    );
  }
}

export default UserBank;
