import React, { Component } from "react";
import { Link } from "react-router-dom";
import { authenticationService } from "../../pages/Authentication/Service/authentication.service";
import avatar from "../../assets/images/user.png";
import menuService from "../../pages/Authentication/Service/menu.service";

class Sidebar extends Component {
  state = {
    menus: [],
    dashboardMenu: {
      label: "Dashboard",
      icon_class: "fa fa-dashboard",
      link: "/",
    },
    fixedMenus: [
      {
        label: "ตรวจบทคัดย่อ",
        icon_class: "fa fa-dashboard",
        link: "/reviewer",
        role: ["reviewer"],
      },
      {
        label: "ตรวจบทคัดย่อโดยผู้เชี่ยวชาญสูงสุด",
        icon_class: "fa fa-dashboard",
        link: "/superreviewer",
        role: ["super_reviewer"],
      },
      {
        label: "จัดการข้อมูลวิทยากร",
        icon_class: "fa fa-user",
        link: "/keynotespeaker",
        role: ["keynote_speaker", "staff"],
      },
      {
        label: "ข้อมูลไฟล์ Presentation ในห้องประชุม",
        icon_class: "fa fa-files-o",
        link: "/meeting/present",
        role: ["staff"],
      },
      {
        label: "ข้อมูลไฟล์ Abstraction ในห้องประชุม",
        icon_class: "fa fa-files-o",
        link: "/meeting/abstract",
        role: ["staff"],
      },
      {
        label: "วิทยากรทั้งหมด",
        icon_class: "fa fa-dashboard",
        link: "/meeting/keynote",
        role: ["staff"],
      },
      {
        label: "ห้องประชุม",
        icon_class: "fa fa-free-code-camp",
        link: "/meeting/room",
        role: ["staff"],
      },
      {
        label: "เครื่องมือสำหรับผู้ดูแลระบบ",
        icon_class: "fa fa-cogs",
        link: "/admintools",
        role: ["admin"],
      },
    ],
    location: window.location.pathname,
  };
  logout() {
    authenticationService.logout();
  }
  Clicklink = (e) => {
    this.setState({ location: window.location.pathname });
  };

  componentDidMount() {
    let menus = [];

    menuService.getCurrentMenu().map((menu) => {
      const entity = {
        menu_id: menu.id,
        label: menu.label,
        icon_class: menu.icon_class,
        link: menu.link,
      };

      if (menu.is_main === false && menu.main_menu_id) {
        const main_menu = menus.find((f) => f.menu_id === menu.main_menu_id);
        if (main_menu) {
          if (main_menu.child === undefined) {
            main_menu.child = [];
          }

          main_menu.child.push(entity);
        }
      } else {
        menus.push(entity);
      }
    });

    const { dashboardMenu, fixedMenus } = this.state;

    this.setState({ menus: [dashboardMenu, ...menus, ...fixedMenus] });
  }

  render() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const user_roles = currentUser.roles;

    if (user_roles === undefined) {
      authenticationService.doLogout();
      window.location.href = "/";
    }

    var menus = this.state.menus
      .filter((item) => {
        let roles = [];
        if (item.role === undefined) {
          roles.push("admin");
        } else {
          roles = item.role;
        }

        return (
          roles.filter((role) => user_roles.indexOf(role) !== -1).length > 0
        );
      })
      .map((item, index) => {
        if (item.child) {
          var active_item = false;
          var child = item.child.map((item_child, index_child) => {
            if (item_child.child) {
              var child_child = item_child.child.map(
                (item_child_child, index_child_child) => {
                  const link_class = [];
                  if (item_child_child.link === this.state.location) {
                    link_class.push("active");
                    active_item = true;
                  }
                  return (
                    <li
                      key={index_child_child}
                      onClick={this.Clicklink.bind(index_child_child)}
                      className={link_class.join(" ")}
                    >
                      <Link to={item_child_child.link}>
                        {item_child_child.label}
                      </Link>
                    </li>
                  );
                }
              );

              return (
                <li key={index_child} className={active_item ? "active" : ""}>
                  <Link to=" ">
                    <span className="nav-label">{item_child.label}</span>{" "}
                    <span className="fa arrow"></span>
                  </Link>
                  <ul
                    className={
                      item_child.link === this.state.location
                        ? "nav nav-third-level collapse in"
                        : "nav nav-third-level collapse"
                    }
                  >
                    {child_child}
                  </ul>
                </li>
              );
            } else {
              const link_class = [];
              if (item_child.link === this.state.location) {
                link_class.push("active");
                active_item = true;
              }
              return (
                <li
                  key={index_child}
                  onClick={this.Clicklink.bind(index_child)}
                  className={link_class.join(" ")}
                >
                  <Link to={item_child.link}>{item_child.label}</Link>
                </li>
              );
            }
          });

          return (
            <li key={index} className={active_item ? "active" : ""}>
              <Link to=" ">
                <i className={item.icon_class}></i>{" "}
                <span className="nav-label">{item.label}</span>{" "}
                <span className="fa arrow"></span>
              </Link>
              <ul
                className={
                  item.link === this.state.location
                    ? "nav nav-second-level collapse in"
                    : "nav nav-second-level collapse"
                }
              >
                {child}
              </ul>
            </li>
          );
        } else {
          return (
            <li key={index}>
              <Link to={item.link ? item.link : "#"}>
                <i className={item.icon_class}></i>{" "}
                <span className="nav-label">{item.label}</span>
              </Link>
            </li>
          );
        }
      });

    return (
      <nav className="navbar-default navbar-static-side" role="navigation">
        <div className="sidebar-collapse">
          <ul className="nav metismenu" id="side-menu">
            <li className="nav-header">
              <div className="dropdown profile-element text-center">
                <a data-toggle="dropdown" className="dropdown-toggle" href="/">
                  <img
                    alt="avartar"
                    className="rounded-circle"
                    src={avatar}
                    width="50px"
                  />
                  <span className="block m-t-xs font-bold">
                    {currentUser.name || ""}
                  </span>
                  <span className="text-muted text-xs block">
                    {currentUser.role_name} <b className="caret"></b>
                  </span>
                </a>
                <ul className="dropdown-menu animated fadeInRight m-t-xs">
                  <li>
                    <Link to="/profile" className="dropdown-item">
                      Profile
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/profile/changepassword"
                      className="dropdown-item"
                    >
                      Change password
                    </Link>
                  </li>
                  <li>
                    <a href="#" className="dropdown-item" onClick={this.logout}>
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
              <div className="logo-element">Forum</div>
            </li>
            {menus}
          </ul>
        </div>
      </nav>
    );
  }
}

export default Sidebar;
