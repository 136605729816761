import React, { Component } from "react";
import DataForm from "./../../../components/DataForm/DataForm";
import FormInput from "./../../../components/DataForm/FormInput";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import { Alert, Services } from "../../Service/Services";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";
import swal from "sweetalert";
import { EmailAPI } from "../../../api";
const $ = window.$;

class OtherEmailTemplateForm extends Component {
  // Set default state
  state = {
    data: {
      name: "",
      bcc: "",
      type: "",
      description: "",
      subject: "",
      message: "",
      email_template_parameters: [],
      email_template_sections: [],
      asset_file_preview: [],
      asset_file: [],
      code: "",
    },
    redirect: false,
    loading: true,
  };

  retrieveData = (id) => {
    axios.get(`${this.props.url}/${id}`).then((res) => {
      const data = res.data;
      const new_data = {
        id: data.id,
        bcc: data.bcc,
        name: data.name,
        code: data.code,
        type: data.type,
        typeLabel:
          data.type === "E" ? "Email" : data.type === "S" ? "SMS" : "PDF",
        description: data.description,
        subject: data.subject,
        message: data.message,
        email_template_parameters: data.email_template_parameters,
        email_template_sections: data.email_template_sections,
        asset_file_preview: data.asset_file_preview,
      };

      this.setState({ loading: false, data: new_data });
    });
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;

    this.retrieveData(id);
  };

  getValue = (target) => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
  };

  onMessageChange = (target, index) => {
    let data = { ...this.state.data };
    data.email_template_sections[index].message = Object.values(target)[0];

    this.setState({ data });
  };

  onSubmit = (e) => {
    const params = { ...this.state.data };
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    Services.submitRequestWithFile({ params, url })
      .then((res) => {
        this.setState({ redirect: false, loading: false });
      })
      .catch((errors) => {
        this.setState({ errors, loading: false });
      });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  };

  onDeleteFile = (name, id) => {
    const data = { ...this.state.data };
    const index = data.asset_file_preview.findIndex((f) => f.id == id);
    data.asset_file_preview[index].is_delete = true;

    this.setState({ data });
  };

  getFields = () => {
    const parameters_records = this.state.data.email_template_parameters.map(
      (item, item_key) => {
        return (
          <tr key={item_key}>
            <td className="text-center">[{item.code}]</td>
            <td>{item.value}</td>
            <td>{item.description}</td>
          </tr>
        );
      }
    );

    const parameters = (
      <>
        <table className="table">
          <thead>
            <tr>
              <th className="text-center">ตัวแปร</th>
              <th className="text-center">ตัวอย่างข้อมูล</th>
              <th className="text-center">รายละเอียด</th>
            </tr>
          </thead>
          <tbody>{parameters_records}</tbody>
        </table>
        <small className="text-muted">
          <strong>วิธีใช้ตัวแปร:</strong>{" "}
          นำตัวแปรไปใส่ไว้ในข้อความที่ต้องการทั้งหัวข้อและข้อความ โดยใช้
          [ชื่อตัวแปร] เช่น [app_name] จะแสดง ชื่อของระบบ เช่น HA Forum
        </small>
      </>
    );

    let email_section;

    if (this.state.data.email_template_sections.length > 0) {
      const email_template_sections =
        this.state.data.email_template_sections.map((item, index) => {
          const messageField = {
            label: item.description + " - [" + item.code + "]",
            name: "message_" + item.id,
            type: "texteditor",
            required: false,
            value: item.message,
          };

          return (
            <div key={index}>
              <div className="row">
                <div className="col-12">
                  <FormInput
                    field={messageField}
                    getValue={(val) => this.onMessageChange(val, index)}
                  ></FormInput>
                </div>
              </div>
            </div>
          );
        });

      email_section = <>{email_template_sections}</>;
    }

    var fields = [
      {
        label: "ชื่อ",
        name: "name",
        type: "label",
        required: false,
        value: this.state.data.name,
      },
      {
        label: "ประเภท",
        name: "typeLabel",
        type: "label",
        required: false,
        value: this.state.data.typeLabel,
      },
      {
        label: "รายละเอียด",
        name: "description",
        type: "label",
        required: false,
        value: this.state.data.description,
      },
      {
        label: "ตัวแปร",
        name: "parameters",
        type: "label",
        required: false,
        value: parameters,
      },
      {
        label: "หัวข้อ",
        name: "subject",
        type: "textbox",
        required: true,
        value: this.state.data.subject,
      },
      {
        label: "bcc",
        name: "bcc",
        type: "textbox",
        required: false,
        value: this.state.data.bcc,
        remark: "ใส่ , คั่นระหว่างอีเมลในกรณีที่มีมากกว่า 1 อีเมล",
      },
    ];

    const { type, email_template_sections } = this.state.data;

    if (type !== "SMS") {
      fields.push({
        label: "ข้อความ",
        name: "message",
        type: "texteditor",
        required: true,
        value: this.state.data.message,
      });

      if (email_template_sections.length > 0) {
        fields.push({
          label: "ส่วนประกอบ",
          name: "email_section",
          type: "label",
          require: false,
          value: email_section,
        });
      }

      fields.push({
        label: "เอกสารแนบ",
        name: "asset_files[]",
        type: "file",
        multiple: true,
        required: false,
        value: this.state.data.asset_file_preview,
      });
    } else if (type === "SMS") {
      fields.push({
        label: "ข้อความ",
        name: "message",
        type: "textarea",
        required: true,
        value: this.state.data.message,
      });
    }

    return fields;
  };

  onPreview = () => {
    const email_template_sections = this.state.data.email_template_sections;
    if (email_template_sections.length > 0) {
      let section_elements = email_template_sections.map((item) => {
        return `<div className="form-check"><input name="section_selection" className="form-check-input" type="checkbox" value="${item.id}" id="${item.code}" checked /><label className="form-check-label" for="${item.code}">&nbsp;&nbsp;${item.description}</label></div>`;
      });

      var elemenmt = $("<div>" + section_elements.join(" ") + "</div>")[0];
      swal({
        text: "กรุณาเลือกส่วนประกอบ:",
        content: elemenmt,
        // buttons: {
        //   confirm: {
        //     value: 'Click Me',
        //   },
        // },
      }).then(() => {
        let section_selection_value = [];
        $("[name=section_selection]:checked").each((index, item) => {
          section_selection_value.push($(item).val());
        });

        const id = this.props.match.params.id;
        const url =
          axios.defaults.baseURL +
          "/email/preview/" +
          id +
          "/" +
          section_selection_value.join("|");

        window.open(url, "_blank");
      });
    } else {
      const id = this.props.match.params.id;
      const url = axios.defaults.baseURL + "/email/preview/" + id;

      window.open(url, "_blank");
    }
  };

  onClickSendEmail = (e) => {
    swal("อีเมลที่ต้องการส่ง:", {
      content: {
        element: "input",
        attributes: {
          placeholder: "อีเมลที่ต้องการส่ง",
          type: "email",
          // value: "", // default value
        },
      },
    }).then((email) => {
      if (email)
        EmailAPI.sendEmailTest(this.state.data.code, email)
          .then(() => {
            Alert.done(`อีเมลถูกส่งไปยัง ${email} เรียบร้อย`);
          })
          .catch(() => {
            Alert.error(`มีข้อผิดพลาด`);
          });
    });
  };

  render() {
    const loading = this.state.loading;
    const fields = loading ? [] : this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;
    const buttons = (
      <>
        <button
          type="button"
          className="btn btn-info mr-2"
          onClick={this.onPreview}
        >
          Preview
        </button>
        {this.state.data.type === "E" && (
          <button
            type="button"
            className="btn btn-danger mr-2"
            onClick={this.onClickSendEmail}
          >
            {" "}
            <i className="fa fa-paper-plane" aria-hidden="true"></i>{" "}
            ทดสอบการส่งอีเมล
          </button>
        )}
      </>
    );

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
          onDelete={this.onDeleteFile}
          buttons={buttons}
        ></DataForm>
      </BoxContainer>
    );
  }
}

OtherEmailTemplateForm.setDefaultProps = {
  url: "",
};

export default OtherEmailTemplateForm;
