import React, { Component } from 'react'
import DataForm from './../../../components/DataForm/DataForm';
// import FormInput from './../../../components/DataForm/FormInput';
import { Redirect } from 'react-router-dom';
import { Services } from '../../Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import axios from '../../../axios';

class FrontendBannerForm extends Component {
  // Set default state
  state = {
    data:{
      name: '',
      link: '',
      asset_file: '',
      asset_file_preview: '',
      active: true,
    },
    datasource: { },
    redirect: false,
    loading: false,
  }

  retrieveData = id => {
    axios.get(`${this.props.url}/${id}`).then(res => {
      const data = res.data;
      this.setState({ data });
    });
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;

    if(id !== undefined){
      this.retrieveData(id);
    }else{
      const loading = false;
      this.setState({loading});
    }
  }

  getValue = target => {
    let data = this.state.data;

    this.setState({
      data: {
        ...data,
        ...target
      }
    });
  }

  onSubmit = e => {
    const params = this.state.data;
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    loading = false;

    Services.submitRequestWithFile({ params, url }).then( res => { this.setState({redirect, loading}) }).catch( errors => {this.setState({ errors,loading })});
  }

  getRedirect = () => {
    const redirect = this.state.redirect;
    
    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  }

  getFields = () => {
    var fields = [
      { label: 'ชื่อ', name: 'name', type: 'textbox', required: true, value: this.state.data.name },
      { label: 'ลิงค์', name: 'link', type: 'url', required: false, value: this.state.data.link },
      { label: 'ไฟล์ภาพ', name: 'asset_file', type: 'file', required: true, multiple: false, value: this.state.data.asset_file_preview, extension: 'image', remark: 'ขนาดภาพ 1024px x 379px (W x H)' },
      { label: 'Active', name: 'active', type: 'switch', required: false, value: this.state.data.active },
    ];

    return fields;
  }

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
        >
        </DataForm>
      </BoxContainer>
    )
  }
}

FrontendBannerForm.setDefaultProps = {
  url: '',
}

export default FrontendBannerForm
