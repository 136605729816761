import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../components/DataTable/DataTable';
import KeynoteSpeakerForm from './KeynoteSpeakerForm';
import TitleHeader from '../../components/TitleHeader/TitleHeader'

class KeynoteSpeaker extends Component {
    render() {
        const api_url = '';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='จัดการข้อมูลวิทยากร' program_type=''></TitleHeader>
                    
                    <Route path={`${this.props.match.path}`} component={props => (<KeynoteSpeakerForm {...props} url={api_url}></KeynoteSpeakerForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default KeynoteSpeaker
