import React, { Component } from "react";
import DataForm from "./../../../components/DataForm/DataForm";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import { Services, Alert } from "../../Service/Services";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";

class UserInternalForm extends Component {
  // Set default state
  state = {
    data: {
      email: "",
      name: "",
      status: "P",
      active: true,
      telephone: "",
      company: 1,
      permission_id: null,
      user_roles: ["admin"],
    },
    datasource: {
      company: [
        { key: 1, label: "สถาบันรับรองคุณภาพสถานพยาบาล (องค์การมหาชน)" },
        { key: 2, label: "อื่นๆ" },
      ],
      user_role: [
        { key: "admin", label: "ผู้ดูแลระบบ" },
        { key: "super_reviewer", label: "ผู้เชี่ยวชาญสูงสุด" },
        { key: "staff", label: "ผู้ประสานงาน / นักวิชาการ" },
      ],
      permission: [],
    },
    redirect: false,
    loading: true,
  };

  init = () => {
    const promise = new Promise((resolve) => {
      const params = {
        tables: [{ name: "permission" }],
      };

      axios.post("datasource", params).then((res) => {
        const permission = res.data.permission.map((item) => {
          return {
            key: item.id,
            label: item.name,
          };
        });

        const datasource = {
          ...this.state.datasource,
          permission,
        };

        resolve(datasource);
      });
    });

    return promise;
  };

  retrieveData = (id, datasource = null) => {
    axios.get(`${this.props.url}/${id}`).then((res) => {
      const data = res.data;
      this.setState({
        data: {
          email: data.email,
          id: data.id,
          name: data.name,
          status: data.status,
          active: data.active,
          telephone: data.telephone,
          company: data.company,
          permission_id: data.permission_id,
          user_roles: data.user_roles.map((item) => item.role),
        },
        loading: false,
        datasource: datasource ? datasource : this.state.datasource,
      });
    });
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;

    this.init().then((datasource) => {
      if (id !== undefined) {
        this.retrieveData(id, datasource);
      } else {
        this.setState({ loading: false, datasource });
      }
    });
  };

  getValue = (target) => {
    let data = this.state.data;
    // const name = Object.keys(target);
    // const value = Object.values(target);

    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
  };

  resetPassword = (e) => {
    Alert.confirm("คุณแน่ใจไหมที่จะรีเซ็ตรหัสผ่าน").then((res) => {
      if (res === true) {
        const id = this.props.match.params.id;
        const url = "auth/resetpassword";
        const params = {
          id,
        };

        axios
          .post(url, params)
          .then((res) => Alert.done().then(this.retrieveData(id)));
      }
    });
  };

  unlocked = (e) => {
    Alert.confirm("คุณแน่ใจไหมที่จะปลดล๊อค").then((res) => {
      if (res === true) {
        const id = this.props.match.params.id;
        const url = "auth/unlocked";
        const params = {
          id,
        };

        axios
          .post(url, params)
          .then((res) => Alert.done().then(this.retrieveData(id)));
      }
    });
  };

  resendActiveEmail = (e) => {
    Alert.confirm("คุณแน่ใจไหมที่จะส่ง Active Email อีกครั้ง").then((res) => {
      if (res === true) {
        const id = this.props.match.params.id;
        const url = "auth/resendactiveemail";
        const params = {
          id,
        };

        axios
          .post(url, params)
          .then((res) => Alert.done().then(this.retrieveData(id)));
      }
    });
  };

  onSubmit = (e) => {
    let params = this.state.data;

    if (params.company === 1) {
      params.is_ha_department = true;
    } else {
      params.is_ha_department = false;
    }

    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    loading = false;

    Services.submitRequest({ params, url })
      .then((res) => {
        this.setState({ redirect, loading });
      })
      .catch((errors) => {
        this.setState({ errors, loading });
      });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  };

  getFields = () => {
    const id = this.props.match.params.id;

    const status = this.state.data.status;
    let status_text;

    switch (status) {
      case "active":
        status_text = '<span class="label label-primary">Active</span>';
        break;

      case "pending":
        status_text = '<span class="label label-warning">Pending</span>';
        break;

      case "locked":
        status_text = '<span class="label label-danger">Locked</span>';
        break;
    }

    var fields = [
      {
        label: "อีเมล",
        name: "email",
        type: id === undefined ? "email" : "label",
        required: true,
        value: this.state.data.email,
      },
      {
        label: "โทรศัพท์มือถือ",
        name: "telephone",
        type: "textbox",
        required: false,
        value: this.state.data.telephone,
      },
      {
        label: "ชื่อ - นามสกุล",
        name: "name",
        type: "textbox",
        required: true,
        value: this.state.data.name,
      },
      {
        label: "หน่วยงาน",
        name: "company",
        type: "radio",
        value: this.state.data.company,
        datasource: this.state.datasource.company,
      },
      {
        label: "บทบาท",
        name: "user_roles",
        required: true,
        type: "checkbox",
        value: this.state.data.user_roles,
        datasource: this.state.datasource.user_role,
      },
      {
        label: "Permission",
        name: "permission_id",
        required: false,
        type: "ddl",
        value: this.state.data.permission_id,
        datasource: this.state.datasource.permission,
      },
      {
        label: "Active",
        name: "active",
        type: "switch",
        value: this.state.data.active,
      },
      { label: "สถานะ", name: "status", type: "label", value: status_text },
    ];

    // if(this.state.data.role === '1'){
    //   fields.push({ label: 'สิทธิ์การใช้งาน', name: 'user_role', type: 'ddl', value: this.state.data.user_role, datasource: this.state.datasource.user_role });
    // }

    return fields;
  };

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;
    const id = this.props.match.params.id;
    let buttons = [];

    if (id !== undefined) {
      const status = this.state.data.status;

      // eslint-disable-next-line default-case
      switch (status) {
        case "active":
          buttons.push(
            <input
              key={1}
              type="button"
              className="btn btn-warning mr-2"
              value="Reset รหัสผ่าน"
              onClick={this.resetPassword}
            />
          );
          break;

        case "pending":
          buttons.push(
            <input
              key={2}
              type="button"
              className="btn btn-primary mr-2"
              value="ส่ง Active Email ใหม่"
              onClick={this.resendActiveEmail}
            />
          );
          break;

        case "locked":
          buttons.push(
            <input
              key={3}
              type="button"
              className="btn btn-danger mr-2"
              value="ปลดล๊อค"
              onClick={this.unlocked}
            />
          );
          break;
      }
    }

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
          buttons={buttons}
        ></DataForm>
      </BoxContainer>
    );
  }
}

UserInternalForm.setDefaultProps = {
  url: "",
};

export default UserInternalForm;
