import { BehaviorSubject } from "rxjs";
// import config from 'config';
// import { handleResponse } from '../_helpers/index';
import axios from "../../../axios";
import swal from "sweetalert";
import { history } from "../_helpers";
import cookie from "js-cookie";
import siteService from "./site.service";
import menuService from "./menu.service";

const LOCAL_STORAGE_USER_NAME = "currentUser";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_NAME))
);

export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
  doLogout,
  setLoginInfo,
  getRedirectPath,
};

function setLoginInfo(res) {
  localStorage.setItem("_token_forum", res.data.token);
  const currentUser = res.data.user;
  localStorage.setItem(LOCAL_STORAGE_USER_NAME, JSON.stringify(currentUser));

  const site = res.data.site;
  const menus = res.data.menus;

  siteService.setCurrentSite(site);
  menuService.setCurrentMenu(menus);
  currentUserSubject.next(currentUser);

  return { currentUser, site, menus };
}

function login(username, password, remember) {
  const params = {
    username,
    password,
  };

  const api_url = "/auth/admin/login";

  var promise = new Promise(function (resolve, reject) {
    axios
      .post(api_url, params)
      .then((res) => {
        const loginInfo = setLoginInfo(res);

        resolve(loginInfo);
      })
      .catch((res) => {
        reject(res);
      });
  });

  return promise;
}

function getRedirectPath({ currentUser, defaultPath = "/" }) {
  let from = defaultPath;

  if (currentUser.roles.includes("reviewer") === true) {
    from = "/reviewer";
  } else if (currentUser.roles.includes("super_reviewer") === true) {
    from = "/superreviewer";
  } else if (currentUser.roles.includes("keynote_speaker") === true) {
    // from = "/keynotespeaker";
    from = "/meeting/keynote/current/edit";
  } else if (currentUser.roles.includes("staff") === true) {
    from = "/meeting/keynote";
  }

  return from;
}

function logout() {
  swal({
    title: "Logout",
    text: "คุณต้องการออกจากระบบหรือไม่",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then((logout) => {
    if (logout) {
      // remove user from local storage to log user out
      this.doLogout();
    }
  });
}

function doLogout() {
  localStorage.removeItem(LOCAL_STORAGE_USER_NAME);
  siteService.removeCurrentSite();
  menuService.removeCurrentMenu();
  localStorage.removeItem("_token_forum");
  // cookie.remove("_token_forum");
  currentUserSubject.next(null);
  history.push("/");
}
