import axios from "../axios";

const changePassword = (email, password) => {
  const promise = new Promise((resolve, reject) => {
    const url = "admintools/changepassword";
    const params = {
      email,
      password,
    };
    axios
      .post(url, params)
      .then((res) => {
        if (res.data.status === false) reject([res.data.message]);
        else resolve(res);
      })
      .catch((res) => {
        reject(res);
      });
  });

  return promise;
};

export default {
  changePassword,
};
