import React, { Component } from 'react'
import FormInput from '../../../components/DataForm/FormInput';
import axios from '../../../axios';
import { Services } from '../../Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class ReportPaymentPaid extends Component {
    state = {
        loading: false,
        errors: [],
        filter: {
          payment_date_start: '',
          payment_date_end: '',
        },
        report_erp_link: axios.defaults.baseURL + '/report/erp',
        report_deviliery_link: axios.defaults.baseURL + '/report/delivery',
        report_payment_link: axios.defaults.baseURL + '/report/payment',
    }

    componentDidMount() {
      const today = new Date();
      const today_text = (today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear());

      this.setState({
        filter: {
          payment_date_start: today_text,
          payment_date_end: today_text,
        }
      });
    }

    printReportERP = () => {
      const { payment_date_start, payment_date_end } = this.state.filter;
      const params = [
        [ 'payment_date_start', payment_date_start ],
        [ 'payment_date_end', payment_date_end ],
      ];

      const url = this.state.report_erp_link + '?' + params.map( i => (i[0] + '=' + i[1])).join('&');
      
      window.open(url, '_blank');
    }

    printReportPayment = () => {
      const { payment_date_start, payment_date_end } = this.state.filter;
      const params = [
        [ 'payment_date_start', payment_date_start ],
        [ 'payment_date_end', payment_date_end ],
      ];

      const url = this.state.report_payment_link + '?' + params.map( i => (i[0] + '=' + i[1])).join('&');
      
      window.open(url, '_blank');
    }

    printReportDelivery = () => {
      
      const { payment_date_start, payment_date_end } = this.state.filter;
      const params = [
        [ 'payment_date_start', payment_date_start ],
        [ 'payment_date_end', payment_date_end ],
      ];

      const url = this.state.report_deviliery_link + '?' + params.map( i => (i[0] + '=' + i[1])).join('&');
      
      window.open(url, '_blank');
    }

    datepickerCallback = e => {
      let filter = { ...this.state.filter, ...e }
      this.setState({ filter });
    }

    getFields = () => {
      let fields = [];
      const filter = this.state.filter;

      fields.push({ label: 'วันที่ชำระเงิน', name: 'payment_date', type: 'datepicker', isRange: true, value: [ filter.payment_date_start, filter.payment_date_end ]})

      return fields.map((field,key) => {
        return (<FormInput key={key} field={field} datepickerCallback={this.datepickerCallback}></FormInput>)
      })
    }

    render() {
      const fields = this.getFields();

        return (
            <div>
              <TitleHeader program_name='รายงานการชำระเงิน' program_type='Report'>
              </TitleHeader>
              <BoxContainer loading={this.state.loading} errors={this.state.errors}>
                <form>
                  {fields}
                  <div className='form-group'>
                    <input type='button' onClick={this.printReportERP} className='btn btn-primary btn-block' value='พิมพ์รายงาน ERP' />
                    <input type='button' onClick={this.printReportDelivery} className='btn btn-default btn-block' value='พิมพ์รายงานที่อยู่ในการจัดส่ง' />
                    <input type='button' onClick={this.printReportPayment} className='btn btn-info btn-block' value='พิมพ์รายงานการจ่ายเงิน' />
                  </div>
                </form>
              </BoxContainer>
            </div>
        )
    }
}

export default ReportPaymentPaid
