import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../components/DataTable/DataTable';
import AdminSiteForm from './AdminSiteForm';
import TitleHeader from '../../components/TitleHeader/TitleHeader'

class AdminSite extends Component {
    render() {
        const columns = [
            { label: 'วันที่', align: 'center', name: 'start_date' },
            { label: 'ชื่องาน', align: 'left', name: 'name', search_type: 'string' },
            { label: 'สถานะ', align: 'left', name: 'status' },
        ];

        const api_url = '/master/site';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='การจัดการงานประชุม' program_type=''></TitleHeader>
                    
                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable allow_import={false} columns={columns} allow_active={true} url={api_url}></DataTable>)} />
                    
                    <Route path={`${this.props.match.path}/create`} component={props => (<AdminSiteForm {...props} url={api_url}></AdminSiteForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<AdminSiteForm {...props} url={api_url}></AdminSiteForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default AdminSite
