import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import AbstractBHForm from "./AbstractBHForm";
import TitleHeader from "../../../components/TitleHeader/TitleHeader";
import { Services } from "../../Service/Services";
import axios from "../../../axios";
import swal from "sweetalert";

class AbstractBH extends Component {
  constructor(props) {
    super(props);
    this.datatable_element = React.createRef();
  }

  onImportFile = (params) => {
    const import_url = "/importabstractbh";

    const new_params = {
      params,
      url: import_url,
    };

    console.log("debug1", this.datatable_element);

    Services.submitRequestWithFile(new_params)
      .then((res) => {
        this.datatable_element.current.refresh();
      })
      .catch((err) => {
        console.log("catch", err);
        swal({
          title: "Error",
          text: err.join("\n"),
          icon: "error",
          button: "OK",
        });
      });
  };

  render() {
    const columns = [
      { label: "รหัสผลงาน", align: "center", name: "abstract_no" },
      { label: "ชื่อหน่วยงาน", align: "left", name: "company_name" },
      { label: "ชื่อผลงาน", align: "left", name: "name" },
      { label: "ชื่อ-สกุล", align: "left", name: "author" },
      {
        label: "ชื่อหน่วยงาน​ (ออกใบประกาศ)",
        align: "left",
        name: "company_cert",
      },
      {
        label: "เลขประจำตัวใบประกาศนียบัตร",
        align: "center",
        name: "certificate_no",
      },
    ];

    const api_url = "/admin/abstract_bh";
    const export_url = axios.defaults.baseURL + "/exportabstractbh";

    return (
      <BrowserRouter>
        <div>
          <TitleHeader
            program_name="BH"
            program_type="ผลงานวิชาการ"
          ></TitleHeader>
          <Route
            exact
            path={`${this.props.match.path}/`}
            component={() => (
              <DataTable
                ref={this.datatable_element}
                columns={columns}
                allow_active={false}
                allow_insert={false}
                allow_edit={false}
                url={api_url}
                allow_print={false}
                onImportFile={this.onImportFile}
                allow_import={true}
                export_url={export_url}
              ></DataTable>
            )}
          />

          <Route
            path={`${this.props.match.path}/create`}
            component={(props) => (
              <AbstractBHForm {...props} url={api_url}></AbstractBHForm>
            )}
          />
          <Route
            path={`${this.props.match.path}/:id/edit`}
            component={(props) => (
              <AbstractBHForm {...props} url={api_url}></AbstractBHForm>
            )}
          />
        </div>
      </BrowserRouter>
    );
  }
}

export default AbstractBH;
