import React, { Component } from 'react'
import DataForm from './../../../components/DataForm/DataForm';
import FormInput from './../../../components/DataForm/FormInput';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import { Services } from '../../Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';

class OfflineBackupForm extends Component {
  // Set default state
  state = {
    data:{
      backup_database: true,
      backup_program: true,
      status: 'P',
      asset_file_id: '',
      error_message: '',
      status_text: '',
      asset_file_url: '',
    },
    redirect: false,
    loading: false,
  }

  retrieveData = id => {
    axios.get(`${this.props.url}/${id}`).then(res => {
      const data = res.data;
      this.setState({ data });
    });
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;

    if(id !== undefined){
      this.retrieveData(id);
    }
  }

  getValue = target => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target
      }
    });
  }

  onSubmit = e => {
    const params = this.state.data;
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    Services.submitRequest({ params, url }).then( res => { this.setState({redirect, loading: false}) }).catch( errors => {this.setState({ errors,loading: false })});
  }

  getRedirect = () => {
    const redirect = this.state.redirect;
    
    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  }

  getFields = () => {
    const id = this.state.id;

    var fields = [
      { label: 'ฐานข้อมูล', name: 'backup_database', type: 'switch', required: false, value: this.state.data.backup_database },
      { label: 'โปรแกรม', name: 'backup_program', type: 'switch', required: false, value: this.state.data.backup_program },
      { label: 'สถานะ', name: 'status', type: 'label', value: this.state.data.status_text },
    ];

    return fields;
  }

  render() {
    const id = this.props.match.params.id;
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;
    let btn_download = (<button disabled className='btn btn-primary btn-block btn-lg'><i className='fa fa-download'></i> Download ข้อมูล</button>);
    let btn_backup = (<button disabled className='btn btn-primary btn-block btn-lg mt-2' type='submit'><i className='fa fa-database'></i> Backup ข้อมูล</button>)

    if(this.state.data.status.toLowerCase() === 'd'){
      const download_program = this.state.data.asset_file_url;

      btn_download = (<a href={download_program} className='btn btn-primary btn-block btn-lg'>
        <i className='fa fa-download'></i> Download ข้อมูล
      </a>);
    }else{
      btn_backup = (<button className='btn btn-primary btn-block btn-lg mt-2' type='submit'><i className='fa fa-database'></i> Backup ข้อมูล</button>)
    }

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
          allow_save={false}
        >
        <div>
          {btn_backup}
          {btn_download}
        </div>
        </DataForm>
      </BoxContainer>
    )
  }
}

OfflineBackupForm.setDefaultProps = {
  url: '',
}

export default OfflineBackupForm
