import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../components/DataTable/DataTable';
import AssetForm from './AssetForm';
import TitleHeader from '../../components/TitleHeader/TitleHeader'

class Asset extends Component {
    render() {
        const columns = [
            { label: 'หมวดหมู่', align: 'left', name: 'email', search_type: 'string' },
        ];

        const api_url = '/user/internal';
        const datasource = [
            { key: 1, active: true, value: ['โรงพยาบาล A' ] },
            { key: 2, active: true, value: ['โรงพยาบาล B' ] },
            { key: 3, active: true, value: ['โรงพยาบาล C' ] },
        ];

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='สถานที่ทำงาน' program_type='ข้อมูลบัญชีผู้ใช้งาน'></TitleHeader>
                    
                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable columns={columns} allow_active={true} datasource={datasource}></DataTable>)} />
                    
                    <Route path={`${this.props.match.path}/create`} component={props => (<AssetForm {...props} url={api_url}></AssetForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<AssetForm {...props} url={api_url}></AssetForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default Asset
