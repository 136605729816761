import React, { Component } from "react";
import { Alert } from "../../pages/Service/Services";

var HtmlToReactParser = require("html-to-react").Parser;
const $ = window.$;

class FileInput extends Component {
  constructor(props) {
    super(props);

    this.element = React.createRef();
  }

  componentDidMount() {
    const options = {
      showPreview: this.props.multiple,
      showUpload: this.props.showUpload,
    };

    $(this.element.current)
      .fileinput(options)
      .on("change", function (event) {
        console.log("change");
      })
      .on("fileselect", (event, numFiles, label) => {
        console.log("fileselect");

        this.onChange(event);
      });
  }

  onChange = (e) => {
    // Active only textbox
    this.props.onChange(e);
  };

  componentDidUpdate = () => {};

  onDelete = (id) => {
    Alert.confirm("คุณแน่ใจไหมจะลบข้อมูลนี้").then((res) => {
      if (res === true) this.props.onDelete(this.props.name, id);
    });
  };

  render() {
    const preview = this.props.value;
    var htmlToReactParser = new HtmlToReactParser();
    var reactElement;
    if (this.props.multiple === true) {
      if (this.props.value instanceof Array) {
        const allow_delete = this.props.onDelete !== null;
        const child = this.props.value.map((item, index) => {
          var preview = htmlToReactParser.parse(item.preview);
          var btn_delete;

          if (allow_delete === true && item.is_delete !== true) {
            btn_delete = (
              <i
                onClick={(e) => {
                  this.onDelete(item.id);
                }}
                className="fa fa-close text-danger"
              ></i>
            );
          }

          if (item.is_delete) {
            preview = <del>{preview}</del>;
          }

          return (
            <li key={index}>
              {preview} {btn_delete}
            </li>
          );
        });

        reactElement = <ul className="list-unstyled">{child}</ul>;
      }
    } else {
      reactElement = htmlToReactParser.parse(preview);
    }

    const extension = this.props.extension;
    let accept = "";

    if (extension === "image") {
      accept = "image/x-png,image/gif,image/jpeg";
    } else if (extension === "pdf") {
      accept = ".pdf";
    }

    let required = this.props.required;

    if (required === true && preview !== "") {
      required = false;
    }

    return (
      <div>
        <input
          type="file"
          required={required}
          accept={accept}
          id={this.props.name}
          name={this.props.name}
          ref={this.element}
          multiple={this.props.multiple}
        />
        <div className="mt-2">{reactElement}</div>
      </div>
    );
  }
}

FileInput.defaultProps = {
  required: false,
  value: "",
  name: "",
  onChange: {},
  multiple: true,
  extension: "",
  showUpload: false,
  onDelete: null,
};

export default FileInput;
