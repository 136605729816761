import axios from "../axios";

const updateCompany = () => {
  const promise = new Promise((resolve, reject) => {
    const url = `master/company/refreshfromapi`;
    axios
      .get(url)
      .then((res) => {
        resolve(res);
      })
      .catch((res) => {
        reject(res);
      });
  });

  return promise;
};
const updateCompanySelect = (id) => {
  const promise = new Promise((resolve, reject) => {
    const url = `master/company`;
    axios
      .get(`${url}/${id}`)
      .then((res) => {
        resolve(res);
      })
      .catch((res) => {
        reject(res);
      });
  });

  return promise;
};
const getCompanyLists = () => {
  const promise = new Promise((resolve, reject) => {
    const url = `master/company`;
    axios
      .get(`${url}`)
      .then((res) => {
        resolve(res);
      })
      .catch((res) => {
        reject(res);
      });
  });

  return promise;
};

export default {
  updateCompany,
  updateCompanySelect,
  getCompanyLists
};
