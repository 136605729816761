import React, { Component } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { authenticationService } from "../Service/authentication.service";
import { history } from "../../../pages/Authentication/_helpers";
import { UserContext } from "../../../context/UserContextProvider";
const $ = window.$;

class login extends Component {
  static contextType = UserContext;
  state = {};

  componentDidMount() {}
  onSubmit = (e) => {
    e.preventDefault();
    var params = {
      email: e.target.email.value,
      password: e.target.password.value,
      remember: false,
    };

    authenticationService
      .login(params.email, params.password, params.remember)
      .then(({ currentUser, site, menus }) => {
        let { from } = this.props.location.state || { from: { pathname: "/" } };

        from = authenticationService.getRedirectPath({
          currentUser,
          defaultPath: from,
        });

        this.props.history.push(from);
        history.go(0);
      })
      .catch((error) => {
        console.log(error);
        swal({
          title: "Error",
          text: error.data,
          icon: "error",
          button: "OK",
        });
      });
  };

  render() {
    return (
      <div className="center-login gray-bg">
        <div className="login animated fadeInDown ">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center logo-name">HA Forum</h1>
            </div>
            <div className="col-12">
              <h3 className="text-center">Welcome to HA Forum</h3>
            </div>
            <div className="col-12">
              <form className="m-t" id="form" onSubmit={this.onSubmit}>
                <div className="form-group row">
                  <div className="col-6 mx-auto">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username / Email"
                      name="email"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-6 mx-auto">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      name="password"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-6 mx-auto">
                    <button
                      type="submit"
                      className="btn btn-primary block full-width"
                    >
                      Login
                    </button>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-6 mx-auto">
                    <div className="form-group row">
                      <div className="text-left col">
                        {/* <label>
                          <input type="checkbox" name="remember" /> Remember me
                        </label> */}
                      </div>
                      <div className="text-right col">
                        <Link to="/forgotpassword">
                          <small>Forgot password?</small>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default login;
