import React, { Component } from 'react'
import FormInput from './../../../components/DataForm/FormInput';

class MeetingWorkshopSection extends Component {
    getValue = (target, index) => {
        const new_data = [ ...this.props.data ];

        const name = Object.keys(target)[0];
        const value = Object.values(target)[0];
        new_data[index][name] = value;

        this.props.onChange(new_data);
    }

    onDelete = index => {
        const new_data = [ ...this.props.data ];
        new_data.splice(index, 1);

        this.props.onChange(new_data);
    }

    onInsert = () => {
        const new_data = [ ...this.props.data ];
        new_data.push({
            section_id: '',
        });

        this.props.onChange(new_data);
    }
  

  render() {
    const row = this.props.data.map((item, index) => {
        const datasource = this.props.datasource;
        const field_section = { name: 'section_id', type: 'ddl', value: item.section_id, datasource, required: true }

        const data = datasource.find( data => data.key == item.section_id );
        let description = '';

        if(data !== undefined){
            description = data.description;
        }

        return (
            <tr key={index}>
                <td>
                    <FormInput field={field_section} getValue={ target => { this.getValue(target, index) }}></FormInput>
                    <small className='text-mute'><strong>รายละเอียด:</strong> {description}</small>
                </td>
                <td className='text-center'>
                <div className="btn-group">
                <button onClick={() => { this.onDelete(index) }} type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Trash" title="Trash" className="btn btn-danger btn-sm ml-1"><i className="fa fa-trash"></i> ลบ</button></div>
                </td>
            </tr>
        )
    });

    return (
      <div>
        <button type='button' onClick={this.onInsert} className='btn btn-primary float-right'><i className='fa fa-plus'></i> เพิ่มข้อมูล</button>
        <table className='table'>
            <thead>
                <tr>
                    <th className='text-center'>หัวข้อประชุม</th>
                    <th className='text-center'>#</th>
                </tr>
            </thead>
            <tbody>
                {row}
            </tbody>
        </table>
      </div>
    )
  }
}

MeetingWorkshopSection.setDefaultProps = {
  datasource: [],
  data: [],
  onChange: {},
}

export default MeetingWorkshopSection