import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import LogFrontendForm from './LogFrontendForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class LogFrontend extends Component {
    render() {
        const columns = [
            { label: 'วันที่ เวลา', align: 'center', name: 'created_at' },
            { label: 'โปรแกรม', align: 'left', name: 'program_name', search_type: 'string' },
            { label: 'ผู้ดำเนินการ', align: 'left', name: 'username', search_type: 'string'  },
            { label: 'รายละเีอยด', align: 'left', name: 'short_description', search_type: 'string'  },
        ];

        const api_url = '/log/frontend';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ประวัติการใช้งานระบบหน้าบ้าน' program_type='ประวัติการใช้งาน'></TitleHeader>
                    
                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable columns={columns} allow_active={false} allow_insert={false} allow_trash={false} allow_edit={false} allow_view={true} url={api_url}></DataTable>)} />
                    
                    <Route path={`${this.props.match.path}/create`} component={props => (<LogFrontendForm {...props} url={api_url}></LogFrontendForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<LogFrontendForm {...props} url={api_url}></LogFrontendForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default LogFrontend
