import React, { Component } from "react";
import FormInput from "./../../../components/DataForm/FormInput";

class MeetingKeynoteSpeakerTransport extends Component {
  getValue = (target, index) => {
    const new_data = { ...this.props.data };
    const name = Object.keys(target)[0];
    const value = Object.values(target)[0];
    new_data[name] = value;

    this.props.onChange(new_data);
  };

  
  getFields = () => {
    const fields = {
      private_car: [
        {
          label: "จาก",
          type: "textbox",
          name: "by_private_car_from",
          value: this.props.data.by_private_car_from,
        },
        {
          label: "ถึง",
          type: "textbox",
          name: "by_private_car_to",
          value: this.props.data.by_private_car_to,
        },
        {
          label: "ระยะทาง",
          type: "textbox",
          name: "by_private_car_distance",
          value: this.props.data.by_private_car_distance,
        },
        {
          label: "ราคา",
          type: "number",
          name: "by_private_car_price",
          value: this.props.data.by_private_car_price,
        },
        {
          label: "ทะเบียนรถ",
          type: "textbox",
          name: "by_private_car_license",
          value: this.props.data.by_private_car_license,
        },
        { label: 'จังหวัด', type: 'ddl', name: 'by_private_car_province_id', value: this.props.data.by_private_car_province_id, datasource: this.props.datasource.province },
      ],
      public_car: [
        {
          label: "จาก",
          type: "textbox",
          name: "by_public_car_from",
          value: this.props.data.by_public_car_from,
        },
        {
          label: "ถึง",
          type: "textbox",
          name: "by_public_car_to",
          value: this.props.data.by_public_car_to,
        },
        {
          label: "ระยะทาง",
          type: "textbox",
          name: "by_public_car_distance",
          value: this.props.data.by_public_car_distance,
        },
        {
          label: "ราคา",
          type: "number",
          name: "by_public_car_price",
          value: this.props.data.by_public_car_price,
        },
      ],
      public_taxi_2: [
        {
          label: "จาก",
          type: "textbox",
          name: "by_taxi_2_from",
          value: this.props.data.by_taxi_2_from,
        },
        {
          label: "ถึง",
          type: "textbox",
          name: "by_taxi_2_to",
          value: this.props.data.by_taxi_2_to,
        },
        {
          label: "ระยะทาง",
          type: "textbox",
          name: "by_taxi_2_distance",
          value: this.props.data.by_taxi_2_distance,
        },
        {
          label: "ราคา",
          type: "number",
          name: "by_taxi_2_price",
          value: this.props.data.by_taxi_2_price,
        },
      ],
      train: [
        {
          label: "จาก",
          type: "textbox",
          name: "by_train_from",
          value: this.props.data.by_train_from,
        },
        {
          label: "ถึง",
          type: "textbox",
          name: "by_train_to",
          value: this.props.data.by_train_to,
        },
        {
          label: "ราคา",
          type: "number",
          name: "by_train_price",
          value: this.props.data.by_train_price,
        },
      ],
      plane: [
        {
          label: "จาก",
          type: "textbox",
          name: "by_plane_from",
          value: this.props.data.by_plane_from,
        },
        {
          label: "ถึง",
          type: "textbox",
          name: "by_plane_to",
          value: this.props.data.by_plane_to,
        },
        {
          label: "ราคา",
          type: "number",
          name: "by_plane_price",
          value: this.props.data.by_plane_price,
        },
      ],
      other: [
        {
          label: "เอกสารแนบ ",
          type: "file",
          name: "asset_file_transport[]",
          value: this.props.data.asset_file_transport_preview,
          multiple: true,
        },
      ],
    };

    return fields;
  };

  onDelete = (name, id) => {
    if (name.includes("asset_file_transport")) {
      const new_data = { ...this.props.data };
      const index = new_data.asset_file_transport_preview.findIndex(
        (item) => item.id === id
      );

      if (index !== -1) {
        new_data.asset_file_transport_preview[index].is_delete = true;
        new_data.asset_file_transport_delete_id.push(id);

        this.props.onChange(new_data);
      }
    }
  };

  render() {
    const fields = this.getFields();    
    const private_car = fields.private_car.map((field, index) => {
      return (
        <FormInput
          key={index}
          field={field}
          getValue={this.getValue}
        ></FormInput>
      );
    });
    const public_car = fields.public_car.map((field, index) => {
      return (
        <FormInput
          key={index}
          field={field}
          getValue={this.getValue}
        ></FormInput>
      );
    });
    const public_taxi_2 = fields.public_taxi_2.map((field, index) => {
      return (
        <FormInput
          key={index}
          field={field}
          getValue={this.getValue}
        ></FormInput>
      );
    });
    const train = fields.train.map((field, index) => {
      return (
        <FormInput
          key={index}
          field={field}
          getValue={this.getValue}
        ></FormInput>
      );
    });
    const plane = fields.plane.map((field, index) => {
      return (
        <FormInput
          key={index}
          field={field}
          getValue={this.getValue}
        ></FormInput>
      );
    });

    const other = fields.other.map((field, index) => {
      return (
        <FormInput
          key={index}
          field={field}
          getValue={this.getValue}
          onDelete={this.onDelete}
        ></FormInput>
      );
    });

    return (
      <>
        <div id="accordion_private_car" className="panel-body">
            <div>
                <div id="heading_private_car" className="head-collapse d-flex justify-content-between align-items-center alert-info p-4" data-toggle="collapse" data-target="#collapse_private_car" aria-expanded="false" aria-controls="collapse_private_car">
                    <h3 className="m-0 w-75">เดินทางโดย รถยนต์ส่วนตัว</h3>                
                    <span className="fa fa-angle-down" aria-hidden="true"></span>
                </div>
                <div id="collapse_private_car" className="collapse bg-light" aria-labelledby="heading_private_car" data-parent="#accordion_private_car">
                    <div className="card-body p-4">
                        {private_car}
                    </div>
                </div>
            </div>
            <div className="hr-line-dashed"></div>
            <div>
                <div id="heading_public_car" className="head-collapse d-flex justify-content-between align-items-center alert-info p-4" data-toggle="collapse" data-target="#collapse_public_car" aria-expanded="false" aria-controls="collapse_public_car">
                    <h3 className="m-0 w-75">เดินทางโดย รถ Taxi 1</h3>                
                    <span className="fa fa-angle-down" aria-hidden="true"></span>
                </div>
                <div id="collapse_public_car" className="collapse bg-light" aria-labelledby="heading_public_car" data-parent="#accordion_public_car">
                    <div className="card-body p-4">
                        {public_car}
                    </div>
                </div>
            </div>
            <div className="hr-line-dashed"></div>
            <div>
                <div id="heading_public_taxi_2" className="head-collapse d-flex justify-content-between align-items-center alert-info p-4" data-toggle="collapse" data-target="#collapse_public_taxi_2" aria-expanded="false" aria-controls="collapse_public_taxi_2">
                    <h3 className="m-0 w-75">เดินทางโดย รถ Taxi 2</h3>                
                    <span className="fa fa-angle-down" aria-hidden="true"></span>
                </div>                
                <div id="collapse_public_taxi_2" className="collapse bg-light" aria-labelledby="heading_public_taxi_2" data-parent="#accordion_public_taxi_2">
                    <div className="card-body p-4">
                        {public_taxi_2}
                    </div>
                </div>
            </div>
            <div className="hr-line-dashed"></div>
            <div>                
                <div id="heading_train" className="head-collapse d-flex justify-content-between align-items-center alert-info p-4" data-toggle="collapse" data-target="#collapse_train" aria-expanded="false" aria-controls="collapse_train">
                    <h3 className="m-0 w-75">เดินทางโดย รถไฟ/รถทัวร์/อื่นๆ</h3>                
                    <span className="fa fa-angle-down" aria-hidden="true"></span>
                </div>
                <div id="collapse_train" className="collapse bg-light" aria-labelledby="heading_train" data-parent="#accordion_train">
                    <div className="card-body p-4">
                        {train}
                    </div>
                </div>
            </div>
            <div className="hr-line-dashed"></div>
            <div>                
                <div id="heading_plane" className="head-collapse d-flex justify-content-between align-items-center alert-info p-4" data-toggle="collapse" data-target="#collapse_plane" aria-expanded="false" aria-controls="collapse_plane">
                    <h3 className="m-0 w-75">เดินทางโดย เครื่องบิน</h3>                
                    <span className="fa fa-angle-down" aria-hidden="true"></span>
                </div>
                <div id="collapse_plane" className="collapse bg-light" aria-labelledby="heading_plane" data-parent="#accordion_plane">
                    <div className="card-body p-4">
                        {plane}
                    </div>
                </div>
            </div>
            <div className="hr-line-dashed"></div>
            <div className="row">
                <div className="col-12 form-group">
                    <p>
                    <strong>การเดินทาง</strong>
                    </p>
                    <ol>
                    <li>
                        การเบิกจ่ายเป็นไปตามระเบียบของสถาบัน และหากเป็นการเดินทางระยะไกล
                        ซึ่งมีสายการบินต้นทุนต่ำบริการ
                        ขอความกรุณาเลือกวิธีการเดินทางสายการบินต้นทุนต่ำแทนการเดินทางโดยรถยนต์
                        หรือช่องทางอื่นๆ ซึ่งมีค่าใช้จ่ายที่สูงกว่า
                    </li>{" "}
                    <li>
                        กรณีเดินทางโดยรถโดยสารประจำทางหรือรถไฟ
                        โปรดนำกากตั๋วมาแสดงในวันประชุม
                    </li>{" "}
                    <li>
                        การออกใบเสร็จ กรณีนำใบเสร็จรับเงินมาเบิกค่าใช้จ่ายจากสถาบัน
                        กรุณาออกใบเสร็จในนาม{" "}
                        <strong>
                        "สถาบันรับรองคุณภาพสถานพยาบาล (องค์การมหาชน)" ระบุสำนักงานใหญ่
                        เลขที่ 88/39 อาคารสุขภาพแห่งชาติ ชั้น 5 กระทรวงสาธารณสุข ซ.6
                        ถ.ติวานนท์ ต.ตลาดขวัญ อ.เมือง จ.นนทบุรี 11000
                        เลขประจำตัวผู้เสียภาษี 0994000002041
                        </strong>
                    </li>
                    </ol>{" "}
                    <p></p>
                </div>
            </div>
            <div className="hr-line-dashed"></div>
            {other}
        </div>
      </>
    );
  }
}

MeetingKeynoteSpeakerTransport.setDefaultProps = {
  datasource: [],
  onChange: {},
  data: [],
};

export default MeetingKeynoteSpeakerTransport;
