export default {
  setCurrentSite,
  getCurrentSite,
  removeCurrentSite,
};

const LOCAL_STORAGE_SITE_NAME = "currentSite";

function setCurrentSite(site) {
  localStorage.setItem(
    LOCAL_STORAGE_SITE_NAME,
    JSON.stringify({
      id: site.id,
      name: site.name,
    })
  );
}

function getCurrentSite() {
  const currentSite = JSON.parse(localStorage.getItem(LOCAL_STORAGE_SITE_NAME));

  return currentSite;
}

function removeCurrentSite() {
  localStorage.removeItem(LOCAL_STORAGE_SITE_NAME);
}
