import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import FrontendPopupForm from './FrontendPopupForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class FrontendPopup extends Component {
    render() {
        const columns = [
            { label: 'หัวข้อ', align: 'left', name: 'topic', search_type: 'string' },
            { label: 'ช่วงเวลา', align: 'center', name: 'period', sortable: false },
        ];

        const api_url = '/master/popup';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='Popup' program_type='Frontend'></TitleHeader>
                    
                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable columns={columns} url={api_url} allow_active={true}></DataTable>)} />
                    
                    <Route path={`${this.props.match.path}/create`} component={props => (<FrontendPopupForm {...props} url={api_url}></FrontendPopupForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<FrontendPopupForm {...props} url={api_url}></FrontendPopupForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default FrontendPopup
