import React, { Component } from "react";
import DataForm from "./../../../components/DataForm/DataForm";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import { Services, Alert } from "../../Service/Services";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";
import FormInput from "../../../components/DataForm/FormInput";
import MemberGroupERPStatus from "./MemberGroupERPStatus";
import { ERPAPI } from "../../../api";
import swal from "sweetalert";

class MemberGroupERPForm extends Component {
  // Set default state
  state = {
    data: {
      id: null,
      receive_date: "",
      old_receive_date: "",
      enable_receive_date: false,
      name: "",
      address1: "",
      address2: "",
      zip_code: "",
      tax_id: "",
      telephone: "",
      email: "",
      ref1: "",
      ref2: "",
      total: "",
      totalText: "",
      company_name: "",
      status: "",
      erp_document_no: "",
      erp_document_date: "",
      erp_errors: [],

      vat_amount: "",
      amount_exc_vat: "",
    },
    datasource: {},
    redirect: false,
    loading: true,
  };

  retrieveData = (id) => {
    axios.get(`/admin/membergrouperp/${id}`).then((res) => {
      const data = res.data;
      data.old_receive_date = data.receive_date;
      data.enable_receive_date = false;
      this.setState({
        data: data,
        loading: false,
      });
    });
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;

    this.retrieveData(id);
  };

  getValue = (target) => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
  };

  onSubmit = (e) => {
    const url = "/admin/membergrouperp";

    const params = {};
    params.id = this.state.data.id;

    params.name = this.state.data.name;
    params.address1 = this.state.data.address1;
    params.address2 = this.state.data.address2;
    params.zip_code = this.state.data.zip_code;
    params.tax_id = this.state.data.tax_id;

    if (this.state.data.enable_receive_date === true) {
      params.receive_date = this.state.data.receive_date;
      params.enable_receive_date = this.state.data.enable_receive_date;
    }

    this.setState({ loading: true });

    Services.submitRequest({ params, url })
      .then((res) => {
        this.retrieveData(this.state.data.id);
      })
      .catch((errors) => {
        this.setState({ errors, loading: false });
      });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  };

  getFields = () => {
    var fields = [];

    return fields;
  };

  onClickEditReceiveDate = (e) => {
    swal("วันที่รับเงิน:", {
      content: {
        element: "input",
        attributes: {
          placeholder: "วันที่รับเงิน",
          type: "input",
          value: this.state.data.receive_date,
        },
      },
    }).then((value) => {
      if (value) {
        this.setState({
          data: {
            ...this.state.data,
            receive_date: value,
            enable_receive_date: true,
          },
        });
      }
    });
  };

  sendToERP = (e) => {
    // e.preventDefault();
    Alert.confirm("คุณแน่ใจไหมจะส่งข้อมูลไปยัง ERP").then((res) => {
      if (res === true) {
        this.setState({ loading: true });
        ERPAPI.sendToERPSingle(this.state.data.id)
          .then(() => {
            Alert.done("ส่งข้อมูลไปยัง ERP เรียบร้อย").then(() =>
              this.retrieveData(this.state.data.id)
            );
          })
          .catch((error) => {
            this.setState({ loading: false });
            console.log("ERROR", error.data);
            Alert.error("ข้อมูลไม่ถูกต้อง \n" + error.data.join("\n"));
          });
      }
    });
  };

  render() {
    // const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;
    let buttons = [
      <button
        type="button"
        key="sendToERP"
        className="btn btn-secondary mr-2"
        onClick={this.sendToERP}
      >
        ส่งข้อมูล ERP
      </button>,
    ];

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <MemberGroupERPStatus
          status={this.state.data.status}
          erp_document_no={this.state.data.erp_document_no}
          erp_document_date={this.state.data.erp_document_date}
          erp_errors={this.state.data.erp_errors}
        />
        <DataForm
          // fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
          buttons={buttons}
        >
          <h2>
            เลขที่ใบเสร็จรับเงิน{" "}
            <span className="badge badge-primary p-2">
              {this.state.data.erp_document_no ?? "-"}
            </span>
          </h2>
          <div className="card mb-3">
            <h5 className="card-header bg-primary">ข้อมูลออกใบเสร็จ</h5>
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <h3 className="card-title">Ref 1: {this.state.data.ref1}</h3>
                </div>
                <div className="col-6">
                  <h3 className="card-title">Ref 2: {this.state.data.ref2}</h3>
                </div>
              </div>
              <div className="form-group mb-0">
                <label
                  className="col-form-label field-required"
                  htmlFor="receive_date"
                >
                  วันที่รับเงิน:
                </label>
                {this.state.data.enable_receive_date ? (
                  <>
                    <del>{this.state.data.old_receive_date}</del>{" "}
                    {this.state.data.receive_date}
                  </>
                ) : (
                  <>{this.state.data.receive_date}</>
                )}
              </div>
              <input
                type="button"
                className="btn btn-success btn-sm"
                value="แก้ไขวันที่รับเงิน"
                onClick={this.onClickEditReceiveDate}
              />
              <FormInput
                field={{
                  label: "รับเงินจาก",
                  name: "name",
                  type: "textbox",
                  required: true,
                  value: this.state.data.name,
                }}
                getValue={this.getValue}
              ></FormInput>
              <FormInput
                field={{
                  label: "ที่อยู่ 1",
                  name: "address1",
                  type: "textbox",
                  required: true,
                  value: this.state.data.address1,
                }}
                getValue={this.getValue}
              ></FormInput>
              <FormInput
                field={{
                  label: "ที่อยู่ 2",
                  name: "address2",
                  type: "textbox",
                  required: true,
                  value: this.state.data.address2,
                }}
                getValue={this.getValue}
              ></FormInput>
              <FormInput
                field={{
                  label: "รหัสไปรษณีย์",
                  name: "zip_code",
                  type: "textbox",
                  required: true,
                  value: this.state.data.zip_code,
                }}
                getValue={this.getValue}
              ></FormInput>
              <FormInput
                field={{
                  label: "รหัสประจำตัวผู้เสียภาษี",
                  name: "tax_id",
                  type: "text",
                  required: true,
                  value: this.state.data.tax_id,
                }}
                getValue={this.getValue}
              ></FormInput>
            </div>
            <div className="card-footer">
              <div className="mb-1">
                <strong>จำนวนเงินรวมVat</strong> {this.state.data.totalText} บาท
              </div>
              <div className="mb-1">
                <strong>Vat%</strong> 7%
              </div>
              <div className="mb-1">
                <strong>จำนวนVat</strong> {this.state.data.vat_amount} บาท
              </div>
              <div className="mb-1">
                <strong>จำนวนไม่รวมVat</strong> {this.state.data.amount_exc_vat}{" "}
                บาท
              </div>
              <div className="mb-1">
                <strong>จำนวนหน่วย</strong> {this.state.data.unit}
              </div>
            </div>
          </div>
          <div className="card">
            <h5 className="card-header bg-info">ข้อมูลติดต่อ</h5>
            <div className="card-body">
              {/* <h5 className="card-title">Special title treatment</h5> */}
              <div className="card-text">
                <div className="mb-1">
                  <strong>ชื่อ รพ:</strong> {this.state.data.company_name}
                </div>
                <div className="mb-1">
                  <strong>อีเมล:</strong> {this.state.data.email}
                </div>
                <div className="mb-1">
                  <strong>โทรศัพท์:</strong> {this.state.data.telephone}
                </div>
              </div>
              {/* <a href="#" className="btn btn-primary">
                Go somewhere
              </a> */}
            </div>
          </div>
        </DataForm>
      </BoxContainer>
    );
  }
}

MemberGroupERPForm.setDefaultProps = {
  url: "",
};

export default MemberGroupERPForm;
