import React, { Component } from "react";
import DataForm from "./../../../components/DataForm/DataForm";
// import FormInput from './../../../components/DataForm/FormInput';
import { Redirect } from "react-router-dom";
import { Services } from "../../Service/Services";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";
import axios from "../../../axios";

class AbstractHACCForm extends Component {
  // Set default state
  state = {
    data: {
      company_id: null,
      abstract_no: "Auto",
      name: "",
      abstract_type: "poster",
      abstract_topic_id: null,
      author: "",
      telephone: "",
      email: "",
      // link_video_poster: "",
      present_file: null,
      present_asset_file_preview: "",
    },
    datasource: {
      abstract_topic: [],
      company: [],
      // abstract_type: [
      //   { label: "Poster", key: "poster" },
      //   { label: "Oral", key: "oral" },
      // ],
    },
    redirect: false,
    loading: true,
  };

  init = () => {
    const promise = new Promise((resolve) => {
      const params = {
        tables: [
          { name: "abstract_topic", ref1: "poster" },
          { name: "company" },
        ],
      };

      axios.post("datasource", params).then((res) => {
        const abstract_topic = res.data.abstract_topic.map((item) => {
          return {
            key: item.id,
            label: item.name,
          };
        });

        const company = res.data.company.map((item) => {
          return {
            key: item.id,
            label: item.name,
          };
        });

        const datasource = {
          ...this.state.datasource,
          company,
          abstract_topic,
        };

        resolve(datasource);
      });
    });

    return promise;
  };

  retrieveData = (id, datasource) => {
    const url = this.props.url;
    axios.get(`${url}/${id}`).then((res) => {
      const data = res.data;
      this.setState({
        data: {
          id: id,
          company_id: data.company_id,
          abstract_no: data.abstract_no,
          name: data.name,
          // abstract_type: data.abstract_type,
          abstract_topic_id: data.abstract_topic_id,
          author: data.author,
          telephone: data.telephone,
          email: data.email,
          // link_video_poster: data.link_video_poster,
          present_asset_file_preview: data.present_asset_file_preview,
        },
        loading: false,
        datasource: datasource,
      });
    });
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;

    this.init().then((datasource) => {
      if (id !== undefined) {
        this.retrieveData(id, datasource);
      } else {
        this.setState({ loading: false, datasource });
      }
    });
  };

  getValue = (target) => {
    let data = this.state.data;

    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
  };

  onSubmit = (e) => {
    const params = this.state.data;
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    loading = false;

    Services.submitRequestWithFile({ params, url })
      .then((res) => {
        this.setState({ redirect, loading });
      })
      .catch((errors) => {
        this.setState({ errors, loading });
      });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  };

  getFields = () => {
    var fields = [
      {
        label: "รหัสผลงานวิชาการ",
        name: "abstract_no",
        type: "label",
        required: false,
        value: this.state.data.abstract_no,
      },
      {
        label: "ชื่อสถาบัน",
        name: "company_id",
        type: "ddl",
        required: true,
        value: this.state.data.company_id,
        datasource: this.state.datasource.company,
        minimumInputLength: 1,
      },
      {
        label: "ชื่อผลงาน",
        name: "name",
        type: "text",
        required: true,
        value: this.state.data.name,
      },
      // {
      //   label: "ประเภทผลงาน",
      //   name: "abstract_type",
      //   type: "radio",
      //   required: true,
      //   value: this.state.data.abstract_type,
      //   datasource: this.state.datasource.abstract_type,
      // },
      {
        label: "หมวดหมู่การนำเสนอ",
        name: "abstract_topic_id",
        type: "ddl",
        required: true,
        value: this.state.data.abstract_topic_id,
        datasource: this.state.datasource.abstract_topic,
      },
      {
        label: "ชื่อ-นามสกุล เจ้าของผลงาน",
        name: "author",
        type: "text",
        required: true,
        value: this.state.data.author,
      },
      {
        label: "เบอร์โทรติดต่อ เจ้าของผลงาน",
        name: "telephone",
        type: "text",
        required: false,
        value: this.state.data.telephone,
      },
      {
        label: "E-mail เจ้าของผลงาน",
        name: "email",
        type: "email",
        required: false,
        value: this.state.data.email,
      },
      // {
      //   label: "Poster Video URL",
      //   name: "link_video_poster",
      //   type: "url",
      //   required: false,
      //   value: this.state.data.link_video_poster,
      // },
      // {
      //   label: "Poster",
      //   name: "present_file",
      //   type: "file",
      //   required: true,
      //   extension: "pdf",
      //   multiple: false,
      //   remark: "หมายเหตุ: รองรับไฟล์ .pdf และขนาดไฟล์ไม่เกิน 800 MB",
      //   value: this.state.data.present_asset_file_preview,
      // },
    ];

    return fields;
  };

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
        ></DataForm>
      </BoxContainer>
    );
  }
}

AbstractHACCForm.setDefaultProps = {
  url: "",
};

export default AbstractHACCForm;
