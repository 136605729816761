import React, { Component } from "react";
import DataForm from "./../../components/DataForm/DataForm";
import FormInput from "./../../components/DataForm/FormInput";
import axios from "../../axios";
import { Redirect } from "react-router-dom";
import { Services } from "../Service/Services";
import BoxContainer from "../../components/BoxContainer/BoxContainer";
import DateRange from "../../components/DateRange/daterange";

class AdminSiteForm extends Component {
  // Set default state
  state = {
    id: null,
    data: {
      id: null,
      name: "",
      start_date: "",
      end_date: "",
      vision: "",
      name_en: "",
      vision_en: "",
      place: "",
      asset_file: "",
      asset_file_preview: "",
      slug: "",
      site_code: "",
      erp_remark: "",
      end_date_register: "",
      start_date_register: "",
      active: true,
      status: "",
      comcode: "",
      meeting_start_date: "",
      meeting_end_date: "",
      site_no: "",
      start_date_early_bird: "",
      end_date_early_bird: "",
      allow_register_online: true,
      allow_register_onground: true,
      allow_register_online_early_bird: false,
      allow_register_onground_early_bird: false,
      online_early_bird_limit: "",
      onground_early_bird_limit: "",

      fiscal_year_code: "",
      project_code: "",
      activity_code: "",
    },
    complete: {
      isCompleteSectionOnline: false,
      isCompleteAbstractTopic: false,
      isCompleteAbstractPeriod: false,
      isCompletePaymentPeriod: false,
      isCompleteCard: false,
      isCompleteEmailTemplate: false,
    },
    datasource: {
      status: [
        { key: "", label: "ยังไม่กำหนด" },
        { key: "currently", label: "งานประชุมวิชาการปัจจุบัน" },
        { key: "past", label: "งานประชุมวิชาการที่ผ่านมา" },
      ],
    },
    redirect: false,
    loading: true,
  };

  retrieveData = (id) => {
    axios.get(`${this.props.url}/${id}`).then((res) => {
      const data = res.data;
      const new_data = {
        id: data.id,
        name: data.name,
        start_date: data.start_date_text,
        end_date: data.end_date_text,
        vision: data.vision,
        name_en: data.name_en,
        vision_en: data.vision_en,
        place: data.place,
        asset_file: data.asset_file,
        asset_file_preview: data.asset_file_preview,
        slug: data.slug,
        site_code: data.site_code,
        erp_remark: data.erp_remark,
        end_date_register: data.end_date_register_text,
        start_date_register: data.start_date_register_text,
        active: data.active,
        status: data.status || "",
        comcode: data.comcode,
        meeting_start_date: data.meeting_start_date_text,
        meeting_end_date: data.meeting_end_date_text,
        site_no: data.site_no,

        start_date_early_bird: data.start_date_early_bird_text,
        end_date_early_bird: data.end_date_early_bird_text,
        allow_register_online: data.allow_register_online,
        allow_register_onground: data.allow_register_onground,
        allow_register_online_early_bird: data.allow_register_online_early_bird,
        allow_register_onground_early_bird:
          data.allow_register_onground_early_bird,
        online_early_bird_limit: data.online_early_bird_limit,
        onground_early_bird_limit: data.onground_early_bird_limit,
        fiscal_year_code: data.fiscal_year_code,
        project_code: data.project_code,
        activity_code: data.activity_code,
      };

      const complete = { ...this.state.complete };
      complete.isCompleteSectionOnline = data.isCompleteSectionOnline;
      complete.isCompleteAbstractTopic = data.isCompleteAbstractTopic;
      complete.isCompleteAbstractPeriod = data.isCompleteAbstractPeriod;
      complete.isCompletePaymentPeriod = data.isCompletePaymentPeriod;
      complete.isCompleteCard = data.isCompleteCard;
      complete.isCompleteEmailTemplate = data.isCompleteEmailTemplate;

      this.setState({
        data: new_data,
        complete: complete,
        loading: false,
        id: id,
      });
    });
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;

    if (id !== undefined) {
      this.retrieveData(id);
    } else {
      const loading = false;
      this.setState({ loading });
    }
  };

  getValue = (target) => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
  };

  onDatepickerCallback = (target) => {
    console.log("onDatepickerCallback", target);
  };

  onSubmit = (e) => {
    const params = { ...this.state.data };
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    loading = false;

    Services.submitRequestWithFile({ params, url })
      .then((res) => {
        this.setState({ redirect: true, loading, id: res.data.id });
      })
      .catch((errors) => {
        this.setState({ errors, loading });
      });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = this.props.url + "/" + this.state.id + "/edit";

      return <Redirect to={url} />;
    }

    return;
  };

  getFields = () => {
    var fields = [
      {
        label: "สถานที่",
        name: "place",
        type: "textbox",
        required: false,
        value: this.state.data.place,
      },
      {
        label: "Logo",
        name: "asset_file",
        type: "file",
        required: false,
        value: this.state.data.asset_file_preview,
        multiple: false,
      },
      {
        label: "สถานะ",
        name: "status",
        type: "radio",
        required: false,
        datasource: this.state.datasource.status,
        value: this.state.data.status,
      },
      {
        label: "Slug",
        name: "slug",
        type: "textbox",
        required: true,
        remark:
          "ขึ้นต้นด้วยตัวอักษรภาษาอังกฤษ พิมพ์เล็กทั้งหมด สามารถใส่ตัวเลขหรือสัญญาลักษณ์ - (dash) ได้ ห้ามมีช่องว่างหรือสัญญาลักษณ์พิเศษอื่นๆ",
        value: this.state.data.slug,
      },
      {
        label: "Site Code",
        name: "site_code",
        type: "textbox",
        required: true,
        value: this.state.data.site_code,
      },
      {
        label: "Com Code",
        name: "comcode",
        type: "textbox",
        required: true,
        value: this.state.data.comcode,
      },
      {
        label: "ครั้งที่จัดการประชุม",
        name: "site_no",
        type: "number",
        required: false,
        value: this.state.data.site_no,
        remark: 'เช่น ครั้งที่ 21 ให้กรอก "21"',
      },
      {
        label: "หมายเหตุสำหรับ ERP",
        name: "erp_remark",
        type: "textbox",
        required: false,
        value: this.state.data.erp_remark,
      },
      {
        label: "ลงทะเบียน Online",
        name: "allow_register_online",
        type: "switch",
        required: false,
        value: this.state.data.allow_register_online,
      },
      {
        label: "ลงทะเบียน Onsite",
        name: "allow_register_onground",
        type: "switch",
        required: false,
        value: this.state.data.allow_register_onground,
      },
      {
        label: "ลงทะเบียน Eary Bird Online",
        name: "allow_register_online_early_bird",
        type: "switch",
        required: false,
        value: this.state.data.allow_register_online_early_bird,
      },
      {
        label: "จำกัดจำนวนคนลงทะเบียน Eary Bird Online",
        name: "online_early_bird_limit",
        type: "number",
        required: false,
        value: this.state.data.online_early_bird_limit,
      },
      {
        label: "ลงทะเบียน Eary Bird Onsite",
        name: "allow_register_onground_early_bird",
        type: "switch",
        required: false,
        value: this.state.data.allow_register_onground_early_bird,
      },
      {
        label: "จำกัดจำนวนคนลงทะเบียน Eary Bird Onsite",
        name: "onground_early_bird_limit",
        type: "number",
        required: false,
        value: this.state.data.onground_early_bird_limit,
      },

      {
        label: "จำกัดจำนวนคนลงทะเบียน Eary Bird Onsite",
        name: "onground_early_bird_limit",
        type: "number",
        required: false,
        value: this.state.data.onground_early_bird_limit,
      },
      {
        label: "ERP Fiscal year code (เช่น 2567)",
        name: "fiscal_year_code",
        type: "textbox",
        required: true,
        value: this.state.data.fiscal_year_code,
      },
      {
        label: "ERP Project code (เช่น 67-32)",
        name: "project_code",
        type: "textbox",
        required: true,
        value: this.state.data.project_code,
      },
      {
        label: "ERP Activity code (เช่น 67-32-01-R)",
        name: "activity_code",
        type: "textbox",
        required: true,
        value: this.state.data.activity_code,
      },

      {
        label: "Active",
        name: "active",
        type: "switch",
        required: false,
        value: this.state.data.active,
      },
    ];

    return fields;
  };

  getCompleteField = (isComplete, label, link) => {
    let a = (
      <a href={link} target="_blank">
        {label}
      </a>
    );
    let field;

    if (isComplete === true)
      field = (
        <li className="text-primary">
          <i className="fa fa-check-circle"></i> {a}
        </li>
      );
    else
      field = (
        <li className="text-danger">
          <i className="fa fa-times-circle"></i> {a}
        </li>
      );

    return field;
  };

  render() {
    const fields = this.getFields().map((field, key) => {
      return (
        <FormInput key={key} field={field} getValue={this.getValue}></FormInput>
      );
    });

    const id = this.props.match.params.id;

    const redirect = this.getRedirect();
    const url = this.props.url;

    const field_name = {
      label: "ชื่องานประชุม",
      name: "name",
      type: "textbox",
      required: true,
      value: this.state.data.name,
    };
    const field_vision = {
      label: "วิสัยทัศน์",
      name: "vision",
      type: "textbox",
      required: true,
      value: this.state.data.vision,
    };
    const field_name_en = {
      label: "ชื่องานประชุม",
      name: "name_en",
      type: "textbox",
      required: false,
      value: this.state.data.name_en,
    };
    const field_vision_en = {
      label: "วิสัยทัศน์",
      name: "vision_en",
      type: "textbox",
      required: false,
      value: this.state.data.vision_en,
    };

    const field_start_end_date_site = (
      <DateRange
        required="true"
        name_start="start_date"
        value_start={this.state.data.start_date}
        value_end={this.state.data.end_date}
        name_end="end_date"
        datepickerCallback={this.getValue}
      ></DateRange>
    );
    const field_start_end_date_meeting = (
      <DateRange
        required="true"
        name_start="meeting_start_date"
        value_start={this.state.data.meeting_start_date}
        value_end={this.state.data.meeting_end_date}
        name_end="meeting_end_date"
        datepickerCallback={this.getValue}
      ></DateRange>
    );
    const field_start_end_date_register = (
      <DateRange
        required="true"
        name_start="start_date_register"
        value_start={this.state.data.start_date_register}
        value_end={this.state.data.end_date_register}
        name_end="end_date_register"
        datepickerCallback={this.getValue}
      ></DateRange>
    );
    const field_start_end_date_early_bird = (
      <DateRange
        required="false"
        name_start="start_date_early_bird"
        value_start={this.state.data.start_date_early_bird}
        value_end={this.state.data.end_date_early_bird}
        name_end="end_date_early_bird"
        datepickerCallback={this.getValue}
      ></DateRange>
    );

    const {
      isCompleteSectionOnline,
      isCompleteAbstractTopic,
      isCompleteAbstractPeriod,
      isCompletePaymentPeriod,
      isCompleteCard,
      isCompleteEmailTemplate,
    } = this.state.complete;

    const sectionOnline_field = this.getCompleteField(
      isCompleteSectionOnline,
      "บริหารจัดการธีมลงทะเบียนออนไลน์",
      "/master/sectiononline"
    );
    const abstractTopic_field = this.getCompleteField(
      isCompleteAbstractTopic,
      "หมวดหมู่การนำเสนอ",
      "/master/abstract_topic"
    );
    const abstractPeriod_field = this.getCompleteField(
      isCompleteAbstractPeriod,
      "ช่วงเวลาส่งผลงาน",
      "/master/abstract_period"
    );
    const paymentPeriod_field = this.getCompleteField(
      isCompletePaymentPeriod,
      "บริหารจัดการค่าเข้างาน",
      "/master/paymentperiod"
    );
    const card_field = this.getCompleteField(
      isCompleteCard,
      "จัดการ Template เอกสาร",
      "/master/card"
    );
    const emailTemplate_field = this.getCompleteField(
      isCompleteEmailTemplate,
      "Email/SMS",
      "/other/emailtemplate"
    );

    let indicator_field;

    if (id !== undefined) {
      indicator_field = (
        <div className="row">
          <div className="col-12">
            <strong>สถานะ: </strong>
            <ul className="list-unstyled">
              {paymentPeriod_field}
              {card_field}
              {emailTemplate_field}
              {abstractTopic_field}
              {abstractPeriod_field}
              {sectionOnline_field}
            </ul>
          </div>
        </div>
      );
    }

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm onSubmit={this.onSubmit} url={url} getValue={this.getValue}>
          <div>
            {indicator_field}
            <div className="tabs-container">
              <ul className="nav nav-tabs" role="tablist">
                <li>
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#tab-1"
                  >
                    TH
                  </a>
                </li>
                <li>
                  <a className="nav-link" data-toggle="tab" href="#tab-2">
                    EN
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div role="tabpanel" id="tab-1" className="tab-pane active">
                  <div className="panel-body">
                    <FormInput
                      getValue={this.getValue}
                      field={field_name}
                    ></FormInput>
                    <FormInput
                      getValue={this.getValue}
                      field={field_vision}
                    ></FormInput>
                  </div>
                </div>
                <div role="tabpanel" id="tab-2" className="tab-pane">
                  <div className="panel-body">
                    <FormInput
                      getValue={this.getValue}
                      field={field_name_en}
                    ></FormInput>
                    <FormInput
                      getValue={this.getValue}
                      field={field_vision_en}
                    ></FormInput>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="col-form-label field-required">
                วันที่เริ่มต้น - วันที่สิ้นสุดของการจัดงาน
              </label>
              {field_start_end_date_site}
            </div>
            <div className="form-group">
              <label className="col-form-label field-required">
                วันที่เริ่มต้น - วันที่สิ้นสุดของการลงทะเบียนเข้าร่วมงาน
              </label>
              {field_start_end_date_register}
              <small className="text-muted">
                ช่วงระยะเวลาที่เปิดให้ลงทะเบียนเข้าร่วมงานผ่านหน้าเว็บไซต์
              </small>
            </div>
            <div className="form-group">
              <label className="col-form-label field-required">
                วันที่เริ่มต้น - วันที่สิ้นสุดของการจัดประชุม
              </label>
              {field_start_end_date_meeting}
              <small className="text-muted">
                ช่วงระยะเวลาที่จัดงานประชุม
                ที่เปิดให้ผู้เข้าร่วมงานลงทะเบียนเข้าไปในงาน
              </small>
            </div>
            <div className="form-group">
              <label className="col-form-label">
                วันที่เริ่มต้น - วันที่สิ้นสุดของ Early Bird
              </label>
              {field_start_end_date_early_bird}
              {/* <small className='text-muted'>ช่วงระยะเวลาที่จัดงานประชุม ที่เปิดให้ผู้เข้าร่วมงานลงทะเบียนเข้าไปในงาน</small> */}
            </div>
            {fields}
          </div>
        </DataForm>
      </BoxContainer>
    );
  }
}

AdminSiteForm.setDefaultProps = {
  url: "",
};

export default AdminSiteForm;
