import axios from "../axios";

const getAuthByToken = ({ token, type }) => {
  const promise = new Promise((resolve, reject) => {
    let url = `auth/authtoken/${type}/${token}`;

    axios
      .get(url)
      .then((res) => {
        resolve(res);
      })
      .catch((res) => {
        reject(res);
      });
  });

  return promise;
};

export default {
  getAuthByToken,
};
