import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import axios from '../../axios';
import { Services } from '../Service/Services';
import swal from 'sweetalert';

class Profile extends Component {
    state = {
    };
    componentWillMount() {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        console.log(user)
        this.setState({
            user: user,
            email: user.email,
            name: user.name
        })
    }
    onSubmit = e => {
        e.preventDefault();
        axios.post('/auth/profile', {
            id: this.state.user.id,
            email: this.state.email,
            name: this.state.name,
        }).then(res => {
            swal({
                title: "Success",
                text: 'บันทึกข้อมูลเสร็จสิ้น',
                icon: "success",
                button: "OK",
            });
            localStorage.setItem('currentUser', JSON.stringify(res.data.user));
        }).catch(error => {
            Services.error(error);
        });
    }
    onChange = (event) => { this.setState({ [event.target.name]: event.target.value }); }

    render() {
        return (
            <form onSubmit={this.onSubmit}>
                <div className="row wrapper wrapper-content animated fadeInRight">
                    <div className='col-12'>
                        <div className='ibox'>
                            <div className='ibox-content'>
                                <div className="form-group">
                                    <label className="col-form-label">ชื่อผู้ใช้:</label> {this.state.user.username}
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="col-form-label">อีเมล:</label> {this.state.email}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="col-form-label">ชื่อ:</label> <input type="text" name="name" className="form-control" onChange={this.onChange} value={this.state.name || ''} />
                                    </div>
                                </div>
                                <div className="hr-line-dashed"></div>
                                <div className="form-group row">
                                    <div className="col-12 text-right">
                                        <button className="btn btn-primary mr-2" type="submit" >บันทึก</button>
                                        <Link className="btn btn-white" to={'/'}>กลับสู่หน้า Dashboard</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}

export default Profile
