import React, { Component } from 'react'
import FormInput from './../../../components/DataForm/FormInput';

class MeetingSectionKeynote extends Component {
    getValue = (target, index) => {
      const new_data = [ ...this.props.data ];
      const name = Object.keys(target)[0];
      const value = Object.values(target)[0];
      new_data[index][name] = value;
  
      this.props.onChange(new_data);
    }
  
    onDelete = index => {
      const new_data = [ ...this.props.data ];
      new_data.splice(index, 1);
  
      this.props.onChange(new_data);
    }
  
    onInsert = () => {
      const new_data = [ ...this.props.data ];
      new_data.push({
          keynote_id: '',
      });
  
      console.log(new_data);
  
      this.props.onChange(new_data);
    }
  
    render() {
      const row = this.props.data.map((item, index) => {
          const field_keynote = { name: 'keynote_id', type: 'ddl', datasource: this.props.datasource, value: item.keynote_id, required: true };
  
          return (
              <tr key={index}>
                  <td><FormInput field={field_keynote} getValue={target => { this.getValue(target, index) }}></FormInput></td>
                  <td className='text-center'>
                    <div className="btn-group">
                      <button onClick={() => { this.onDelete(index) }} type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Trash" title="Trash" className="btn btn-danger btn-sm ml-1"><i className="fa fa-trash"></i> ลบ</button></div>
                  </td>
              </tr>
          )
      });
  
      return (
        <div>
              <button type='button' onClick={this.onInsert} className='btn btn-primary float-right'><i className='fa fa-plus'></i> เพิ่มข้อมูล</button>
              <table className='table'>
                  <thead>
                      <tr>
                          <th className='text-center'>ชื่อ - นามสกุล</th>
                          <th className='text-center'>#</th>
                      </tr>
                  </thead>
                  <tbody>
                      {row}
                  </tbody>
              </table>
        </div>
      )
    }
  }
  
MeetingSectionKeynote.setDefaultProps = {
    datasource: [],
    onChange: {},
    data: [],
}

export default MeetingSectionKeynote
