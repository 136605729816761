import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import FormInput from './FormInput';
import axios from '../../axios';
import { Services } from '../../pages/Service/Services';
class DataForm extends Component {
    state = {
        radio_text: true,
        branch_text: ''
    }
    componentWillMount() {
        // this.setState({ radio_text: this.props.branch });
    }
    getActive = (active) => {
        this.setState({
            active: active
        })
        this.props.getActive(active);
    }
    getValue = (value) => {
        this.setState({
            value
        })
        this.props.getValue(value);
    }
    onSubmit = e => {
        e.preventDefault();
        this.props.onSubmit(e);
    }
    Resetpassword = e => {
        e.preventDefault();
        axios.post('/auth/resetpassword', {
            id: this.props.id
        }).then(res => {
            this.setState({ redirect: Services.response(res) });
        }).catch(error => {
            Services.error(error);
        });
    }
    onChange = event => {
        this.props.getValue({ [event.target.name]: event.target.value })
    }
    onRadioChange = e => {
        if (e.target.value === 'true') {
            this.setState({ radio_text: true });
            this.props.getValue({
                branch: true,
                branch_text: ''
            })

        } else if (e.target.value === 'false') {
            this.setState({ radio_text: false });
            this.props.getValue({
                branch: false,
            })
        }
    }
    render() {
        const fields = this.props.fields.map((field, index) => <FormInput key={index} field={field}
            getActive={this.getActive.bind()}
            getValue={this.getValue.bind()}
            onDelete={this.props.onDelete}
        ></FormInput>);
        var radio = null;
        var radio_text = null;

        if (this.props.radio) {
            radio = this.props.radio.map((field, index) =>
                <div className="form-check form-check-inline" key={index}>
                    <input className="form-check-input" type="radio" name={field.name} id={field.label} value={field.value} onClick={this.onRadioChange} defaultChecked={field.checked} />
                    <label className="form-check-label" htmlFor={field.label}>{field.label}</label>
                </div>
            );
            if (this.props.branch === false) {
                this.state.radio_text = this.props.branch; //cheating
            }
            radio_text =
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-control form-control-sm" type="text" name='branch_text' defaultValue={this.props.branch_text ? this.props.branch_text : this.state.branch_text} onChange={this.onChange} />
                </div>
        }

        var resetpassword = null;
        if (this.props.resetpassword) {
            resetpassword = <button className="btn btn-info mr-2" name="reset_password" onClick={this.Resetpassword}>Reset password</button>
        }

        const url_back = this.props.url;

        var back_button;

        if(url_back !== undefined && url_back !== ''){
            back_button = <Link className="btn btn-white" to={url_back}>ย้อนกลับ</Link>
        }

        var save_button;
        const allow_save = this.props.allow_save;
        if(allow_save !== undefined && allow_save === true){
            save_button = <button className="btn btn-primary mr-2" name='submit' value='submit' type="submit">บันทึก</button>
        }

        let seperate_line;

        if(this.props.hideSeperateLine === false){
            seperate_line = <div className="hr-line-dashed"></div>
        }

        return (
            <form onSubmit={this.onSubmit} noValidate>
                <div className="form-group row">
                    <div className="col-12 text-right">
                        {this.props.buttons}
                        {resetpassword}
                        {save_button}
                        {back_button}
                    </div>
                </div>
                {seperate_line}
                {fields}
                {radio}
                {!this.state.radio_text && radio_text}
                {this.props.children}
                {seperate_line}
                <div className="form-group row">
                    <div className="col-12 text-right">
                        {this.props.buttons}
                        {resetpassword}
                        {save_button}
                        {back_button}
                    </div>
                </div>
            </form>
        )
    }
}

DataForm.defaultProps = {
    fields: [],
    onSubmit: null,
    url: '',
    allow_save: true,
    buttons: null,
    hideSeperateLine: false,
    onDelete: null,
}

export default DataForm
