import React, { useEffect, useReducer, useState } from "react";
import { GuestAPI } from "../../../api";
import { Alert } from "../../Service/Services";
import LoaderContainer from "../../../components/BoxContainer/LoaderContainer";
import axios from "../../../axios";

const initialState = {
  companies: [],
  isLoading: true,
};

const ACTION = {
  RETRIEVE: "retrieve",
  START_LOADING: "start_loading",
  ERROR: "error",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION.RETRIEVE:
      return {
        ...state,
        isLoading: false,
        companies: action.payload,
      };

    case ACTION.START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION.ERROR:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

const EmailToSupervisorBody = ({ companies, onSubmit }) => {
  const onPrintClick = (company) => {
    const url =
      axios.defaults.baseURL + "/report/guestcompanyletter/" + company.key;

    window.open(url, "_blank");
  };

  return (
    <form action="#" onSubmit={onSubmit} disabled={companies.length === 0}>
      <div className="modal-body">
        <div className="accordion" id="accordionCompanyMail">
          {companies.map((company, index) => (
            <div className="card" key={index}>
              <div className="card-header text-left" id={`heading-${index}`}>
                <div className="form-group form-check float-left mb-0">
                  <input
                    type="checkbox"
                    name="selectCompany"
                    id={`selectCompany-${index}`}
                    value={company.key}
                    className="form-check-input"
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`selectCompany-${index}`}
                  >
                    {company.comapny_name}
                  </label>
                  <span className="badge badge-primary mx-2">
                    {company.guests.length} คน
                  </span>
                  <br />
                  <small className="text-muted">
                    <strong>Email to:</strong>{" "}
                    {company.guests
                      .filter((f) => f.company_email)
                      .map((m) => m.company_email)
                      .filter(
                        (value, index, array) => array.indexOf(value) === index
                      )
                      .join(", ")}
                  </small>
                </div>

                <button
                  className="btn btn-link float-right"
                  type="button"
                  data-toggle="collapse"
                  data-target={`#collapse-${index}`}
                  aria-expanded="true"
                  aria-controls={`collapse-${index}`}
                >
                  <i className="fa fa-chevron-down"></i>
                </button>
              </div>

              <div
                id={`collapse-${index}`}
                className="collapse"
                aria-labelledby={`heading-${index}`}
                data-parent="#accordionCompanyMail"
              >
                <div className="card-body text-left">
                  <ol className="">
                    {company.guests.map((guest, guest_index) => (
                      <li className="" key={guest_index}>
                        {guest.name}{" "}
                        {guest.company_email && (
                          <small className="badge badge-info mx-2">
                            {guest.company_email}
                          </small>
                        )}
                      </li>
                    ))}
                  </ol>
                  <button
                    className="btn btn-warning btn-block"
                    type="button"
                    onClick={() => onPrintClick(company)}
                  >
                    <i className="fa fa-print"></i> พิมพ์
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          Close
        </button>
        <button type="submit" className="btn btn-primary">
          <i className="fa fa-print"></i> ส่งอีเมลแจ้งต้นสังกัด
        </button>
      </div>
    </form>
  );
};

export default ({}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const retrieve = () => {
    GuestAPI.getCompanyEmail().then((data) => {
      dispatch({ type: ACTION.RETRIEVE, payload: data });
    });
  };

  useEffect(() => {
    //  load companylists
    retrieve();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    let id_list = [];

    if (state.companies.length > 1) {
      id_list = Array.from(e.target.selectCompany)
        .filter((item) => item.checked)
        .map((item) => item.value);
    } else if (e.target.selectCompany.checked) {
      id_list = [e.target.selectCompany.value];
    }

    if (id_list.length === 0)
      return Alert.error("กรุณาเลือกรายการที่ต้องการส่ง");

    Alert.confirm(
      `คุณแน่ใจไหมที่จะส่งอีเมลต้นสังกัดจำนวน ${id_list.length} ฉบับ`
    ).then(() => {
      dispatch({ type: ACTION.START_LOADING });
      GuestAPI.sendCompanyEmail(id_list)
        .then((data) => {
          Alert.done(`ส่งเสร็จสิ้น ${data.success} รายการ`).then(retrieve);
        })
        .catch((ex) => {
          Alert.error("มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ");
          dispatch({ type: ACTION.ERROR, payload: [] });
        });
    });
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary ml-2"
        data-toggle="modal"
        data-target="#popupEmailToSupervisor"
        onClick={() => {}}
      >
        <i className="fa fa-paper-plane-o"></i>
        &nbsp;ส่งอีเมลต้นสังกัด
      </button>

      <div
        className="modal fade"
        id="popupEmailToSupervisor"
        tabIndex="-1"
        aria-labelledby="popupEmailToSupervisorLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5 className="modal-title" id="popupEmailToSupervisorLabel">
                ส่งอีเมลต้นสังกัด
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <LoaderContainer isLoading={state.isLoading}>
              <EmailToSupervisorBody
                companies={state.companies}
                onSubmit={onSubmit}
              />
            </LoaderContainer>
          </div>
        </div>
      </div>
    </>
  );
};
