import React, { Component } from 'react'
import axios from '../../../axios';
import { Link } from 'react-router-dom';
import { Alert } from '../../Service/Services';

class UserExternalMember extends Component {
  // Set default state
  state = {
    url: {
			print_card: '/memberprintcard',
			print_letter: '/memberprintletter'
		},    
  }

  printInvoice = (index, url_invoice, refresh = false) => {

    window.open(url_invoice, '_blank');

    if(refresh === true) this.reload();
	}

	printCard = (id) => {
		this.setState({ loading: true });

		const url = `${this.state.url.print_card}/${id}`;

		axios.get(url).then( res => {
			let url = res.data;
			window.open(url, '_blank');
			this.setState({ loading: false });
		});
	}

	printLetter = (id) => {
		this.setState({ loading: true });

		const url = `${this.state.url.print_letter}/${id}`;

		axios.get(url, { responseType: 'blob' }).then( res => {
			let response = res.request.response;
			let url = window.URL.createObjectURL(response);
			window.open(url, '_blank');
			this.setState({ loading: false });
		});
	}

	cancelPaymentPopup = (id) => {
		window.location.href = 'member/cancel/' + id;
	}

  onDelete = (id) => {
    Alert.confirm('คุณแน่ใจไหมที่จะทำการลบรายการนี้').then( res => {
      if(res === true){
        axios.delete('member/' + id);
        this.reload();
      }
    });
  }

  reload = () => {
    // reload data
    this.props.onReload();
  }

  getMemberGroupFields = () => {
    const datasource = this.props.datasource.map( (item, index) => {
      const data = item.data;
      let invoice_status_element;
      let row_class_name = [];  
      const is_cancel = data.is_cancel;
  
      if(data.is_printed){
        let class_name = ['btn', 'btn-link'];
        if(is_cancel === true){
          class_name.push('text-danger');
        }else{
          class_name.push('text-primary');
        }

        invoice_status_element = (<button type='button' onClick={ e => this.printInvoice(index, data.url_invoice) } className={class_name.join(' ')}><i className="fa fa-file-pdf-o"> <u>ใบรายการจ่ายเงิน</u></i></button>);
      }else{
        invoice_status_element = (<button type='button' onClick={ e => this.printInvoice(index, data.url_invoice, true) } className="btn btn-link text-danger"><i className="fa fa-print"></i> <u>พิมพ์ใบรายการจ่ายเงิน</u></button>);
      }
  
      let buttons = [];
  
      if(data.is_paid && is_cancel === false){
        const print_report = (
          <div key='3' className="btn-group" role="group">
            <button id="btnGroupDrop1" type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            เอกสาร
            </button>
            <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
              <a href className="dropdown-item" onClick={ e => this.printCard(item.key)}>บัตรเข้าร่วมงาน</a>
              <a href className="dropdown-item" onClick={ e => this.printLetter(item.key)}>จดหมายเข้าร่วมงาน</a>
            </div>
          </div>
        )
  
        buttons.push(print_report);
        // buttons.push(<a key='4'  href className="btn btn-danger" onClick={ e => this.cancelPaymentPopup(item.key)}><i className='fa fa-times'></i> ขอคืนเงินค่าลงทะเบียน</a>)
      }
  
      // buttons.push(<a key='1' className="btn btn-secondary" href={'member/form/' + item.key}><i className="fa fa-pencil"></i></a>);
			// buttons.push(<a key='2' className="btn btn-danger" href onClick={ e => this.onDelete(item.key) }><i className="fa fa-trash"></i></a>);
  
      let payment_status_element;
  
      if(data.payment_status === 'paid'){
        payment_status_element = (<span className='badge badge-primary'>{data.payment_status_text}</span>);
      }else{
        payment_status_element = (<span className='badge badge-danger'>{data.payment_status_text}</span>);
      }
  
      if(is_cancel === true){
        row_class_name.push('text-danger');
  
        payment_status_element = (<span className='badge badge-danger'>ขอคืนเงิน</span>)
  
        buttons.push(<u><a key='5' href className="text-danger" onClick={ e => this.cancelPaymentPopup(item.key)}><i className='fa fa-info'></i> รายละเอียดการขอคืนเงิน</a></u>);
      }
  
      const button_group = (<div className="btn-group" role="group" aria-label="Button group with nested dropdown">{buttons}</div>);
  
      return (
        <tr key={index} className={row_class_name.join(' ')}>
          <td className='text-center'>{data.register_date}</td>
          <td className='text-right'>{data.count}</td>
          <td className='text-center'>{payment_status_element}</td>
          <td className='text-center'>{invoice_status_element}</td>
          <td className='text-center text-white'>
            <div className='mb-2'>
              {button_group}
            </div>
          </td>
        </tr>
      )
    });

    const fields = (
      <table className='table table-stripped'>
          <thead>
              <tr>
                <th className='text-center'>วันที่ลงทะเบียน</th>
                <th className='text-center'>จำนวนสมาชิก</th>
                <th className='text-center'>สถานะ</th>
                <th className='text-center'>การพิมพ์ใบแจ้งหนี้</th>
                <th className='text-center'>#</th>
              </tr>
          </thead>
          <tbody>
            {datasource}
          </tbody>
        </table>
    );

    return fields;
  }

  render() {
    const member_group_fields = this.getMemberGroupFields();

    return (
      <>
        {/* <Link to='/user/external/membergroups/' className='btn btn-success float-right'><i className="fa fa-plus"></i> เพิ่มข้อมูล</Link> */}
        {member_group_fields}
      </>
    );
  }
}

UserExternalMember.setDefaultProps = {
  datasource: [],
  onReload: {},
}

export default UserExternalMember