import React, { Component, Fragment } from 'react'

class LableStatus extends Component {
    render() {
        let status = this.props.status;
        let label = '';
        if (this.props.status) {
            if (status === "A") {
                label = <span className="label label-primary">Active</span>;

            } else if (status === "P") {
                label = <span className="label label-warning">Pending</span>;
            }else{
                label = <span className="label label-danger">Locked</span>;
            }
        }
        return (
            <Fragment>
                {label ? label : null}
            </Fragment>
        )
    }
}

export default LableStatus
