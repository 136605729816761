import React, { Component } from 'react'
import DataForm from './../../../components/DataForm/DataForm';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import { Services } from '../../Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import TableForm from '../../../components/TableForm/TableForm';

class PublishCategoryForm extends Component {
  // Set default state
  state = {
    data:{
      id: null,
      name: '',
      active: true,
      publish_subcategories: [],
    },
    datasource: {

    },
    redirect: false,
    loading: true,
  }

  retrieveData = id => {
    axios.get(`${this.props.url}/${id}`).then(res => {
      const data = res.data;
      const new_data = {
        id: data.id,
        name: data.name,
        active: data.active,
        publish_subcategories: data.publish_subcategories.map( item => {
          return {
            id: item.id,
            name: item.name,
            active: item.active,
          }
        }),
      }

      this.setState({ data: new_data, loading: false });
    });
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;

    if(id !== undefined){
      this.retrieveData(id);
    }else{
      const loading = false;
      this.setState({loading});
    }
  }

  getValue = target => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target
      }
    });
  }

  onSubmit = e => {
    const params = this.state.data;
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    Services.submitRequest({ params, url }).then( res => { this.setState({redirect, loading: false}) }).catch( errors => {this.setState({ errors,loading: false })});
  }

  getRedirect = () => {
    const redirect = this.state.redirect;
    
    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  }

  getFields = () => {
    var fields = [
      { label: 'หมวดหมู่', name: 'name', type: 'textbox', required: true, value: this.state.data.name },
      { label: 'Active', name: 'active', type: 'switch', required: false, value: this.state.data.active },
    ];

    return fields;
  }

  getSubcategoryValue = value => {
    this.setState( state => ({
      data: {
        ...state.data,
        publish_subcategories: value.publish_subcategories,
      }
    }))
  }

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;
    const subcategory_form = {
      controls: [
        { name: 'name', label: 'ชื่อ', default_value: '', type: 'textbox' },
        { name: 'active', label: 'Active', default_value: true, type: 'switch' },
      ],
      label: 'หมวดหมู่ย่อย',
      name: 'publish_subcategories',
      value: this.state.data.publish_subcategories,
      getValue: this.getSubcategoryValue,
    }

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
        >
        <TableForm {...subcategory_form}></TableForm>
        </DataForm>
      </BoxContainer>
    )
  }
}

PublishCategoryForm.setDefaultProps = {
  url: '',
}

export default PublishCategoryForm
