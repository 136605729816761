import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import UserCertificateForm from './UserCertificateForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class UserCertificate extends Component {
    render() {
        const columns = [

        ];

        const api_url = '';
        const datasource = [
            
        ];

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='จัดการ Certificate' program_type='ข้อมูลบัญชีผู้ใช้งาน'></TitleHeader>
                    <Route path={`${this.props.match.path}/`} component={props => (<UserCertificateForm {...props} url={api_url}></UserCertificateForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default UserCertificate
