import React, { Component } from "react";
import axios from "./../../axios";
import FormInput from "./../DataForm/FormInput";
const _ = require("lodash");
const $ = window.$;

class SearchCriteria extends Component {
  constructor(props) {
    super(props);

    this.filter_element = React.createRef();
  }

  state = {
    keyword: "",
    filter: {},
    show_advance_search: false,
  };

  submitForm = (e) => {
    e.preventDefault();

    this.search();

    this.hideAdvanceSearch();
  };

  search = () => {
    var search_params = {
      keyword: this.state.keyword,
      filter: this.state.filter,
    };

    this.props.search(search_params);
  };

  handleChange = (e) => {
    this.keywordChange(e.target.value);
  };

  keywordChange = (value) => {
    this.setState({ keyword: value });
  };

  componentDidMount = (e) => {
    var debounced = _.debounce(this.search, 1000);

    $(this.filter_element.current)
      .autocomplete({
        source: (request, response) => {
          const params = {
            autocomplete: 1,
            keyword: request.term,
          };

          axios.get(this.props.url, { params }).then((res) => {
            const results = res.data;

            response(results);
          });
        },
        change: (e, ui) => {
          if (ui.item) {
            const value = ui.item.value;

            this.keywordChange(value);
          }
        },
        select: (e, ui) => {
          if (ui.item) {
            const value = ui.item.value;

            this.keywordChange(value);
          }
        },
      })
      .off("keyup")
      .on("keyup", debounced);

    $("body").on("keydown", (e) => {
      if (e.which === 27) {
        this.hideAdvanceSearch();
      }
    });
  };

  getValue = (value) => {
    const filter = this.state.filter;

    console.log(filter, value);

    $.extend(true, filter, value);

    this.setState({ filter });
  };

  hideAdvanceSearch = () => {
    this.setState({ show_advance_search: false });
  };

  toggleAdvanceSearch = (e) => {
    this.setState({ show_advance_search: !this.state.show_advance_search });
  };

  removeFilter = (item) => {
    var filter = this.state.filter;
    filter[item.name] = null;

    this.setState({ filter });

    this.search();
  };

  render() {
    const fields = this.props.filter.map((item) => {
      let search_options = {
        range: false,
      };

      if (item.search) {
        search_options = {
          ...search_options,
          ...item.search,
        };
      }

      const field = {
        type: item.search_type,
        name: item.name,
        label: item.label,
        value: this.state.filter[item.name],
        ...search_options,
      };

      return field;
    });

    const advance_search_control = fields.map((field, index) => {
      if (field.range === true) {
        let field_from = { ...field };
        let field_to = { ...field };

        field_from.label = field_from.label + " (จาก)";
        field_from.name = field_from.name + "_from";
        field_to.label = field_to.label + " (ถึง)";
        field_to.name = field_to.name + "_to";

        const from = (
          <FormInput
            field={field_from}
            getValue={this.getValue}
            datepickerCallback={this.getValue}
          ></FormInput>
        );
        const to = (
          <FormInput
            field={field_to}
            getValue={this.getValue}
            datepickerCallback={this.getValue}
          ></FormInput>
        );

        return (
          <React.Fragment key={index}>
            {from}
            {to}
          </React.Fragment>
        );
      } else {
        return (
          <FormInput
            key={index}
            field={field}
            getValue={this.getValue}
            datepickerCallback={this.getValue}
          ></FormInput>
        );
      }
    });

    const filter_by = fields
      .filter(
        (item) =>
          item.value !== undefined && item.value !== "" && item.value !== null
      )
      .map((item, index) => {
        let value = item.value;

        if (item.type === "ddl") {
          const data = item.datasource.find((f) => f.key == item.value);
          if (data) {
            value = data.label;
          }
        }

        return (
          <span className="badge badge-primary mx-1" key={index}>
            {item.label}: {value}
            <span
              className="remove"
              onClick={() => this.removeFilter(item)}
              title="Remove"
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </span>
          </span>
        );
      });

    const advance_search_container_class = [
      "advance-search-container",
      "container",
      "py-3",
    ];

    if (this.state.show_advance_search === true) {
      advance_search_container_class.push("show");
    }

    return (
      <form onSubmit={this.submitForm}>
        <div className="search-criteria-container">
          <div className="input-group mb-0 search-criteria">
            <span className="input-group-prepend">
              <button type="submit" className="btn btn-primary">
                <i className="fa fa-search"></i>
              </button>
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              value={this.state.keyword}
              ref={this.filter_element}
              name="keyword"
              id="keyword"
              onChange={this.handleChange}
            />
            <div className="input-group-append">
              <span
                className="input-group-text"
                onClick={this.toggleAdvanceSearch}
              >
                <div className="toggle-advance-search">
                  <i className="fa fa-caret-down" aria-hidden="true"></i>
                </div>
              </span>
            </div>
          </div>
          <div className="mt-1 filter-by-container">
            <small>Filter by</small> {filter_by}
          </div>
          <div className={advance_search_container_class.join(" ")}>
            {advance_search_control}
            <div className="text-right">
              <button type="reset" className="btn btn-link">
                Reset
              </button>
              <button type="submit" className="ml-2 btn btn-primary">
                Search
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

SearchCriteria.defaultProps = {
  url: "",
  filter: [],
};

export default SearchCriteria;
