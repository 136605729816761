import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import UserExternalForm from './UserExternalForm';
import UserExternalMember from './UserExternalMember';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class UserExternal extends Component {
    render() {
        const columns = [
            { label: 'ชื่อ - นามสกุล', align: 'left', name: 'name', search_type: 'string' },
            { label: 'อีเมล', align: 'left', name: 'email', search_type: 'string' },
            { label: 'โรงพยาบาล', align: 'left', name: 'company_name' },
            { label: 'สถานะ', align: 'center', name: 'status' },
        ];

        const api_url = '/user/external';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ผู้ใช้งานภายนอก' program_type='ข้อมูลบัญชีผู้ใช้งาน'></TitleHeader>
                    
                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable allow_import={false} columns={columns} allow_trash={true} url={api_url}></DataTable>)} />
                    
                    <Route path={`${this.props.match.path}/create`} component={props => (<UserExternalForm {...props} url={api_url}></UserExternalForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<UserExternalForm {...props} url={api_url}></UserExternalForm>)} />
                    <Route path={`${this.props.match.path}/membergroups/:id?`} component={props => (<UserExternalMember {...props}></UserExternalMember>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default UserExternal
