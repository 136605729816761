import React, { Component } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import axios from "../../axios";
import Switcher from "./../Switcher/Switcher";
import { Services } from "../../pages/Service/Services";

var HtmlToReactParser = require("html-to-react").Parser;

const SummaryFooter = ({ columns, data, show_action }) => {
  const convertToNumber = (value) => {
    if (value === undefined) return 0;
    if (typeof value === "number") return value;

    const [number] = value.trim().split(" ");

    const convertedNumber = Services.convertToDecimal(
      number.replaceAll(",", "")
    );

    return convertedNumber;
  };

  const first_sum_field_index = columns.findIndex(
    (f) => f.summaryable === true
  );

  return (
    <tr className="bg-danger">
      <td className="text-right" colSpan={first_sum_field_index + 1}>
        รวม
      </td>
      {columns
        .filter((f, index) => index > first_sum_field_index - 1)
        .map((column, columnIndex) => {
          if (column.summaryable !== true) return <td key={columnIndex}></td>;

          const sumValue = data.reduce((acc, item) => {
            return (
              acc +
              convertToNumber(item.value[columnIndex + first_sum_field_index])
            );
          }, 0);

          return (
            <td key={columnIndex} className="text-right">
              {Services.convertToMoney(sumValue)}
            </td>
          );
        })}
      {show_action === true && <td></td>}
    </tr>
  );
};

class SearchResult extends Component {
  state = {
    order_by: [],
  };

  isReactElement = (element) => {
    return React.isValidElement(element);
  };

  deleteHandler = (del) => {
    swal({
      title: "Delete",
      text: "ต้องการลบข้อมูลนี้หรือไม่",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .delete(this.props.url + "/" + del, { data: { id: del } })
          .then((res) => {
            if (res.data.status) {
              swal({
                title: "Success",
                text: "ข้อมูลถูกลบ",
                icon: "success",
                button: "OK",
              }).then(() => {
                this.props.search();
              });
            } else {
              let error_msg = "ไม่สามารถลบข้อมูลนี้ได้";
              if (res.data.message) {
                error_msg = res.data.message;
              }

              swal({
                title: "Eror",
                text: error_msg,
                icon: "error",
                button: "OK",
              });
            }
          });
      }
    });
  };

  toggleSelection = (e) => {
    let checked = e.target.checked;
    let selected_items = [];
    if (checked) {
      selected_items = this.props.data.map((item) => {
        return item;
      });
    }

    this.props.onSelectItem(selected_items);
  };

  toggleSelectionChild = (e, key, item) => {
    const checked = e.target.checked;
    let selected_items = [...this.props.selected_items];
    const index = selected_items.findIndex((f) => f.key === key);

    if (checked === true && index === -1) {
      selected_items.push(item);
    } else if (checked === false && index !== -1) {
      selected_items.splice(index, 1);
    }

    this.props.onSelectItem(selected_items);
  };

  order_by = (item) => {
    let order_by = [];

    if (
      this.state.order_by.length > 0 &&
      this.state.order_by[0] === item.name
    ) {
      if (this.state.order_by[1] === "desc") {
        order_by = [item.name, "asc"];
      } else {
        order_by = [item.name, "desc"];
      }
    } else {
      order_by = [item.name, "asc"];
    }

    this.setState({ order_by });

    const params = { order_by: order_by.join(",") };

    this.props.search(params);
  };

  SwitchHandler = (value, key) => {
    const url = this.props.url;

    if (url !== "") {
      const update_url = `${url}/${key}`;
      //update active
      const params = {
        flag: "active",
        id: key,
        ...value,
      };

      axios
        .put(update_url, params)
        .then((res) => {
          // do something
          window.toastr.options = {
            closeButton: true,
            debug: false,
            progressBar: true,
            preventDuplicates: false,
            positionClass: "toast-top-right",
            onclick: null,
            showDuration: "400",
            hideDuration: "1000",
            timeOut: "7000",
            extendedTimeOut: "1000",
            showEasing: "swing",
            hideEasing: "linear",
            showMethod: "fadeIn",
            hideMethod: "fadeOut",
          };

          window.toastr.success("ข้อมูลถูกอัพเดทเรียบร้อย", "Update complete!");
        })
        .catch((err) => {
          let error_msg = err.response.data.message;
          if (error_msg === undefined || error_msg === "") {
            error_msg = "ไม่สามารถ Inactive ข้อมูลนี้ได้";
          }

          swal({
            title: "Eror",
            text: error_msg,
            icon: "error",
            button: "OK",
          });
        });
    }
  };

  render() {
    const path = window.location.pathname;

    var col_headers = this.props.columns.map((item, index) => {
      const class_name = ["text-center"];
      const styles = {};
      let sortable = false;

      if (item.sortable === undefined || item.sortable === true) {
        class_name.push("sortable");
        sortable = true;
      }

      if (
        this.state.order_by.length > 0 &&
        this.state.order_by[0] === item.name
      ) {
        class_name.push(this.state.order_by[1]);
      }

      if (item.width) {
        styles.minWidth = item.width;
      }

      return (
        <th
          className={class_name.join(" ")}
          style={styles}
          key={index}
          onClick={() => {
            if (sortable === true) {
              this.order_by(item);
            }
          }}
        >
          {item.label}
        </th>
      );
    });

    let col_header_checkbox;
    if (this.props.allow_checkbox) {
      col_header_checkbox = (
        <th key={-1} className="text-center">
          <div className="checkbox m-r-xs">
            <input
              checked={
                this.props.data.length !== 0 &&
                this.props.selected_items.length === this.props.data.length
              }
              type="checkbox"
              className="checkbox-head"
              onChange={this.toggleSelection}
            />
          </div>
        </th>
      );
    } else {
      col_header_checkbox = (
        <th key={-1} className="text-center">
          #
        </th>
      );
    }

    col_headers.unshift(col_header_checkbox);

    if (this.props.allow_active === true) {
      var col_header_active = (
        <th key={-2} className="text-center">
          # Active
        </th>
      );
      col_headers.push(col_header_active);
    }

    if (this.props.show_action === true) {
      var col_header_action = (
        <th key={-3} className="text-center">
          # Action
        </th>
      );
      col_headers.push(col_header_action);
    }

    var row_data;

    if (this.props.loading) {
      row_data = (
        <tr>
          <td colSpan={col_headers.length} className="text-center">
            Loading ...
          </td>
        </tr>
      );
    } else {
      const { records_per_page, page_number } = this.props.criteria;

      var running = records_per_page * page_number - records_per_page + 1;
      row_data = this.props.data.map((item) => {
        const key = item.key;

        var col_data = item.value.map((sub_item, sub_index) => {
          var align_class = "text-left";
          var align = this.props.columns[sub_index].align;

          if (align === "center") {
            align_class = "text-center";
          } else if (align === "right") {
            align_class = "text-right";
          }

          var class_name = [align_class];
          var reactElement;

          if (this.isReactElement(sub_item) === true) {
            reactElement = sub_item;
          } else {
            var htmlInput = sub_item;
            var htmlToReactParser = new HtmlToReactParser();
            reactElement = htmlToReactParser.parse(htmlInput);

            if (reactElement === undefined) reactElement = sub_item;
          }

          return (
            <td key={sub_index} className={class_name.join(" ")}>
              {reactElement}
            </td>
          );
        });

        var col_data_checkbox = (
          <td key={-3} className="text-center">
            {running++}
          </td>
        );

        if (this.props.allow_checkbox === true) {
          let checked =
            this.props.selected_items.findIndex((f) => f.key === key) >= 0;

          col_data_checkbox = (
            <td key={-3} className="text-center">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={key}
                  checked={checked}
                  onChange={(e) => {
                    this.toggleSelectionChild(e, key, item);
                  }}
                />
              </div>
            </td>
          );
        }

        col_data.unshift(col_data_checkbox);

        if (this.props.allow_active === true) {
          var col_data_active = (
            <td key={-2} className="text-center">
              <Switcher
                name="active"
                value={item.active}
                onChange={(value) => this.SwitchHandler(value, key)}
              ></Switcher>
            </td>
          );
          col_data.push(col_data_active);
        }

        if (this.props.show_action === true) {
          var print_btn;

          if (this.props.allow_print === true) {
            print_btn = (
              <button
                type="button"
                data-toggle="tooltip"
                data-placemeent="top"
                data-original-title="Print"
                title="Print"
                className="btn btn-warning btn-small mr-1"
                onClick={() => this.props.onPrintClick(item)}
              >
                <i className="fa fa-print"></i> พิมพ์
              </button>
            );
          }

          var trash_btn;

          if (this.props.allow_trash === true) {
            trash_btn = (
              <button
                type="button"
                data-toggle="tooltip"
                data-placemeent="top"
                data-original-title="Trash"
                title="Trash"
                className="btn btn-danger btn-small ml-1"
                onClick={() => this.deleteHandler(key)}
              >
                <i className="fa fa-trash"></i> ลบ
              </button>
            );
          }

          var view_btn;
          if (this.props.allow_view === true) {
            view_btn = (
              <span>
                <Link
                  data-toggle="tooltip"
                  data-placemeent="top"
                  data-original-title="View"
                  title="View"
                  to={`${path}/${key}/view`}
                  className="btn btn-info btn-small"
                >
                  <i className="fa fa-eye"></i> เรียกดู
                </Link>
              </span>
            );
          }

          var edit_btn;
          if (this.props.allow_edit === true) {
            edit_btn = (
              <span>
                <Link
                  data-toggle="tooltip"
                  data-placemeent="top"
                  data-original-title="Edit"
                  title="Edit"
                  to={`${path}/${key}/edit`}
                  className="btn btn-secondary btn-small"
                >
                  <i className="fa fa-pencil"></i> แก้ไข
                </Link>
              </span>
            );
          }

          // var preview_btn;
          // preview_btn = (
          //     <button type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Trash" title="Trash" className="btn btn-danger btn-small mr-1" onClick={() => this.deleteHandler(key)}>
          //                                 <i className="fa fa-envelope-o"></i> พรีวิว
          //                             </button>
          // )

          var col_data_action = (
            <td key={-1} className="text-center">
              <div className="btn-group">
                {print_btn}
                {view_btn}
                {edit_btn}
                {trash_btn}
              </div>
            </td>
          );

          col_data.push(col_data_action);
        }

        // col_data.push(
        //     <td key={-4} className='text-center'><div className="mx-auto my-1 dropdown">
        //       <button className="btn btn-small btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        //         <i className='fa fa-cogs'></i>
        //       </button>
        //       <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        //         <a className="dropdown-item" href="#">Action</a>
        //         <a className="dropdown-item" href="#">Another action</a>
        //         <a className="dropdown-item" href="#">Something else here</a>
        //       </div>
        //     </div></td>);

        const class_row = [];

        if (item.active === false) {
          class_row.push("bg-danger text-white");
        }

        return (
          <tr key={key} className={class_row.join(" ")}>
            {col_data}
          </tr>
        );
      });
    }

    return (
      <div className="table-responsive">
        <table className="table table-striped table-bordered table-hover sortable">
          <thead>
            <tr className="table-header">{col_headers}</tr>
          </thead>
          <tbody>{row_data}</tbody>
          <tfoot>
            {this.props.show_summary_footer === true && (
              <SummaryFooter
                columns={this.props.columns}
                data={this.props.data}
                show_action={this.props.show_action}
              />
            )}
            {this.props.show_summary_footer === false && <tr>{col_headers}</tr>}
          </tfoot>
        </table>
      </div>
    );
  }
}

SearchResult.defaultProps = {
  allow_active: false,
  criteria: {},
  allow_print: false,
  onPrintClick: [],
  allow_trash: true,
  allow_view: false,
  allow_edit: true,
  allow_checkbox: false,
  selected_items: [],
  show_action: true,
  show_summary_footer: false,
  onSelectItem: {}, // fn
};

export default SearchResult;
