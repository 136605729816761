import React, { Component } from "react";
import DataForm from "../../../components/DataForm/DataForm";
import FormInput from "../../../components/DataForm/FormInput";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import { Services } from "../../Service/Services";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";

class FrontendGalleryForm extends Component {
  // Set default state
  state = {
    data: {
      name: "",
      asset_file_preview: "",
      asset_file: "",
      asset_file_detail: "",
      gallery_detail_preview: "",
      active: true,
      gallery_detail_delete_id: [],
    },
    redirect: false,
    loading: false,
  };

  retrieveData = (id) => {
    axios.get(`${this.props.url}/${id}`).then((res) => {
      const data = res.data;
      data.gallery_detail_delete_id = [];

      this.setState({ data });
    });
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;

    if (id !== undefined) {
      this.retrieveData(id);
    } else {
      const loading = false;
      this.setState({ loading });
    }
  };

  getValue = (target) => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
  };

  onSubmit = (e) => {
    const params = this.state.data;
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    loading = false;

    Services.submitRequestWithFile({ params, url })
      .then((res) => {
        this.setState({ redirect, loading });
      })
      .catch((errors) => {
        this.setState({ errors, loading });
      });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  };

  onDelete = (name, id) => {
    var data = { ...this.state.data };

    const index = data.gallery_detail_preview.findIndex(
      (item) => item.id === id
    );

    if (index !== -1) {
      data.gallery_detail_preview[index].is_delete = true;
      data.gallery_detail_delete_id.push(id);
    }

    this.setState({ data });
  };

  getFields = () => {
    var fields = [
      {
        label: "ชื่ออัลบั้ม",
        name: "name",
        type: "textbox",
        required: true,
        value: this.state.data.name,
      },
      {
        label: "ภาพหน้าปก",
        name: "asset_file",
        type: "file",
        multiple: false,
        required: true,
        value: this.state.data.asset_file_preview,
        extension: "image",
      },
      {
        label: "ภาพกิจกรรม",
        name: "asset_file_detail[]",
        type: "file",
        multiple: true,
        required: false,
        value: this.state.data.gallery_detail_preview,
        extension: "image",
      },
      {
        label: "Active",
        name: "active",
        type: "switch",
        required: true,
        value: this.state.data.active,
      },
    ];

    return fields;
  };

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
          onDelete={this.onDelete}
        ></DataForm>
      </BoxContainer>
    );
  }
}

FrontendGalleryForm.setDefaultProps = {
  url: "",
};

export default FrontendGalleryForm;
