import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
// import DataTable from '../../../components/DataTable/DataTable';
import FormInput from '../../../components/DataForm/FormInput';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import axios from '../../../axios';
import Pagination from './../../../components/Pagination/Pagination';
import SearchTool from './../../../components/DataTable/SearchTool';
import BoxContainer from './../../../components/BoxContainer/BoxContainer';
import { Alert, Services } from '../../Service/Services';

const $ = window.$;

class MeetingStaffPresentation extends Component {
    state = {
        datasource: [],
        api_url: '/meeting/present',
        loading: true,
        criteria: {
            records_per_page: 10,
            page_number: 1,
        },
        total_pages: 1,
        total_records: 0,
        page_status: false,
    }

    changeRecordPerPage = records_per_page => {
        const params = {
            ...this.state.criteria,
            records_per_page
        }

        this.retrieveData(params);
    }

    changePage = page_number => {
        const params = {
            ...this.state.criteria,
            page_number
        }

        this.retrieveData(params);
    }

    getStartRowRunning = () => {
        let start_running = ((this.state.criteria.page_number - 1) * this.state.criteria.records_per_page);

        return start_running + 1;
    }

    getEndRowRunning = () => {
        let start_row_running = this.getStartRowRunning();
        let end_running = (parseInt(start_row_running) + parseInt(this.state.criteria.records_per_page)) - 1;

        if (this.state.criteria.records_per_page === 0 || end_running > this.state.total_records) return this.state.total_records;
        else return end_running;
    }

    componentDidMount = () => {
        this.retrieveData();
    }

    retrieveData = (params = {}) => {
        const loading = this.state.loading;

        if(loading === false){ 
            this.setState({loading: true});
        }

        const api_url = this.state.api_url;
        var default_params = {
            records_per_page: this.state.criteria.records_per_page,
            page_number: params.page_number ? params.page_number : this.state.criteria.page_number,
        };

        $.extend(true, default_params, params);
        
        axios.get(api_url, { params: default_params }).then(res => {
            const total_records = parseInt(res.headers.total_records);
            const datasource = res.data;

            let total_pages = 1;
            if (default_params.records_per_page === 0 || total_records === 0) {
                total_pages = 1;
            } else {
                total_pages = Math.ceil(total_records / default_params.records_per_page);
            }

            const new_state = {
                datasource,
                loading: false,
                criteria: default_params,
                total_pages,
                total_records,
                page_status: true,
            };

            this.setState(new_state);
        });
    }

    getValue = (target, index) => {
        Alert.confirm("คุณแน่ใจไหมที่จะอัพโหลดไฟล์ทับไฟล์เดิม").then( res => {
            if(res === true){
                this.setState({ loading: true });

                const params = {
                    url: (this.state.api_url),
                    params: { ...target, id: index },
                }

                Services.submitRequestWithFile(params).then( res => {
                    this.retrieveData();
                });
            }else{
                // clear file
            }
        });
    }

    render() {
        const columns = [
            { label: 'วันที่ เวลา', align: 'center', name: 'date_and_time' },
            { label: 'ชื่อห้อง', align: 'left', name: 'room_name' },
            { label: 'หัวข้อ', align: 'left', name: 'section_name' },
            { label: 'วิทยากร', align: 'right', name: 'keynote_speaker_name' },
            { label: 'Present file', align: 'left', name: 'present_file' },
            { label: '#', align: 'center', name: 'publish' },
        ];

        const table_headers = columns.map( (item, key) => {
            return (
                <th className='text-center' key={key}>{item.label}</th>
            );
        });

        const datasource = this.state.datasource.map( (item, key) => {
            const data = item.value;
            const id = item.key;

            return (
                <tr key={key}>
                    <td className='text-center'>{data.date_and_time}</td>
                    <td>{data.room_name}</td>
                    <td>{data.section_name}</td>
                    <td>{data.keynote_speaker_name}</td>
                    <td>{Services.htmlParse(data.present_files)}</td>
                    <td>
                        <FormInput field={{ name:'publish', type:'file', multiple: false }} getValue={ (e) => this.getValue(e, id)}></FormInput>
                    </td>
                </tr>
            )
        });

        console.log(datasource);

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ข้อมูลไฟล์ Presentation ในห้องประชุม' program_type='ห้องประชุม'></TitleHeader>
                    <BoxContainer loading={this.state.loading}>
                        <div className='row'>
                            <div className='col-12'>
                                <SearchTool
                                    changeRecordPerPage={this.changeRecordPerPage}
                                    allow_insert={false}
                                    allow_trash={false}
                                    allow_import={false}
                                ></SearchTool>
                                <table className='table table-striped table-bordered table-hover'>
                                    <thead>
                                        <tr>
                                            {table_headers}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {datasource}
                                    </tbody>
                                </table>
                                <div>
                                    <div className="pull-right">
                                        <Pagination totalPages={this.state.total_pages} onPageChange={this.changePage} currentPage={this.state.criteria.page_number}></Pagination>
                                    </div>
                                    <div className="pull-left">Showing {this.getStartRowRunning()} to {this.getEndRowRunning()} of {this.state.total_records} records</div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </BoxContainer>
                </div>
            </BrowserRouter>
        )
    }
}

export default MeetingStaffPresentation
