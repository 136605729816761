import React, { Component } from "react";
import DataForm from "./../../../components/DataForm/DataForm";
import FormInput from "./../../../components/DataForm/FormInput";
import DateRange from "../../../components/DateRange/daterange";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import { Services } from "../../Service/Services";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";
import UserPromotionCompanies from "./UserPromotionCompanies";
const $ = window.$;

class UserPromotionForm extends Component {
  // Set default state
  state = {
    data: {
      name: "",
      register_amount: "",
      free_amount: "",
      companies: [],
      active: true,
      is_online: "1",
      start_date: "",
      end_date: "",
    },
    datasource: {
      company: [],
      register_type: [
        { key: "1", label: "Online" },
        { key: "0", label: "Onsite" },
      ],
    },
    redirect: false,
    loading: true,
  };

  retrieveData = (id, datasource) => {
    axios.get(`${this.props.url}/${id}`).then((res) => {
      const data = res.data;
      const new_data = {
        id: data.id,
        name: data.name,
        register_amount: data.register_amount,
        free_amount: data.free_amount,
        active: data.active,
        companies: data.promotion_companies.map(
          (promotion_company) => promotion_company.company_id
        ),
        is_online: data.is_online,
        start_date: data.start_date_text,
        end_date: data.end_date_text,
      };

      this.setState({ data: new_data, loading: false, datasource });
    });
  };

  init = () => {
    const promise = new Promise((resolve) => {
      const params = {
        tables: [{ name: "company" }],
      };

      axios.post("datasource", params).then((res) => {
        const company = res.data.company.map((item) => {
          return {
            key: item.id,
            label: item.name,
          };
        });

        const datasource = {
          ...this.state.datasource,
          company: company,
        };

        resolve(datasource);
      });
    });

    return promise;
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;

    this.init().then((datasource) => {
      if (id !== undefined) {
        this.retrieveData(id, datasource);
      } else {
        this.setState({ loading: false, datasource });
      }
    });
  };

  getValue = (target) => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
  };

  onSubmit = (e) => {
    const params = this.state.data;
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    Services.submitRequest({ params, url })
      .then((res) => {
        this.setState({ redirect, loading });
      })
      .catch((errors) => {
        this.setState({ errors, loading });
      });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  };

  getFields = () => {
    const id = this.state.id;

    var fields = [
      {
        label: "ประเภทการลงทะเบียน",
        name: "is_online",
        type: "radio",
        value: this.state.data.is_online,
        datasource: this.state.datasource.register_type,
      },
      {
        label: "ชื่อโปรโมชั่น",
        name: "name",
        type: "text",
        required: true,
        value: this.state.data.name,
      },
      {
        label: "จำนวนที่ลงทะเบียน",
        name: "register_amount",
        type: "number",
        required: true,
        value: this.state.data.register_amount,
      },
      {
        label: "จำนวนที่ฟรี",
        name: "free_amount",
        type: "number",
        required: true,
        value: this.state.data.free_amount,
      },
      {
        label: "Active",
        name: "active",
        type: "switch",
        value: this.state.data.active,
      },
    ];

    return fields;
  };

  getValue = (target) => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
  };

  onUpdateCompany = (newCompanies) => {
    this.setState({ data: { ...this.state.data, companies: newCompanies } });
  };

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          // fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          // getValue={this.getValue}
        >
          <div className="form-group">
            <label className="col-form-label">
              วันที่เริ่มต้น - วันที่สิ้นสุด
            </label>
            <DateRange
              name_start="start_date"
              value_start={this.state.data.start_date}
              value_end={this.state.data.end_date}
              name_end="end_date"
              datepickerCallback={this.getValue}
              required={false}
            ></DateRange>
          </div>
          {fields.map((field) => (
            <FormInput field={field} getValue={this.getValue} />
          ))}
          <UserPromotionCompanies
            companies={this.state.data.companies}
            onUpdate={this.onUpdateCompany}
            datasource={this.state.datasource}
          />
        </DataForm>
      </BoxContainer>
    );
  }
}

UserPromotionForm.setDefaultProps = {
  url: "",
};

export default UserPromotionForm;
