import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "../../axios";
import AbstractScoreSummary from "../Abstract/AbstractScoreSummary/AbstractScoreSummary";
const Chart = require("chart.js");
const $ = window.$;

class Dashboard extends Component {
  state = {
    data: {
      companies: [],
      count_member_site: {
        count_register_site: 0,
        count_payment_site: 0,
      },
      count_member_online_site: {
        count_register_site: 0,
        count_payment_site: 0,
      },
      abstracts: {
        count_abstract: 0,
        count_poster: 0,
      },
      status_abstracts: {
        waiting: 0,
        approve: 0,
        done: 0,
      },
      section_theme: [],
      graph_online: [],
      graph_offline: [],
      count_register_offline_and_online: [],
    },
  };

  componentDidMount() {
    $(document).ready(() => {
      axios.get("dashboard").then((res) => {
        const graph_online = res.data.graph_online;
        var lineDataOnline = {
          labels: graph_online.labels,
          datasets: [
            {
              label: "จำนวนคนลงทะเบียน",
              backgroundColor: "rgba(26,179,148,0.5)",
              borderColor: "rgba(26,179,148,0.7)",
              pointBackgroundColor: "rgba(26,179,148,1)",
              pointBorderColor: "#fff",
              data: graph_online.register,
            },
            {
              label: "จำนวนคนชำระเงิน",
              backgroundColor: "rgba(220,220,220,0.5)",
              borderColor: "rgba(220,220,220,1)",
              pointBackgroundColor: "rgba(220,220,220,1)",
              pointBorderColor: "#fff",
              data: graph_online.paid,
            },
          ],
        };

        const graph_offline = res.data.graph_offline;
        var lineDataOffline = {
          labels: graph_offline.labels,
          datasets: [
            {
              label: "จำนวนคนลงทะเบียน",
              backgroundColor: "rgba(26,179,148,0.5)",
              borderColor: "rgba(26,179,148,0.7)",
              pointBackgroundColor: "rgba(26,179,148,1)",
              pointBorderColor: "#fff",
              data: graph_offline.register,
            },
            {
              label: "จำนวนคนชำระเงิน",
              backgroundColor: "rgba(220,220,220,0.5)",
              borderColor: "rgba(220,220,220,1)",
              pointBackgroundColor: "rgba(220,220,220,1)",
              pointBorderColor: "#fff",
              data: graph_offline.paid,
            },
          ],
        };

        var lineOptions = {
          responsive: true,
        };

        var lineChart = document.getElementById("lineChart");
        if (lineChart !== null) {
          var ctx = lineChart.getContext("2d");
          new Chart(ctx, {
            type: "line",
            data: lineDataOffline,
            options: lineOptions,
          });
        }

        var lineChart2 = document.getElementById("lineChart-2");
        if (lineChart2 !== null) {
          var ctx = lineChart2.getContext("2d");
          new Chart(ctx, {
            type: "line",
            data: lineDataOnline,
            options: lineOptions,
          });
        }

        const pie_color = [
          "#87b7ec",
          "#a403a9",
          "#ae858a",
          "#cd4973",
          "#acce61",
          "#9b129e",
          "#6be9da",
          "#67b68f",
          "#7bc01f",
          "#045c69",
          "#631337",
        ];

        var pie_data = res.data.count_register_offline_and_online.map(
          (item, index) => {
            return {
              label: item.name,
              data: item.count,
              color: pie_color[index],
            };
          }
        );

        var pie_data2 = res.data.section_theme.map((item, index) => {
          return {
            label: item.name,
            data: item.members_count,
            color: pie_color[index],
          };
        });

        if (pie_data && $("#flot-pie-chart").length > 0) {
          $.plot($("#flot-pie-chart"), pie_data, {
            series: {
              pie: {
                show: true,
              },
            },
            grid: {
              hoverable: true,
            },
            tooltip: true,
            tooltipOpts: {
              content: function (label, x, y, serie) {
                const percent = Math.round(serie.datapoint[0]);

                return percent + "% (" + y + "), " + label;
              },
              shifts: {
                x: 20,
                y: 0,
              },
              defaultTheme: false,
            },
            label: {
              show: true,
            },
            legend: {
              position: "ne",
              labelFormatter: function (label, series) {
                var percent = Math.round(series.percent);
                var number = series.data[0][1]; //kinda weird, but this is what it takes
                return (
                  "&nbsp;<b>" +
                  label +
                  "</b>:&nbsp;" +
                  percent +
                  "% (" +
                  number +
                  ")"
                );
              },
            },
          });
        }

        if (pie_data2 && $("#flot-pie-chart-2").length > 0) {
          $.plot($("#flot-pie-chart-2"), pie_data2, {
            series: {
              pie: {
                show: true,
              },
            },
            grid: {
              hoverable: true,
            },
            tooltip: true,
            tooltipOpts: {
              content: function (label, x, y, serie) {
                const percent = Math.round(serie.datapoint[0]);

                return percent + "% (" + y + "), " + label;
              },
              shifts: {
                x: 20,
                y: 0,
              },
              defaultTheme: false,
            },
            label: {
              show: true,
            },
            legend: {
              position: "ne",
              labelFormatter: function (label, series) {
                var percent = Math.round(series.percent);
                var number = series.data[0][1]; //kinda weird, but this is what it takes
                return (
                  "&nbsp;<b>" +
                  label +
                  "</b>:&nbsp;" +
                  percent +
                  "% (" +
                  number +
                  ")"
                );
              },
            },
          });
        }

        this.setState({
          data: res.data,
        });
      });
    });
  }

  render() {
    const companies = this.state.data.companies.map((item, key) => {
      return (
        <tr key={key}>
          <td className="text-center">{key + 1}</td>
          <td>{item.company_name}</td>
          <td className="text-right">{item.members_online_count}</td>
          <td className="text-right">{item.members_offline_count}</td>
          <td className="text-right">{item.members_count}</td>
        </tr>
      );
    });

    const count_register_site =
      this.state.data.count_member_site.count_register_site;
    const count_payment_site =
      this.state.data.count_member_site.count_payment_site;
    const count_online_register_site =
      this.state.data.count_member_online_site.count_register_site;
    const count_online_payment_site =
      this.state.data.count_member_online_site.count_payment_site;
    const count_abstract = this.state.data.abstracts.count_abstract;
    const count_poster = this.state.data.abstracts.count_poster;
    const abstract_status_waiting = this.state.data.status_abstracts.waiting;
    const abstract_status_approve = this.state.data.status_abstracts.approve;
    const abstract_status_done = this.state.data.status_abstracts.done;

    return (
      <div className="">
        <div className="wrapper wrapper-content">
          <div className="row">
            <div
              className="col-sm-3 col-6 text-center"
              style={{ padding: "10px" }}
            >
              <strong>ผลงานวิชาการ</strong>
            </div>
            <div
              className="col-sm-3 col-6 text-center bg-info"
              style={{ padding: "10px" }}
            >
              รอการตรวจสอบ{" "}
              <span className="badge badge-primary">
                {abstract_status_waiting}
              </span>
            </div>
            <div
              className="col-sm-3 col-6 text-center bg-warning"
              style={{ padding: "10px" }}
            >
              กำลังตรวจสอบ{" "}
              <span className="badge badge-primary">
                {abstract_status_approve}
              </span>
            </div>
            <div
              className="col-sm-3 col-6 text-center bg-primary"
              style={{ padding: "10px" }}
            >
              สิ้นสุดการตรวจสอบ{" "}
              <span className="badge badge-primary">
                {abstract_status_done}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <AbstractScoreSummary></AbstractScoreSummary>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-12">
                  <div className="ibox ">
                    <div className="ibox-title">
                      <span className="label label-primary float-right">
                        จำนวน Poster
                      </span>
                      <h5>ผลงานที่ผ่าน</h5>
                    </div>
                    <div className="ibox-content">
                      <div className="row">
                        <div className="col-6">
                          <h1 className="no-margins">{count_abstract}</h1>
                        </div>
                        <div className="col-6 text-center">
                          <h1 className="no-margins">{count_poster}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="ibox ">
                    <div className="ibox-title pr-2">
                      <span className="label label-primary float-right">
                        จ่ายเงินแล้ว
                      </span>
                      <h5>สมาชิกที่ลงทะเบียนออฟไลน์</h5>
                    </div>
                    <div className="ibox-content">
                      <div className="row">
                        <div className="col-6">
                          <h1 className="no-margins">{count_register_site}</h1>
                        </div>
                        <div className="col-6 text-center">
                          <h1 className="no-margins">{count_payment_site}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="ibox ">
                    <div className="ibox-title pr-2">
                      <span className="label label-primary float-right">
                        จ่ายเงินแล้ว
                      </span>
                      <h5>สมาชิกที่ลงทะเบียนออนไลน์</h5>
                    </div>
                    <div className="ibox-content">
                      <div className="row">
                        <div className="col-6">
                          <h1 className="no-margins">
                            {count_online_register_site}
                          </h1>
                        </div>
                        <div className="col-6 text-center">
                          <h1 className="no-margins">
                            {count_online_payment_site}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="ibox ">
                    <div className="ibox-title">
                      <h5>สมาชิกที่ลงทะเบียนออฟไลน์</h5>
                    </div>
                    <div className="ibox-content">
                      <canvas id="lineChart" height="70"></canvas>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="ibox ">
                    <div className="ibox-title">
                      <h5>สมาชิกที่ลงทะเบียนออนไลน์</h5>
                    </div>
                    <div className="ibox-content">
                      <canvas id="lineChart-2" height="70"></canvas>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="ibox ">
                    <div className="ibox-title">
                      <h5>การลงทะเบียนเข้าร่วมงาน</h5>
                    </div>
                    <div className="ibox-content">
                      <div className="flot-chart">
                        <div
                          className="flot-chart-content"
                          id="flot-pie-chart"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="ibox ">
                    <div className="ibox-title">
                      <h5>ลงทะเบียนออนไลน์</h5>
                    </div>
                    <div className="ibox-content">
                      <div className="flot-chart">
                        <div
                          className="flot-chart-content"
                          id="flot-pie-chart-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="ibox ">
                <div className="ibox-title">
                  <h5>รพ. ที่ลงทะเบียนสูงสุด</h5>
                  <div className="ibox-tools">
                    <a className="collapse-link">
                      <i className="fa fa-chevron-up"></i>
                    </a>
                    <a className="close-link">
                      <i className="fa fa-times"></i>
                    </a>
                  </div>
                </div>
                <div className="ibox-content table-responsive">
                  <table className="table table-hover no-margins">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>ชื่อ รพ.</th>
                        <th>Online</th>
                        <th>Onsite</th>
                        <th>จำนวนสมาชิกรวม</th>
                      </tr>
                    </thead>
                    <tbody>{companies}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
