import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import TitleHeader from "../../../components/TitleHeader/TitleHeader";
import axios from "../../../axios";

class ReportQuestionnaire extends Component {
  printReport = (criteria) => {
    const url = axios.defaults.baseURL + "/report/abstract/questionair";

    window.open(url, "_blank");
  };

  render() {
    const columns = [
      {
        label: "หน่วยงาน",
        align: "left",
        name: "company_name",
        sortable: false,
      },
      {
        label: "รหัสผลงาน",
        align: "center",
        name: "abstract_no",
        sortable: false,
      },
      {
        label: "ชื่อผลงาน",
        align: "left",
        name: "abstract_name",
        sortable: false,
      },
      {
        label: "ชื่อ-สกุล เจ้าของผลงาน",
        align: "left",
        name: "owner_name",
        sortable: true,
      },
      {
        label: "เบอร์โทร เจ้าของผลงาน",
        align: "left",
        name: "owner_telephone",
        sortable: true,
      },
      {
        label: "E-mail เจ้าของผลงาน",
        align: "left",
        name: "owner_email",
        sortable: true,
      },
    ];

    const api_url = "/abstract/questionair/";

    const buttons = [
      {
        label: "พิมพ์รายงาน",
        className: "btn btn-primary",
        onClick: this.printReport,
      },
    ];

    return (
      <BrowserRouter>
        <div>
          <TitleHeader
            program_name="แบบสอบถาม"
            program_type="รายงาน"
          ></TitleHeader>

          <Route
            exact
            path={`${this.props.match.path}/`}
            component={() => (
              <DataTable
                show_action={false}
                allow_import={false}
                columns={columns}
                allow_active={false}
                allow_insert={false}
                allow_trash={false}
                allow_edit={false}
                url={api_url}
                buttons={buttons}
              ></DataTable>
            )}
          />
        </div>
      </BrowserRouter>
    );
  }
}

export default ReportQuestionnaire;
