import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import ReviewerForm from "./ReviewerForm";
import ReviewerIndex from "./ReviewerIndex";
import TitleHeader from "../../components/TitleHeader/TitleHeader";

class Reviewer extends Component {
  render() {
    const columns = [
      { label: "รหัสผลงาน", align: "center", name: "abstract_no" },
      {
        label: "หัวข้อ",
        align: "left",
        name: "abstract_topic_name",
        sortable: false,
      },
      { label: "ชื่อผลงาน", align: "left", name: "name" },
      { label: "คะแนน", align: "center", name: "score", sortable: true },
      { label: "เอกสาร", align: "left", name: "asset_file", sortable: false },
    ];

    const api_url = "/reviewer";

    return (
      <BrowserRouter>
        <div>
          <TitleHeader
            program_name="ตรวจบทคัดย่อ"
            program_type=""
          ></TitleHeader>

          <Route
            exact
            path={`${this.props.match.path}/`}
            component={() => <ReviewerIndex columns={columns} url={api_url} />}
          />
          {/* <Route path={`${this.props.match.path}/create`} component={props => (<ReviewerForm {...props} url={api_url}></ReviewerForm>)} /> */}
          <Route
            path={`${this.props.match.path}/:id/:action`}
            component={(props) => (
              <ReviewerForm {...props} url={api_url}></ReviewerForm>
            )}
          />
        </div>
      </BrowserRouter>
    );
  }
}

export default Reviewer;
