import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import MeetingWorkshopForm from './MeetingWorkshopForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class MeetingWorkshop extends Component {
    render() {
        const columns = [
            { label: 'ห้องประชุม', align: 'left', name: 'room_name', search_type: 'string' },
            { label: 'ชื่อ Workshop', align: 'left', name: 'name', search_type: 'string' },
            { label: 'จำนวนที่เปิดรับ', align: 'center', name: 'capacity', sortable: false },
            { label: 'ระยะเวลากิจกรรม', align: 'center', name: 'transaction_date', sortable: false },
        ];

        const api_url = '/meeting/workshop';
        // const datasource = [
        //     { key: 1, active: true, value: ['Room 1', 'Workshop 1', '10 / 100', '10/10/2019 09:00 - 12:00' ] },
        //     { key: 2, active: true, value: ['Room 2', 'Workshop 2', '100 / 100', '11/10/2019 09:00 - 12:00' ] },
        //     { key: 3, active: true, value: ['Room 3', 'Workshop 3', '0 / 100', '12/10/2019 09:00 - 12:00' ] },
        // ];

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='Workshop' program_type='ห้องประชุม'></TitleHeader>
                    
                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable columns={columns} allow_active={true} url={api_url}></DataTable>)} />
                    
                    <Route path={`${this.props.match.path}/create`} component={props => (<MeetingWorkshopForm {...props} url={api_url}></MeetingWorkshopForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<MeetingWorkshopForm {...props} url={api_url}></MeetingWorkshopForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default MeetingWorkshop
