import React, { Component } from "react";
import DataForm from "./../../../components/DataForm/DataForm";
import FormInput from "./../../../components/DataForm//FormInput";
import axios from "../../../axios";
import { Redirect, Link } from "react-router-dom";
import { Services, Alert } from "../../Service/Services";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";
import UserExternalMember from "./UserExternalMember";

class UserExternalForm extends Component {
  // Set default state
  state = {
    data: {
      company_id: "",
      company_text: "",
      telephone: "",
      username: "",
      email: "",
      password: "",
      password_confirmation: "",
      company_address: "",
      company_sub_district: "",
      company_district: "",
      company_province_id: "",
      company_zip_code: "",
      company_tax_identity_no: "",
      name: "",
      active: true,
      status: "",
      member_groups: [],
      allow_hacc: false,
    },
    datasource: {
      province: [],
      company: [],
      career: [],
      prefix_name: [],
    },
    redirect: false,
    loading: true,
  };

  retrieveData = (id, datasource = null) => {
    axios.get(`${this.props.url}/${id}`).then((res) => {
      const data = res.data.entity;
      const member_groups = res.data.member_groups;
      const new_data = {
        id: data.id,
        company_id: data.company_id ?? data.company_text,
        company_text: data.company_text,
        telephone: data.telephone,
        username: data.username,
        email: data.email,
        password: data.password,
        password_confirmation: data.password_confirmation,
        company_address: data.company_address,
        company_sub_district: data.company_sub_district,
        company_district: data.company_district,
        company_province_id: data.company_province_id,
        company_zip_code: data.company_zip_code,
        company_tax_identity_no: data.company_tax_identity_no,
        name: data.name,
        member_groups: member_groups,
        active: data.active,
        status: data.status,
        allow_hacc: data.allow_hacc,
      };

      if (datasource === null) {
        datasource = this.state.datasource;
      }

      this.setState({ loading: false, data: new_data, datasource });
    });
  };

  init = () => {
    const promise = new Promise((resolve) => {
      const params = {
        tables: [
          { name: "province" },
          { name: "company" },
          { name: "career" },
          { name: "prefix_name" },
        ],
      };

      axios.post("datasource", params).then((res) => {
        const prefix_name = res.data.prefix_name.map((item) => {
          return {
            key: item.id,
            label: item.name,
          };
        });

        const career = res.data.career.map((item) => {
          return {
            key: item.id,
            label: item.name,
          };
        });

        const province = res.data.province.map((item) => {
          return {
            key: item.id,
            label: item.name,
          };
        });

        const company = res.data.company.map((item) => {
          return {
            key: item.id,
            label: item.name,
          };
        });

        const datasource = {
          ...this.state.datasource,
          prefix_name,
          career,
          province,
          company,
        };

        resolve(datasource);
      });
    });

    return promise;
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;

    this.init().then((datasource) => {
      if (id !== undefined) {
        this.retrieveData(id, datasource);
      } else {
        this.setState({ loading: false, datasource });
      }
    });
  };

  getValue = (target) => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
  };

  resetPassword = (e) => {
    Alert.confirm("คุณแน่ใจไหมที่จะรีเซ็ตรหัสผ่าน").then((res) => {
      if (res === true) {
        const id = this.props.match.params.id;
        const url = "auth/resetpassword";
        const params = {
          id,
        };

        axios
          .post(url, params)
          .then((res) => Alert.done().then(this.retrieveData(id)));
      }
    });
  };

  unlocked = (e) => {
    Alert.confirm("คุณแน่ใจไหมที่จะปลดล๊อค").then((res) => {
      if (res === true) {
        const id = this.props.match.params.id;
        const url = "auth/unlocked";
        const params = {
          id,
        };

        axios
          .post(url, params)
          .then((res) => Alert.done().then(this.retrieveData(id)));
      }
    });
  };

  resendActiveEmail = (e) => {
    Alert.confirm("คุณแน่ใจไหมที่จะส่ง Active Email อีกครั้ง").then((res) => {
      if (res === true) {
        const id = this.props.match.params.id;
        const url = "auth/resendactiveemail";
        const params = {
          id,
        };

        axios
          .post(url, params)
          .then((res) => Alert.done().then(this.retrieveData(id)));
      }
    });
  };

  onSubmit = (e) => {
    const params = this.state.data;
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    Services.submitRequest({ params, url })
      .then((res) => {
        this.setState({ redirect, loading: false });
      })
      .catch((errors) => {
        this.setState({ errors, loading: false });
      });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  };

  getAccountFields = () => {
    const id = this.props.match.params.id;

    let fields = [
      {
        label: "ชื่อ - นามสกุล",
        name: "name",
        type: "textbox",
        required: true,
        value: this.state.data.name,
      },
      {
        label: "Username",
        name: "username",
        type: "textbox",
        required: true,
        value: this.state.data.username,
      },
      {
        label: "อีเมล",
        name: "email",
        type: "textbox",
        required: true,
        value: this.state.data.email,
      },
      {
        label: "เบอร์โทรศัพท์",
        name: "telephone",
        type: "textbox",
        required: true,
        value: this.state.data.telephone,
      },
      {
        label: "เปิดใช้งานฟังก์ชั่น HACC",
        name: "allow_hacc",
        type: "switch",
        required: true,
        value: this.state.data.allow_hacc,
      },
    ];

    if (id === undefined) {
      fields.push({
        label: "รหัสผ่าน",
        name: "password",
        type: "password",
        required: true,
        value: this.state.data.password,
      });
      fields.push({
        label: "ยืนยันรหัสผ่าน",
        name: "password_confirmation",
        type: "password",
        required: true,
        value: this.state.data.password_confirmation,
      });
    }

    return fields;
  };

  getStatusFields = () => {
    const id = this.props.match.params.id;

    let fields = [];

    if (id !== undefined) {
      const status = this.state.data.status;
      let status_text;

      // eslint-disable-next-line default-case
      switch (status) {
        case "active":
          status_text = '<span class="label label-primary">Active</span>';
          break;

        case "pending":
          status_text = '<span class="label label-warning">Pending</span>';
          break;

        case "locked":
          status_text = '<span class="label label-danger">Locked</span>';
          break;
      }

      fields.push({
        label: "สถานะ",
        name: "status",
        type: "label",
        required: false,
        value: status_text,
      });
    }

    fields.push({
      label: "Active",
      name: "active",
      type: "switch",
      required: false,
      value: this.state.data.active,
    });

    return fields;
  };

  getCompanyFields = () => {
    const { company_id, company_text } = this.state.data;
    const datasource_company = [...this.state.datasource.company];

    let fields = [];

    fields.push({
      label: "ชื่อสถาบัน",
      name: "company_id",
      type: "ddl",
      required: true,
      value: company_id,
      allow_new_item: true,
      datasource: datasource_company,
    });

    const x = datasource_company.filter((f) => f.key == company_id);

    if (
      company_id !== "" &&
      datasource_company.filter((f) => f.key == company_id).length === 0
    ) {
      fields.push({
        label: "หมายเลขประจำตัวผู้เสียภาษี",
        name: "company_tax_identity_no",
        type: "textbox",
        required: true,
        value: this.state.data.company_tax_identity_no,
      });
      fields.push({
        label: "ที่อยู่",
        name: "company_address",
        type: "textbox",
        required: false,
        value: this.state.data.company_address,
      });
      fields.push({
        label: "ตำบล",
        name: "company_sub_district",
        type: "textbox",
        required: false,
        value: this.state.data.company_sub_district,
      });
      fields.push({
        label: "อำเภอ",
        name: "company_district",
        type: "textbox",
        required: false,
        value: this.state.data.company_district,
      });
      fields.push({
        label: "จังหวัด",
        name: "company_province_id",
        type: "ddl",
        required: false,
        value: this.state.data.company_province_id,
        datasource: this.state.datasource.province,
      });
      fields.push({
        label: "รหัสไปรษณีย์",
        name: "company_zip_code",
        type: "textbox",
        required: false,
        value: this.state.data.company_zip_code,
      });
    }

    return fields;
  };

  onReload = () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    this.retrieveData(id);
  };

  getButtons = () => {
    const id = this.props.match.params.id;
    let buttons = [];

    if (id !== undefined) {
      const status = this.state.data.status;

      // eslint-disable-next-line default-case
      switch (status) {
        case "active":
          buttons.push(
            <input
              key={1}
              type="button"
              className="btn btn-warning mr-2"
              value="Reset รหัสผ่าน"
              onClick={this.resetPassword}
            />
          );
          break;

        case "pending":
          buttons.push(
            <input
              key={2}
              type="button"
              className="btn btn-primary mr-2"
              value="ส่ง Active Email ใหม่"
              onClick={this.resendActiveEmail}
            />
          );
          break;

        case "locked":
          buttons.push(
            <input
              key={3}
              type="button"
              className="btn btn-danger mr-2"
              value="ปลดล๊อค"
              onClick={this.unlocked}
            />
          );
          break;
      }
    }

    return buttons;
  };

  render() {
    const redirect = this.getRedirect();
    const url = this.props.url;

    const comapny_fields = this.getCompanyFields().map((field, key) => {
      return (
        <FormInput field={field} key={key} getValue={this.getValue}></FormInput>
      );
    });

    const account_fields = this.getAccountFields().map((field, key) => {
      return (
        <FormInput field={field} key={key} getValue={this.getValue}></FormInput>
      );
    });

    const status_fields = this.getStatusFields().map((field, key) => {
      return (
        <FormInput field={field} key={key} getValue={this.getValue}></FormInput>
      );
    });

    const buttons = this.getButtons();

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
          buttons={buttons}
        >
          <div>
            <h3 className="alert alert-primary">ข้อมูลสถาบัน</h3>
            {comapny_fields}
            <h3 className="alert alert-primary">ข้อมูลบัญชี</h3>
            {account_fields}
            <div className="hr-line-dashed"></div>
            <h3 className="alert alert-primary">ข้อมูลผู้เข้าร่วมงาน</h3>
            <UserExternalMember
              datasource={this.state.data.member_groups}
              onReload={this.onReload}
            />
            <div className="hr-line-dashed"></div>
            <h3 className="alert alert-primary">สถานะ</h3>
            {status_fields}
          </div>
        </DataForm>
      </BoxContainer>
    );
  }
}

UserExternalForm.setDefaultProps = {
  url: "",
};

export default UserExternalForm;
