import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import MeetingRoomForm from './MeetingRoomForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class MeetingRoom extends Component {
    render() {
        const columns = [
            { label: 'ชื่อห้อง', align: 'left', name: 'name', search_type: 'string' },
            { label: 'ชื่อธีมห้อง', align: 'left', name: 'theme_name', search_type: 'string' },
            { label: 'จำนวนสูงสุด', align: 'right', name: 'amount', search_type: 'string' },
        ];

        const api_url = '/meeting/room';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ห้องประชุม' program_type='ห้องประชุม'></TitleHeader>
                    
                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable columns={columns} allow_active={true} url={api_url}></DataTable>)} />
                    
                    <Route path={`${this.props.match.path}/create`} component={props => (<MeetingRoomForm {...props} url={api_url}></MeetingRoomForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<MeetingRoomForm {...props} url={api_url}></MeetingRoomForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default MeetingRoom
