import axios from "../axios";

const sendEmailTest = (code, email) => {
  const promise = new Promise((resolve, reject) => {
    const url = `email/sendtest/${code}/${email}`;

    axios
      .get(url)
      .then((res) => {
        resolve(res);
      })
      .catch((res) => {
        reject(res);
      });
  });

  return promise;
};

export default {
  sendEmailTest,
};
