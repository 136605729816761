import React, { Component } from "react";
import MemberGroupERPForm from "./MemberGroupERPForm";
import TitleHeader from "../../../components/TitleHeader/TitleHeader";
import MemberGRoupERPIndex from "./MemberGroupERPIndex";
import { BrowserRouter, Route } from "react-router-dom";

class MemberGroupERP extends Component {
  render() {
    const api_url = "/admin/membergrouperp";

    return (
      <BrowserRouter>
        <div>
          <TitleHeader
            program_name="ระบบการรับเงิน"
            program_type="ข้อมูลบัญชีผู้ใช้งาน"
          ></TitleHeader>

          <Route
            exact
            path={`${this.props.match.path}/`}
            component={() => <MemberGRoupERPIndex url={api_url} />}
          />

          <Route
            path={`${this.props.match.path}/:id/edit`}
            component={(props) => (
              <MemberGroupERPForm {...props} url={api_url}></MemberGroupERPForm>
            )}
          />
        </div>
      </BrowserRouter>
    );
  }
}

export default MemberGroupERP;
